import React, { useState, useEffect, useContext } from "react"

import { Row, Col } from "@bootstrap-styled/v4"
import styled from "styled-components"

import Container from "components/common/Container"
import api from "services/api"

import {
  GET_CATEGORIES_ENDPOINT,
  GET_FORMS_ENDPOINT,
  GET_PUBLISHED_FORMS_ENDPOINT,
  ORDER_FILTER_LABEL_PARAM,
  ORDER_FILTER_TITLE_PARAM,
} from "constants/endpoints"

import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import { TextField } from "@material-ui/core"
import { CreateFormContext } from "contexts/CreateFormContext"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const InputFont = styled.div`
  font-size: 13px;
`
const TitleFont = styled.div`
  color: #24445c;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
`

const CustomContainer = styled(Container)`
  padding: 30px;
  .MuiTextField-root {
    margin-top: 5px;
  }
`
const CustomFormControl = styled(FormControl)`
  min-height: 32px !important;
`

const FormInformations = ({ className, ...props }) => {
  const {
    title,
    setTitle,
    isBackupAllowed,
    setIsBackupAllowed,
    abreviation,
    setAbreviation,
    category,
    setCategory,
    previousForm,
    setPreviousForm,
    weight,
    setWeight,
    titleInputLabel,
    setTitleInputLabel,
    allFormsOfTheCategoryAndTheirNewWeight,
    setAllFormsOfTheCategoryAndTheirNewWeight,
  } = useContext(CreateFormContext)

  const [categories, setCategories] = useState(null)
  const [positions, setPositions] = useState([])
  const [forms, setForms] = useState([])
  const [categoriesItems, setCategoriesItems] = useState([])
  
  useEffect(() => {
    api
      .get(`${GET_CATEGORIES_ENDPOINT}?${ORDER_FILTER_LABEL_PARAM}`)
      .then((data) => {
        if (data) {
          setCategories(data["hydra:member"])
          return data["hydra:member"]
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])

  useEffect(() => {
    api
      .get(`${GET_PUBLISHED_FORMS_ENDPOINT}&${ORDER_FILTER_TITLE_PARAM}&pagination=false`)
      .then((data) => {
        if (data) {
          const forms = data["hydra:member"]
          const totalNumberOfItems = data["hydra:totalItems"]
          setForms(forms)
          return
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])

  useEffect(() => {
    if (categories) {
      categories.map((category) => {
        categoriesItems.push(
          <MenuItem value={category["@id"]} key={category.id}>
            {category.label}
          </MenuItem>
        )
      })
      setCategoriesItems([...categoriesItems])
    }
  }, [categories])

  useEffect(() => {
    if (category != null) {
      positions.splice(0, positions.length)
      setPositions(...positions)

      api
        .get(GET_FORMS_ENDPOINT + `?category=${category}`)
        .then((data) => {
          const nbOfForms = data["hydra:totalItems"]
          // on stocke tous les forms de la catégories car leur weight va être modifié possiblement dû à
          // la création du nouveau form qui va avoir un certain weight. Il faudra donc tous les redéfinir
          setAllFormsOfTheCategoryAndTheirNewWeight([...data["hydra:member"]])
          for (let i = 1; i <= nbOfForms + 1; i++) {
            positions.push(
              <MenuItem value={i} key={"infos" + i}>
                <InputFont>{i}</InputFont>
              </MenuItem>
            )
          }
          setPositions(positions)
        })
        .catch((e) => alert("erreur : " + e.message))
    }
  }, category)

  const handleFormNameChange = (event) => {
    setTitle(event.target.value || "")
  }

  const handleAbbreviationChange = (event) => {
    setAbreviation(event.target.value || "")
  }

  const handleCategoryChange = (event) => {
    setCategory(event.target.value || "")
  }

  const handlePositionChange = (event) => {
    setWeight(event.target.value)
    const weightOfTheFormToCreate = event.target.value - 1
    allFormsOfTheCategoryAndTheirNewWeight.map((form, index) => {
      form.newWeight = index
    })
  }

  const handlePreviousFormChange = (event) => {
    setPreviousForm(event.target.value)
  }

  const handleFormInputLabelChange = (event) => {
    setTitleInputLabel(event.target.value)
  }

  const handleIsBackupAllowedChange = (e) => {
    setIsBackupAllowed(!isBackupAllowed)
  }

  return (
    <CustomContainer className={className}>
      <h3>
        <TitleFont>Informations du formulaire</TitleFont>
      </h3>
      <Row className="justify-content-center align-items-end">
        <Col xs="4">
          <FormControl fullWidth={true} margin="none">
            <TextField
              margin="none"
              id={`label`}
              label={"Libellé du formulaire"}
              value={title}
              onChange={handleFormNameChange}
              hiddenLabel={true}
              InputProps={{ style: { minHeight: "32px", fontSize: "13px", lineHeight: "0px" } }}
            />
          </FormControl>
        </Col>
        <Col xs="4">
          <CustomFormControl fullWidth={true}>
            <InputLabel id="category-select-label" shrink={category ? true : false}>
              Catégorie du formulaire
            </InputLabel>
            <Select
              labelId="category-select-label"
              value={category}
              onChange={handleCategoryChange}
              margin="dense"
              style={{ minHeight: "32px", fontSize: "13px" }}
            >
              {categoriesItems}
            </Select>
          </CustomFormControl>
        </Col>
        <Col xs="4">
          <FormControl fullWidth={true} margin="none">
            <TextField
              margin="none"
              id={`inputLabel`}
              label={"Abréviation du formulaire"}
              value={abreviation}
              onChange={handleAbbreviationChange}
              hiddenLabel={true}
              InputProps={{ style: { minHeight: "32px", fontSize: "13px", lineHeight: "0px" } }}
            />
          </FormControl>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-end">
        <Col xs="4">
          <FormControl componentClass="CustomFormControl" fullWidth={true}>
            <InputLabel id="position-select-label" shrink={weight ? true : false}>
              Position du formulaire
            </InputLabel>
            <Select
              labelId="position-select-label"
              value={weight}
              onChange={handlePositionChange}
              margin="dense"
              label="test"
              style={{ minHeight: "32px", fontSize: "13px" }}
            >
              {positions}
            </Select>
          </FormControl>
        </Col>
        <Col xs="4">
          <FormControl fullWidth={true} margin="none">
            <TextField
              margin="none"
              id={`label`}
              label={"Label du titre du formulaire"}
              value={titleInputLabel ? titleInputLabel : ""}
              onChange={handleFormInputLabelChange}
              hiddenLabel={true}
              InputProps={{ style: { minHeight: "32px", fontSize: "13px", lineHeight: "0px" } }}
            />
          </FormControl>
        </Col>
        <Col xs="4">
          <FormControl componentClass="CustomFormControl" fullWidth={true}>
            <InputLabel id="previous-select-label">Formulaire précédent</InputLabel>
            <Select
              value={previousForm}
              onChange={handlePreviousFormChange}
              margin="dense"
              label="Formulaire précédent"
              style={{ minHeight: "32px", fontSize: "13px" }}
            >
              <MenuItem value="">
                <InputFont>Choisissez un formulaire</InputFont>
              </MenuItem>
              {forms.map((form) => {
                return (
                  <MenuItem value={form["@id"]} key={form["@id"]}>
                    <InputFont>{form.title}</InputFont>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row className="justify-content-left">
      <Col xs="12">
      <FormControlLabel
            control={
              <Checkbox
                checked={isBackupAllowed}
                onChange={handleIsBackupAllowedChange}
                name={"isBackupAllowed"}
                color="primary"
              />
            }
            label={"Autoriser les remplaçants pour ce type de formulaire"}
          />

      </Col>
      </Row>
    </CustomContainer>
  )
}

export default styled(FormInformations)`
  margin: 30px 70px 0;
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
    text-align: center;
  }
  .MuiTextField-root {
    margin-top: 5px;
  }
`
