import { useHistory } from "react-router-dom"
import DemandeCreationContext from "contexts/DemandeCreationContext"
import React, { useContext } from "react"
import styled from "styled-components"

const CancelLink = ({
  onClick,
  className,
  children,
  viewDemand,
  isView,
  setSearchValues,
  ...props
}) => {
  const { setCategory, setTitle, setValues, setForm, setValideurs, setBu } = useContext(
    DemandeCreationContext
  )
  let history = useHistory()

  const handleClick = () => {
    setValues([])
    setCategory(null)
    setForm(null)
    setTitle("")
    setBu("")
    setValideurs([])
    history.push("/")
  }

  if (!children) {
    children = "Annuler"
  }

  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  )
}

export default styled(CancelLink)`
  cursor: pointer;
`
