import React, { useState } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import SquareButton from "components/common/SquareButton"
import Colors from "theme/Colors"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"

import { Row, Col } from "@bootstrap-styled/v4"
import Plus from "components/icons/Plus"
import ProfileFields from "components/adminCreateForm/settingWorkFlow/variableFields/ProfileFields"
import PosteFields from "components/adminCreateForm/settingWorkFlow/variableFields/PosteFields"
import LineManagerFields from "components/adminCreateForm/settingWorkFlow/variableFields/LineManagerFields"
import { steps } from "constants/demande"
import { list } from "postcss"

const CustomRow = styled(Row)`
  margin-bottom: 5px;
`

const CustomSelect = styled(Select)`
  @media screen and (max-width: 1200px) {
    margin: 0 0 10px 0px !important;
  }
`

const CustomFormControl = styled(FormControl)`
  min-width: 200px !important;
  width: 100%;
`

const ButtonContainer = styled.div`
  padding: 15px 15px;
  margin: 10px 15px 0 20px;
  @media screen and (max-width: 1200px) {
    margin: 10px 15px 0 0;
  }
`

const PlusIcon = styled(Plus)`
  font-size: 20px;
  @media screen and (max-width: 1200px) {
    font-size: 15px;
    padding: 2px;
  }
`

const CustomInputLabel = styled(InputLabel)`
  background-color: ${Colors.white};
`

const AddDocsButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`

const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 56px;

  @media screen and (max-width: 1200px) {
    text-align: center;
    padding: 10px;
  }
`

const AddValidatorButton = styled(SquareButton)`
  min-height: 56px;
  max-height: 56px;
  max-width: 200px;
  min-width: 140px;
  margin-top: 5px;

  @media screen and (max-width: 1200px) {
    margin: 10px 0 0 0 !important;
  }
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const Input = styled.input`

  height: 56px;
  padding: 10px 20px;
  font-family: Helvetica, sans - serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 100%;
   @media screen and (max-width: 1200px) {

    margin: 0 0 10px 0px !important;
  }
}
`

const adaptedFields = {
  responsable_hierarchique: LineManagerFields,
  profile: ProfileFields,
  poste: PosteFields,
}

const AddValidatorAdmin = ({ className, addValidator, maxPosition, ...props }) => {
  const [validator, setValidator] = useState({ type: "profile" })
  const [postesList, setPostesList] = useState([])
  const [adaptedFormFilled, setAdaptedFormFilled] = useState(false)

  const getListOfAvailablePostes = (liste) => {
    setPostesList(liste)
  }

  const handleValidatorEdit = () =>
    setValidator({
      type: "read_only",
      validated: false,
    })

  const handlePositionChange = (event) => {
    validator.weight = event.target.value
    setValidator({ ...validator })
  }

  const handleLabelChange = (event) => {
    validator.label = event.target.value || ""
    setValidator({ ...validator })
  }

  const handleCreateValidator = () => {
    addValidator(validator)
    setValidator(null)
  }

  const handleStepChange = (event) => {
    validator.type = event.target.value
    setValidator({ ...validator })
  }

  const isAdaptedFieldsFilled = (fillState) => {
    setAdaptedFormFilled(fillState)
  }

  if (validator) {
    const positions = []
    for (let i = 1; i <= maxPosition; i++) {
      positions.push(
        <MenuItem value={i} key={"validator" + i}>
          VALIDEUR {i}
        </MenuItem>
      )
    }
    const stepsItems = []
    for (let i = 0; i < steps.length; i++) {
      stepsItems.push(
        <MenuItem value={steps[i].value} key={"step" + i}>
          {steps[i].label}
        </MenuItem>
      )
    }
    const AdaptedFields = adaptedFields[validator.type]

    return (
      <Container className={className}>
        <CustomRow className="justify-content-start">
          <Col xs="12" xl="4">
            <CustomFormControl variant="outlined">
              <CustomInputLabel htmlFor={`elment-position`}>Position</CustomInputLabel>
              <CustomSelect
                value={validator && validator.weight != null ? validator.weight : ""}
                onChange={handlePositionChange}
                inputProps={{
                  name: `elment-position`,
                  className,
                }}
              >
                <MenuItem value={null}>Choisissez une valeur</MenuItem>
                {positions}
              </CustomSelect>
            </CustomFormControl>
          </Col>
          <Col xs="12" xl="4">
            <CustomFormControl variant="outlined">
              <CustomInputLabel htmlFor={`step`}>Type d'intervenant</CustomInputLabel>
              <CustomSelect
                value={validator && validator.type ? validator.type : "profile"}
                onChange={handleStepChange}
                inputProps={{
                  name: `step`,
                  className,
                }}
              >
                {stepsItems}
              </CustomSelect>
            </CustomFormControl>
          </Col>
          <Col xs="12" xl="3">
            <Input
              className=""
              type="text"
              onChange={handleLabelChange}
              value={validator.label}
              placeholder="Label"
            />
          </Col>
        </CustomRow>
        <CustomRow className="justify-content-start">
          {AdaptedFields && (
            <AdaptedFields
              postes={postesList}
              sendListOfAvalaiblePostes={getListOfAvailablePostes}
              isFilled={isAdaptedFieldsFilled}
              validator={validator}
            />
          )}

          {adaptedFormFilled && validator.weight != null && validator.label && (
            <Col xs="12" xl="auto">
              <AddValidatorButton noGutters={true} onClick={handleCreateValidator}>
                Ajouter
              </AddValidatorButton>
            </Col>
          )}
        </CustomRow>
      </Container>
    )
  } else {
    return (
      <ButtonContainer>
        <AddDocsButtonContainer>
          <AddDocsButton onClick={handleValidatorEdit}>
            <PlusIcon />
            <Spacer />
            Ajouter un intervenant
          </AddDocsButton>
        </AddDocsButtonContainer>
      </ButtonContainer>
    )
  }
}

export default styled(AddValidatorAdmin)`
  padding: 20px;
  margin: 20px;
  border: 1px solid #2656c8;
`
