import ReactTooltip from "react-tooltip"
import React, { useState } from "react"

import styled from "styled-components"
import Colors from "theme/Colors"

const CustomTip = styled(ReactTooltip)`
  background-color: ${Colors.primary}!important;
  color: white !important;
  border-radius: 8px !important;
  &.place-top {
    &:after {
      border-top-color: ${Colors.primary} !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: ${Colors.primary} !important;
    }
  }
  font-size: 10px !important;
  padding: 8px !important;
`

export default CustomTip
