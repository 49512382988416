import React, { useState, useContext } from "react"
import styled from "styled-components"

import { Row } from "@bootstrap-styled/v4"

import ElContainer from "components/common/Container"
import Image from "components/common/Image"

import Images from "theme/Images"
import Colors from "theme/Colors"
//import Colors from "theme/Colors"

const Logo = styled(Image)`
  width: 260px;
  margin-bottom: 36px;
`
const DisconnectedRowContainer = styled(Row)`
  min-height: 100vh;
`

const DisconnectedContainer = styled(ElContainer)`
  max-width: 600px;
  width: 100%;
  padding: 50px !important;
`

const TitleText = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 25px;
  font-weight: bold;
  text-align: center;
`

const Disconnected = () => {
  return (
    <DisconnectedRowContainer className={"align-items-center justify-content-center"}>
      <DisconnectedContainer>
        <Row className={"align-items-center justify-content-center"}>
          <Logo src={Images.logo_groupe} />
          <TitleText>Vous avez bien été déconnecté </TitleText>
        </Row>
      </DisconnectedContainer>
    </DisconnectedRowContainer>
  )
}

export default Disconnected
