import React from "react"

const SvgNouveauFormulaire = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 55 56" {...props}>
    <g transform="translate(-24 -562)" fillRule="nonzero" fill="none">
      <path
        d="M57 579.533c0-.623-.242-1.21-.681-1.65l-2.193-2.2a2.374 2.374 0 00-1.66-.683h-.133v4.667H57v-.134z"
        fill="#FFF"
      />
      <path
        d="M51.051 590.719c.147-.443.404-.855.74-1.189L57 584.393v-3.3h-5.406a.744.744 0 01-.745-.761V575H39.31c-1.274 0-2.309 1.025-2.309 2.285v21.43c0 1.26 1.035 2.285 2.308 2.285h15.363c1.273 0 2.329-1.025 2.329-2.285v-5.685l-.846.817c-.342.336-.743.58-1.196.73l-2.446.809-.373.02c-.616 0-1.199-.297-1.561-.794a1.894 1.894 0 01-.262-1.715l.735-2.178z"
        fill="#FFF"
      />
      <path
        d="M52.398 591l-.71 2.13a.408.408 0 00.517.515l2.128-.71L52.398 591zM58.124 585L53 590.124l2.21 2.21 5.123-5.125-2.21-2.209zM62.518 582.792c-.288-.292-.712-.459-1.162-.459-.45 0-.875.167-1.165.46L59 583.99l2.328 2.342c.653-.656 1.103-1.11 1.192-1.198.64-.646.64-1.697-.002-2.343z"
        fill="#FFF"
      />
      <g fill="#000">
        <path d="M62.94 585.448l.002-.001.043-.043a1.817 1.817 0 01-.044.044zM62.985 585.404l.066-.067-.066.067zM63.051 585.337c.049-.048.05-.049 0 0z" />
      </g>
    </g>
  </svg>
)

export default SvgNouveauFormulaire
