import React, { useState, useEffect } from "react"

import styled from "styled-components"

import ElementContainer from "components/form/element/ElementContainer"
import SquareButton from "components/common/SquareButton"

import Colors from "theme/Colors"
import TableLine from "components/form/element/TableLine"
import FormLabel from "@material-ui/core/FormLabel"
import { Row, Col } from "@bootstrap-styled/v4"

const Bold = styled.span`
  font-weight: bold;
`

const ContentContainer = styled.div`
  ${(props) => `min-width: ${props.minWidth};`}
`

const StyledElementContainer = styled(ElementContainer)`
  overflow-x: auto;
`

const calculateTotal = (lines) => {
  const total = {}
  if (!lines || !lines.length) {
    return total
  }
  lines.map((line) => {
    const responses = line.responses
    responses.map((response) => {
      if (response.type === "sum_response") {
        if (total[response.question] == null) {
          total[response.question] = 0
        }
        total[response.question] += response.value
      }
    })
  })
  return total
}

const Table = ({ className, element, editable, onChange, value, ...props }) => {
  const [lines, setLines] = useState(
    value
      ? value.responseLines
      : [{ type: "response_line", responses: [], question: element["@id"] }]
  )
  const [sums, setSums] = useState(
    value && value.responseLines ? calculateTotal(value.responseLines) : null
  )

  useEffect(() => {
    if (value && value.responseLines) {
      return
    }
    const allLines = []
    for (let i = 0; i < element.linesLabel.length; i++) {
      allLines[i] = { type: "response_line", responses: [], question: element["@id"] }
    }
    setLines([...allLines])
  }, [])

  const handleChange = (line, position) => {
    if (!editable) {
      return
    }
    lines[position] = line
    onChange({
      ...value,
      type: "response_table",
      responseLines: lines,
      question: element["@id"],
    })
    // setTotal(calculateTotal(lines))
    setLines([...lines])
  }

  const calculateSum = () => {
    setSums({ ...calculateTotal(lines) })
  }

  return (
    <StyledElementContainer className="investment" width={element.width}>
      <FormLabel>{element.title}</FormLabel>
      <ContentContainer className="content-container" minWidth={`${element.minWidth}px`}>
        <div>
          {lines.map((item, index) => {
            return (
              <TableLine
                labelColumnWidth={element.labelColumnWidth}
                lineLabel={element.linesLabel && element.linesLabel[index]}
                questions={element.questions}
                editable={editable}
                onChange={handleChange}
                key={index}
                line={index}
                lineAnswers={item}
                tableMinWidth={element.minWidth}
                sumCallBack={calculateSum}
              />
            )
          })}
          {sums && (
            <Row className="justify-content-start align-items-center" noGutters={true}>
              <ElementContainer width={element.labelColumnWidth} noResponsive />

              {element.questions.map((question, index) => {
                return (
                  <ElementContainer
                    key={index}
                    width={question.width}
                    // minWidth={minWidth}
                    noResponsive={true}
                  >
                    <Bold>{sums[question["@id"]] ? `Total : ${sums[question["@id"]]}` : ""}</Bold>
                  </ElementContainer>
                )
              })}
            </Row>
          )}
        </div>
      </ContentContainer>
    </StyledElementContainer>
  )
}

export default styled(Table)`
background - color: transparent;
min - height: 32px;

color: ${Colors.black};
font - size: 13px;
  &.MuiInputBase - root {
    font - family: "Open Sans";
}
`
