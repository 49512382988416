import React from "react"

const SvgNouvelleDemande = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 56 60" {...props}>
    <g transform="translate(-24 -290)" fill="#FFF" fillRule="nonzero">
      <path d="M55.33 306.74V303H39.82c-1.383 0-2.488 1.143-2.488 2.527v24.733a2.48 2.48 0 002.488 2.493h20.207a2.483 2.483 0 002.491-2.493v-20.025h-3.704c-1.917 0-3.486-1.579-3.486-3.495zm-.887 13.094h-2.595v2.586c0 1.071-.85 1.94-1.921 1.94a1.925 1.925 0 01-1.922-1.94v-2.586h-2.597a1.944 1.944 0 01-1.944-1.94c0-1.071.876-1.94 1.947-1.94h2.594v-2.586c0-1.071.85-1.94 1.922-1.94 1.07 0 1.921.869 1.921 1.94v2.587h2.595a1.94 1.94 0 010 3.879z" />
      <path d="M57.273 303.022v3.718c0 .847.695 1.551 1.542 1.551h3.703v-.035l-5.245-5.234z" />
    </g>
  </svg>
)

export default SvgNouvelleDemande
