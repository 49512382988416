import React from "react"

const SvgFirst = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 11 10" {...props}>
    <g fill="#D1D1D1" fillRule="nonzero">
      <path d="M10.107 9.812a.5.5 0 01-.71 0L5.26 5.682a.75.75 0 01-.22-.53v-.38a.77.77 0 01.22-.53L9.397.112a.5.5 0 01.71 0l.71.71a.49.49 0 010 .7l-3.448 3.44 3.448 3.44a.5.5 0 010 .71l-.71.7z" />
      <path d="M5.107 9.812a.5.5 0 01-.71 0L.26 5.682a.75.75 0 01-.22-.53v-.38a.77.77 0 01.22-.53L4.397.112a.5.5 0 01.71 0l.71.71a.49.49 0 010 .7l-3.448 3.44 3.448 3.44a.5.5 0 010 .71l-.71.7z" />
    </g>
  </svg>
)

export default SvgFirst
