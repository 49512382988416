import React, { useState } from "react"
import SquareButton from "components/common/SquareButton"
import styled from "styled-components"
import Plus from "components/icons/Plus"
import { Row, Col } from "@bootstrap-styled/v4"
import Colors from "theme/Colors"
const AddButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 56px;
  max-height: 56px;
  //min-width: 170px;
  padding: 10px;
  @media screen and (max-width: 1200px) {
    text-align: center;
    padding: 10px;
  }
`
const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const AddSomethingButton = ({ title, onClick, ...props }) => {
  return (
    <AddButton onClick={onClick} {...props}>
      <center>
        <Col>
          <Row className="align-items-center justify-content-center">
            <Col xs="1">
              <Plus />
            </Col>
            <Col xs="auto">
              <Spacer />
              {title}
            </Col>
          </Row>
        </Col>
      </center>
    </AddButton>
  )
}

export default AddSomethingButton
