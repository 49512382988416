import React, { useState, useEffect } from "react"

import styled from "styled-components"
import FormControl from "@material-ui/core/FormControl"

import ErrorPopUP from "components/common/PopUp"
import ElementContainer from "components/form/element/ElementContainer"
import FileItem from "components/form/element/FileItem"
import SquareButton from "components/common/SquareButton"
import Plus from "components/icons/Plus"

import Colors from "theme/Colors"

const DEFAULT_ERROR_MSG = "Le fichier n'a pas pu être uploadé."

const AddDocsButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`

const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 50px;
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const Attachment = ({ className, element, editable, value, onChange, ...props }) => {
  const [files, setFiles] = useState(value && value.mediaObjects ? value.mediaObjects : [])
  const [uploading, setUploading] = useState(false)
  const [errorPopUpOpened, setErrorPopupOpened] = useState(false)
  const [alreadyIntializeMandatoryResponse, setAlreadyIntializeMandatoryResponse] = useState(false)

  let errorMsg = DEFAULT_ERROR_MSG

  const handleFileAdd = (event) => {
    if (!event.target.files || !event.target.files[0]) {
      return
    }

    // const newFiles = [...files, event.target.files[0]]
    // setFiles(newFiles)
    files.push(event.target.files[0])
    setUploading(true)
    setFiles([...files])
  }

  const handleFileAdded = (position, file) => {
    files[position] = file
    onChange({
      ...value,
      type: "file",
      mediaObjects: files,
      question: element["@id"],
    })
    setFiles([...files])
    setUploading(false)
  }

  useEffect(() => {
    if (element && element.isMandatory && !alreadyIntializeMandatoryResponse && !value) {
      onChange({
        ...value,
        type: "file",
        mediaObjects: [],
        question: element["@id"],
      })
      setAlreadyIntializeMandatoryResponse(true)
    }
  }, [element])

  const deleteFile = (key) => {
    files.splice(key, 1)
    onChange({
      ...value,
      type: "file",
      mediaObjects: files,
      question: element["@id"],
    })
    setFiles([...files])
  }

  const handleError = (key, errorMessage) => {
    errorMsg = errorMessage
    setErrorPopupOpened(true)
    deleteFile(key)
    setUploading(false)
  }

  const handleClosePopUp = () => {
    setErrorPopupOpened(false)
    errorMsg = DEFAULT_ERROR_MSG
  }

  return (
    <ElementContainer width={element.width}>
      <FormControl fullWidth={true} margin="none">
        {files.map((item, key) => (
          <FileItem
            className="align-items-center"
            key={key}
            position={key}
            item={item}
            editable={editable}
            onFileDeleted={deleteFile}
            onFileAdded={handleFileAdded}
            onError={handleError}
            value={value}
          />
        ))}
        {editable && !uploading && (
          <AddDocsButtonContainer className="ml-3 mb-2">
            <input type="file" hidden onChange={handleFileAdd} />
            <AddDocsButton>
              <Plus />
              <Spacer />
              Ajouter un document
            </AddDocsButton>
          </AddDocsButtonContainer>
        )}
      </FormControl>
      <ErrorPopUP open={errorPopUpOpened} close={handleClosePopUp}>
        {errorMsg}
      </ErrorPopUP>
    </ElementContainer>
  )
}

export default styled(Attachment)`
  background-color: transparent;
  min-height: 32px;
  width: 100%;
  color: ${Colors.black};
  font-size: 13px;
  &.MuiInputBase-root {
    font-family: "Open Sans";
  }
  input {
    margin-left: 15px;
  }
`
