import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"

import api from "services/api"
import {
  POST_MEDIA_OBJECT_ENDPOINT,
  GET_MEDIA_OBJECT_ENDPOINT_WHITOUT_BASE,
  BASE_URL,
} from "constants/endpoints"

import Document from "components/icons/Document"
import Delete from "components/icons/Delete"
import CustomTip from "components/common/ToolTip"
import SweetAlert from "react-bootstrap-sweetalert"
import Colors from "theme/Colors"

const FileText = styled.a`
  font-size: 13px;
  font-weight: 600;
  margin: 0 45px 0 20px;
`

const DeleteButton = styled(Delete)`
  cursor: pointer;
`

const FileItem = ({
  className,
  item,
  position,
  editable,
  onFileAdded,
  onFileDeleted,
  onError,
  value,
  ...props
}) => {
  const [progress, setProgress] = useState(0)
  const [fileObject, setfileObject] = useState(null)
  const [displayAlert, setDisplayAlert] = useState(false)

  useEffect(() => {
    if (item && item.constructor && item instanceof File) {
      setProgress("En cours de chargement...")

      const formData = new FormData()
      formData.append("mediaObjectFile", item, item.name)
      formData.append("label", item.name)
      api
        .post(POST_MEDIA_OBJECT_ENDPOINT, formData, {
          isFormData: true,
        })
        .then((data) => {
          if (!data) {
            onError(
              position,
              "Une erreur est survenue lors de l'upload du fichier, merci de re-essayer."
            )
            return
          }
          // const parsedData = JSON.parse(data)
          onFileAdded(position, GET_MEDIA_OBJECT_ENDPOINT_WHITOUT_BASE(data.id))
        })
        .catch(() => {
          onError(position, "Le fichier que vous avez uploadé est trop lourd.")
        })
    } else if (typeof item === "string") {
      api
        .get(`${BASE_URL}${item}`)
        .then((data) => setfileObject(data))
        .catch((e) => console.log("can t display file", e))
    }
  }, [item, position])

  const deleteFile = () => {
    if (item && item.constructor && item instanceof File) {
      setDisplayAlert(false)
      onFileDeleted(position)
    } else if (!value) {
      api
        .delete(`${BASE_URL}${item}`)
        .then(() => setDisplayAlert(false))
        .then(() => onFileDeleted(position))
        .catch(() => console.log("can t delete file"))
    } else {
      const mediaObjectsCopy = value.mediaObjects.slice()
      mediaObjectsCopy.splice(position, 1)
      api
        .put(`${BASE_URL}${value["@id"]}`, { mediaObjects: mediaObjectsCopy })
        .then(() => {
          api
            .delete(`${BASE_URL}${item}`)
            .then(() => setDisplayAlert(false))
            .then(() => onFileDeleted(position))
        })
        .catch((e) => console.log("can't delete file"))
    }
  }

  const confirmFileDeletion = () => {
    setDisplayAlert(true)
  }

  const hideAlert = () => {
    setDisplayAlert(false)
  }

  return (
    <Row className="align-items-center p-3">
      <Col xs={12}>
        <Document />
        {fileObject ? (
          <FileText href={`${BASE_URL}${fileObject.contentUrl}`} target="_blank">
            {fileObject.label}
          </FileText>
        ) : (
          <FileText>{progress}</FileText>
        )}
        {editable && (
          <>
            <DeleteButton onClick={confirmFileDeletion} data-tip data-for="delete" />
            <CustomTip id="delete" place="bottom" effect="solid">
              <span>supprimer</span>
            </CustomTip>
          </>
        )}
        {displayAlert && (
          <SweetAlert
            info
            title="Êtes-vous sûr de vouloir supprimer ce fichier ?"
            onConfirm={deleteFile}
            onCancel={hideAlert}
            confirmBtnText="Supprimer le fichier"
            confirmBtnStyle={{
              backgroundColor: Colors.primary,
              textDecoration: "none",
              minHeight: "40px",
              borderRadius: "20px",
              color: Colors.white,
              padding: "7px 10px",
              verticalAlign: "middle",
            }}
            cancelBtnStyle={{
              textDecoration: "none",
              minHeight: "40px",
              borderRadius: "20px",
              color: Colors.black,
              padding: "7px 10px",
              verticalAlign: "middle",
            }}
            showCancel={true}
            cancelBtnText="Annuler"
          />
        )}
      </Col>
    </Row>
  )
}

export default styled(FileItem)`
  font-size: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
`
