import styled from "styled-components"
import { Link } from "react-router-dom"

import Colors from "theme/Colors"

export default styled(Link)`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  color: ${Colors.black};

  &:hover {
    text-decoration: none;
  }
`
