import React from "react"

const UserContext = React.createContext({
  profile: null,
  setProfile: () => {},
})

export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer
export default UserContext
