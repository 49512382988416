import React, { useEffect, useState, useContext } from "react"

import styled from "styled-components"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

import ElementContainer from "components/form/element/ElementContainer"

import { GET_BUSINESS_UNITS_ENDPOINT } from "constants/endpoints"
import api from "services/api"
import UserContext from "contexts/UserContext"

const BusinessUnitHeader = ({ className, editable, onChange, value, label, ...props }) => {
  const [selectValue, setValue] = useState(value || "")
  const [bus, setBus] = useState([])
  const { profile } = useContext(UserContext)

  useEffect(() => {
    api
      .get(GET_BUSINESS_UNITS_ENDPOINT)
      .then((data) => {
        if (data) {
          setBus(data["hydra:member"])

          const defaultValue = data["hydra:member"].find((item) => {
            return item.id === profile.bu.id
          })

          if (!value && editable) {
            if (defaultValue) {
              onChange(defaultValue["@id"])
              setValue(defaultValue["@id"])
            }
          }
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])

  const handleChange = (event) => {
    const selectedValue = event.target.value || ""

    if (selectedValue) {
      onChange(selectedValue)
    } else {
      onChange(null)
    }
    setValue(selectedValue)
  }

  return (
    <ElementContainer width={100}>
      <FormControl fullWidth={true} margin="none">
        <InputLabel htmlFor={`elment-bu`}>{label}</InputLabel>
        <Select
          value={selectValue}
          onChange={handleChange}
          inputProps={{
            name: `elment-bu`,
            id: `elment-bu}`,
            className,
            ...(editable ? {} : { readOnly: true }),
          }}
        >
          <MenuItem value={null}>Choisissez une valeur</MenuItem>
          {bus.map((bu) => (
            <MenuItem value={bu["@id"]} key={bu.id}>
              {bu.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ElementContainer>
  )
}

export default styled(BusinessUnitHeader)`
  &.MuiInputBase-input {
    font-family: "Open Sans", sans-serif;
  }
`
