import React from "react"

const AuthenticationContext = React.createContext({
  token: null,
  setToken: () => {},
})

export const AuthenticationProvider = AuthenticationContext.Provider
export const AuthenticationConsumer = AuthenticationContext.Consumer
export default AuthenticationContext
