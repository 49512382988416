import React from "react"
import styled from "styled-components"
import Colors from "theme/Colors"

const Status = ({ children, status, className, ...props }) => (
  <div className={className} status={status} {...props}>
    <span>
      {status === "draft" && "Brouillon"}
      {status === "doing" && "En cours"}
      {status === "to_validate" && "A valider"}
      {status === "waitting" && "En attente"}
      {status === "closed" && "Cloturée"}
      {status === "refused" && "Refusée"}
      {status === "published" && "Publié"}
      {status === "unpublished" && "Dépublié"}
    </span>
  </div>
)

export default styled(Status)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.background};
  min-height: 30px;
  width: 100px;
  border-radius: 15px;
  border: 2px solid
    ${(props) => {
      let color = Colors.primary
      switch (props.status) {
        case "draft":
          color = Colors.primaryVeryDark
          break
        case "to_validate":
          color = Colors.toValid
          break
        case "doing":
          color = Colors.doing
          break
        case "waitting":
          color = Colors.waitting
          break
        case "closed":
          color = Colors.primary
          break
        case "refused":
          color = Colors.greyLight
          break
        case "published":
          color = Colors.green
          break
        case "unpublished":
          color = Colors.primary
          break
        default:
          color = Colors.primaryDark
      }
      return color
    }};
  color: ${Colors.greyLight};
`
