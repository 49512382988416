import React, { useState } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import AutoComplete from "components/form/AutoComplete"
import SquareButton from "components/common/SquareButton"
import Colors from "theme/Colors"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import { TextField } from "@material-ui/core"
import { Row, Col } from "@bootstrap-styled/v4"
import Plus from "components/icons/Plus"
import Text from "components/common/Text.js"

const CustomSelect = styled(Select)`
  @media screen and (max-width: 1200px) {
    margin: 0 0 10px 0px !important;
  }
`

const CustomFormControl = styled(FormControl)`
  //min-width: 200px !important;
  width: 100%;
`

const ButtonContainer = styled.div`
  padding: 15px 15px;
  margin: 10px 15px 0 20px;
  @media screen and (max-width: 1200px) {
    margin: 10px 15px 0 0;
  }
`

const PlusIcon = styled(Plus)`
  font-size: 20px;
  @media screen and (max-width: 1200px) {
    font-size: 15px;
    padding: 2px;
  }
`

const CustomInputLabel = styled(InputLabel)`
  background-color: ${Colors.white};
`

const AddDocsButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`

const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 56px;
  padding: 10px;

  @media screen and (max-width: 1200px) {
    text-align: center;
    padding: 10px;
  }
`

const AddCategoryButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  min-height: 56px;
  max-height: 56px;
  max-width: 230px;
  min-width: 54.75px;
  font-size: 13px;
  font-weight: 600;

  @media screen and (max-width: 1200px) {
    margin: 10px 0 0 0 !important;
  }
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const Input = styled.input`
 
  height: 56px;
  padding: 10px 20px;
  font-family: Helvetica, sans - serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 100%;
   @media screen and (max-width: 1200px) {
   
    margin: 0 0 10px 0px !important;
  }
}
`
const CustomAutoComplete = styled(AutoComplete)`
  width: 100%;
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0px !important;
  }
`
const AddCategoryContainer = styled(Container)`
  width: 100%;
`

const CustomTextField = styled(TextField)`
  width: 100%;
`
const MicroContainerTitle = styled(Text)`
  color: #000000;
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
`
const InputFont = styled(Text)`
  font-size: 13px;
`

const AddCategory = ({ className, addCategory, maxPosition, data, onChange, ...props }) => {
  const [category, setCategory] = useState(null)

  const handleCategoryEdit = () =>
    setCategory({
      type: "read_only",
      weight: -1,
    })

  const handlePositionChange = (event) => {
    category.weight = event.target.value - 1 // || ""
    setCategory({ ...category })
  }

  const handleLabelChange = (event) => {
    category.label = event.target.value || ""
    setCategory({ ...category })
  }

  const handleCreateCategory = () => {
    addCategory(category)
    setCategory(null)
  }

  if (category) {
    const positions = []
    for (let i = 1; i <= maxPosition + 1; i++) {
      positions.push(
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>
      )
    }

    return (
      <AddCategoryContainer className={className}>
        <Row className=" align-items-end">
          <Col className="col-2">
            <MicroContainerTitle>Position</MicroContainerTitle>
          </Col>
          <Col>
            <MicroContainerTitle>Nom de la catégorie</MicroContainerTitle>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center flex-nowrap">
          <Col className="col-1">
            <CustomFormControl>
              <Select
                value={category && category.weight !== "" ? category.weight + 1 : ""}
                onChange={handlePositionChange}
                inputProps={{
                  name: `elment-position`,
                }}
              >
                <MenuItem value={null}>Choisissez une valeur</MenuItem>
                {positions}
              </Select>
            </CustomFormControl>
          </Col>
          <Col>
            <FormControl fullWidth={true} margin="none">
              <TextField
                id={`category-add`}
                value={category.label}
                onChange={handleLabelChange}
                InputProps={{ style: { "min-height": "32px", "font-size": "13px" } }}
              />
            </FormControl>
          </Col>

          {category.label && category.weight !== -1 && (
            <Col className="col-2">
              <AddCategoryButton noGutters={true} onClick={handleCreateCategory}>
                Ajouter
              </AddCategoryButton>
            </Col>
          )}
        </Row>
      </AddCategoryContainer>
    )
  } else {
    return (
      <center>
        <ButtonContainer onClick={handleCategoryEdit}>
          <AddDocsButtonContainer>
            <AddDocsButton>
              <Row className="justify-content-center align-items-center">
                <Col xs="auto">
                  <PlusIcon />
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center">
                <Col xs="auto">
                  <Spacer />
                  Ajouter une catégorie
                </Col>
              </Row>
            </AddDocsButton>
          </AddDocsButtonContainer>
        </ButtonContainer>
      </center>
    )
  }
}

export default styled(AddCategory)`
  padding: 20px;
  margin: 20px;
  border: 1px solid #2656c8;
`
