import React, { useState } from "react"
import styled from "styled-components"
import Container from "components/common/Container"
import SquareButton from "components/common/SquareButton"
import Colors from "theme/Colors"
import Plus from "components/icons/Plus"
import api from "services/api"
import { POST_MEDIA_OBJECT_ENDPOINT, BASE_URL } from "constants/endpoints"
import Success from "components/icons/Success"
import { Row, Col } from "@bootstrap-styled/v4"

const AddLogoContainer = styled(Container)`
  margin: 80px;
`
const AddLogoDiv = styled.div`
  margin: 80px;
`
const AddDocsButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`
const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 50px;
`
const UploadSuccessed = styled(Col)`
  margin: 5px;
  text-align: center;
`
const uploadFailed = styled(Col)`
  margin: 5px;
  // text-align: center;
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const SuccessPicto = styled(Success)`
  font-size: 30px;
  margin-right: 25px;
`
const SpacedRow = styled(Row)`
  margin: 10px;
`

const LogoAttachment = ({ idBu, onImageChange, handleClick }) => {
  const [uploading, setUploading] = useState(false)
  const [newImage, setNewImage] = useState(false)
  const [uploadFailed, setUploadFailed] = useState(false)

  const handleFileAdd = (event) => {
    if (!event.target.files || !event.target.files[0]) {
      return
    }
    const file = event.target.files[0]
    setUploading(true)
    // POST pour le mettre dans un média object
    const formData = new FormData()
    formData.append("mediaObjectFile", file, file.name)
    formData.append("label", file.name)
    api
      .post(POST_MEDIA_OBJECT_ENDPOINT, formData, {
        isFormData: true,
      })
      .then((response) => {
        return api.put(`${BASE_URL}/api/b_us/${idBu}`, {
          logo: `/api/media_objects/${response.id}`,
        })
      })
      .then((response2) => {
        if (response2.logo.id != null && response2.logo.id != undefined) {
          setNewImage(true)
          onImageChange(true)
        }
      })
      .catch((e) => {
        setUploadFailed(true)
        return alert("erreur : " + e.message)
      })

    setUploading(false)
  }
  return (
    <Col>
      {!newImage && !uploadFailed && (
        <Col>
          <SpacedRow className="justify-content-center">
            <h3>Ajouter un logo</h3>
          </SpacedRow>
          <SpacedRow className="justify-content-center">
            <small>
              Choisissez un logo depuis votre poste de travail. <br />
              La taille recommandée est de 100x60 px. La taille maximale autorisée est de 2 Mo.
            </small>
          </SpacedRow>
          <SpacedRow className="justify-content-center">
            <AddDocsButtonContainer className="ml-3 mb-2">
              <input type="file" hidden onChange={handleFileAdd} />
              <AddDocsButton>
                <Plus />
                <Spacer />
                Choisir un fichier
              </AddDocsButton>
            </AddDocsButtonContainer>
          </SpacedRow>{" "}
        </Col>
      )}
      {newImage && (
        <UploadSuccessed className="justify-content-center">
          <h3>Votre image a été changée avec succès </h3>
        </UploadSuccessed>
      )}
      {!newImage && uploadFailed && (
        <uploadFailed className="justify-content-center">
          <h3> Echec du chargement de votre image</h3>
        </uploadFailed>
      )}
    </Col>
  )
}

export default LogoAttachment
