import React from "react"

const SvgClose = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 50 50" {...props}>
    <g
      strokeWidth={2}
      stroke="#000"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M48.25 1.75l-46.5 46.5M1.75 1.75l46.5 46.5" />
    </g>
  </svg>
)

export default SvgClose
