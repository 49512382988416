import React, { useState } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { hot } from "react-hot-loader/root"
import { DEV } from "constants/environement"
import BootstrapProvider from "@bootstrap-styled/provider"

import "App.css"

import Anonymous from "routes/anonymous"
import Authenticated from "routes/authenticated"

import Normalize from "theme/Normalize"
import Default from "theme/Default"

import { AuthenticationProvider } from "./contexts/AuthenticationContext"

import TokenHelper from "services/token"

import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    fontFamily: "Open Sans",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [{ fontFamily: "Open Sans" }],
      },
    },
  },
})

function App() {
  const [token, setToken] = useState(TokenHelper.getToken)

  TokenHelper.setStateModifier(setToken)
  return (
    <BootstrapProvider className="App" theme={{ "$font-family-sans-serif": "Open Sans" }}>
      <ThemeProvider theme={theme}>
        <Normalize />
        <Default />
        <AuthenticationProvider value={{ token, setToken: TokenHelper.setToken }}>
          <Router>
            <Route component={token ? Authenticated : Anonymous} />
          </Router>
        </AuthenticationProvider>
      </ThemeProvider>
    </BootstrapProvider>
  )
}

export default DEV ? hot(App) : App
