import React from "react"

const SvgChangeUser = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 64 58" {...props}>
    <g transform="translate(-20 -742)" fill="#FFF" fillRule="evenodd">
      <path
        d="M53.518 758.977a6.271 6.271 0 012.875 4.652 4.905 4.905 0 002.107.479c2.754 0 4.986-2.263 4.986-5.054 0-2.791-2.232-5.054-4.986-5.054-2.728 0-4.94 2.223-4.982 4.977zm-3.267 10.348c2.754 0 4.986-2.262 4.986-5.054 0-2.79-2.232-5.053-4.986-5.053-2.754 0-4.988 2.263-4.988 5.054 0 2.791 2.234 5.053 4.988 5.053zm2.115.345h-4.231c-3.52 0-6.385 2.903-6.385 6.471v5.245l.013.082.357.113c3.36 1.064 6.278 1.419 8.68 1.419 4.693 0 7.413-1.356 7.58-1.442l.334-.171h.035v-5.246c.002-3.568-2.863-6.471-6.383-6.471zm8.25-5.217h-4.199a6.267 6.267 0 01-1.896 4.338c3.129.943 5.418 3.884 5.418 7.358v1.616c4.146-.154 6.535-1.344 6.692-1.424l.333-.172H67v-5.246c0-3.568-2.864-6.47-6.384-6.47zm-19.115-.345c.976 0 1.883-.288 2.652-.78a6.271 6.271 0 012.32-3.989c.005-.094.014-.188.014-.284 0-2.791-2.233-5.054-4.986-5.054-2.755 0-4.986 2.263-4.986 5.054 0 2.79 2.231 5.053 4.986 5.053zm4.478 4.683a6.27 6.27 0 01-1.895-4.314c-.156-.012-.31-.024-.469-.024h-4.23c-3.52 0-6.385 2.902-6.385 6.47v5.246l.013.08.357.114c2.695.853 5.1 1.246 7.19 1.37v-1.584c0-3.474 2.29-6.414 5.42-7.358z"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgChangeUser
