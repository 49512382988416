import React from "react"

const SvgPlus = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
    <g transform="matrix(1 0 0 -1 0 20)" fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={10} cy={10} r={10} />
      <rect fill="#24445C" x={9} y={5} width={2} height={10} rx={1} />
      <rect fill="#24445C" transform="rotate(90 10 10)" x={9} y={5} width={2} height={10} rx={1} />
    </g>
  </svg>
)

export default SvgPlus
