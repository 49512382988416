import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { Row } from "@bootstrap-styled/v4"

import Profils from "components/icons/Profils"
import api from "services/api"
import { BASE_URL } from "constants/endpoints"

const CustomRow = styled(Row)`
  min-height: 56px;
`

const Firstname = styled.span`
  text-transform: capitalize;
`

const ProfilsPicto = styled(Profils)`
  font-size: 35px;
  margin-right: 20px;
`
const ProfileDisplay = ({ className, validator, onChange, ...props }) => {
  const [profile, setProfile] = useState(null)
  useEffect(() => {
    let validatorUrl = null
    if (typeof validator.profile == "string") {
      validatorUrl = validator.profile
    } else {
      validatorUrl = validator.profile["@id"]
    }
    api
      .get(`${BASE_URL}${validatorUrl}`)

      .then((data) => {
        setProfile(data)
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [validator.profile])

  return (
    <div className={className}>
      <CustomRow noGutters={true} className="align-items-center">
        <>
          <ProfilsPicto />
          <span>
            <Firstname>{profile && profile.firstname.toLowerCase()}</Firstname>{" "}
            {profile && profile.lastname}
          </span>
        </>
      </CustomRow>
    </div>
  )
}

export default styled(ProfileDisplay)`
  text-align: left;
  min-width: 250px;
`
