import React from "react"
import styled from "styled-components"

const TitleContainer = styled.div`
  font-size: 23px;
  font-weight: bold;
`
const PageTop = ({ className, title, getBackComponent, ...props }) => {
  return (
    <div className={className}>
      <div>{title && <TitleContainer>{title}</TitleContainer>}</div>
      {getBackComponent()}
    </div>
  )
}

export default styled(PageTop)`
  padding: 40px 70px 40px 40px;
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: black;
    font-family: "Open Sans";
  }
`
