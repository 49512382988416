import React from "react"

const SvgOutlineSuccess = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="#2656C8" fillRule="evenodd">
      <path d="M12.05 21.563L23.3 10.243l-.663-.697L11.72 20.448l-5.294-5.302-.663.727 5.625 5.69z" />
      <path
        d="M15 30c4.007 0 7.774-1.56 10.607-4.394A14.899 14.899 0 0030 15a14.9 14.9 0 00-4.394-10.607A14.9 14.9 0 0015 0C10.993 0 7.226 1.56 4.393 4.394A14.901 14.901 0 000 15a14.9 14.9 0 004.394 10.607A14.9 14.9 0 0015 30zM5.056 5.056A13.97 13.97 0 0115 .937h.001c3.756 0 7.287 1.463 9.943 4.119A13.968 13.968 0 0129.062 15c0 3.756-1.462 7.288-4.118 9.944A13.97 13.97 0 0115 29.063h-.001a13.971 13.971 0 01-9.943-4.119A13.97 13.97 0 01.938 15c0-3.756 1.462-7.288 4.118-9.944z"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgOutlineSuccess
