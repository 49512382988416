class Token {
  constructor() {
    const data = localStorage.getItem("token")
    this.token = data ? JSON.parse(data) : null
    this.currentProfile = null
    this.isNewProfile = false
    this.isExpired = false
  }

  setStateModifier = (stateModifier) => {
    this.stateModifier = stateModifier
  }

  setToken = (token) => {
    this.token = token

    if (this.stateModifier) {
      this.stateModifier(token)
    }

    if (token) {
      const content = this.token.split(".")
      const infos = JSON.parse(atob(content[1]))
      if (infos.profile != this.currentProfile) {
        this.flushSessionData()
      }
      localStorage.setItem("token", JSON.stringify(token))
    } else {
      //si il n'y a plus de token
      localStorage.removeItem("token")

      // c'est pas très bien, mais on vide aussi le reste du localStorage
      if (!this.isExpired) {
        this.flushSessionData()
        this.isExpired = false
      }
    }
  }

  flushSessionData = () => {
    localStorage.removeItem("demandeCreation.category")
    localStorage.removeItem("demandeCreation.form")
    localStorage.removeItem("demandeCreation.values")
    localStorage.removeItem("demandeCreation.valideurs")
    localStorage.removeItem("demandeCreation.title")
    localStorage.removeItem("demandeCreation.bu")
  }

  getToken = () => {
    if (this.token) {
      const content = this.token.split(".")
      const infos = JSON.parse(atob(content[1]))

      const now = Math.round(new Date().getTime() / 1000)
      const expirationTime = infos.exp - 20
      if (expirationTime < now) {
        this.currentProfile = infos.profile
        this.isExpired = true
        this.setToken(null)
        return null
      }
    }

    return this.token
  }
}

const TokenSingleton = (function () {
  var instance = null
  return new (function () {
    this.getInstance = () => {
      if (instance == null) {
        instance = new Token()
      }
      return instance
    }
  })()
})()

export default {
  getToken: TokenSingleton.getInstance().getToken,
  setToken: TokenSingleton.getInstance().setToken,
  setStateModifier: TokenSingleton.getInstance().setStateModifier,
}
