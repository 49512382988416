import React, { useState, useContext, useEffect } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import OutlineSuccess from "components/icons/OutlineSuccess"
import { Row, Col } from "@bootstrap-styled/v4"
import Success from "components/icons/Success"
import DeletePicto from "components/icons/Delete"
import PosteDisplay from "components/adminCreateForm/settingWorkFlow/variableValidatorRenders/PosteDisplay"
import LineManagerDisplay from "components/adminCreateForm/settingWorkFlow/variableValidatorRenders/LineManagerDisplay"
import ProfileDisplay from "components/adminCreateForm/settingWorkFlow/variableValidatorRenders/ProfileDisplay"
import Pen from "components/icons/Pen"
import AddValidatorAdmin from "components/adminCreateForm/settingWorkFlow/AddValidatorAdmin"
import { CreateFormContext } from "contexts/CreateFormContext"
import Profils from "components/icons/Profils"
import Text from "components/common/Text"

const CustomContainer = styled(Container)`
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0 !important;
  }
`

const CheckIcon = styled(OutlineSuccess)`
  font-size: 30px;
  margin-right: 25px;
`
const SuccessPicto = styled(Success)`
  font-size: 30px;
  margin-right: 25px;
`

const ValidatorText = styled.div`
  font-weight: bold;
  min-width: 150px;
`

const ValidatorLabel = styled.div`
  min-width: 150px;
`

const DeleteIcon = styled(DeletePicto)`
  margin-left: 25px;
  font-size: 25px;
  &:hover {
    cursor: pointer;
  }
`
const Firstname = styled.span`
  text-transform: capitalize;
`

const ProfilsPicto = styled(Profils)`
  font-size: 35px;
  margin-right: 20px;
`

const ContributorNotified = ({ className, contributor, onDelete, key, index, ...props }) => {
  const { bu } = useContext(CreateFormContext)
  const handleDelete = () => {
    onDelete(index)
  }

  const matchBuLabel = (iri) => {
    return bu.find((bu) => bu["@id"] === iri)
  }

  // console.log(matchBuLabel(contributor.bu))
  return (
    <CustomContainer className={className}>
      <Row className="align-items-center" noGutters={true}>
        <Col>
          <Row className="align-items-center" noGutters={true}>
            <CheckIcon />
            <ValidatorText className="">
              {matchBuLabel(contributor.businessUnit) &&
                matchBuLabel(contributor.businessUnit).label}
            </ValidatorText>
          </Row>
        </Col>
        <Col>
          <Row className="align-items-center" noGutters={true}>
            <Text>{contributor.label}</Text>
          </Row>
        </Col>
        <Col xs="auto">
          {" "}
          <Row noGutters={true} className="align-items-center">
            <ProfilsPicto />
            <span>
              <Firstname>
                {contributor.profile.firstname && contributor.profile.firstname.toLowerCase()}
              </Firstname>{" "}
              {contributor.profile.lastname && contributor.profile.lastname}
            </span>
          </Row>
        </Col>
        <Col xs="1">
          <DeleteIcon onClick={handleDelete} />
        </Col>
      </Row>
    </CustomContainer>
  )
}

export default styled(ContributorNotified)`
  padding: 15px 25px;
  margin: 20px 20px 0;
`
