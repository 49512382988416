import React, { useState, useEffect } from "react"

import FormControl from "@material-ui/core/FormControl"
import { TextField } from "@material-ui/core"

const SelectingWidth = ({ onWidthChange, fieldWidth }) => {
  return (
    <>
      <FormControl fullWidth={true} margin="none">
        <TextField
          margin="none"
          label={"Largeur du champ (en pourcentage)"}
          value={fieldWidth}
          onChange={onWidthChange}
          hiddenLabel={true}
          InputProps={{ style: { minHeight: "32px", fontSize: "13px", lineHeight: "0px" } }}
        />
      </FormControl>
    </>
  )
}

export default SelectingWidth
