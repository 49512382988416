import React, { useState, useEffect } from "react"

import styled from "styled-components"
import FormControl from "@material-ui/core/FormControl"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import ElementContainer from "components/form/element/ElementContainer"
import Colors from "theme/Colors"
import FormLabel from "@material-ui/core/FormLabel"
import StyledRadio from "components/common/Radio"
import api from "services/api"
import { BASE_URL } from "constants/endpoints"

const RadioButton = ({
  className,
  element,
  editable,
  onChange,
  value,
  matchingResponse,
  ...props
}) => {
  const [choiceValue, setValue] = useState(value && value.choice ? value.choice : "")
  const [alreadyIntializeMandatoryResponse, setAlreadyIntializeMandatoryResponse] = useState(false)

  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    const initialisedValue = matchingResponse[0]

    api
      .get(`${BASE_URL}${matchingResponse[0].choice}`)
      .then(({ weight }) => {
        element.choices.map((choice) => {
          if (choice.weight === weight) {
            onChange({
              ...value,
              type: "unique_choice",
              choice: choice["@id"],
              question: element["@id"],
            })
            setValue(choice["@id"])
          }
        })
      })
      .catch((e) => {
        console.error(e.message)
      })
  }, [matchingResponse])

  useEffect(() => {
    if (element && element.isMandatory && !alreadyIntializeMandatoryResponse && !value) {
      onChange({
        ...value,
        type: "unique_choice",
        choice: null,
        question: element["@id"],
      })
      setAlreadyIntializeMandatoryResponse(true)
    }
  }, [element])

  const handleChange = (event) => {
    if (!editable) {
      return
    }
    const checkBoxValue = event.target.value || ""
    if (checkBoxValue) {
      onChange({
        ...value,
        type: "unique_choice",
        choice: checkBoxValue,
        question: element["@id"],
      })
    } else {
      onChange({
        ...value,
        type: "unique_choice",
        choice: null,
        question: element["@id"],
      })
    }
    setValue(checkBoxValue)
  }

  return (
    <ElementContainer width={element.width}>
      <FormControl fullWidth={true} margin="none">
        <FormLabel component="legend" className={element.isMandatory && "mandatory-field"}>
          {element.title}
        </FormLabel>
        <RadioGroup
          row
          name={`element-radio-${element.id}`}
          value={choiceValue}
          onChange={handleChange}
        >
          {element.choices.map((choice) => (
            <FormControlLabel
              value={choice["@id"]}
              key={choice.id}
              control={<StyledRadio />}
              label={choice.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </ElementContainer>
  )
}

export default styled(RadioButton)``
