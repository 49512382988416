import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"

import api from "services/api"
import Profils from "components/icons/Profils"

import { BASE_URL } from "constants/endpoints"

import LoaderOverlay from "components/common/LoaderOverlay"
import Pen from "components/icons/Pen"
import CustomTip from "components/common/ToolTip"
import AutoComplete from "components/form/AutoComplete"

const CustomRow = styled(Row)`
  min-height: 56px;
`

const Firstname = styled.span`
  text-transform: capitalize;
`

const ProfilsPicto = styled(Profils)`
  font-size: 35px;
  margin-right: 20px;
`
const EditIcon = styled(Pen)`
  &:hover {
    cursor: pointer;
  }
`
const CustomAutoComplete = styled(AutoComplete)`
  width: 100%;
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0px !important;
  }
`

const Editable = ({ className, validator, onChange, validated, ...props }) => {
  const [profile, setProfile] = useState(null)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    let validatorUrl = null
    if (typeof validator.profile == "string") {
      validatorUrl = validator.profile
    } else {
      if (!validator.profile || validator.profile === null) {
        return
      }
      validatorUrl = validator.profile["@id"]
    }
    api
      .get(`${BASE_URL}${validatorUrl}`)

      .then((data) => {
        setProfile(data)
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [validator.profile])

  if (!profile) {
    return <LoaderOverlay />
  }

  const handleEditMode = () => {
    setEditMode(true)
  }

  const handleProfileChange = (profile) => {
    if (!profile) {
      return
    }
    setProfile(profile)
    setEditMode(false)
    onChange({
      ...validator,
      profile: profile["@id"],
    })
  }

  return (
    <div className={className}>
      <CustomRow noGutters={true} className="align-items-center">
        <ProfilsPicto />
        <span>
          {editMode ? (
            <CustomAutoComplete onProfileChange={handleProfileChange} />
          ) : (
            <>
              {" "}
              <Firstname>{profile.firstname.toLowerCase()}</Firstname> {profile.lastname}
            </>
          )}
        </span>
        <span>
          {!editMode && !validated && (
            <Col>
              <EditIcon onClick={handleEditMode} data-tip data-for="editWorkFlow" />
              <CustomTip id="editWorkFlow" place="bottom" effect="solid">
                <span>Modifier le collaborateur</span>
              </CustomTip>
            </Col>
          )}
        </span>
      </CustomRow>
    </div>
  )
}

export default styled(Editable)`
  text-align: left;
  min-width: 250px;
`
