import React, { useState, useEffect } from "react"

import styled from "styled-components"
import Colors from "theme/Colors"
import { Row } from "@bootstrap-styled/v4"
import api from "services/api"
import ValidatorItem from "components/newDemande/ValidatorItem"
import Validator from "components/form/Validator"
import Container from "components/common/Container"
import Pen from "components/icons/Pen"
import CrossedPen from "components/icons/CrossedPen"
import AddValidator from "components/form/AddValidator"
import CustomTip from "components/common/ToolTip"

import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const ContainerTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 600;
  margin: 0;

  padding: 20px;
`

const EditIcon = styled(Pen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const CantEditIcon = styled(CrossedPen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const FlowContainer = styled.div`
  margin: 40px;
  margin-top: 10px;
`

const ValidationFlow = ({
  className,
  valideurs,
  setValideurs,
  profile,
  readOnly,
  data,
  isDraft,
  demande,
  hasPreviousForm,
  validationUnitaire,
  setValidationUnitaire,
  ...props
}) => {
  const [editFlow, setEditFlow] = useState(false)

  const handleClick = () => {
    setEditFlow(!editFlow)
  }

  if (!profile) {
    return null
  }

  const handleNotifyToApprove = (data) => {
    valideurs[data.weight] = data
    setValideurs([...valideurs])
  }

  const handleChange = (data) => {
    if (valideurs[data.weight].id !== data.id) {
      return
    }
    valideurs[data.weight] = data
    setValideurs([...valideurs])
  }

  const handleAdd = (validator) => {
    valideurs.splice(validator.weight, 0, validator)

    valideurs.map((item, index) => {
      if (!item) {
        return null
      }

      valideurs[index].weight = index
    })
    setValideurs([...valideurs])
  }

  const handleDelete = (validator) => {
    valideurs.splice(validator.weight, 1)

    valideurs.map((item, index) => {
      if (!item) {
        return null
      }

      valideurs[index].weight = index
    })
    setValideurs([...valideurs])
  }

  const handleValidationUnitaireChange = (e) => {
    setValidationUnitaire(!validationUnitaire)
  }

  return (
    <Container className={className}>
      <ContainerTitle>
        Flux d’envoi
        {!readOnly && (
          <>
            {editFlow ? (
              <CantEditIcon onClick={handleClick} />
            ) : (
              <>
                <EditIcon onClick={handleClick} data-tip data-for="editWorkFlow" />
                <CustomTip id="editWorkFlow" place="bottom" effect="solid">
                  <span>Editer le work flow</span>
                </CustomTip>
              </>
            )}
          </>
        )}
      </ContainerTitle>
      {editFlow ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={validationUnitaire}
                onChange={handleValidationUnitaireChange}
                name={"unitaryValidation"}
                color="primary"
              />
            }
            label={"Validation unitaire par défaut"}
          />
          {valideurs.map((valideur, key) => {
            if (!valideur) {
              return null
            }
            return (
              <Validator
                isDraft={isDraft}
                validator={valideur}
                key={key}
                onChange={handleChange}
                onDelete={handleDelete}
                onNotifyToApprove = {handleNotifyToApprove}
                currentValidator = {demande.currentValidator}
                hasPreviousForm={hasPreviousForm}
              />
            )
          })}
          <AddValidator addValidator={handleAdd} maxPosition={valideurs.length + 1} />
        </>
      ) : (
        <FlowContainer>
          <Row>
            {valideurs.map((element, key) => {
              if (!element) {
                return null
              }

              return (
                <ValidatorItem
                  demandeStatus={demande.status}
                  currentValidator={demande.currentValidator}
                  initialValidator={demande.initialValidator}
                  validator={element}
                  key={key}
                />
              )
            })}
          </Row>
        </FlowContainer>
      )}
    </Container>
  )
}

export default styled(ValidationFlow)`
  padding: 20px;
  padding-top: 25px;
  margin: 30px 70px 30px;
`
