import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import styled from "styled-components"
import Colors from "theme/Colors"
import { Row, Col } from "@bootstrap-styled/v4"
import DemandeCreationContext from "contexts/DemandeCreationContext"

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-size: 16px;
`

const Container = styled(Row)`
  width: 200px;
  border-radius: 5px;
  background-color: ${Colors.primaryVeryDark};
  border: 1px solid ${Colors.primaryVeryDark};
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  color: ${Colors.white};
  height: 100%;
  min-height: 120px;
  padding: 15px;

  &:hover,
  &.active {
    cursor: pointer;
    background-color: ${Colors.white};
    border: 1px solid ${Colors.primaryVeryDark};
    color: ${Colors.primaryVeryDark};
    font-weight: 800;
  }
`

const Form = ({ className, item, ...props }) => {
  const { form, setForm } = useContext(DemandeCreationContext)
  let history = useHistory()

  const chooseForm = () => {
    setForm(item.id, item.build)
    history.push("contenu")
  }

  return (
    <div className={className} onClick={chooseForm}>
      <Container
        className={`justify-content-center align-items-center ${form === item.id ? "active" : ""}`}
        noGutters={true}
      >
        <Col xs="12">
          <Text>{item.title}</Text>
        </Col>
      </Container>
    </div>
  )
}

export default styled(Form)`
  height: 100%;
  padding-bottom: 30px;
  margin-top: 15px;

  @media screen and (max-width: 1200px) {
    margin: 0;
    text-align: center;
  }
`
