import React from "react"

import styled from "styled-components"
import Colors from "theme/Colors"

const BasicTextInput = ({
  placeholder,
  className,
  secured,
  defaultValue,
  onChange,
  name,
  ...props
}) => {
  const handleChange = (event) => {
    const newValue = event.target.value
    onChange(name, newValue)
  }

  return (
    <input
      className={className}
      type={secured ? "password" : "text"}
      placeholder={placeholder}
      value={defaultValue}
      onChange={handleChange}
      name={name}
      {...props}
    />
  )
}

const TextInput = styled(BasicTextInput)`
  min-height: 40px;
  width: 100%;
  max-width: 330px;
  border: 1px solid ${Colors.border};
  color: ${Colors.black};
  border-radius: 3px;
  padding: 0 10px;
  margin-bottom: 15px;
`
export default TextInput
