import React, { useState, useRef, useEffect } from "react"

import styled from "styled-components"
import Autosuggest from "react-autosuggest"

import api from "services/api"
import { SEARCH_PROFILES_ENDPOINT } from "constants/endpoints"

const AutoComplete = ({ className, onProfileChange, ...props }) => {
  const [value, setValue] = useState("")
  const currentValue = useRef(value)
  const [suggestions, setSuggestions] = useState([])
  const [lastSelectedSuggestion, setLastSelectedSuggestion] = useState("")

  const isSuggestionUnselected = (newValue) => {
    if (
      lastSelectedSuggestion &&
      newValue.toUpperCase() !==
        lastSelectedSuggestion.firstname.toUpperCase() +
          " " +
          lastSelectedSuggestion.lastname.toUpperCase()
    ) {
      onProfileChange(null)
      return
    }
    onProfileChange(lastSelectedSuggestion)
  }

  const onChange = (event, { newValue }) => {
    setValue(newValue)
    currentValue.current = newValue
    if (newValue === "" && onProfileChange) {
      onProfileChange(null)
    }
    isSuggestionUnselected(newValue)
  }

  const getSuggestionValue = (suggestion) => {
    return `${suggestion.firstname} ${suggestion.lastname}`
  }

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.firstname} {suggestion.lastname}
    </div>
  )

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    api.get(SEARCH_PROFILES_ENDPOINT("", value)).then((data) => {
      if (value === currentValue.current) {
        setSuggestions(data["hydra:member"])
      }
    })
    setSuggestions([])
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: "Nom du collaborateur",
    value,
    onChange: onChange,
  }

  const onSuggestionSelected = (event, { suggestion }) => {
    onProfileChange(suggestion)
    setLastSelectedSuggestion(suggestion)
  }

  // Finally, render it!
  return (
    <Autosuggest
      className={className}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={onSuggestionSelected}
      theme={{
        container: {
          position: "relative",
        },
        input: {
          minWidth: "100%",
          height: "56px",
          paddingLeft: "20px",
          fontFamily: "Helvetica, sans - serif",
          fontWeight: "300",
          fontSize: "16px",
          border: "1px solid #aaa",
          borderRadius: "4px",
        },
        inputFocused: {
          outline: "none",
        },
        inputOpen: {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        suggestionsContainer: {
          display: "none",
        },
        suggestionsContainerOpen: {
          display: "block",
          position: "absolute",
          top: "51px",
          minWidth: "400px",
          border: "1px solid #aaa",
          backgroundColor: "#fff",
          fontFamily: "Helvetica, sans - serif",
          fontWeight: "300",
          fontSize: "16px",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          zIndex: 2,
        },
        suggestionsList: {
          margin: 0,
          padding: 0,
          listStyleType: "none",
        },
        suggestion: {
          cursor: "pointer",
          padding: "10px 20px",
        },
        suggestionHighlighted: {
          backgroundColor: "#ddd",
        },
      }}
    />
  )
}

export default styled(AutoComplete)`
  .react-autosuggest__container {
    padding: 10px;
  }
`
