import React from "react"
import styled from "styled-components"

import Header from "components/layout/Header"
import Menu from "components/layout/Menu"
import { Row, Col } from "@bootstrap-styled/v4"

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 2;
`

const SubHeaderContainer = styled(Row)`
  margin-top: 80px;
`

const Item = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`

const LayoutContainer = styled(Col)`
  padding-left: 100px !important ;
`

const Layout = ({ className, children, ...props }) => {
  return (
    <Row className={`${className} justify-content-stretch`} noGutters={true}>
      <Col xs="12">
        <HeaderContainer>
          <Header></Header>
        </HeaderContainer>
      </Col>
      <Col xs="12">
        <SubHeaderContainer noGutters={true}>
          <Menu></Menu>
          <LayoutContainer>
            <Item>{children}</Item>
          </LayoutContainer>
        </SubHeaderContainer>
      </Col>
    </Row>
  )
}

export default styled(Layout)`
  flex-direction: column;
`
