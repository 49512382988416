import React, { useState, useEffect } from "react"
import ParameterContainer from "components/common/ParameterContainer"
import styled from "styled-components"
import api from "services/api"
import { GET_ADMIN_LIST, BASE_URL } from "constants/endpoints"
import { Row, Col } from "@bootstrap-styled/v4"
import AdminField from "components/adminManagement/AdminField"
import AddAdmin from "components/adminManagement/AddAdmin"
import LoaderOverlay from "components/common/LoaderOverlay"

const CustomRow = styled(Row)`
  margin: 20px;
`

const AdminManager = () => {
  const [adminList, setAdminList] = useState([])
  const [loading, setLoading] = useState(false)

  const refresh = () => {
    // voir comment on fait pour récupérer la liste des admins. Pour l'instant je recupère juste la première page des profils
    setLoading(true)
    api
      .get(`${GET_ADMIN_LIST}`)
      .then((data) => {
        if (data) {
          setAdminList(data["hydra:member"])
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        return alert("erreur : " + e.message)
      })
  }

  useEffect(refresh, [])

  const handleDelete = (admin) => {
    const id = admin.id
    api
      .put(`${BASE_URL}/api/profiles/${id}`, { admin: false })
      .then(() => {
        refresh()
      })
      .catch((e) => alert(e.message))
  }

  const handleAddAdmin = (id) => {
    api
      .put(`${BASE_URL}/api/profiles/${id}`, { admin: true })
      .then(() => {
        refresh()
      })
      .catch((e) => alert(e.message))
  }

  return (
    <ParameterContainer title="Liste des administrateurs">
      {loading && <LoaderOverlay />}
      <Row className="w-100">
        {adminList.map((admin, index) => {
          return <AdminField key={admin.id} admin={admin} onDelete={handleDelete} />
        })}
      </Row>

      <CustomRow className="justify-content-start align-items-start w-100">
        <AddAdmin onAddAdmin={handleAddAdmin} />
      </CustomRow>
    </ParameterContainer>
  )
}

export default AdminManager
