import React from "react"

import styled from "styled-components"
import Image from "components/common/Image"
import NoticeButton from "components/form/NoticeButton"
import { Row, Col } from "@bootstrap-styled/v4"

import Container from "components/common/Container"
import Colors from "theme/Colors"

const PictoImage = styled(Image)`
  max-width: 180px;
  margin: 0 20px;
`

const FormTitle = styled.span`
  color: ${Colors.black};
  font-size: 23px;
  font-weight: bold;
`

const FormNumber = styled.span`
  font-size: 23px;
  color: ${Colors.black};
  font-weight: 300;
`

const ResponsiveRow = styled(Row)`
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;

  @media screen and (max-width: 1200px) {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
`

const HeaderForm = ({ className, picture, formTitle, formNumber, formNotice, ...props }) => {
  return (
    <Container className={className}>
      <ResponsiveRow className={"d-flex"}>
        <Col xs="auto" xl="auto">
          <PictoImage src={picture} />
        </Col>
        <Col xl="auto">
          <FormTitle>{formTitle && formTitle.toUpperCase()}</FormTitle>
          {formNumber && <FormNumber>{formNumber}</FormNumber>}
        </Col>
        <Col xs="auto">
          <NoticeButton notice={formNotice} />
        </Col>
      </ResponsiveRow>
    </Container>
  )
}

export default styled(HeaderForm)`
  padding: 30px;
  margin: 30px 70px 0;
  text-align: center;

  @media screen and (max-width: 1200px) {
    margin: 30px 0px 0px 0px;
  }
`
