import React, { useEffect } from "react"
import { Link } from "react-router-dom"

import styled from "styled-components"
import Colors from "theme/Colors"
import Container from "components/common/Container"
import { Row, Col } from "@bootstrap-styled/v4"
import AutoComplete from "components/form/AutoComplete"
import FormControl from "@material-ui/core/FormControl"

import Backup from "./Backup"


const CategoryRow = styled(Row)`

margin-top:30px;
padding-left:20px;
color : ${Colors.primaryVeryDark};
font-size:17px;

`

const CustomAutoComplete = styled(AutoComplete)`
  width: 100%;
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0px !important;
  }
`

const CustomRow = styled(Row)`
  margin-bottom: 20px;
  padding-left:40px;
`
const CustomCol = styled(Col)`
  margin-bottom: 0px;
  padding:0px;
`

const BackupsList = ({
  className,
  forms,
  backupPlan,
  handleBackupChange,
  handleDeleteBackup,
  ...props
}) => {

  return (

    <CustomRow className="align-items-end ">
      <CustomCol xs="12">
        {Object.keys(forms).map((category, index) => {
          return (
            <>
            <CategoryRow className="align-items-end">
                    <b>{category}</b>
            </CategoryRow>
         
              {forms[category].map((form, index) => {
                const backup = backupPlan.backups.find(backup => backup.form == form.id);
                
              return (
                <>
                     <Backup form={form}  handleBackupChange={handleBackupChange} handleDeleteBackup={handleDeleteBackup} id={backup ? backup.id : null}  profile= {backup ? backup.profile : null}   />
                </>
              )
        })}       
        </>
      )
    })}
      </CustomCol>
    </CustomRow>
  )
}

export default BackupsList
