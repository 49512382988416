import React, { useState, useEffect } from "react"
import ParameterContainer from "components/common/ParameterContainer"
import CategoryAdminContainer from "components/common/CategoryAdminContainer"
import styled from "styled-components"
import api from "services/api"
import { GET_CATEGORIES_ENDPOINT, BASE_URL } from "constants/endpoints"
import { Row, Col } from "@bootstrap-styled/v4"
import SquareButton from "components/common/SquareButton"
import Colors from "theme/Colors"
import AddCategoryAdmin from "components/common/AddCategoryAdmin"

const MyRow = styled(Row)`
  margin: 20px;
`

const ButtonContainer = styled.div`
  padding: 15px 15px;
  margin: 10px 15px 0 20px;
  @media screen and (max-width: 1200px) {
    margin: 10px 15px 0 0;
  }
`

const SaveButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`

const SaveButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 56px;
  padding: 10px;

  @media screen and (max-width: 1200px) {
    text-align: center;
    padding: 10px;
  }
`
const CategoriesCol = styled(Col)`
  margin: 0 30px;
`

const CategoryAdmin = () => {
  const [categories, setCategories] = useState([])
  const [categoriesChanged, setCategoriesChanged] = useState(false)
  const [categoryDeleted, setCategoryDeleted] = useState(false)
  const [categoriesSaved, setCategoriesSaved] = useState(false)

  const refresh = () => {
    api
      .get(`${GET_CATEGORIES_ENDPOINT}`)
      .then((data) => {
        if (data) {
          setCategories(data["hydra:member"])
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }
  useEffect(refresh, [])

  const handleDelete = (id) => {
    api
      .delete(`${BASE_URL}/api/categories/${id}`)
      .catch((e) => alert("erreur : " + e.message))
      .then(() => {
        refresh()
        setCategoryDeleted(true)
      })
  }
  const handleCategoriesNameChanged = (category, newName) => {
    if (!categoriesChanged) {
      setCategoriesChanged(true)
    }
    category.label = newName
    setCategories([...categories])
  }
  const handleCategoriesChanged = (position1, position2, category) => {
    setCategoriesChanged(true)
    categories.splice(position1 - 1, 1)
    categories.splice(position2 - 1, 0, category)

    categories.map((category, index) => {
      category.weight = index
    })
    setCategories([...categories])
  }

  const saveChangement = () => {
    const promise = updateAllCategories()
    promise.then((value) => {
      setCategoriesSaved(true)
      refresh()
      setCategoriesChanged(false)
    })
    promise.catch((e) => {})
  }

  const updateAllCategories = () => {
    const promise = new Promise((resolve, reject) => {
      categories.map((category, index) => {
        api
          .put(`${BASE_URL}/api/categories/${category.id}`, {
            label: `${category.label}`,
            weight: category.weight,
          })
          .then((data) => {
            resolve(data)
          })
          .catch((e) => reject(e.message))
      })
    })
    return promise
  }

  const addCategory = (category) => {
    api
      .post(`${BASE_URL}/api/categories`, {
        label: category.label,
        weight: category.weight,
      })
      .then((categoryToAdd) => {
        categories.splice(categoryToAdd.weight, 0, categoryToAdd)
        categories.map((category, index) => {
          if (!category) {
            return null
          }
          categories[index].weight = index
        })
        const promise = updateAllCategories()
        promise.then((value) => {
          setCategories([...categories])
        })
      })
      .catch((e) => alert("erreur : " + e.message))
  }

  return (
    <ParameterContainer title="Catégories des formulaires">
      <CategoriesCol>
        <Row className="justify-content-center" xs="2">
          {categories.map((category, index) => {
            return (
              <CategoryAdminContainer
                key={category.id}
                category={category}
                maxPosition={categories.length}
                position={index + 1}
                onCategoryPositionChange={handleCategoriesChanged}
                onNameChange={handleCategoriesNameChanged}
                handleDelete={handleDelete}
              />
            )
          })}
        </Row>

        <MyRow className="justify-content-start align-items-center">
          <Col xs="12">
            <Row>
              <AddCategoryAdmin maxPosition={categories.length} addCategory={addCategory} />
            </Row>
          </Col>
        </MyRow>
        {categoriesChanged && (
          <Row>
            <Col>
              <center>
                <ButtonContainer>
                  <SaveButtonContainer>
                    <SaveButton onClick={saveChangement}>Enregistrer les modifications</SaveButton>
                  </SaveButtonContainer>
                </ButtonContainer>
              </center>
            </Col>
          </Row>
        )}
      </CategoriesCol>
    </ParameterContainer>
  )
}

export default CategoryAdmin
