import React, { useContext } from "react"
import styled from "styled-components"

import PageTop from "components/layout/PageTop"

import ProgressionViewer from "components/newDemande/ProgressionViewer"
import CategoryChooser from "components/newDemande/CategoryChooser"
import { Next, Back } from "components/icons"
import { Row, Col } from "@bootstrap-styled/v4"

import DemandeCreationContext from "contexts/DemandeCreationContext.js"

import CancelLink from "components/common/CancelLink"
import NavigationButton from "components/common/NavigationButton"

const Spacer = styled.span`
  width: 20px;
  height: 1 px;
`
const CustomRow = styled(Row)`
  margin: 30px 70px 30px 70px !important;
  @media screen and (max-width: 1200px) {
    margin: 20px 0px 10px 0px !important;
  }
`

const ChooseCategory = ({ className, ...props }) => {
  const { category } = useContext(DemandeCreationContext)
  const cancelButton = () => <CancelLink></CancelLink>
  const previousButton = () => (
    <NavigationButton to="/" isActive={true}>
      <Back />
      <Spacer />
      Précédent
    </NavigationButton>
  )
  const nextButton = () => (
    <NavigationButton isNext={true} to="choix-formulaire" isActive={!!category}>
      Suivant
      <Spacer />
      <Next />
    </NavigationButton>
  )

  return (
    <div className={className}>
      <PageTop getBackComponent={cancelButton} title={"Nouvelle demande"} />
      <ProgressionViewer
        //next={nextButton}
        //previous={previousButton}
        title="Création d'un nouveau formulaire"
        percent={10}
      ></ProgressionViewer>
      <CategoryChooser />
      <CustomRow className="justify-content-end d-xs-block">
        <Col xs="auto p-0">{nextButton()}</Col>
      </CustomRow>
    </div>
  )
}

export default styled(ChooseCategory)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
