import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { Row } from "@bootstrap-styled/v4"

import api from "services/api"
import Profils from "components/icons/Profils"

import { BASE_URL } from "constants/endpoints"

import LoaderOverlay from "components/common/LoaderOverlay"

const CustomRow = styled(Row)`
  min-height: 56px;
`

const Firstname = styled.span`
  text-transform: capitalize;
`

const ProfilsPicto = styled(Profils)`
  font-size: 35px;
  margin-right: 20px;
`

const ReadOnly = ({ className, validator, onChange, ...props }) => {
  const [profile, setProfile] = useState(null)
  useEffect(() => {
    let mounted = true
    let validatorUrl = null
    if (typeof validator.profile == "string") {
      validatorUrl = validator.profile
    } else if (validator.profile && validator.profile["@id"]) {
      validatorUrl = validator.profile["@id"]
    }
    if (validatorUrl) {
      api
        .get(`${BASE_URL}${validatorUrl}`)
        .then((data) => {
          if (mounted && data) {
            setProfile(data)
          }
        })
        .catch((e) => alert("erreur : " + e.message))
    }
    return () => (mounted = false)
  }, [validator.profile])

  if (!profile) {
    return <LoaderOverlay />
  }

  return (
    <div className={className}>
      <CustomRow noGutters={true} className="align-items-center">
        <ProfilsPicto />
        <span>
          <Firstname>{profile.firstname.toLowerCase()}</Firstname> {profile.lastname}
        </span>
      </CustomRow>
    </div>
  )
}

export default styled(ReadOnly)`
  text-align: left;
  min-width: 250px;
`
