import React, { useState, useEffect } from "react"
import ParameterContainer from "components/common/ParameterContainer"
import styled from "styled-components"
import { Col } from "@bootstrap-styled/v4"

import api from "services/api"
import {
  POST_CONFIG,
  GET_CONFIG_BY_NAME,
  POST_MEDIA_OBJECT_ENDPOINT,
  GET_MEDIA_OBJECT_ENDPOINT_WHITOUT_BASE,
  BASE_URL,
} from "constants/endpoints"
import LoaderOverlay from "components/common/LoaderOverlay"
import FileItem from "components/form/element/FileItem"
import SquareButton from "components/common/SquareButton"
import Plus from "components/icons/Plus"
import Colors from "theme/Colors"

const AddDocsButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`

const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 50px;
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const HelpFile = ({ ...props }) => {
  const [config, setConfig] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    api
      .get(GET_CONFIG_BY_NAME("help_doc"))
      .then((data) => {
        if (!data) {
          return
        }
        if (data["hydra:totalItems"] < 1) {
          return api.post(POST_CONFIG, { name: "help_doc", value: null })
        }
        return data
      })
      .then((data) => {
        if (!data) {
          return
        }
        setConfig(data["hydra:member"][0])
      })
      .then(() => {
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        alert(e.message)
      })
  }, [])

  const handleDelete = () => {
    setLoading(true)
    api
      .put(`${BASE_URL}${config["@id"]}`, {
        value: {
          mediaObject: null,
        },
      })
      .then((data) => {
        setConfig(data)
        setLoading(false)
      })
      .catch((e) => {
        alert(e.message)
        setLoading(false)
      })
  }

  const handleFileAdd = (event) => {
    if (event.target.files.length < 1) {
      return
    }
    setLoading(true)
    const rawFile = event.target.files[0]
    const formData = new FormData()
    formData.append("mediaObjectFile", rawFile, rawFile.name)
    formData.append("label", rawFile.name)
    api
      .post(POST_MEDIA_OBJECT_ENDPOINT, formData, {
        isFormData: true,
      })
      .then((data) => {
        return api.put(`${BASE_URL}${config["@id"]}`, {
          value: { mediaObject: GET_MEDIA_OBJECT_ENDPOINT_WHITOUT_BASE(data.id) },
        })
      })
      .then((data) => {
        setConfig(data)
        setLoading(false)
      })
      .catch((e) => {
        alert(e.message)
        setLoading(false)
      })
  }

  const handleError = () => {
    //todo: on gère l'erreur
    console.log("error")
  }

  const displayFile =
    config && config.value && config.value.mediaObject ? config.value.mediaObject : null

  return (
    <ParameterContainer title="Fichier aide">
      {loading && <LoaderOverlay />}
      <Col xs={12}>
        {displayFile ? (
          <FileItem
            item={config.value.mediaObject}
            position={0}
            editable={true}
            onFileAdded={handleFileAdd}
            onFileDeleted={handleDelete}
            onError={handleError}
          />
        ) : (
          <AddDocsButtonContainer className="ml-3 mb-2">
            <input type="file" hidden onChange={handleFileAdd} />
            <AddDocsButton>
              <Plus />
              <Spacer />
              Ajouter un document
            </AddDocsButton>
          </AddDocsButtonContainer>
        )}
      </Col>
    </ParameterContainer>
  )
}

export default HelpFile
