import React from "react"

import { Row, Col } from "@bootstrap-styled/v4"

import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"

const TextAreaParameters = ({ saveParameters, fieldParameters, label, fieldWidth, ...props }) => {
  const validateChangement = () => {
    saveParameters({ type: "text_area" })
  }
  return (
    <>
      <Row className={"justify-content-center"}>
        <OkButton onClick={validateChangement}>Valider</OkButton>
      </Row>
    </>
  )
}

export default TextAreaParameters
