import React, { useContext } from "react"

import styled from "styled-components"

import { Row, Col } from "@bootstrap-styled/v4"

import ElContainer from "components/common/Container"
import Image from "components/common/Image"

import Images from "theme/Images"
import Colors from "theme/Colors"
import Success from "components/icons/Success"
import Text from "components/common/Text"
import SquareButton from "components/common/SquareButton"
import Button from "components/common/Button"
import { CreateFormContext } from "contexts/CreateFormContext"
import api from "services/api"
import { BASE_URL } from "constants/endpoints"
import { useHistory } from "react-router-dom"

const SuccessPicto = styled(Success)`
  font-size: 107px;
  margin-right: 25px;
`

const Logo = styled(Image)`
  width: 260px;
  margin-bottom: 36px;
`
const RowContainer = styled(Row)`
  min-height: 100vh;
`

const Container = styled(ElContainer)`
  max-width: 600px;
  width: 100%;
  padding: 50px !important;
`

const RowContent = styled(Row)`
  flex-direction: column;
`

const TitleText = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 25px;
  font-weight: bold;
  text-align: center;
`
const Spacer = styled.div`
  height: 10px;
`

const FormCreated = () => {
  const { newFormId } = useContext(CreateFormContext)
  let history = useHistory()

  const setFormStatusToPublished = () => {
    api
      .put(`${BASE_URL}/api/forms/${newFormId}`, { status: "published" })
      .then((data) => {
        history.push("/")
      })
      .catch((e) => {
        return alert("erreur : " + e.message)
      })
  }

  const redirectToHomePage = () => {
    history.push("/")
  }

  return (
    <RowContainer className={"align-items-center justify-content-center"}>
      <Col xs="12">
        <RowContent className={"align-items-center justify-content-center"}>
          <SuccessPicto primary={Colors.white} secondary={Colors.primary} />
          <TitleText> Votre formulaire a bien été enregistré ! </TitleText>
        </RowContent>
        <RowContent className={"align-items-center justify-content-center"}>
          <Text>
            Votre formulaire est enregistré en tant que brouillon, pour le publier à l’ensemble des
            coollaborateurs cliquez sur le bouton ci-dessous.
          </Text>
        </RowContent>
        <RowContent className={"align-items-center justify-content-center"}>
          <Button onClick={setFormStatusToPublished}>Publier mon formulaire</Button>
        </RowContent>
        <RowContent>
          <Spacer />
        </RowContent>
        <RowContent className={"align-items-center justify-content-center"}>
          <Button onClick={redirectToHomePage}>Fermer</Button>
        </RowContent>
      </Col>
    </RowContainer>
  )
}

export default FormCreated
