import React, { useState, useRef, useEffect } from "react"

import styled from "styled-components"
import FormControl from "@material-ui/core/FormControl"

import ElementContainer from "components/form/element/ElementContainer"

import Colors from "theme/Colors"
import FormTextField from "components/form/common/SingleLineTextField"

const TextInput = ({
  fullWidth,
  className,
  noLabel,
  element,
  editable,
  onChange,
  noResponsive,
  value,
  minWidth,
  matchingResponse,
  ...props
}) => {
  const [elementValue, setValue] = useState(value ? value.value : "")
  const [alreadyIntializeMandatoryResponse, setAlreadyIntializeMandatoryResponse] = useState(false)
  const handleChange = (event) => {
    onChange({
      ...value,
      type: "text",
      value: event.target.value,
      question: element["@id"],
    })
    setValue(event.target.value)
  }

  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    const initialisedValue = matchingResponse[0].value
    setValue(matchingResponse[0].value)
    onChange({
      ...value,
      type: "text",
      value: initialisedValue,
      question: element["@id"],
    })
  }, [matchingResponse])

  useEffect(() => {
    if (element && element.isMandatory && !alreadyIntializeMandatoryResponse && !value) {
      onChange({
        ...value,
        type: "text",
        value: "",
        question: element["@id"],
      })
      setAlreadyIntializeMandatoryResponse(true)
    }
  }, [element])

  return (
    <ElementContainer
      width={fullWidth ? 100 : element.width}
      minWidth={minWidth}
      noResponsive={noResponsive}
    >
      <FormControl fullWidth={true} margin="none" data-tip data-for="too-long" id="TooltipExample">
        <FormTextField
          id={`element-${element.id}`}
          label={noLabel ? "" : element.title}
          InputProps={{
            inputProps: { style: { minHeight: "25px" } },
            className,
            ...(editable ? {} : { readOnly: true }),
          }}
          InputLabelProps={
            element.isMandatory && {
              style: { fontWeight: "bold" },
            }
          }
          value={elementValue}
          onChange={handleChange}
        />
      </FormControl>
    </ElementContainer>
  )
}

export default styled(TextInput)`
  background-color: transparent;
  min-height: 32px;
  line-height: 0px;
  width: 100%;
  color: ${Colors.black};
  font-size: 13px;
  &.MuiInputBase-root {
    font-family: "Open Sans";
  }
`
