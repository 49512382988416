import React from "react"

import Radio from "@material-ui/core/Radio"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import styled from "styled-components"

import Colors from "theme/Colors"
import SvgRectangle from "@material-ui/icons/CheckBox"

const UnCheckedIcon = styled(CheckBoxOutlineBlankIcon)`
  color: ${Colors.primaryVeryDark};
`
const CheckedIcon = styled(SvgRectangle)`
  color: ${Colors.primary};
`

const StyledRadio = ({ children, className, ...props }) => (
  <Radio
    className={className}
    icon={<UnCheckedIcon fontSize="small" />}
    checkedIcon={<CheckedIcon fontSize="medium" />}
    disableRipple
    {...props}
  />
)

export default styled(StyledRadio)`
  :hover {
    background-color: ${Colors.primaryVeryDarkOpacity} !important;
  }
`
