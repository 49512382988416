import React, { useState, useEffect } from "react"

import styled from "styled-components"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import api from "services/api"
import { BASE_URL } from "constants/endpoints"

import ElementContainer from "components/form/element/ElementContainer"

const SelectList = ({
  fullWidth,
  className,
  noLabel,
  element,
  editable,
  onChange,
  value,
  minWidth,
  noResponsive,
  matchingResponse,
  ...props
}) => {
  const [selectValue, setValue] = useState(value && value.choice ? value.choice : "")
  const [alreadyIntializeMandatoryResponse, setAlreadyIntializeMandatoryResponse] = useState(false)

  useEffect(() => {
    if (element && element.isMandatory && !alreadyIntializeMandatoryResponse && !value) {
      onChange({
        ...value,
        type: "unique_choice",
        choice: null,
        question: element["@id"],
      })
      setAlreadyIntializeMandatoryResponse(true)
    }
  }, [element])

  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    // const initialisedValue = matchingResponse[0].value
    api
      .get(`${BASE_URL}${matchingResponse[0].choice}`)
      .then(({ weight }) => {
        element.choices.map((choice) => {
          if (choice.weight === weight) {
            onChange({
              ...value,
              type: "unique_choice",
              choice: choice["@id"],
              question: element["@id"],
            })
            setValue(choice["@id"])
          }
        })
      })
      .catch((e) => {
        console.error(e.message)
      })
  }, [matchingResponse])

  const handleChange = (event) => {
    const selectedValue = event.target.value || ""

    if (selectedValue) {
      onChange({
        ...value,
        type: "unique_choice",
        choice: selectedValue,
        question: element["@id"],
      })
    } else {
      onChange({
        ...value,
        type: "unique_choice",
        choice: null,
        question: element["@id"],
      })
    }
    setValue(selectedValue)
  }

  return (
    <ElementContainer
      width={fullWidth ? 100 : element.width}
      minWidth={minWidth}
      noResponsive={noResponsive}
    >
      <FormControl fullWidth={true} margin="none">
        {!noLabel && (
          <InputLabel
            htmlFor={`elment-${element.id}`}
            className={element.isMandatory && "mandatory-field"}
          >
            {element.title}
          </InputLabel>
        )}
        <Select
          value={selectValue}
          onChange={handleChange}
          inputProps={{
            name: `elment-${element.id}`,
            id: `elment-${element.id}`,
            className,
            ...(editable ? {} : { readOnly: true }),
          }}
        >
          <MenuItem value={null}>Choisissez une valeur</MenuItem>
          {element.choices.map((choice) => (
            <MenuItem value={choice["@id"]} key={choice.id}>
              {choice.label}
            </MenuItem>
          ))}
        </Select>
        {element.description && <FormHelperText>{element.description}</FormHelperText>}
      </FormControl>
    </ElementContainer>
  )
}

export default styled(SelectList)`
  &.MuiInputBase-input {
    font-family: "Open Sans", sans-serif;
  }
`
