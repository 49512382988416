import React from "react"

const SvgBack = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 12 20" {...props}>
    <path
      d="M10 2l-8 8 8 8"
      stroke="#FFF"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
)

export default SvgBack
