import React, { useContext } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"

import UserContext from "contexts/UserContext"
import AuthenticationContext from "contexts/AuthenticationContext"

import PageTop from "components/layout/PageTop"
import Link from "components/common/Link"
import Container from "components/common/Container"
import SquareButton from "components/common/SquareButton"
import Initials from "components/user/Initials"

import Colors from "theme/Colors"

const InitialsContainer = styled.div`
  justify-content: center;
  display: flex;
  z-index: 1;
`
const ProfileInitials = styled(Initials)`
  background-color: ${Colors.white};
  margin: 0;
`

const ProfileContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const DataContainer = styled(Container)`
  margin-top: -51px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 20px;
  @media screen and (min-width: 1200px) {
    width: 1000px;
  }
`

const Username = styled.p`
  color: ${Colors.primary};
  font-size: 25px;
  font-weight: 600;
  text-transform: capitalize;
`

const ProfileFieldContainer = styled.div`
  margin-bottom: 65px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ProfileField = styled.span`
  color: ${Colors.greyLight};
  font-size: 15px;
  font-weight: 300;
  text-transform: capitalize;
  padding-bottom: 15px;
  margin: 20px 15px 0;
  border-bottom: 1px solid #d7d7d7;
  min-width: 250px;
  text-align: left;
`

const LineReturn = styled.div`
  width: 100%;
`

const LogoutButton = styled(SquareButton)`
  max-width: 250px;
  min-height: 50px;
`

const Profile = ({ className, ...props }) => {
  const { profile } = useContext(UserContext)
  const { setToken } = useContext(AuthenticationContext)
  const backBuntton = () => <Link to="/">Retour</Link>

  const disconnect = () => {
    setToken(null)

    props.history.push("/disconnected")
  }

  if (!profile) {
    return null
  }
  return (
    <div className={className}>
      <PageTop getBackComponent={backBuntton} />
      {profile && (
        <ProfileContainer>
          <InitialsContainer>
            <ProfileInitials firstname={profile.firstname} lastname={profile.lastname} size={102} />
          </InitialsContainer>
          <DataContainer>
            <Username>
              {profile.firstname.toLowerCase()} {profile.lastname.toLowerCase()}
            </Username>
            <ProfileFieldContainer>
              <ProfileField>{profile.firstname.toLowerCase()}</ProfileField>
              <ProfileField>{profile.lastname.toLowerCase()}</ProfileField>
              <LineReturn />
              <ProfileField>{profile.location.code}</ProfileField>
              <ProfileField>{profile.postes[0] ? profile.postes[0].label : ""}</ProfileField>
            </ProfileFieldContainer>
            <LogoutButton onClick={disconnect}>Se déconnecter</LogoutButton>
          </DataContainer>
        </ProfileContainer>
      )}
    </div>
  )
}

export default styled(Profile)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
