import React, { useState, useRef, useEffect } from "react"

import styled from "styled-components"
import FormControl from "@material-ui/core/FormControl"

import ElementContainer from "components/form/element/ElementContainer"

import Colors from "theme/Colors"
import FormTextField from "components/form/common/SingleLineTextField"
import Text from "components/common/Text"

const ErrorText = styled(Text)`
  font-size: 13px;
  color: ${Colors.red};
`

const Numeric = ({
  fullWidth,
  className,
  element,
  noLabel,
  editable,
  onChange,
  value,
  minWidth,
  noResponsive,
  parentType,
  matchingResponse,
  ...props
}) => {
  const [elementValue, setValue] = useState(value ? value.value : "")
  const [notANumberWarning, setNotANumberWarning] = useState(false)
  const [alreadyIntializeMandatoryResponse, setAlreadyIntializeMandatoryResponse] = useState(false)

  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    const initialisedValue = matchingResponse[0].value
    onChange({
      ...value,
      type: "numeric_response",
      value: initialisedValue,
      question: element["@id"],
    })
    setValue(initialisedValue)
  }, [matchingResponse])

  useEffect(() => {
    if (element && element.isMandatory && !alreadyIntializeMandatoryResponse && !value) {
      onChange({
        ...value,
        type: "numeric_response",
        value: null,
        question: element["@id"],
      })
      setAlreadyIntializeMandatoryResponse(true)
    }
  }, [element])

  const handleChange = (event) => {
    const inputWithReplacedComma = event.target.value.replace(",", ".")
    // const floatRegex = /^[+-]?\d+((\.\d+)|(\,\d+))?$/
    const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
    if (!inputWithReplacedComma.match(floatRegex) && inputWithReplacedComma !== "") {
      setNotANumberWarning(true)
      setTimeout(() => {
        setNotANumberWarning(false)
      }, 3000)
      return
    }
    setNotANumberWarning(false)
    setValue(inputWithReplacedComma)
    onChange({
      ...value,
      type: "numeric_response",
      value: parseFloat(inputWithReplacedComma),
      question: element["@id"],
    })
  }

  return (
    <ElementContainer
      width={fullWidth ? 100 : element.width}
      minWidth={minWidth}
      noResponsive={noResponsive}
    >
      <FormControl fullWidth={true} margin="none" data-tip data-for="too-long" id="TooltipExample">
        <FormTextField
          id={`element-${element.id}`}
          label={noLabel ? "" : element.title}
          InputProps={{
            inputProps: { style: { "min-height": "25px" } },
            className,
            ...(editable ? {} : { readOnly: true }),
          }}
          value={elementValue}
          onChange={handleChange}
          InputLabelProps={
            element.isMandatory && {
              style: { fontWeight: "bold" },
            }
          }
        />
      </FormControl>
      {notANumberWarning && <ErrorText>Attention, vous devez insérer un nombre</ErrorText>}
    </ElementContainer>
  )
}

export default styled(Numeric)`
  background-color: transparent;
  min-height: 32px;
  line-height: 0px;
  width: 100%;
  color: ${Colors.black};
  font-size: 13px;
  &.MuiInputBase-root {
    font-family: "Open Sans";
  }
`
