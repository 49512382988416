import React, { useState, useEffect, useContext } from "react"
import { Row, Col } from "@bootstrap-styled/v4"
import FormControl from "@material-ui/core/FormControl"
import styled from "styled-components"
import Settings from "components/icons/Settings"
import Popup from "reactjs-popup"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"

import Select from "@material-ui/core/Select"

import Parameters from "components/adminCreateForm/fieldsParameters/Parameters"
import Text from "components/common/Text.js"
import { CreateFormContext } from "contexts/CreateFormContext"

const formElts = {
  text_input: {
    name: "Champ de saisie libre",
    advises: "Renseignez le libellé du champ et choisissez le pourcentage de la largeur",
  },
  select_list: {
    name: "Liste déroulante",
    advises:
      "Renseignez le libellé du champ et choisissez le pourcentage de la largeur. Saisissez les choix que l'utilisateur pourra sélectionner",
  },
  sub_question: {
    name: "Question Oui/Non",
    advises: "",
  },
  text_area: { name: "Zone de texte", advises: "" },
  attachment: { name: "Dossier à joindre", advises: "" },
  investment: {
    name: "Détails d'investissement",
    advises: "",
  },
  business_unit: { name: "Business Unit", advises: "" },
  site: { name: "Nom du site", advises: "" },
  radio: { name: "Question à choix unique", advises: "Insérez les réponses possibles" },
  checkbox: { name: "Checkbox", advises: "Insérez les réponses possibles" },
  date_time: { name: "Date", advises: "" },
  container: { name: "Container" },
  comment_question: { name: "Commentaire" },
  numeric_question: { name: "Question numérique", advises: "" },
  table_question: { name: "Tableau de questions", advises: "" },
  fixed_table_question: { name: "Tableau à nombre de lignes fixes", advises: "" },
  sum: { name: "Somme", advises: "" },
}

const SettingsButton = styled(Settings)`
  cursor: pointer;
  font-size: 24px;
`
const SettingsDiv = styled.div`
  min-width: 100px;
  min-height: 100px;
  max-height: 600px;
  max-width: 900px;
  text-align: center;
  overflow-x: hidden;
  .MuiTextField-root {
    margin-top: 5px;
  }
`
const SettingsBigLogo = styled(Settings)`
  font-size: 40px;
`
const TitleFont = styled.div`
  color: #24445c;
  titlefont-family: "Open Sans";
  titlefont-size: 18px;
  titlefont-weight: 600;
`
const UserAdvisesText = styled(Text)`
  color: #24445c;
  font-size: 13px;
`

const FieldSettings = ({ saveParameters, fieldParameters, parentType, closePopup, ...props }) => {
  const [selectedFieldType, setSelectedFieldType] = useState(fieldParameters.type)
  const [fieldTypeInitialized, setFieldTypeInitialized] = useState(false)
  const [fieldTypesItems, setFieldTypeItems] = useState([])
  const [previousQuestion, setPreviousQuestion] = useState(
    fieldParameters.questionToRetrieveResponse ? fieldParameters.questionToRetrieveResponse : null
  )
  const { allPreviousQuestions } = useContext(CreateFormContext)

  const handlePreviousQuestionChange = (event) => {
    setPreviousQuestion(event.target.value)
  }

  useEffect(() => {
    if (selectedFieldType && !fieldTypeInitialized) {
      setFieldTypeInitialized(true)
    }
    if (previousQuestion && fieldTypeInitialized) {
      setPreviousQuestion(null)
    }
  }, [selectedFieldType])

  useEffect(() => {
    fieldParameters.questionToRetrieveResponse = previousQuestion
    if (previousQuestion === null) {
      fieldParameters.retrieveResponseInThePreviousDemande = false
    } else {
      fieldParameters.retrieveResponseInThePreviousDemande = true
    }
  }, [previousQuestion])

  useEffect(() => {
    if (parentType === "table_question" || parentType === "fixed_table_question") {
      setFieldTypeItems([
        <MenuItem value={"text_input"} key={"text_input"}>
          {formElts["text_input"].name}
        </MenuItem>,
        <MenuItem value={"select_list"} key={"select_list"}>
          {formElts["select_list"].name}
        </MenuItem>,
        <MenuItem value={"date_time"} key={"date_time"}>
          {formElts["date_time"].name}
        </MenuItem>,
        <MenuItem value={"numeric_question"} key={"numeric_question"}>
          {formElts["numeric_question"].name}
        </MenuItem>,
        <MenuItem value={"sum"} key={"sum"}>
          {formElts["sum"].name}
        </MenuItem>,
      ])
    }

    for (const property in formElts) {
      if (property !== "container" && property !== "sum") {
        fieldTypesItems.push(
          <MenuItem value={property} key={property}>
            {formElts[property].name}
          </MenuItem>
        )
      }
    }
  }, [])

  const handleFieldTypeChange = (event) => {
    setSelectedFieldType(event.target.value)
  }
  return (
    <Popup
      trigger={<SettingsButton />}
      modal
      closeOnDocumentClick
      contentStyle={{ width: "", borderRadius: "10px", padding: "25px" }}
    >
      {(close) => (
        <SettingsDiv>
          <Row className="align-items-top">
            <Col xs="auto">
              <SettingsBigLogo />
            </Col>
            <Col xs="auto">
              <h3>
                <TitleFont>
                  Paramètres
                  {selectedFieldType &&
                    formElts[selectedFieldType] &&
                    ` du champ de type : "
                 ${formElts[selectedFieldType].name}"`}
                </TitleFont>
              </h3>
              <UserAdvisesText>
                {selectedFieldType &&
                  formElts[selectedFieldType] &&
                  formElts[selectedFieldType].advises}
              </UserAdvisesText>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col>
              <Row>
                <Col xs="12">
                  <FormControl componentClass="CustomFormControl" fullWidth={true}>
                    <InputLabel id="field-type-select">Type du champ</InputLabel>
                    <Select
                      value={selectedFieldType}
                      onChange={handleFieldTypeChange}
                      margin="dense"
                    >
                      <MenuItem value={null}>Choisissez une valeur</MenuItem>
                      {fieldTypesItems}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              {selectedFieldType &&
                allPreviousQuestions &&
                selectedFieldType !== "table_question" &&
                selectedFieldType !== "fixed_table_question" &&
                selectedFieldType !== "container" && (
                  <Row>
                    <Col xs="12">
                      <FormControl componentClass="CustomFormControl" fullWidth={true}>
                        <InputLabel id="field-type-select">
                          Question dont il faut recopier les réponses
                        </InputLabel>
                        <Select
                          value={previousQuestion}
                          onChange={handlePreviousQuestionChange}
                          margin="dense"
                        >
                          <MenuItem value={null}></MenuItem>
                          {allPreviousQuestions.map((question, key) => {
                            if (question.type === selectedFieldType) {
                              return (
                                <MenuItem value={question["@id"]} key={key}>
                                  {question.title}
                                </MenuItem>
                              )
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                )}
              {selectedFieldType && (
                <Parameters
                  element={selectedFieldType}
                  saveParameters={saveParameters}
                  fieldParameters={fieldParameters}
                  closePopup={close}
                  parentType={parentType}
                />
              )}
            </Col>
          </Row>
        </SettingsDiv>
      )}
    </Popup>
  )
}

export default FieldSettings
