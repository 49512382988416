import React, { useState, useEffect } from "react"

import { Row, Col } from "@bootstrap-styled/v4"

import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"
import ChangeLabel from "components/adminCreateForm/fieldsParameters/commonParameters/ChangeLabel"

const SubQuestionParameters = ({ availableWidth, saveParameters, fieldParameters, ...props }) => {
  const [subfieldDescription, setSubfieldDescription] = useState(
    fieldParameters.subFieldDescription || ""
  )
  const [subfieldTitle, setSubfieldTitle] = useState(fieldParameters.subFieldTitle || "")
  const validateChangement = () => {
    saveParameters({
      type: "sub_question",
      subFieldDescription: subfieldDescription,
      subFieldTitle: subfieldTitle,
    })
  }

  const onSubfieldTitleChange = (event) => {
    setSubfieldTitle(event.target.value || "")
  }

  const onSubfieldDescriptionChange = (event) => {
    setSubfieldDescription(event.target.value || "")
  }
  return (
    <div>
      <Row>
        <Col>
          <ChangeLabel
            onLabelChange={onSubfieldTitleChange}
            label={subfieldTitle}
            inputLabel={"Titre du sous-champ"}
          />
        </Col>
      </Row>
      <Row className={"justify-content-center"}>
        <OkButton onClick={validateChangement}>Valider</OkButton>
      </Row>
    </div>
  )
}

export default SubQuestionParameters
