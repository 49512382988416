import React, { useState, useEffect } from "react"

import styled from "styled-components"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"

import ElementContainer from "components/form/element/ElementContainer"
import { Row, Col } from "@bootstrap-styled/v4"
import api from "services/api"
import { BASE_URL } from "constants/endpoints"

const CheckboxForm = ({
  className,
  element,
  editable,
  onChange,
  value,
  matchingResponse,
  ...props
}) => {
  const [choiceValue, setValue] = useState([])
  const [alreadyIntializeMandatoryResponse, setAlreadyIntializeMandatoryResponse] = useState(false)
  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    // const initialisedValue = matchingResponse[0].value
    if (matchingResponse[0].choices.length === 0) {
      return
    }
    const previousChoices = matchingResponse[0].choices
    const checkedWeight = []
    previousChoices.map((choice, index) => {
      api
        .get(`${BASE_URL}${choice}`)
        .then(({ weight }) => {
          checkedWeight.push(weight)
        })
        .then(() => {
          if (checkedWeight.length !== previousChoices.length) {
            return
          }
          const userChoices = []
          checkedWeight.map((weightToChecked) => {
            element.choices.map((choice) => {
              if (choice.weight === weightToChecked) {
                userChoices.push(choice["@id"])
              }
            })
          })

          // userChoices, ce sont les choices à checked
          choiceValue.map((choice) => {
            if (userChoices.includes(choice.id)) {
              choice.checked = true
            }
          })

          onChange({
            ...value,
            type: "multiple_choice",
            question: element["@id"],
            choices: userChoices,
          })
          setValue([...choiceValue])
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

    // api
    //   .get(`${BASE_URL}${matchingResponse[0].choice}`)
    //   .then(({ weight }) => {
    //     element.choices.map((choice) => {
    //       if (choice.weight === weight) {
    //         onChange({
    //           ...value,
    //           type: "unique_choice",
    //           choice: choice["@id"],
    //           question: element["@id"],
    //         })
    //         setValue(choice["@id"])
    //       }
    //     })
    //   })
    //   .catch((e) => {
    //     console.error(e.message)
    //   })
  }, [matchingResponse])

  useEffect(() => {
    if (element && element.isMandatory && !alreadyIntializeMandatoryResponse && !value) {
      onChange({
        ...value,
        type: "multiple_choice",
        question: element["@id"],
        choices: [],
      })
      setAlreadyIntializeMandatoryResponse(true)
    }
  }, [element])

  useEffect(() => {
    element.choices.map((choice) => {
      choiceValue.push({ id: choice["@id"], checked: false })
    })

    if (value && value.choices) {
      value.choices.map((choice) => {
        const found = choiceValue.find((element) => element.id === choice)
        if (found) {
          found.checked = true
        }
      })
    }
    //Je recupère les bonnes choiceValues mais le state ne rerend pas les checkbox
    setValue([...choiceValue])
  }, [])

  const handleChange = (event) => {
    if (choiceValue[event.target.name]) {
      choiceValue[event.target.name].checked = event.target.checked
    }
    const userChoices = []
    choiceValue.map((choice) => {
      if (choice.checked) {
        userChoices.push(choice.id)
      }
    })
    onChange({
      ...value,
      type: "multiple_choice",
      question: element["@id"],
      choices: userChoices,
    })
    setValue([...choiceValue])
  }

  return (
    <ElementContainer width={element.width}>
      <Row>
        <Col xs="auto">
          <FormLabel component="legend" className={element.isMandatory && "mandatory-field"}>
            {element.title}
          </FormLabel>
        </Col>
      </Row>
      <FormGroup row>
        {element.choices.map((choice, index) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={choiceValue[index] ? choiceValue[index].checked : false}
                onChange={handleChange}
                name={index}
                color="primary"
              />
            }
            label={choice.label}
          />
        ))}
      </FormGroup>
    </ElementContainer>
  )
}

export default styled(CheckboxForm)``
