import React from "react"

import { Row, Col } from "@bootstrap-styled/v4"

import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"

const SiteParameters = ({ saveParameters, fieldParameters, label, fieldWidth, ...props }) => {
  const validateChangement = () => {
    saveParameters({ type: "site" })
  }
  return (
    <Row className={"justify-content-center"}>
      <OkButton onClick={validateChangement}>Valider</OkButton>
    </Row>
  )
}

export default SiteParameters
