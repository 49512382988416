import React from "react"
import { Modal, ModalHeader, ModalBody } from "@bootstrap-styled/v4"
import ReactHtmlParser from "react-html-parser"

import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"

const CustomModal = ({ title, content, toggle, ...props }) => {
  function isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  if (!content) {
    content = "<p></p>"
  }

  let contentToDisplay = content
  if (isJson(content)) {
    contentToDisplay = draftToHtml(JSON.parse(content))
  }

  return (
    <Modal {...props}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{ReactHtmlParser(contentToDisplay)}</ModalBody>
    </Modal>
  )
}

export default CustomModal
