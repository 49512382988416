import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"

import PageTop from "components/layout/PageTop"
import Container from "components/common/Container"
import DisplayDemandesList from "components/demandesList/DisplayDemandesList"
import SearchDemandes from "components/demandesList/SearchDemandes"
import Pager from "components/common/Pager"

import api from "services/api"
import {
  GET_DEMANDES_ENDPOINT,
  DELETE_DEMANDE_ENDPOINT,
  POST_CLONE_DEMANDES_ENPOINT,
  GET_PDF_ENDPOINT,
} from "constants/endpoints"
import { ACTIVE } from "constants/demande"
import DeleteDone from "components/demandesList/DeleteDoneAlert"
import UserContext from "contexts/UserContext"

const CustomContainer = styled(Container)`
  padding: 10px 0 10px 0;
  margin: 0px 70px 30px !important;

  @media screen and (max-width: 1200px) {
    margin: 0;
    max-width: 100%;
    margin: 0;
    text-align: center;
    overflow: scroll;
  }
`

const DemandesList = ({ className, ...props }) => {
  const [demandesList, setDemandeList] = useState([])
  const [searchValues, setSearchValues] = useState({})
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [totalItems, setTotalItems] = useState(1)
  const [deletedStatus, setDeletedStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const { profile } = useContext(UserContext)

  const refreshDemandeList = () => {
    let params = ""
    if (!searchValues.status) {
      // params += "?status[]=draft&status[]=doing&status[]=to_validate&status[]=waitting"
      params += "?status[]=to_validate&status[]=doing&status[]=draft&status[]=waitting"
    } else {
      params += `?status[]=${searchValues.status}`
    }
    if (searchValues.year) {
      params += `&year=${searchValues.year}`
    }
    if (searchValues.form) {
      params += `&form=${searchValues.form}`
    }
    if (searchValues.text) {
      params += `&full_text=${searchValues.text}`
    }
    if (searchValues.bu) {
      params += `&bu=${searchValues.bu}`
    }
    params += `&page=${page}&itemsPerPage=${itemsPerPage}`
    return api.get(`${GET_DEMANDES_ENDPOINT}${params}`).then((data) => {
      if (!data || !data["hydra:member"]) {
        return
      }
      setDemandeList(data["hydra:member"])
      setTotalItems(data["hydra:totalItems"])
    })
  }

  useEffect(() => {
    refreshDemandeList().then(() => setLoading(false))
  }, [
    searchValues.status,
    searchValues.text,
    searchValues.year,
    searchValues.form,
    searchValues.bu,
    page,
    itemsPerPage,
  ])

  const handleSearchValuesChange = (newValues) => {
    setSearchValues(newValues)
  }

  const handleDelete = (demandeId) => () => {
    setLoading(true)
    api.delete(DELETE_DEMANDE_ENDPOINT(demandeId)).then(() => {
      setDeletedStatus(true)
      refreshDemandeList().then(() => setLoading(false))
    })
  }

  const handleDuplicate = (demande) => () => {
    setLoading(true)
    const data = { demandeToClone: demande }
    api
      .post(POST_CLONE_DEMANDES_ENPOINT, data)
      .then((response) => {
        //console.log(response)
        setTimeout(() => {
          refreshDemandeList().then(() => setLoading(false))
        }, 2000)
      })
      .catch((e) => console.log(e, "handle duplicate"))
  }

  const handlePdf = (demandeId, demandeNumber) => () => {
    setLoading(true)
    api
      .get(GET_PDF_ENDPOINT(demandeId))
      .then((blob) => {
        // var file = new Blob([data], { type: "application/pdf" })
        //const file = new Blob([data], { type: "application/pdf" })
        const filename = demandeNumber ? demandeNumber : "demande_" + demandeId
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          const a = document.createElement("a"),
            url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          setTimeout(function () {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }, 0)
        }
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  return (
    <div className={className}>
      <PageTop title={"Demandes en cours de traitement"} getBackComponent={() => null} />
      <SearchDemandes
        type={ACTIVE}
        searchValues={searchValues}
        setSearchValues={handleSearchValuesChange}
      />
      <CustomContainer>
        {deletedStatus && (
          <DeleteDone
            deletedStatus={deletedStatus}
            handleDeletedStatus={setDeletedStatus}
          ></DeleteDone>
        )}
        <DisplayDemandesList
          demandes={demandesList}
          deleteDemand={handleDelete}
          duplicateDemand={handleDuplicate}
          exportPdf={handlePdf}
          loading={loading}
          profile={profile}
        />
        <Pager
          page={page}
          itemsPerPage={itemsPerPage}
          onPageChange={setPage}
          onItemsPerPageChange={setItemsPerPage}
          totalItems={totalItems}
        />
      </CustomContainer>
    </div>
  )
}

export default styled(DemandesList)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  @media screen and (max-width: 1200px) {
    text-align: center;
  }
`
