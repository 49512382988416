import React from "react"
import { Row, Col } from "@bootstrap-styled/v4"

import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"

const AttachmentParameters = ({ saveParameters, fieldParameters, ...props }) => {
  const validateChangement = () => {
    saveParameters({ type: "attachment" })
  }

  return (
    <>
      <Row className={"justify-content-center"}>
        <OkButton onClick={validateChangement}>Valider</OkButton>
      </Row>
    </>
  )
}

export default AttachmentParameters
