import React from "react"

const SvgCrossedPen = (props) => (
  <svg width="1em" height="1em" viewBox="-1 -1 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#4A4A4A" cx={15} cy={15} r={15} />
      <path
        d="M23.028 6.972a3.326 3.326 0 00-4.698 0v.001L7.548 17.754a.3.3 0 00-.069.113.26.26 0 00-.01.025l-1.46 5.73a.302.302 0 00.369.368l5.73-1.459c.008-.002.015-.006.024-.01a.3.3 0 00.114-.069l10.78-10.78.002-.002a3.325 3.325 0 000-4.698zm-.427.427a2.722 2.722 0 01.202 3.618l-3.82-3.82a2.722 2.722 0 013.618.202zm-4.057.215l3.843 3.842-1.083 1.083-3.843-3.844 1.083-1.081zM7.087 21.84c.477.214.859.596 1.073 1.073l-1.44.366.367-1.439zm1.661.922a2.737 2.737 0 00-1.51-1.51l.685-2.695 3.52 3.52-2.695.685zm3.284-.951l-3.844-3.843 8.846-8.846 3.843 3.844-8.845 8.845z"
        fill="#4A4A4A"
        fillRule="nonzero"
      />
      <path stroke="#4A4A4A" strokeLinecap="square" d="M5.5 4.5l19 22" />
    </g>
  </svg>
)

export default SvgCrossedPen
