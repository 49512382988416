import React, { useState } from "react"
import SquareButton from "components/common/SquareButton"
import styled from "styled-components"
import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"
import ChangeLabel from "components/adminCreateForm/fieldsParameters/commonParameters/ChangeLabel"
import { choicesValuesConflicts } from "components/adminCreateForm/fieldsParameters/SelectListParameters"
import Delete from "components/icons/Delete"
import Colors from "theme/Colors"
import { Row, Col } from "@bootstrap-styled/v4"
import { resetWeight } from "components/adminCreateForm/functions/resetWeight.js"

const AddChoice = styled(SquareButton)`
  //max-width: 50px !important;
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
`
const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`

const InvestmentDetailsParameters = ({
  saveParameters,
  fieldParameters,
  label,
  fieldWidth,
  ...props
}) => {
  const [choices, setChoices] = useState(fieldParameters.choices || [])
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false)
  const validateChangement = () => {
    if (choices.length !== 0) {
      saveParameters({
        type: "investment",
        categories: choices,
      })
    } else {
      setDisplayErrorMessage(true)
    }
  }

  const handleAddChoice = () => {
    var joined = choices.concat([
      {
        label: "",
        weight: choices.length,
        value: "",
      },
    ])
    setChoices([...joined])
  }

  const handleChoiceLabelChange = (newLabelEvent, index) => {
    choices[index].label = newLabelEvent.target.value
    setChoices([...choices])
  }

  const handleChoiceValueLabelChange = (newValueLabelEvent, index) => {
    choices[index].value = newValueLabelEvent.target.value
    setChoices([...choices])
  }

  const deleteChoice = (index) => {
    let updatedArrayOfChoices = choices.slice()
    updatedArrayOfChoices.splice(index, 1)
    resetWeight(index, updatedArrayOfChoices.length, updatedArrayOfChoices)
    setChoices([...updatedArrayOfChoices])
  }

  return (
    <>
      <Row className="align-items-end">
        {choices.map((choice, index) => {
          return (
            <>
              <Col xs="10">
                <ChangeLabel
                  onLabelChange={(newLabelEvent) => {
                    handleChoiceLabelChange(newLabelEvent, index)
                  }}
                  inputLabel={"Libellé du choix"}
                  label={choice.label}
                />
              </Col>
              <Col xs="2">
                <DeleteButton
                  onClick={() => {
                    deleteChoice(index)
                  }}
                />
              </Col>
            </>
          )
        })}
      </Row>
      <Row className={"justify-content-center"}>
        <AddChoice onClick={handleAddChoice}>Ajouter un élément à la liste déroulante</AddChoice>{" "}
      </Row>
      {displayErrorMessage && (
        <h4>
          Vos changements n'ont pas été pris en compte. Vérifiez que le libellé du champ est bien
          renseigné.
        </h4>
      )}
      <Row className={"justify-content-center"}>
        <OkButton onClick={validateChangement}>Valider</OkButton>
      </Row>
    </>
  )
}

export default InvestmentDetailsParameters
