import React, { useContext } from "react"
import styled from "styled-components"

import PageTop from "components/layout/PageTop"

import ProgressionViewer from "components/newDemande/ProgressionViewer"
import FormChooser from "components/newDemande/FormChooser"
import { Next, Back } from "components/icons"
import DemandeCreationContext from "contexts/DemandeCreationContext"
import CancelLink from "components/common/CancelLink"
import NavigationButton from "components/common/NavigationButton"
import { Row, Col } from "@bootstrap-styled/v4"

const Spacer = styled.span`
  width: 20px;
  height: 1 px;
`
const CustomRow = styled(Row)`
  margin: 30px 70px 30px 70px !important;
  @media screen and (max-width: 1200px) {
    margin: 10px 0px 10px 50px !important;
  }
`

const ChooseForm = ({ className, ...props }) => {
  const { form } = useContext(DemandeCreationContext)
  const cancelButton = () => <CancelLink></CancelLink>

  const nextButton = () => (
    <NavigationButton to="contenu" isActive={!!form} isNext={true}>
      Suivant
      <Spacer />
      <Next />
    </NavigationButton>
  )
  const previousButton = () => (
    <NavigationButton to="choix-categorie" isActive={true}>
      <Back />
      <Spacer />
      Précédent
    </NavigationButton>
  )

  return (
    <div className={className}>
      <PageTop getBackComponent={cancelButton} title={"Nouvelle demande"} />
      <ProgressionViewer
        next={nextButton}
        previous={previousButton}
        title="Choisir un formulaire"
        percent={30}
      ></ProgressionViewer>
      <FormChooser />
      <CustomRow className="justify-content-end">
        <Col xs="auto p-0">{nextButton()}</Col>
      </CustomRow>
    </div>
  )
}

export default styled(ChooseForm)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
