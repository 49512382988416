import React, { useEffect } from "react"
import { Link } from "react-router-dom"

import styled from "styled-components"
import Colors from "theme/Colors"
import { Table, Thead, Tr, Th, Tbody, Td, Row, Col } from "@bootstrap-styled/v4"

import Status from "components/demandesList/Status"

import Show from "components/icons/Show"

import DeleteAlert from "components/demandesList/DeleteDemandeButton"
import DuplicatePicto from "components/common/DuplicateButton"
import LoaderOverlay from "components/common/LoaderOverlay"
import PdfPicto from "components/common/PdfButton"
import CustomTip from "components/common/ToolTip"

const moment = require("moment")

const CustomTable = styled(Table)`
  // width: 100%;
`

const TableHead = styled(Th)`
  font-size: 13px;
  font-weight: 600;
  border-top: none !important;
  vertical-align: middle !important;
  border-color: ${Colors.background} !important;
  border-bottom-width: 1px !important;
`

const TableContent = styled(Td)`
  font-size: 13px;
  border-top: none !important;
  vertical-align: middle !important;
`

const ShowPicto = styled(Show)`
  font-size: 30px;
`
const DisplayDemandesList = ({
  className,
  demandes,
  editable,
  deleteDemand,
  delDemand,
  duplicateDemand,
  exportPdf,
  loading,
  profile,
  ...props
}) => {
  useEffect(() => CustomTip.rebuild(), [demandes])
  const currentPathname = window.location.pathname

  if (loading) {
    return <LoaderOverlay />
  }

  return (
    <CustomTable>
      {/*c'est le className=" ..." ci dessous qui posait des prb de responsivité */}
      {/* <CustomTable>className="ml-xl-4 mr-xl-4 ml-2 mr-2"> */}
      <Thead>
        <Tr>
          <TableHead className="ml-2">DATE DE CRÉATION</TableHead>
          <TableHead>N° DU DOCUMENT</TableHead>
          <TableHead>TYPE DE DEMANDE</TableHead>
          <TableHead>LIBELLE DE LA DEMANDE</TableHead>
          <TableHead>DEMANDEUR</TableHead>
          <TableHead>BU</TableHead>
          <TableHead>STATUT</TableHead>
          <TableHead>ACTIONS</TableHead>
        </Tr>
      </Thead>
      <Tbody>
        <CustomTip
          showTipPointer={false}
          getContent={(dataTip) => {
            return dataTip
          }}
          place="bottom"
          effect="solid"
        />
        {demandes.map((demande) => {
          if (!demande) {
            return null
          }
          return (
            <Tr key={demande.id}>
              <TableContent>{moment(demande.createdAt).format("DD/MM/YYYY")}</TableContent>
              <TableContent>{demande.number}</TableContent>
              <TableContent>{demande.form.title}</TableContent>
              <TableContent>{demande.title}</TableContent>
              <TableContent>
                <span>
                  {demande.author.firstname} {demande.author.lastname}
                </span>
              </TableContent>
              <TableContent>{demande.businessUnit ? demande.businessUnit.code : ""}</TableContent>
              <TableContent>
                <Status
                  status={demande.status}
                  {...(demande.currentValidator
                    ? {
                        "data-tip": `${demande.currentValidator.firstname} ${demande.currentValidator.lastname}`,
                      }
                    : null)}
                />
              </TableContent>
              <TableContent>
                <Row className="align-items-center justify-content-around" noGutters={true}>
                  <Col xs="auto">
                    <PdfPicto onClick={exportPdf(demande.id, demande.number)} data-tip="PDF" />
                  </Col>
                  <Col xs="auto">
                    {demande.author.id && demande.author.id == profile.id && (
                      <DuplicatePicto onClick={duplicateDemand(demande.id)} data-tip="Dupliquer" />
                    )}
                  </Col>
                  <Col xs="auto">
                    <Link to={currentPathname + `/${demande.id}`}>
                      <ShowPicto data-tip="ouvrir" />
                    </Link>
                  </Col>
                  <Col xs="auto">
                    {demande.status === "draft" && (
                      <>
                        <DeleteAlert
                          delDemand={deleteDemand(demande.id)}
                          alertTitle={demande.title}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </TableContent>
            </Tr>
          )
        })}
      </Tbody>
    </CustomTable>
  )
}

export default DisplayDemandesList
