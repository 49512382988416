import React, { useEffect, useState } from "react"
// import ModalTemplate from "components/common/ModalTemplate"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import { Row, Col } from "@bootstrap-styled/v4"
import styled from "styled-components"
import Button from "components/common/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

const Wrapper = styled.div``

const ValidateButton = styled(Button)`
  min-width: 200px;
`

const ButtonsContainer = styled(Row)`
  margin: 10px 70px 30px !important;
`

const SelectValidatorModal = ({ valideurs, onValidate, ...props }) => {
  const [availableValideurs, setAvailableValideurs] = useState([])
  const [menuItemsArray, setMenuItemsArray] = useState([])
  const [selectedValideur, setSelectedValideur] = useState(null)
  const [open, setOpen] = useState(true)
  useEffect(() => {
    const localAvailableValideurs = []
    const localMenuItemsArray = []

    valideurs.map((valideur, index) => {
      if (valideur.validated) {
        localAvailableValideurs.push(valideur)
        localMenuItemsArray.push(
          <MenuItem value={valideur.id} key={valideur.id}>
            {valideur.label} - {valideur.profile.firstname} {valideur.profile.lastname}
          </MenuItem>
        )
      }
    })
    setMenuItemsArray([...localMenuItemsArray])
    setAvailableValideurs([...localAvailableValideurs])
  }, [])

  const handleValideurChange = (e) => {
    setSelectedValideur(e.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Wrapper>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        fullWidth={true}
      >
        <DialogTitle>Retour à un valideur</DialogTitle>
        <DialogContent>
          <form>
            <FormControl fullWidth>
              <InputLabel>Sélectionnez un valideur</InputLabel>
              <Select value={selectedValideur} onChange={handleValideurChange}>
                {menuItemsArray}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <ValidateButton
            onClick={handleClose}
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
              marginRight: 0,
            }}
          >
            Annuler
          </ValidateButton>
          <ValidateButton
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
              marginRight: 0,
            }}
            onClick={() => {
              if (!selectedValideur) {
                return
              }
              onValidate(selectedValideur)
            }}
          >
            <Col xs="auto">Enregistrer</Col>
          </ValidateButton>
        </DialogActions>
      </Dialog>
      {/* <ModalTemplate {...props}>
        <Row>
          <Col xs="12">
            <FormControl fullWidth>
              <InputLabel>Sélectionnez un valideur</InputLabel>
              <Select value={selectedValideur} onChange={handleValideurChange}>
                {menuItemsArray}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <ButtonsContainer className="justify-content-end">
          <Col xs="auto">
            <ValidateButton
              style={{ justifyContent: "center", alignItems: "center" }}
              onClick={() => {
                if (!selectedValideur) {
                  return
                }
                onValidate(selectedValideur)
              }}
            >
              <Col xs="auto">Enregistrer</Col>
            </ValidateButton>
          </Col>
        </ButtonsContainer>
      </ModalTemplate> */}
    </Wrapper>
  )
}

export default SelectValidatorModal
