import React, { useEffect, useState, useContext } from "react"

import styled from "styled-components"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

import ElementContainer from "components/form/element/ElementContainer"

import { GET_LOCATIONS_ENDPOINT } from "constants/endpoints"
import api from "services/api"
import UserContext from "contexts/UserContext"

const Site = ({ className, element, editable, onChange, value, matchingResponse, ...props }) => {
  const [selectValue, setValue] = useState(value && value.value ? value.value : "")
  const [sites, setSites] = useState([])
  const [alreadyIntializeMandatoryResponse, setAlreadyIntializeMandatoryResponse] = useState(false)
  const { profile } = useContext(UserContext)

  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    const initialisedValue = matchingResponse[0].value
    onChange({
      ...value,
      type: "text",
      value: initialisedValue,
      question: element["@id"],
    })
    setValue(initialisedValue)
  }, [matchingResponse])

  useEffect(() => {
    if (element && element.isMandatory && !alreadyIntializeMandatoryResponse && !value) {
      onChange({
        ...value,
        type: "text",
        value: null,
        question: element["@id"],
      })
      setAlreadyIntializeMandatoryResponse(true)
    }
  }, [element])

  useEffect(() => {
    api
      .get(GET_LOCATIONS_ENDPOINT)
      .then((data) => {
        if (data) {
          setSites(data["hydra:member"])

          const defaultValue = data["hydra:member"].find((item) => {
            return item["@id"] === profile.location
          })

          if (!value && editable) {
            if (defaultValue) {
              onChange({
                ...value,
                type: "text",
                value: defaultValue["@id"],
                question: element["@id"],
              })
              setValue(defaultValue["@id"])
            }
          }
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])

  const handleChange = (event) => {
    const selectedValue = event.target.value || ""
    if (selectedValue) {
      onChange({
        ...value,
        type: "text",
        value: selectedValue,
        question: element["@id"],
      })
    } else {
      onChange({
        ...value,
        type: "text",
        value: null,
        question: element["@id"],
      })
    }
    setValue(selectedValue)
  }

  return (
    <ElementContainer width={element.width}>
      <FormControl fullWidth={true} margin="none">
        <InputLabel
          htmlFor={`elment-${element.id}`}
          className={element.isMandatory && "mandatory-field"}
        >
          {element.title}
        </InputLabel>
        <Select
          value={selectValue}
          onChange={handleChange}
          inputProps={{
            name: `elment-${element.id}`,
            id: `elment-${element.id}`,
            className,
            ...(editable ? {} : { readOnly: true }),
          }}
        >
          <MenuItem value={null}>Choisissez une valeur</MenuItem>
          {sites.map((site) => (
            <MenuItem value={site["@id"]} key={site.id}>
              {site.code}
            </MenuItem>
          ))}
        </Select>
        {element.description && <FormHelperText>{element.description}</FormHelperText>}
      </FormControl>
    </ElementContainer>
  )
}

export default styled(Site)`
  &.MuiInputBase-input {
    font-family: "Open Sans", sans-serif;
  }
`
