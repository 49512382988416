import React, { useState, useContext } from "react"

import styled from "styled-components"
import DemandeCreationContext from "contexts/DemandeCreationContext"
import UserContext from "contexts/UserContext"
import Colors from "theme/Colors"
import { Row } from "@bootstrap-styled/v4"
import ValidationEditFlow from "components/newDemande/ValidationEditFlow"
import ValidatorItem from "components/newDemande/ValidatorItem"
import Container from "components/common/Container"
import Pen from "components/icons/Pen"
import CrossedPen from "components/icons/CrossedPen"
import CustomTip from "components/common/ToolTip"

const ContainerTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`

const EditIcon = styled(Pen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const CantEditIcon = styled(CrossedPen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const FlowContainer = styled.div`
  margin: 40px;
`

const ValidationFlowReview = ({ className, ...props }) => {
  const { valideurs, setValideurs } = useContext(DemandeCreationContext)
  const { profile } = useContext(UserContext)
  const [editFlow, setEditFlow] = useState(false)

  const handleClick = () => {
    setEditFlow(!editFlow)
  }

  if (!profile) {
    return null
  }

  const handleChange = (data) => {
    if (valideurs[data.weight].id !== data.id) {
      return
    }

    valideurs[data.weight] = data
    setValideurs([...valideurs])
  }

  return (
    <Container className={className}>
      <ContainerTitle>
        Flux d’envoi
        {editFlow ? (
          <CantEditIcon onClick={handleClick} />
        ) : (
          <>
            <EditIcon onClick={handleClick} data-tip data-for="editWorkFlow" />
            <CustomTip id="editWorkFlow" place="bottom" effect="solid">
              <span>Editer le work flow</span>
            </CustomTip>
          </>
        )}
      </ContainerTitle>
      {editFlow ? (
        <ValidationEditFlow />
      ) : (
        <FlowContainer>
          <Row>
            {valideurs.map((element, key) => {
              if (!element) {
                return null
              }

              return <ValidatorItem validator={element} key={key} />
            })}
          </Row>
        </FlowContainer>
      )}
    </Container>
  )
}

export default styled(ValidationFlowReview)`
  padding: 30px;
  margin: 0px 70px 60px;
  @media screen and (max-width: 1200px) {
    margin: 0;
    text-align: center;
  }
`
