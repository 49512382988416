import React, { useState } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import ElementContainer from "components/form/element/ElementContainer"
import ReactHtmlParser from "react-html-parser"
import { Row, Col } from "@bootstrap-styled/v4"

import Colors from "theme/Colors"

const Comment = ({ className, element, value, ...props }) => {
  const [elementValue, setValue] = useState(element.content)

  return (
    <ElementContainer width={element.width}>
      <Row className="justify-content-start">
        <div>
          <Col xs="12">{ReactHtmlParser(elementValue)}</Col>
        </div>
      </Row>
    </ElementContainer>
  )
}

export default styled(Comment)`
  background-color: transparent;
  min-height: 32px;
  width: 100%;
  color: ${Colors.black};
  font-size: 13px;
  /* height: 10px; */
  &.MuiInputBase-root {
    font-family: "Open Sans";
  }
`
