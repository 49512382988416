import React from "react"

import styled from "styled-components"

import { Row, Col } from "@bootstrap-styled/v4"

const ButtonCol = styled(Col)`
  margin: 10px 50px 0 50px;
  @media screen and (max-width: 790px) {
    margin: auto;
    margin-top: 10px;
  }
`

const PageBottom = ({ className, next, ...props }) => {
  return (
    <div className={className}>
      <Row className="align-items-end justify-content-end">
        <ButtonCol className="d-xs-block" xs="auto">
          {next && next()}
        </ButtonCol>
      </Row>
    </div>
  )
}

export default styled(PageBottom)`
  padding: 19px 0 0 0;
  margin: 0 70px;
  margin-bottom: 20px;
  text-align: center;

  @media screen and (max-width: 1200px) {
    margin: 0;
  }
`
