import React, { useState, useEffect } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import OutlineSuccess from "components/icons/OutlineSuccess"
import Colors from "theme/Colors"
import ReadOnly from "components/form/validator/ReadOnly"
import Poste from "components/form/validator/Poste"
import { Row, Col } from "@bootstrap-styled/v4"
import Success from "components/icons/Success"
import DeletePicto from "components/icons/Delete"
import { DELETE_VALIDEUR_ENDPOINT } from "constants/endpoints"
import api from "services/api"
import Editable from "components/form/validator/Editable"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const CustomContainer = styled(Container)`
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0 !important;
  }
`

const CheckIcon = styled(OutlineSuccess)`
  font-size: 30px;
  margin-right: 25px;
`
const SuccessPicto = styled(Success)`
  font-size: 30px;
  margin-right: 25px;
`

const ValidatorText = styled.div`
  font-weight: bold;
  min-width: 150px;
`

const ValidatorLabel = styled.div`
  min-width: 150px;
`

const DeleteIcon = styled(DeletePicto)`
  margin-left: 25px;
  font-size: 25px;
  &:hover {
    cursor: pointer;
  }
`

const validatorsElts = {
  read_only: ReadOnly,
  poste: Poste,
  editable: Editable,
}

const Validator = ({
  className,
  validator,
  isDraft,
  onDelete,
  onNotifyToApprove,
  bu,
  isEditable,
  hasPreviousForm,
  currentValidator,
  ...props
}) => {
  let Element = null
  if (validator) {
    Element = validatorsElts[validator.type]

    if (!Element) {
      return null
    }
  }
  const { validated } = validator

  const handleDelete = () => {
    onDelete(validator)
  }

  const handleNotifyToApprove = (value) => {
    validator.notifyOnApprove = !validator.notifyOnApprove
    onNotifyToApprove(!validator.notifyOnApprove)
  }

  return (
    <CustomContainer className={className}>
      <Row className="align-items-center" noGutters={true}>
        <Col>
          <Row className="align-items-center" noGutters={true}>
            {validated ? <SuccessPicto primary={Colors.success} /> : <CheckIcon />}

            <ValidatorText className="">VALIDEUR {validator.weight}</ValidatorText>
            <ValidatorLabel>{validator.label}</ValidatorLabel>
          </Row>
        </Col>
        <Col xs="auto">
          <Element className="" validator={validator} bu={bu} validated={validated} {...props} />
        </Col>
        <Col xs="2">
        {(validator.canBeNotifiedToApprove ) && (
          
        <FormControlLabel
        control={
          <Checkbox
            checked={validator.notifyOnApprove}
            onChange={handleNotifyToApprove}
            name={"notifyOnApprove"}
            color="primary"
            disabled={(currentValidator && currentValidator['@id'] == validator.profile['@id']) || validated }
          />
        }
        label={"Notifier immédiatement"}
      />)}
          {!validator.step ? (
            <>{!validated && <DeleteIcon onClick={handleDelete} />} </>
          ) : (
            <>{hasPreviousForm && <DeleteIcon onClick={handleDelete} />}</>
          )}
        </Col>
      </Row>
    </CustomContainer>
  )
}

export default styled(Validator)`
  padding: 15px 25px;
  margin: 20px 20px 0;
`
