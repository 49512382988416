import React, { useEffect, useContext } from "react"

import styled from "styled-components"
import api from "services/api"
import DemandeCreationContext from "contexts/DemandeCreationContext"
import UserContext from "contexts/UserContext"

import Validator from "components/form/Validator"
import AddValidator from "components/form/AddValidator"

import { GET_FORM_ENDPOINT, GET_N_PLUS_1_ENDPOINT, BASE_URL } from "constants/endpoints"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const ValidationEdit = ({ className, item, ...props }) => {
  const { profile } = useContext(UserContext)
  const { form, valideurs, setValideurs, bu, unitaryValidation, setUnitaryValidation } = useContext(
    DemandeCreationContext
  )
  const profileId = profile ? profile.id : -1

  useEffect(() => {
    if (valideurs && valideurs.length > 0) {
      return
    }
    api
      .get(GET_FORM_ENDPOINT(form))
      .then((data) => {
        const localValideurs = []
        let valideursResolved = 0
        data.steps.map((step) => {
          const valideur = {
            label: step.label,
            weight: step.weight,
            step: step["@id"],
            profile: step.profile,
            validated: false,
            type: step.type === "",
            isEditable: step.editable,
            canBeNotifiedToApprove: step.notifyToApprove
          }

          const determineStepType = new Promise((resolve, reject) => {
            if (step.type === "responsable_hierarchique" && profile) {
              return api
                .get(GET_N_PLUS_1_ENDPOINT(profile.id))
                .then((data) => {
                  if (step.editable === true) {
                    valideur.type = "editable"
                  } else {
                    valideur.type = "read_only"
                  }
                  valideur.profile = data["@id"]
                  valideur.notifyOnApprove = step.notifyOnApprove
                  resolve(valideur)
                })
                .catch((e) => {
                  reject("le n plus 1 n a pas été trouvé")
                })
            } else if (step.type === "poste") {
              if (step.editable === true) {
                valideur.type = "editable"
              } else {
                valideur.type = "poste"
              }
              if (valideurs && valideurs[step.weight]) {
                valideur.profile = valideurs[step.weight].profile
              }
              valideur.notifyOnApprove = step.notifyOnApprove
              resolve(valideur)
            } else if (step.type === "profile") {
              if (step.editable === true) {
                valideur.type = "editable"
              } else {
                valideur.type = "read_only"
              }
              valideur.notifyOnApprove = step.notifyOnApprove
              resolve(valideur)
            } else if (step.type === "initiator") {
              valideur.type = "read_only"
              valideur.profile = profile["@id"]
              valideur.notifyOnApprove = step.notifyOnApprove
              resolve(valideur)
            }
            reject()
          })
          determineStepType
            .then((valideur) => {
              orderAndPositionValideursInArray(localValideurs, valideur)
              valideursResolved++
            })
            .catch((e) => {
              valideursResolved++
            })
            .then(() => {
              if (valideursResolved >= data.steps.length) {
                const filtredValideurs = localValideurs.filter((item) => item !== null)

                // filtredValideurs.splice(0, 0, null)
                filtredValideurs.map((item, index) => {
                  if (!item) {
                    return null
                  }

                  filtredValideurs[index].weight = index
                })
                setValideurs([...filtredValideurs])
              }
            })
        })
        return
      })

      .catch((e) => console.log("erreur récupération details form", e))
  }, [form, profileId, profile])

  const handleChange = (data) => {
    valideurs[data.weight] = data
    setValideurs([...valideurs])
  }

  const handleAdd = (validator) => {
    valideurs.splice(validator.weight, 0, validator)

    valideurs.map((item, index) => {
      if (!item) {
        return null
      }

      valideurs[index].weight = index
    })
    setValideurs([...valideurs])
  }

  const handleDelete = (validator) => {
    valideurs.splice(validator.weight, 1)

    valideurs.map((item, index) => {
      if (!item) {
        return null
      }

      valideurs[index].weight = index
    })
    setValideurs([...valideurs])
  }

  const handleValidatorChange = (profile, validator) => {
    validator.profile = profile["@id"]
    setValideurs([...valideurs])
  }

  const orderAndPositionValideursInArray = (arrayToOrder, valideurToAdd) => {
    arrayToOrder.unshift(valideurToAdd)
    arrayToOrder.sort((a, b) => {
      if (a.weight < b.weight) {
        return -1
      } else if (a.weight > b.weight) {
        return 1
      } else {
        return 0
      }
    })
  }
  const handleValidationUnitaireChange = (e) => {
    setUnitaryValidation(!unitaryValidation)
  }

    const handleNotifyToApprove = (data) => {
        valideurs[data.weight] = data
        setValideurs([...valideurs])
      }

  return (
    <div className={className}>
      <FormControlLabel
        control={
          <Checkbox
            checked={unitaryValidation}
            onChange={handleValidationUnitaireChange}
            name={"unitaryValidation"}
            color="primary"
          />
        }
        label={"Validation unitaire ?"}
      />
      {valideurs.map((element, key) => {
        if (!element) {
          return null
        }
        return (
          <Validator
            validator={element}
            key={key}
            onChange={handleChange}
            onDelete={handleDelete}
            bu={bu}
            onValidatorChange={handleValidatorChange}
            onNotifyToApprove = {handleNotifyToApprove}
          />
        )
      })}
      <AddValidator addValidator={handleAdd} maxPosition={valideurs.length + 1} />
    </div>
  )
}

export default styled(ValidationEdit)`
  padding-top: 20px;
  margin: 0 50px 10px 50px;
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0 !important;
  }
`
