import React, { useState, useEffect } from "react"
import { Row, Col } from "@bootstrap-styled/v4"

import styled from "styled-components"
import api from "services/api"

import HeaderForm from "components/editDemande/HeaderForm"
import FormElement from "components/form/FormElement"
import TitleInput from "components/form/element/TitleInput"
import BusinessUnitHeader from "components/form/element/BusinessUnitHeader"
import Container from "components/common/Container"
import Pen from "components/icons/Pen"
import CrossedPen from "components/icons/CrossedPen"
import NoticeButton from "components/form/NoticeButton"

import Colors from "theme/Colors"

import { GET_FORM_ENDPOINT, BASE_URL } from "constants/endpoints"

const TitleContainer = styled(Container)`
  padding: 30px;
  margin: 30px 70px 0;
`

const EditIcon = styled(Pen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const CantEditIcon = styled(CrossedPen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const ContainerTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`

const DemandeNumber = styled.p`
  font-size: 22px;
  font-weight: 300;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 10px;
  text-align: center;
`

const Form = ({
  className,
  hideInputs,
  form,
  demandeNumber,
  values,
  onChange,
  title,
  onTitleChange,
  bu,
  onBuChange,
  profile,
  status,
  previousFormResponses,
  readOnly,
  isDraftAndValidatorIsAuthor,
  inputTitleLabel,
  ...props
}) => {
  const [formDetails, setFormDetails] = useState(null)
  const [pictureUrl, setPictureUrl] = useState(null)

  useEffect(() => {
    api
      .get(GET_FORM_ENDPOINT(form.id))
      .then((data) => {
        setFormDetails(data)
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [form])

  useEffect(() => {
    if (!bu || bu.length < 1) {
      return
    }
    api.get(`${BASE_URL}${bu}`).then((buDetails) => {
      if (buDetails.logo) {
        setPictureUrl(`${BASE_URL}${buDetails.logo.contentUrl}`)
      } else {
        setPictureUrl(null)
      }
    })
  }, [bu])

  const titleEditable = !hideInputs

  const handleChange = (data) => {
    const index = values.findIndex((item) => {
      return item.question === data.question
    })

    if (index === 0 || index > 0) {
      values[index] = data
    } else {
      values.push(data)
    }

    onChange(values)
  }

  const handleSetTitle = (value) => {
    onTitleChange(value)
  }
  const handleSetBu = (value) => {
    onBuChange(value)
  }

  if (!formDetails) {
    return null
  }

  return (
    <div className={className}>
      <HeaderForm picture={pictureUrl} formTitle={formDetails.title} status={status} />
      <TitleContainer>
        <Row className="align-items-center justify-content-between">
          <Col xs="8">
            <TitleInput
              label={inputTitleLabel ? inputTitleLabel : "Titre de la demande"}
              onChange={handleSetTitle}
              editable={titleEditable}
              value={title}
            />
          </Col>
          <Col xs="auto">
            <DemandeNumber>{demandeNumber}</DemandeNumber>
          </Col>
        </Row>
      </TitleContainer>

      <TitleContainer>
        <Row className="align-items-center justify-content-between">
          <Col xs="4">
            <BusinessUnitHeader
              label="Business Unit"
              onChange={handleSetBu}
              editable={titleEditable}
              value={bu}
            />
          </Col>
          <Col xs="auto">
            <NoticeButton notice={formDetails.notice} />
          </Col>
        </Row>
      </TitleContainer>

      {formDetails.questions.map((element) => (
        <FormElement
          element={element}
          key={element.id}
          onChange={handleChange}
          hideInputs={hideInputs}
          readOnly={readOnly}
          value={values.find((item) => {
            return item.question === element["@id"]
          })}
          previousFormResponses={previousFormResponses}
          hasPreviousForm={form.previousForm != null}
          isDraftAndValidatorIsAuthor={isDraftAndValidatorIsAuthor}
        />
      ))}
    </div>
  )
}

export default styled(Form)`
  padding-bottom: 2px;
`
