import React from "react"

import styled from "styled-components"
import Colors from "theme/Colors"

import Container from "components/common/Container"

import Action from "components/editDemande/Action"

const ContainerTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 20px;
`

const Actions = ({ className, actions, editable, ...props }) => {
  return (
    <Container className={className}>
      <ContainerTitle>Historique</ContainerTitle>
      {actions.length > 0 && actions.map((item, key) => <Action action={item} key={key} />)}
    </Container>
  )
}

export default styled(Actions)`
  padding: 20px;
  margin: 30px 70px 60px 70px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 40px !important;
  }
`
