import React, { Fragment, useState, useEffect } from "react"

import styled from "styled-components"
import Colors from "theme/Colors"
import { Row, Col } from "@bootstrap-styled/v4"

import Modal from "components/common/Modal"

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

const NoticeButton = ({ className, notice, ...props }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  if (modalIsOpen) {
    disableBodyScroll(document.body)
  } else {
    enableBodyScroll(document.body)
  }

  const toggleModal = () => setModalIsOpen(!modalIsOpen)
  return (
    <Fragment>
      <Row className="justify-content-end d-xs-block">
        <Col xs="auto" className="mt-3" xl="auto">
          <div className={className} onClick={toggleModal}>
            Lire la notice
          </div>
        </Col>
      </Row>
      <Modal title="Notice" content={notice} isOpen={modalIsOpen} toggle={toggleModal} size="lg" />
    </Fragment>
  )
}

export default styled(NoticeButton)`
  box-shadow: 0 0 15px 0 ${Colors.greyBorder};
  min-height: 30px;
  max-width: 175px;
  border-radius: 15px;
  background-color: ${Colors.primaryVeryDark};
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 600;
  padding: 3px 30px;
  &:hover {
    cursor: pointer;
  }
`
