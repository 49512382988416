import React, { useContext, useState } from "react"
import styled from "styled-components"
import UserContext from "contexts/UserContext"
import AuthenticationContext from "contexts/AuthenticationContext"

import PageTop from "components/layout/PageTop"
import Link from "components/common/Link"
import LogoBusinessUnit from "components/common/LogoBusinessUnit"
import CategoryAdmin from "components/common/CategoryAdmin"
import AdminManager from "components/adminManagement/AdminManager"
import DemandeVisibility from "components/demandeVisibility/DemandeVisibility"
import HelpFile from "components/helpFile/HelpFile"

const Parameters = ({ className, ...props }) => {
  const { profile } = useContext(UserContext)
  const backBuntton = () => <Link to="/">Retour</Link>
  const { token } = useContext(AuthenticationContext)
  const content = token.split(".")
  const infos = JSON.parse(atob(content[1]))
  const isAdmin = infos.roles[0] === "ROLE_ADMIN"
  if (!profile) {
    return null
  }
  return (
    isAdmin && (
      <div className={className}>
        <PageTop getBackComponent={backBuntton} title={"Paramètres"} />
        <LogoBusinessUnit />
        <CategoryAdmin />
        <AdminManager />
        <DemandeVisibility />
        <HelpFile />
      </div>
    )
  )
}

export default styled(Parameters)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
