import React, { useState, useEffect, useContext } from "react"

import { Row, Col } from "@bootstrap-styled/v4"
import styled from "styled-components"
import { Redirect } from "react-router-dom"

import Container from "components/common/Container"
import Form from "components/newDemande/Form"
import api from "services/api"

import { GET_FORMS_ENDPOINT } from "constants/endpoints"
import DemandeCreationContext from "contexts/DemandeCreationContext"

const CustomCol = styled(Col)`
  //min-width: 230px;
`

const FormChooser = ({ className, ...props }) => {
  const { category } = useContext(DemandeCreationContext)
  const [forms, setForms] = useState(null)
  useEffect(() => {
    api
      .get(`${GET_FORMS_ENDPOINT}?category=${category}&status=published&exists[previousForm]=false`)
      .then((data) => {
        if (data) {
          setForms(data["hydra:member"])
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [category])

  if (!category) {
    return <Redirect to="choix-categorie" />
  }

  return (
    <Container className={className}>
      <Row className="justify-content-center">
        {forms &&
          forms.map((form) => (
            <CustomCol xs="auto" key={form.id}>
              <Form item={form} />
            </CustomCol>
          ))}
      </Row>
    </Container>
  )
}

export default styled(FormChooser)`
  margin: 30px 70px 0;
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
    text-align: center;
  }
`
