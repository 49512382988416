import React, { useState } from "react"

import styled from "styled-components"
import Colors from "theme/Colors"
import Container from "components/common/Container"
import { Row, Col } from "@bootstrap-styled/v4"

import Status from "components/backupList/Status"
import LoaderOverlay from "components/common/LoaderOverlay"
import CustomTip from "components/common/ToolTip"
import BackupsList from "./BackupsList"
import Schedule from "./Schedule"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Backup from "./Backup"
import SquareButton from "components/common/SquareButton"

import Link from "components/common/Link"
import DisabledLink from "components/common/DisabledLink"

const moment = require("moment")


const CustomRow = styled(Row)`
  padding:40px;
`


const CustomLink = styled(SquareButton)`
background-color: ${Colors.primaryVeryDark};
min-height: 50px;
width: 300px;
border-radius: 5px;
color: ${Colors.white};
&:-moz-focus-inner {
  border: 0;
}
&:hover {
  cursor: pointer;
}
`

const CustomDisabledLink = styled(DisabledLink)`
background-color: ${Colors.grey};
min-height: 50px;
width: 300px;
border-radius: 5px;
color: ${Colors.white};
&:-moz-focus-inner {
  border: 0;
}
`


const BackupMode = ({
  className,
  backupPlan,
  handleIsFormRestrictionChange
}) => {
  //useEffect(() => CustomTip.rebuild(), [backups])
  const currentPathname = window.location.pathname


  return (
    <CustomRow>
      <Col xs="4">

      {backupPlan.isFormRestriction ? (
      <CustomLink  onClick={handleIsFormRestrictionChange}>
          Pour tous les formulaires
      </CustomLink>
      ) : (

      <CustomDisabledLink >
         Pour tous les formulaires
      </CustomDisabledLink>


      )}
      </Col>
      <Col xs="4">

      {!backupPlan.isFormRestriction ? (
      <CustomLink onClick={handleIsFormRestrictionChange} >
          Pour chaque formulaire
      </CustomLink>
      ) : (

      <CustomDisabledLink >
     Pour chaque formulaire
      </CustomDisabledLink>


      )}
      </Col>
  </CustomRow>
  )
}

export default BackupMode
