import React from "react"

const SvgDisplayFullMenuPicto = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 59 49" {...props}>
    {/* <defs>
      <filter
        x="-33.5%"
        y="-1.9%"
        width="167%"
        height="103.7%"
        filterUnits="objectBoundingBox"
        id="DisplayFullMenuPicto_svg__a"
      >
        <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={7.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.149019608 0 0 0 0 0.337254902 0 0 0 0 0.784313725 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs> */}
    <g
      // filter="url(#DisplayFullMenuPicto_svg__a)"
      transform="translate(-32 -133)"
      fill="#FFF"
      fillRule="nonzero"
    >
      <path d="M62.012 146.052H46.89a1.89 1.89 0 000 3.78h15.122a1.89 1.89 0 000-3.78zM62.012 153.613H46.89a1.89 1.89 0 000 3.78h15.122a1.89 1.89 0 000-3.78zM62.012 161.174H46.89a1.89 1.89 0 000 3.78h15.122a1.89 1.89 0 000-3.78zM69.684 164.84a1 1 0 01-.6-.923v-8a1 1 0 01.6-.924.953.953 0 011.06.217l3.888 4c.19.195.285.45.285.707 0 .256-.095.512-.285.707l-3.889 4a.952.952 0 01-1.06.216z" />
    </g>
  </svg>
)

export default SvgDisplayFullMenuPicto
