import React, { useState } from "react"
import styled from "styled-components"
import Popup from "reactjs-popup"
import LogoAttachment from "components/common/LogoAttachment"
import Image from "components/common/Image"
import SquareButton from "components/common/SquareButton"
import Colors from "theme/Colors"

const LogoFrame = styled(Image)`
  &:hover {
    cursor: pointer;
  }
`
const CustomPopUp = styled(Popup)`
  // padding: 20px;
  // margin: 20px;
  // text-align: center;
  // height: 100%;
  // width: 20%;
`

const OkButton = styled(SquareButton)`
  max-width: 50px !important;
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
`
const AddLogoPopUp = ({ image, id, onImageChange, ...props }) => {
  return (
    // <Row ClassName="justify-content-center">
    <CustomPopUp
      trigger={<LogoFrame src={image}></LogoFrame>}
      modal
      closeOnDocumentClick
      contentStyle={{ width: "", borderradius: "10px" }}
    >
      {(close) => (
        <>
          <LogoAttachment onImageChange={onImageChange} idBu={id} />
          <div className="actions">
            <center>
              <OkButton variant="primary" size="sm" onClick={close}>
                OK
              </OkButton>
            </center>
          </div>
        </>
      )}
    </CustomPopUp>

    // </Row>
  )
}

export default AddLogoPopUp
