import React from "react"

import styled from "styled-components"
import Colors from "theme/Colors"
import moment from "moment"
import { Row, Col } from "@bootstrap-styled/v4"

const ActionTitle = styled(Col)`
  color: ${Colors.black};
  font-size: 18px;
  font-weight: 600;
`
const ActionContent = styled(Col)`
  color: ${Colors.black};
  font-size: 18px;
  text-align: justify;
  max-width: 100% !important;
  && {
    max-width: 100%;
  }
`
const Capitalize = styled.span`
  text-transform: capitalize;
`

const Action = ({ className, action, ...props }) => {
  const createdAt = moment(action.date)
  return (
    <Row className={className}>
      <ActionTitle xs="auto">
        {createdAt.format("DD/MM/Y à HH[h]mm")} -{" "}
        <Capitalize>
          {`${action.profile.firstname.toLowerCase()} ${action.profile.lastname.toLowerCase()}`}
        </Capitalize>
      </ActionTitle>
      <ActionContent xs="12">
        {action.description.split("\n").map((item) => (
          <>
            {item}
            <br />
          </>
        ))}
      </ActionContent>
    </Row>
  )
}

export default styled(Action)`
  margin-top: 25px;
`
