import React from "react"
import styled from "styled-components"
import Colors from "theme/Colors"

const Status = ({ children, status, className, ...props }) => (
  <div className={className} status={status} {...props}>
    <span>
      {!status && "Inactif"}
      {status && "Actif"}
    </span>
  </div>
)

export default styled(Status)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.background};
  min-height: 30px;
  width: 100px;
  border-radius: 15px;
  border: 2px solid
    ${(props) => {
      let color = props.status ? Colors.green : Colors.grey
      return color
    }};
  color:   ${(props) => {
    let color = props.status ? Colors.green : Colors.grey
    return color
  }};
`
