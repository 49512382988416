import React from "react"

import styled from "styled-components"
import Colors from "theme/Colors"
import moment from "moment"

const CommentTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 300;
`
const CommentContent = styled.p`
  color: ${Colors.black};
  font-size: 18px;
  margin: 20px 20px;
  text-align: justify;
`

const Comment = ({ className, comment, ...props }) => {
  const createdAt = moment(comment.createdAt)
  return (
    <div>
      <CommentTitle>
        {createdAt.format("DD/MM/Y À HH[H]mm")} - {comment.profile.firstname}{" "}
        {comment.profile.lastname}
      </CommentTitle>
      <CommentContent>{comment.content}</CommentContent>
    </div>
  )
}

export default styled(Comment)``
