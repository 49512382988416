import React, { useState, useContext } from "react"
import styled from "styled-components"

import AuthenticationContext from "contexts/AuthenticationContext"

import { Row } from "@bootstrap-styled/v4"

import ElContainer from "components/common/Container"
import Image from "components/common/Image"
import Text from "components/common/Text"
import TextInput from "components/common/TextInput"
import Button from "components/common/Button"

import Images from "theme/Images"
//import Colors from "theme/Colors"

import api from "services/api"
import { LOGIN_LDAP } from "constants/endpoints"

const Logo = styled(Image)`
  width: 260px;
  margin-bottom: 36px;
`
const LoginRowContainer = styled(Row)`
  min-height: 100vh;
`

const LoginContainer = styled(ElContainer)`
  max-width: 600px;
  width: 100%;
  padding: 50px !important;
`

const CustomText = styled(Text)`
  text-align: center;
`

const Login = () => {
  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const { setToken } = useContext(AuthenticationContext)

  const formValues = {
    login: {
      value: login,
      setter: setLogin,
    },
    password: {
      value: password,
      setter: setPassword,
    },
  }

  const handleChange = (name, value) => {
    formValues[name].setter(value)
  }

  const handleClick = (e) => {
    e.preventDefault()
    setLoading(true)
    api
      .post(LOGIN_LDAP, {
        username: login,
        password: password,
      }, {anonymous: true})
      .then((data) => {
        const token = data.token
        setToken(token)
      })
      .catch((e) => {
        alert("Veuillez vérifier votre login et votre mot de passe.")
        setLoading(false)
      })
  }

  return (
    <LoginRowContainer className={"align-items-center justify-content-center"}>
      <LoginContainer>
        <Row className={"align-items-center justify-content-center"}>
          <Logo src={Images.logo_groupe} />
          <CustomText>
            Renseignez votre identifiant et votre mot de passe
            <br />
            pour vous connecter
          </CustomText>
          <TextInput
            placeholder="saisissez votre identifiant"
            onChange={handleChange}
            name="login"
          />
          <TextInput
            name="password"
            placeholder="saisissez votre mot de passe"
            secured={true}
            onChange={handleChange}
          />
          <Button onClick={handleClick} loading={loading}>
            Connexion
          </Button>
        </Row>
      </LoginContainer>
    </LoginRowContainer>
  )
}

export default Login
