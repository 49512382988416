import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"

import styled from "styled-components"
import Colors from "theme/Colors"
import api from "services/api"
import { Row, Col } from "@bootstrap-styled/v4"

import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"

import SquareButton from "components/common/SquareButton"

import {
  GET_FORMS_ENDPOINT,
  GET_CATEGORIES_ENDPOINT,
  ORDER_FILTER_LABEL_PARAM,
} from "constants/endpoints"
import { STATUS } from "constants/form"

import Container from "components/common/Container"

import Search from "components/icons/Search"
import Plus from "components/icons/Plus"

const moment = require("moment")

const Spacer = styled.div`
  border-top: 1px solid ${Colors.grey};
  margin: 20px 0;
`

const SearchIcon = styled(Search)`
  font-size: 20px;
`

const CustomFomControl = styled(FormControl)`
  min-height: 50px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 10px !important;
  }
`

const CustomInput = styled(OutlinedInput)`
  min-height: 50px;
`

const CustomLabel = styled(InputLabel)`
  background-color: ${Colors.white};
`

const CustomSquareButton = styled(SquareButton)`
  min-height: 50px;
  max-width: 240px;
  padding: 15px;
`
const CustomDarkSquareButton = styled(CustomSquareButton)`
  background-color: ${Colors.primaryVeryDark};
`

const PlusIcon = styled(Plus)`
  font-size: 20px;
  margin-right: 10px;
`

const years = []
for (let i = 2019; i <= moment().format("YYYY"); i++) {
  years.push(i)
}

const SearchForm = ({ className, type, searchValues, setSearchValues, ...props }) => {
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState("")
  const [text, setText] = useState("")
  const [status, setStatus] = useState("")
  const [year, setYear] = useState("")
  const [form, setForm] = useState("")
  let history = useHistory()

  useEffect(() => {
    api
      .get(`${GET_CATEGORIES_ENDPOINT}?${ORDER_FILTER_LABEL_PARAM}`)
      .then((data) => {
        if (data) {
          setCategories(data["hydra:member"])
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])
  const statusAvailable = STATUS

  const handleStatusChange = (event) => {
    const value = event.target.value || ""
    setStatus(value)
  }

  const handleCategoryChange = (event) => {
    const value = event.target.value || ""
    setCategory(value)
  }

  const handleTextChange = (event) => {
    const value = event.target.value || ""
    setText(value)
  }

  const handleApply = () => {
    setSearchValues({
      status: status != "" ? status : null,
      category: category != "" ? category : null,
      title: text != "" ? text : null,
    })
  }

  const enterToApply = (event) => {
    if (event.key != "Enter") {
      return
    }
    handleApply()
  }

  const handleReset = () => {
    setSearchValues({
      status: null,
      category: null,
      title: null,
    })
    setStatus("")
    setCategory("")
    setText("")
  }

  const handleNewForm = () => {
    history.push("/nouveau-formulaire")
  }
  return (
    <Container className={className}>
      <Row className="justify-content-between">
        <Col lg="6">
          <CustomFomControl fullWidth={true}>
            <TextField
              inputProps={{
                "aria-label": "weight",
                onKeyPress: enterToApply,
              }}
              label="Rechercher un formulaire"
              labelWidth={0}
              value={text}
              onChange={handleTextChange}
              InputProps={{ style: { minHeight: "32px", lineHeight: "3px" } }}
            />
          </CustomFomControl>
        </Col>
        <Col xs="auto">
          <CustomSquareButton noGutters={true} onClick={handleNewForm}>
            <PlusIcon /> Nouveau formulaire
          </CustomSquareButton>
        </Col>
      </Row>
      <Spacer />
      <Row>
        <Col md="8" lg="9">
          <Row>
            <Col md="6" lg="6">
              <CustomFomControl fullWidth={true} margin="none" variant="outlined">
                <CustomLabel htmlFor={`search-form`}>Catégorie</CustomLabel>
                <Select
                  inputProps={{
                    name: `form`,
                    id: `search-form`,
                  }}
                  value={category}
                  onChange={handleCategoryChange}
                >
                  <MenuItem value={null}>Choisissez une valeur</MenuItem>
                  {categories.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </CustomFomControl>
            </Col>

            <Col md="6" lg="6">
              <CustomFomControl fullWidth={true} margin="none" variant="outlined">
                <CustomLabel htmlFor={`search-status`}>Statut</CustomLabel>
                <Select
                  inputProps={{
                    name: `status`,
                    id: `search-status`,
                  }}
                  value={status}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={null}>Choisissez une valeur</MenuItem>
                  {statusAvailable.map((item) => (
                    <MenuItem value={item.key} key={item.key}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </CustomFomControl>
            </Col>
          </Row>
        </Col>
        <Col lg="3" md="4">
          <Row>
            <Col>
              <CustomDarkSquareButton className="mb-sm-2" noGutters={true} onClick={handleApply}>
                Appliquer
              </CustomDarkSquareButton>
            </Col>
            <Col>
              <CustomDarkSquareButton noGutters={true} onClick={handleReset} responsiveMargin>
                Réinitialiser
              </CustomDarkSquareButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default styled(SearchForm)`
  height: 100%;
  padding: 30px 20px;
  margin: 0 70px 30px;

  @media screen and (max-width: 1200px) {
    margin: 0 0 30px;
    text-align: center;
    overflow: scroll;
  }
`
