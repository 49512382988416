import React, { useState, useContext, useEffect } from "react"

import { Row, Col } from "@bootstrap-styled/v4"
import styled from "styled-components"

import Container from "components/common/Container"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import AddSomethingButton from "components/common/AddSomethingButton"
import { CreateFormContext } from "contexts/CreateFormContext"
import draftToHtml from "draftjs-to-html"

const TitleFont = styled.div`
  color: #24445c;
  titlefont-family: "Open Sans";
  titlefont-size: 18px;
  titlefont-weight: 600;
`

const CustomContainer = styled(Container)`
  padding: 30px;
`

const WrapperStyleProperties = {
  margin: "5px",
  border: "1px solid",
  borderColor: "#D1D1D1",
}

const EditorStyleProperties = {
  padding: "0 10px",
}

const ToolbarStyleProperties = {
  borderBottom: "1px solid",
}

const FormGuide = ({ className, ...props }) => {
  const [enable, setEnable] = useState(false)
  const { notice, setNotice } = useContext(CreateFormContext)
  const onEditorStateChange = (editorState) => {
    setNotice(editorState)
  }
  useEffect(() => {
    if (notice && draftToHtml(convertToRaw(notice.getCurrentContent())) !== `<p></p>`) {
      setEnable(true)
    }
  }, [])

  return (
    <CustomContainer className={className}>
      <h3>
        <TitleFont>Notice du formulaire (facultatif)</TitleFont>
      </h3>{" "}
      {enable ? (
        <>
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            wrapperStyle={WrapperStyleProperties}
            toolbarStyle={ToolbarStyleProperties}
            editorStyle={EditorStyleProperties}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "history",
              ],
            }}
            onEditorStateChange={onEditorStateChange}
            editorState={notice}
          />{" "}
        </>
      ) : (
        <Col>
          <AddSomethingButton
            title={"Ajouter une notice au formulaire"}
            onClick={() => {
              setEnable(!enable)
            }}
          />
        </Col>
      )}
    </CustomContainer>
  )
}

export default styled(FormGuide)`
  margin: 30px 70px 0;
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
    text-align: center;
  }
`
