import styled from "styled-components"
import Container from "components/common/Container"
import React, { useState } from "react"
import { Row, Col } from "@bootstrap-styled/v4"
import Plus from "components/icons/Plus"
import Minus from "components/icons/Minus"
import LoaderOverlay from "components/common/LoaderOverlay"

const AdminContainer = styled(Container)`
  margin: 0px 70px 30px;
  padding: 30px 25px;
`
const TitleFont = styled.div`
  color: #24445c;
  titlefont-family: "Open Sans";
  titlefont-size: 18px;
  titlefont-weight: 600;
`
const MyPlus = styled(Plus)`
  &:hover {
    cursor: pointer;
  }
`
const MyMinus = styled(Minus)`
  &:hover {
    cursor: pointer;
  }
`
const ParameterContainer = ({ title, loading, children, ...props }) => {
  const [opened, setOpened] = useState(false)
  const handleClick = () => setOpened(!opened)

  if (loading) {
    return <LoaderOverlay />
  }

  return (
    <AdminContainer>
      <Row className="justify-content-center">
        <Col xs={10}>
          <h3>
            <TitleFont>{title}</TitleFont>
          </h3>
        </Col>
        <Col align="right">
          {opened ? <MyMinus onClick={handleClick} /> : <MyPlus onClick={handleClick} />}
        </Col>
      </Row>
      {opened && <Row className="justify-content-center">{children}</Row>}
    </AdminContainer>
  )
}

export default ParameterContainer
