import React from "react"

import styled from "styled-components"
import Image from "components/common/Image"
import Status from "components/demandesList/Status"
import { Row, Col } from "@bootstrap-styled/v4"

import Container from "components/common/Container"
import Colors from "theme/Colors"

const PictoImage = styled(Image)`
  max-width: 180px;
  margin: 0 20px;
`

const FormTitle = styled.span`
  color: ${Colors.black};
  font-size: 23px;
  font-weight: bold;
`

const HeaderForm = ({ className, picture, formTitle, formNumber, status, ...props }) => {
  return (
    <Container className={className}>
      <Row className={"align-items-center justify-content-between"}>
        <Col xs="auto">
          <PictoImage src={picture} />
        </Col>
        <Col xs="auto">
          <FormTitle>{formTitle.toUpperCase()}</FormTitle>
        </Col>
        <Col xs="auto">
          <Status status={status} />
        </Col>
      </Row>
    </Container>
  )
}

export default styled(HeaderForm)`
  padding: 30px;
  margin: 30px 70px 0;
  text-align: center;
`
