import React from "react"
import NewBlockContainer from "./NewBlockContainer"
import InputField from "components/adminCreateForm/newBlock/InputField.js"

const Field = ({
  className,
  onDelete,
  field,
  position,
  saveParameters,
  onLabelChange,
  deepContainer,
  othersClass,
  listOfParentIndex,
  parentType,
  ...props
}) => {
  const handleLabelChange = (event) => {
    onLabelChange(event.target.value || "", position)
  }
  const handleDelete = (position) => {
    onDelete(position)
  }

  return (
    <>
      {field.type == "container" ||
      field.type == "table_question" ||
      field.type === "fixed_table_question" ? (
        <NewBlockContainer
          field={field}
          blockIndex={position}
          key={field.key}
          onDelete={onDelete}
          deepContainer={deepContainer ? true : false}
          othersClass={deepContainer ? "embeddedContainer deepContainer" : othersClass}
          parentType={parentType}
          saveParameters={saveParameters}
        />
      ) : (
        <InputField
          onLabelChange={handleLabelChange}
          onDelete={handleDelete}
          saveParameters={saveParameters}
          field={field}
          position={position}
          key={field.key}
          parentType={parentType}
        />
      )}
    </>
  )
}
export default Field
