import React, { useContext } from "react"
import styled from "styled-components"

import PageTop from "components/layout/PageTop"
import Link from "components/common/Link"

import ProgressionViewer from "components/newDemande/ProgressionViewer"
import ValidationEditFlow from "components/newDemande/ValidationEditFlow"

import { Next, Back } from "components/icons"

import DemandeCreationContext from "contexts/DemandeCreationContext"
import CancelLink from "components/common/CancelLink"
import NavigationButton from "components/common/NavigationButton"

import { Row, Col } from "@bootstrap-styled/v4"

const Spacer = styled.span`
  width: 20px;
  height: 1 px;
`
const CustomRow = styled(Row)`
  margin: 0px 70px 30px 70px !important;
  @media screen and (max-width: 1200px) {
    margin: 0px 0px 10px 0px !important;
  }
`

const ValidationFlow = ({ className, ...props }) => {
  const { valideurs } = useContext(DemandeCreationContext)
  const cancelButton = () => <CancelLink></CancelLink>
  const nextButton = () => {
    let valid = true
    if (valideurs && valideurs.length > 0) {
      valideurs.map((item) => {
        if (!item) {
          return null
        }
        valid = valid && !!item.profile
        return null
      })
    } else {
      valid = false
    }

    return (
      <NavigationButton to="recapitulatif" isNext={true} isActive={!!valid}>
        Suivant
        <Spacer />
        <Next />
      </NavigationButton>
    )
  }
  const previousButton = () => (
    <NavigationButton isActive to="contenu">
      <Back />
      <Spacer />
      Précédent
    </NavigationButton>
  )

  return (
    <div className={className}>
      <PageTop getBackComponent={cancelButton} title={"Nouvelle demande"} />
      <ProgressionViewer
        next={nextButton}
        previous={previousButton}
        title="Saisie du workflow"
        percent={70}
      />
      <ValidationEditFlow />
      <CustomRow className="justify-content-end">
        <Col xs="auto p-0">{nextButton()}</Col>
      </CustomRow>
    </div>
  )
}

export default styled(ValidationFlow)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
