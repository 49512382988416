export const ACTIVE_STATUS = [
  { key: "draft", label: "brouillon" },
  { key: "doing", label: "en cours" },
  { key: "to_validate", label: "A valider" },
  { key: "waitting", label: "En attente" },
]

export const ARCHIVED_STATUS = [
  { key: "refused", label: "Refusée" },
  { key: "closed", label: "Cloturée" },
]

export const DRAFT = "draft"
export const DOING = "doing"
export const TO_VALIDATE = "to_validate"
export const WAITTING = "waitting"
export const REFUSED = "refused"
export const CLOSED = "closed"

export const ACTIVE = "active"
export const ARCHIVED = "archived"

// types de steps
export const steps = [
  { value: "responsable_hierarchique", label: "Responsable Hiérarchique" },
  { value: "poste", label: "Choisir un poste" },
  { value: "profile", label: "Choisir un profil" },
]
