import React, { useState, useEffect } from "react"
import ParameterContainer from "components/common/ParameterContainer"
import LogoBusinessUnitContainer from "components/common/LogoBusinessUnitContainer"
import api from "services/api"
import { GET_BUSINESS_UNITS_ENDPOINT } from "constants/endpoints"
import { Row } from "@bootstrap-styled/v4"
//récupère sous forme d'objet json tousles business unit existant

const LogoBusinessUnit = () => {
  const [businessUnits, setBusinessUnits] = useState([])

  useEffect(() => {
    api
      .get(`${GET_BUSINESS_UNITS_ENDPOINT}`)
      .then((data) => {
        if (data) {
          setBusinessUnits(data["hydra:member"])
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])
  return (
    <ParameterContainer title="Logos des Business Unit">
      {businessUnits.map((bu) => {
        return <LogoBusinessUnitContainer bu={bu} />
      })}
    </ParameterContainer>
  )
}

export default LogoBusinessUnit
