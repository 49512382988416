import React from "react"

const SvgHome = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 58 54" {...props}>
    <g transform="translate(-23 -203)" fill="#FFF" fillRule="evenodd">
      <path d="M50 220.91l10.618 8.836c0 .013.003.031.01.056.005.025.008.043.008.056v8.948c0 .323-.117.603-.35.84-.234.235-.511.354-.831.354h-7.091v-7.16h-4.728V240h-7.09a1.13 1.13 0 01-.831-.355 1.151 1.151 0 01-.351-.839v-8.948c0-.05.006-.087.018-.112L50 220.91z" />
      <path d="M35.207 227.738l4.075-3.418v-7.663a.575.575 0 01.596-.6h3.572c.174 0 .316.056.428.168a.589.589 0 01.167.432v3.663l4.54-3.832A2.159 2.159 0 0150 216a2.16 2.16 0 011.414.488l13.38 11.25c.123.1.191.235.204.404a.612.612 0 01-.13.441l-1.154 1.39a.633.633 0 01-.39.207.687.687 0 01-.448-.132L50 219.212l-12.877 10.836a.57.57 0 01-.39.131h-.056a.634.634 0 01-.391-.206l-1.154-1.39a.612.612 0 01-.13-.442.549.549 0 01.205-.403z" />
    </g>
  </svg>
)

export default SvgHome
