import React, { useState, useEffect } from "react"
import ParameterContainer from "components/common/ParameterContainer"
import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"
import AddContributorVisibility from "components/demandeVisibility/AddContributorVisibility"

import api from "services/api"
import { GET_FORMS_ENDPOINT, GET_BUSINESS_UNITS_ENDPOINT, BASE_URL } from "constants/endpoints"
import DemandeVisibilityField from "components/demandeVisibility/DemandeVisibilityField"
import LoaderOverlay from "components/common/LoaderOverlay"
import Pager from "components/common/Pager"

const CustomRow = styled(Row)`
  margin: 20px;
`

const DemandeVisibility = ({ ...props }) => {
  const [formList, setFormList] = useState([])
  const [buList, setBuList] = useState([])
  const [formsToDisplay, setFormsToDisplay] = useState([])
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const refresh = () => {
    setLoading(true)
    api
      .get(`${BASE_URL}/api/form_accesses?page=${page}&itemsPerPage=${itemsPerPage}`)
      .then((data) => {
        if (data) {
          const formAccessArray = data["hydra:member"]
          setTotalItems(data["hydra:totalItems"])
          const formProfile = []
          formAccessArray.map((element) => {
            formProfile.push({
              iri: element["@id"],
              profile: element.profile,
              form: element.form?.title,
              bu: element.bu?.label,
              id: element.id,
            })
          })
          setFormsToDisplay([...formProfile])
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        return alert(e.message)
      })
  }
  useEffect(() => {
    refresh()
    api
      .get(GET_FORMS_ENDPOINT + "?pagination=false")
      .then((data) => {
        if (data) {
          setFormList(data["hydra:member"])
        }
      })
      .catch((e) => {
        alert(e.message)
      })
    api
      .get(GET_BUSINESS_UNITS_ENDPOINT)
      .then((data) => {
        if (data) {
          setBuList(data["hydra:member"])
        }
      })
      .catch((e) => {
        alert(e.message)
      })
  }, [])

  useEffect(() => {
    refresh()
  }, [page, itemsPerPage])

  const handleDelete = (profile, formIri) => {
    const elementFound = formsToDisplay.find((element) => {
      return element.profile["@id"] === profile && element.form === formIri
    })
    api
      .delete(`${BASE_URL}${elementFound.iri}`)
      .then(() => refresh())
      .catch((e) => alert(e.message))
  }

  return (
    <ParameterContainer title="Visibilité des demandes">
      {loading && <LoaderOverlay />}
      <CustomRow className="w-100">
        {formsToDisplay.map((element) => {
          return (
            <DemandeVisibilityField
              key={element.id}
              profile={element.profile}
              onDelete={handleDelete}
              form={element.form}
              bu={element.bu}
            />
          )
        })}
      </CustomRow>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
          marginTop: "10px",
        }}
      >
        <Pager
          page={page}
          itemsPerPage={itemsPerPage}
          onPageChange={setPage}
          onItemsPerPageChange={setItemsPerPage}
          totalItems={totalItems}
        />
      </div>
      <AddContributorVisibility formList={formList} refresh={refresh} buList={buList} />
    </ParameterContainer>
  )
}

export default DemandeVisibility
