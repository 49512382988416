import React, { useState, useEffect } from "react"
import { Row, Col } from "@bootstrap-styled/v4"
import FormControl from "@material-ui/core/FormControl"
import { TextField } from "@material-ui/core"
import Delete from "components/icons/Delete"
import styled from "styled-components"
import FieldSettings from "components/adminCreateForm/newBlock/FieldSettings"

const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`
const Spacer = styled.div`
  width: 10px;
`

const InputField = ({
  className,
  onLabelChange,
  position,
  onDelete,
  saveParameters,
  field,
  parentType,
}) => {
  const handleFieldParametersChange = (object) => {
    saveParameters(object, position)
  }

  const handleDelete = () => {
    if (field.id) {
      onDelete(position)
    } else {
      onDelete(position)
    }
  }

  return (
    <div className={className}>
      <Col xs="auto">
        <Row className={"align-items-end w-100"} noGutters={true}>
          <Col>
            <FormControl fullWidth={true} margin="none">
              <TextField
                margin="none"
                label={`Libellé du champ${field.isMandatory ? " *" : ""}`}
                value={field.title}
                onChange={onLabelChange}
                hiddenLabel={true}
                InputProps={{
                  style: {
                    minHeight: "32px",
                    fontSize: "13px",
                    width: "100%",
                    lineHeight: "0px",
                  },
                }}
              />
            </FormControl>
          </Col>
          <Col xs="auto">
            <FieldSettings
              saveParameters={handleFieldParametersChange}
              label={field.label}
              fieldParameters={field}
              closePopup={() => {
                window.close()
              }}
              parentType={parentType}
            />
          </Col>
          <Col xs="auto">
            <Spacer />
          </Col>
          <Col xs="auto">
            <DeleteButton onClick={handleDelete} />
          </Col>
        </Row>
      </Col>
    </div>
  )
}
export default styled(InputField)`
  width: ${({ field }) => (field.width && field.width > 0 ? field.width : 100)}%;
`
