import React, { useState } from "react"
import { Switch, Route, useRouteMatch } from "react-router-dom"
import { useHistory } from "react-router-dom"

import DemandeCreation, {
  demandeCreationData,
  DemandeCreationContextProvider,
} from "contexts/DemandeCreationContext"

import ChooseCategory from "./ChooseCategory"
import ChooseForm from "./ChooseForm"
import FillForm from "./FillForm"
import ValidationFlow from "./ValidationFlow"
import ReviewForm from "./ReviewForm"
import DemandeValidated from "./DemandeValidated"

const NewDemandeRouter = () => {
  let { path } = useRouteMatch()
  let { history } = useHistory()

  return (
    <DemandeCreationContextProvider>
      <Switch>
        <Route exact path={path} component={ChooseCategory} />
        <Route exact path={`${path}/choix-categorie`} component={ChooseCategory} />
        <Route exact path={`${path}/choix-formulaire`} component={ChooseForm} />
        <Route exact path={`${path}/contenu`} component={FillForm} />
        <Route exact path={`${path}/workflow`} component={ValidationFlow} />
        <Route exact path={`${path}/recapitulatif`} component={ReviewForm} />
        <Route exact path={`${path}/validation/:id`} component={DemandeValidated} />
      </Switch>
    </DemandeCreationContextProvider>
  )
}
export default NewDemandeRouter
