import React, { useState, useEffect } from "react"

const DemandeCreationContext = React.createContext({})

const formContext = {}

const getCategory = () => {
  return parseInt(localStorage.getItem("demandeCreation.category") || "0", 10)
}

const getForm = () => {
  return parseInt(localStorage.getItem("demandeCreation.form") || null || "0", 10)
}

const getBuild = () => {
  return parseInt(localStorage.getItem("demandeCreation.build") || null || "0", 10)
}

const getValues = () => {
  const data = localStorage.getItem("demandeCreation.values")
  const decodedData = data ? JSON.parse(data) : []

  return Array.isArray(decodedData) ? decodedData : []
}

const getValideurs = () => {
  const data = localStorage.getItem("demandeCreation.valideurs")
  const decodedData = data ? JSON.parse(data) : []

  return Array.isArray(decodedData) ? decodedData : []
}

const getTitle = () => {
  const data = localStorage.getItem("demandeCreation.title")
  return data || ""
}

const getUnitaryValidation = () => {
  const data = localStorage.getItem("demandeCreation.unitaryValidation")
  const decodedData = data ? JSON.parse(data) : false
  return decodedData === true || false
}

const getNewComment = () => {
  const data = localStorage.getItem("demandeCreation.newComment")
  return data || ""
}

const getBu = () => {
  const data = localStorage.getItem("demandeCreation.bu")
  return data || ""
}

const getMailingList = () => {
  const data = localStorage.getItem("demandeCreation.mailingList")
  return data || ""
}

const setContextMailingList = (setState) => (mailingList) => {
  setState(mailingList)
  localStorage.setItem("demandeCreation.mailingList", mailingList)
}

const setContextTitle = (setState) => (title) => {
  setState(title)
  localStorage.setItem("demandeCreation.title", title)
}

const setContextNewComment = (setState) => (comment) => {
  setState(comment)
  localStorage.setItem("demandeCreation.newComment", comment)
}

const setContextBu = (setState) => (bu) => {
  setState(bu)
  localStorage.setItem("demandeCreation.bu", bu)
}

const setContextCategory = (setState) => (category) => {
  setState(category)
  localStorage.setItem("demandeCreation.category", category)
}

const setContextForm = (setFormState) => (form, build) => {
  const currentFormId = getForm()
  const currentBuildId = getBuild()
  if (currentFormId !== 0 && (currentFormId !== form || currentBuildId != build)) {
    formContext.resetDatas()
  }
  setFormState(form)
  localStorage.setItem("demandeCreation.form", form)
  localStorage.setItem("demandeCreation.build", build)
}

const setContextValues = (setState) => (values) => {
  setState(values)
  localStorage.setItem("demandeCreation.values", JSON.stringify(values))
}

const setContextValideurs = (setState) => (valideurs) => {
  setState(valideurs)
  localStorage.setItem("demandeCreation.valideurs", JSON.stringify(valideurs))
}

const setContextUnitaryValidation = (setState) => (unitaryValidation) => {
  setState(unitaryValidation)
  localStorage.setItem("demandeCreation.unitaryValidation", JSON.stringify(unitaryValidation))
}

export const DemandeCreationContextProvider = ({ children }) => {
  const [category, setCategoryState] = useState(getCategory)
  const [form, setFormState] = useState(getForm)
  const [valideurs, setValideursState] = useState(getValideurs)

  const [values, setValuesState] = useState(getValues)
  const [title, setTitleState] = useState(getTitle)
  const [bu, setBuState] = useState(getBu)
  const [newComment, setNewCommentState] = useState(getNewComment)
  const [unitaryValidation, setUnitaryValidationState] = useState(getUnitaryValidation)
  const [hasAlreadyGotUnitaryValidation, setHasAlreadyGotUnitaryValidation] = useState(false)

  const setCategory = setContextCategory(setCategoryState)
  const setForm = setContextForm(setFormState)
  const setValues = setContextValues(setValuesState)
  const setValideurs = setContextValideurs(setValideursState)
  const setTitle = setContextTitle(setTitleState)
  const setBu = setContextBu(setBuState)
  const setNewComment = setContextNewComment(setNewCommentState)
  const setUnitaryValidation = setContextUnitaryValidation(setUnitaryValidationState)

  formContext.resetDatas = () => {
    setValues([])
    setTitle("")
    setBu("")
    setValideurs([])
    setUnitaryValidation(false)
    setHasAlreadyGotUnitaryValidation(false)
  }

  return (
    <DemandeCreationContext.Provider
      value={{
        category,
        form,
        values,
        valideurs,
        title,
        bu,
        newComment,
        unitaryValidation,
        hasAlreadyGotUnitaryValidation,
        setCategory,
        setForm,
        setValues,
        setValideurs,
        setTitle,
        setBu,
        setNewComment,
        setUnitaryValidation,
        setHasAlreadyGotUnitaryValidation,
      }}
    >
      {children}
    </DemandeCreationContext.Provider>
  )
}

export default DemandeCreationContext
