import React from "react"

const SvgDuplicate = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 24" {...props}>
    <g fill="#4A4A4A" fillRule="nonzero">
      <path d="M18.14 3.325h-1.47c-.03 0-.057.01-.086.017V1.826C16.584.82 15.731 0 14.681 0H1.932C.882 0 .03.82.03 1.826V18.86c0 1.008.854 1.827 1.903 1.827h2.236v.825c0 .97.821 1.76 1.83 1.76H18.14c1.01 0 1.83-.79 1.83-1.76V5.086c0-.971-.82-1.761-1.83-1.761zM.782 18.86V1.826c0-.6.516-1.087 1.15-1.087h12.75c.634 0 1.15.488 1.15 1.087V18.86c0 .6-.516 1.088-1.15 1.088H1.931c-.634 0-1.15-.488-1.15-1.088zm18.436 2.652c0 .563-.483 1.022-1.078 1.022H5.998c-.594 0-1.077-.459-1.077-1.022v-.825h9.76c1.05 0 1.903-.82 1.903-1.827V4.046c.029.007.056.017.087.017h1.47c.594 0 1.077.459 1.077 1.023v16.426z" />
      <path d="M12.446 4.802H4.168a.373.373 0 00-.376.37c0 .204.168.37.376.37h8.278a.373.373 0 00.376-.37.373.373 0 00-.376-.37zM12.446 7.758H4.168a.373.373 0 00-.376.369c0 .204.168.37.376.37h8.278a.373.373 0 00.376-.37.373.373 0 00-.376-.37zM12.446 10.713H4.168a.373.373 0 00-.376.37c0 .203.168.369.376.369h8.278a.373.373 0 00.376-.37.373.373 0 00-.376-.37zM8.683 13.668H4.168a.373.373 0 00-.376.37c0 .204.168.369.376.369h4.515a.373.373 0 00.376-.37.373.373 0 00-.376-.369z" />
    </g>
  </svg>
)

export default SvgDuplicate
