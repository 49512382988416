import React, { useState } from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import { Row, Col } from "@bootstrap-styled/v4"
const LineManagerFields = ({ validator, isFilled, ...props }) => {
  const [editable, setEditable] = useState(false)
  const [notifyOnClosed, setNotifyOnClosed] = useState(true)
  const [notifyToApprove, setNotifyToApprove] = useState(true)
  delete validator.profile
  delete validator.poste
  validator.editable = editable
  validator.notifyOnClosed = notifyOnClosed
  validator.notifyToApprove = notifyToApprove

  const handleEditableChange = (event) => {
    //event.target.value est une string
    if (event.target.value == "true") {
      setEditable(true)
    } else {
      setEditable(false)
    }
  }
  const handleNotifyOnCloseChange = (event) => {
    //event.target.value est une string
    if (event.target.value == "true") {
      setNotifyOnClosed(true)
    } else {
      setNotifyOnClosed(false)
    }
  }

  const handleNotifyToApproveChange = (event) => {
    //event.target.value est une string
    if (event.target.value == "true") {
      setNotifyToApprove(true)
    } else {
      setNotifyToApprove(false)
    }
  }

  isFilled(true)
  return (
    <Col xs="10">
      <Row>
        <Col xs="4">
          <FormControl component="fieldset">
            <FormLabel component="legend">Editable ?</FormLabel>
            <RadioGroup
              aria-label="editable"
              name="editable"
              value={editable}
              onChange={handleEditableChange}
              row
            >
              <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
            </RadioGroup>
          </FormControl>
        </Col>
        <Col xs="5">
          <FormControl component="not">
            <FormLabel component="legend_notify">
              Notifier le contributeur lorsque la demande est close
            </FormLabel>
            <RadioGroup
              aria-label="notify"
              name="notify"
              value={notifyOnClosed}
              onChange={handleNotifyOnCloseChange}
              row
            >
              <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
            </RadioGroup>
          </FormControl>
        </Col>
        <Col xs="5">
          <FormControl component="not">
            <FormLabel component="legend_notify">
              Autoriser la notification immédiate
            </FormLabel>
            <RadioGroup
              aria-label="notify-approve"
              name="notify-approve"
              value={notifyToApprove}
              onChange={handleNotifyToApproveChange}
              row
            >
              <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
            </RadioGroup>
          </FormControl>
        </Col>
      </Row>
    </Col>
  )
}

export default LineManagerFields
