import React, { useState, useEffect } from "react"
import AutoComplete from "components/form/AutoComplete"
import styled from "styled-components"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import { Row, Col } from "@bootstrap-styled/v4"

const CustomAutoComplete = styled(AutoComplete)`
  width: 100%;
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0px !important;
  }
`

const CustomFormControl = styled(FormControl)`
  margin-top: 5px !important;
`

const ProfileFields = ({ onProfileChange, isFilled, validator, ...props }) => {
  const [profile, setProfile] = useState(validator.profile)
  const [editable, setEditable] = useState(false)
  const [notifyOnClosed, setNotifyOnClosed] = useState(true)
  const [notifyToApprove, setNotifyToApprove] = useState(false)
  validator.notifyOnClosed = notifyOnClosed
  validator.notifyToApprove = notifyToApprove
  useEffect(() => {
    isFilled(profile != null)
  }, [profile != null])
  delete validator.poste

  const handleProfileChange = (profile) => {
    validator.profile = {
      "@id": profile["@id"],
      lastname: profile.lastname,
      firstname: profile.firstname,
    }
    setProfile(validator.profile)
  }

  const handleEditableChange = (event) => {
    //event.target.value est une string
    if (event.target.value == "true") {
      setEditable(true)
      validator.editable = true
    } else {
      setEditable(false)
      validator.editable = false
    }
  }
  const handleNotifyOnCloseChange = (event) => {
    //event.target.value est une string
    if (event.target.value == "true") {
      setNotifyOnClosed(true)
    } else {
      setNotifyOnClosed(false)
    }
  }

  const handleNotifyToApproveChange = (event) => {
    //event.target.value est une string
    if (event.target.value == "true") {
      setNotifyToApprove(true)
    } else {
      setNotifyToApprove(false)
    }
  }

  return (
    <>
      <Col xs="12">
        <CustomAutoComplete onProfileChange={handleProfileChange} />
      </Col>
      <Col xs="3">
        <CustomFormControl component="fieldset">
          <FormLabel component="legend">Editable ?</FormLabel>
          <RadioGroup
            aria-label="editable"
            name="editable"
            value={editable}
            onChange={handleEditableChange}
            row
          >
            <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
            <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
          </RadioGroup>
        </CustomFormControl>
      </Col>
      <Col xs="4">
        <CustomFormControl>
          <FormLabel>Notifier lorsque la demande est close</FormLabel>
          <RadioGroup
            aria-label="notify"
            name="notify"
            value={notifyOnClosed}
            onChange={handleNotifyOnCloseChange}
            row
          >
            <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
            <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
          </RadioGroup>
        </CustomFormControl>
      </Col>
      <Col xs="4">
        <CustomFormControl>
          <FormLabel>Autoriser la notification immédiate</FormLabel>
          <RadioGroup
            aria-label="notify-approve"
            name="notify-approve"
            value={notifyToApprove}
            onChange={handleNotifyToApproveChange}
            row
          >
            <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
            <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
          </RadioGroup>
        </CustomFormControl>
      </Col>
    </>
  )
}

export default ProfileFields
