import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"

import ElementContainer from "components/form/element/ElementContainer"
import SquareButton from "components/common/SquareButton"
import Plus from "components/icons/Plus"

import Colors from "theme/Colors"
import InvestmentLine from "components/form/element/InvestmentLine"

const ContentContainer = styled.div`
  @media screen and (max-width: 1690px) {
    overflow-x: scroll;
  }
`

const Content = styled.div`
  min-width: 1300px;
`

const AddDocsButtonContainer = styled.div`
  width: 215px;
  margin-top: 10px;
`

const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 50px;
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const TotalLine = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 600;
  margin: 30px 0 0 0;
`

const calculateTotal = (lines) => {
  let total = 0
  if (!lines || !lines.length) {
    return total
  }
  lines.map((line) => {
    if (line && line.total) {
      if (typeof line.total == "string") {
        total += parseFloat(line.total.replace(",", "."))
      } else if (typeof line.total == "number") {
        total += line.total
      }
    }
    return null
  })
  return total
}

const InvestmentDetails = ({
  className,
  element,
  editable,
  onChange,
  value,
  matchingResponse,
  ...props
}) => {
  const [lines, setLines] = useState(value ? value.investmentLines : [{}])
  const [total, setTotal] = useState(
    value && value.investmentLines ? calculateTotal(value.investmentLines) : 0
  )
  const [alreadyIntializeMandatoryResponse, setAlreadyIntializeMandatoryResponse] = useState(false)

  /*const isTablet = useMediaQuery({
    query: '(max-device-width: 1240px)'
  })
  const[width] = useState(isTablet ? element.width : "100%")*/
  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    // const initialisedValue = matchingResponse[0].value
    // setValue(matchingResponse[0].value)
    // onChange({
    //   ...value,
    //   type: "text",
    //   value: initialisedValue,
    //   question: element["@id"],
    // })
  }, [matchingResponse])

  useEffect(() => {
    if (element && element.isMandatory && !alreadyIntializeMandatoryResponse && !value) {
      onChange({
        ...value,
        type: "investment_details",
        investmentLines: [{}],
        question: element["@id"],
      })
      setAlreadyIntializeMandatoryResponse(true)
    }
  }, [element])

  const handleChange = (line, position) => {
    if (!editable) {
      return
    }
    lines[position] = line
    onChange({
      ...value,
      type: "investment_details",
      investmentLines: lines,
      question: element["@id"],
      //width: width,
    })
    setTotal(calculateTotal(lines))
    setLines([...lines])
  }

  const handleDelete = (key) => {
    if (!editable) {
      return
    }

    lines.splice(key, 1)
    onChange({
      ...value,
      type: "investment_details",
      investmentLines: lines,
      question: element["@id"],
    })
    setTotal(calculateTotal(lines))
    setLines([...lines])
  }

  const handleAddLine = () => {
    lines.push({})
    setLines([...lines])
  }

  return (
    <ElementContainer className="investment" width={element.width}>
      <ContentContainer>
        <Content>
          <Row className="justify-content-center align-items-center" noGutters={true}>
            <Col>
              <ElementContainer noResponsive={true} width={13.74}>
                Fournisseur
              </ElementContainer>

              <ElementContainer noResponsive={true} width={7.2}>
                Quantité
              </ElementContainer>

              <ElementContainer noResponsive={true} width={7.2}>
                Prix Unitaire
              </ElementContainer>

              <ElementContainer noResponsive={true} width={20.8}>
                Description
              </ElementContainer>

              <ElementContainer noResponsive={true} width={7.2}>
                Total HT
              </ElementContainer>

              <ElementContainer noResponsive={true} width={14.29}>
                Centre de coût
              </ElementContainer>

              <ElementContainer noResponsive={true} width={10.2}>
                Durée d'utilisation
              </ElementContainer>

              <ElementContainer noResponsive={true} width={14.29}>
                Catégorie
              </ElementContainer>
            </Col>
          </Row>
          {lines.map((item, key) => (
            <InvestmentLine
              categories={element.categories}
              editable={editable}
              onChange={handleChange}
              onDelete={handleDelete}
              value={item}
              position={key}
              key={key}
            />
          ))}
        </Content>
      </ContentContainer>
      {editable && (
        <AddDocsButtonContainer onClick={handleAddLine}>
          <AddDocsButton noGutters={true}>
            <Plus />
            <Spacer />
            Ajouter une ligne
          </AddDocsButton>
        </AddDocsButtonContainer>
      )}
      {total > 0 && (
        <TotalLine>
          Total HT :{" "}
          {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(total)}
        </TotalLine>
      )}
    </ElementContainer>
  )
}

export default styled(InvestmentDetails)`
  background-color: transparent;
  min-height: 32px;

  color: ${Colors.black};
  font-size: 13px;
  &.MuiInputBase-root {
    font-family: "Open Sans";
  }
`
