import React from "react"
import styled from "styled-components"
import Link from "./Link"

import Colors from "theme/Colors"

const CustomLink = styled(Link)`
  background-color: ${Colors.primary};
`

const FakeButton = styled.div`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.grey};
  color: ${Colors.white};
  text-align: center;
  &:hover {
    cursor: not-allowed;
  }
`

const NavigationButton = ({ isActive, children, className, isNext, ...props }) =>
  isActive ? (
    <CustomLink isNext={isNext} className={className} {...props}>
      {children}
    </CustomLink>
  ) : (
    <FakeButton isNext={isNext} className={className}>
      {children}
    </FakeButton>
  )

export default styled(NavigationButton)`
  min-height: 40px;
  min-width: 200px;
  box-shadow: 0 0 15px 0 rgba(38, 86, 200, 0.3);
  border-radius: 35px;
  color: ${Colors.white};
  font-size: 18px;
  padding: ${(props) => (props.isNext ? "7px 0px 8px 70px" : "7px 45px 8px 0px")};
`
