import React, { useState, useEffect } from "react"

import { Row, Col } from "@bootstrap-styled/v4"
import styled from "styled-components"

import Container from "components/common/Container"
import Category from "components/newDemande/Category"
import api from "services/api"

import { GET_CATEGORIES_ENDPOINT } from "constants/endpoints"

const CategoryChooser = ({ className, ...props }) => {
  const [categories, setCategories] = useState(null)
  useEffect(() => {
    api
      .get(GET_CATEGORIES_ENDPOINT)
      .then((data) => {
        if (data) {
          setCategories(data["hydra:member"])
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])
  return (
    <Container className={className}>
      <Row className="justify-content-center">
        {categories &&
          categories.map((category) => (
            <Col xs="auto" key={category.id}>
              <Category item={category} />
            </Col>
          ))}
      </Row>
    </Container>
  )
}

export default styled(CategoryChooser)`
  margin: 30px 70px 0;
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
    text-align: center;
  }
`
