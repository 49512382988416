import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import styled from "styled-components"
import Colors from "theme/Colors"
import { Row, Col } from "@bootstrap-styled/v4"
import DemandeCreationContext from "contexts/DemandeCreationContext"

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-size: 16px;
`

const Container = styled(Row)`
  width: 200px;
  border-radius: 5px;
  background-color: ${Colors.primaryVeryDark};
  border: 1px solid ${Colors.primaryVeryDark};
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  color: ${Colors.white};
  height: 100%;
  min-height: 120px;
  padding: 15px;
  margin-top: 15px;
  &:hover,
  &.active {
    cursor: pointer;
    background-color: ${Colors.white};
    border: 1px solid ${Colors.primaryVeryDark};
    color: ${Colors.primaryVeryDark};
    font-weight: 800;
  }
`

const Category = ({ className, item, ...props }) => {
  const { category, setCategory } = useContext(DemandeCreationContext)
  let history = useHistory()

  const chooseCategory = () => {
    setCategory(item.id)
    history.push("choix-formulaire")
  }

  return (
    <div className={className} onClick={chooseCategory}>
      <Container
        className={`justify-content-center align-items-center ${
          category === item.id ? "active" : ""
        }`}
        noGutters={true}
      >
        <Col>
          <Text>{item.label}</Text>
        </Col>
      </Container>
    </div>
  )
}

export default styled(Category)`
  height: 100%;
  padding-bottom: 30px;
`
