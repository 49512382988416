import React, { useState, useEffect } from "react"
import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"
import ChangeLabel from "components/adminCreateForm/fieldsParameters/commonParameters/ChangeLabel"
import SquareButton from "components/common/SquareButton"
import styled from "styled-components"
import Colors from "theme/Colors"
import { Row, Col } from "@bootstrap-styled/v4"
import Delete from "components/icons/Delete"
import { choicesValuesConflicts } from "components/adminCreateForm/fieldsParameters/SelectListParameters"

import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import Switch from "@material-ui/core/Switch"

const AddChoice = styled(SquareButton)`
  //max-width: 50px !important;
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  margin: 10px;
`
const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`
const spacer = styled.div`
  width: 20px;
`

const RadioParameters = ({ saveParameters, fieldParameters, label, fieldWidth, ...props }) => {
  const [choices, setChoices] = useState(fieldParameters.choices || [])

  const validateChangement = () => {
    if (choices.length !== 0) {
      saveParameters({
        type: "radio",
        title: label,
        width: fieldWidth,
        choices: choices,
      })
    }
  }

  const handleAddChoice = () => {
    var joined = choices.concat([
      {
        label: "",
        weight: choices.length,
        value: "",
      },
    ])
    setChoices([...joined])
  }

  const handleChoiceLabelChange = (newLabelEvent, index) => {
    choices[index].label = newLabelEvent.target.value
    setChoices([...choices])
  }

  const deleteChoice = (index) => {
    let updatedArrayOfChoices = choices.slice()
    updatedArrayOfChoices.splice(index, 1)
    setChoices([...updatedArrayOfChoices])
  }

  return (
    <div>
      <Row className="align-items-end justify-content-center w-100">
        {choices.map((choice, index) => {
          return (
            <>
              <Col xs="10">
                <ChangeLabel
                  onLabelChange={(newLabelEvent) => {
                    handleChoiceLabelChange(newLabelEvent, index)
                  }}
                  inputLabel={"Libellé du choix"}
                  label={choice.label}
                />
              </Col>
              <Col xs="2">
                <DeleteButton
                  onClick={() => {
                    deleteChoice(index)
                  }}
                />
              </Col>
            </>
          )
        })}
      </Row>
      <Row className={"justify-content-center"}>
        <AddChoice onClick={handleAddChoice}>Ajouter une réponse</AddChoice>{" "}
      </Row>
      <Row className={"justify-content-center"}>
        <OkButton onClick={validateChangement}>Valider</OkButton>
      </Row>
    </div>
  )
}

export default RadioParameters
