import HttpError from "standard-http-error"

import { ENDPOINT } from "constants/endpoints"
import { DEV } from "constants/environement"

import TokenHelper from "services/token"

const request = async (method, path, params, config) => {
  //headers.set("Accept", "application/json")

  const headersToAdd = {}
  const token = TokenHelper.getToken()
  if (token) {
    headersToAdd["Authorization-EL"] = `Bearer ${token}`
  } else if (config && !config.anonymous) {
    return
  }

  const init = {
    method,
  }

  if (params) {
    headersToAdd["Content-Type"] = "application/json"
    init.body = JSON.stringify(params)
  }

  if (config && config.isFormData) {
    init.body = params
    delete headersToAdd["Content-Type"]
  }

  if (!config) {
    config = {}
  }

  const headers = new Headers({
    ...headersToAdd,
    ...config.headers,
  })

  init.headers = headers

  if (DEV) {
    //console.log("⬆️", method, path, [...headers.values()], params || "")
  }

  let response = null
  try {
    response = await fetch(ENDPOINT + path, init)
  } catch (e) {
    console.log(e)
    response = {}
    response.status = 700
  }
  if (DEV) {
    //console.log(response.status >= 500 ? "❗️" : response.status >= 400 ? "⚠️" : "✅", response)
  }

  if (!response || response.status == 401) {
    TokenHelper.setToken()
    //console.log(response)
    return
  }

  if (response.status >= 400) {
    if (response.status == 400) {
      const json = await response.json()
      if (json["hydra:description"]) {
        const message = json["hydra:description"]
        if (
          message.includes("Attention la question") ||
          message.includes("La réponse à") ||
          message.includes("Vous devez renseigner tous les champs obligatoires")
        ) {
          throw new HttpError(response.status, json["hydra:description"])
        }
      }
    }
    throw new HttpError(response.status, response.statusText)
  }

  if (response.status === 204) {
    return null
  }

  const isJson = response.headers.get("content-type").match(/json/)

  if (isJson) {
    return response.json()
  }

  return response.blob()
}

export default {
  get: (path, config) => request("GET", path, null, config),
  post: (path, params, config) => request("POST", path, params, config),
  put: (path, params, config) => request("PUT", path, params, config),
  delete: (path) => request("DELETE", path),
}
