import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import Colors from "theme/Colors"

import { Row, Col } from "@bootstrap-styled/v4"
import Close from "components/icons/Close"
import Success from "components/icons/Success"

const SuccessPicto = styled(Success)`
  font-size: 100px;
  box-shadow: 0 0 15px 0 ${Colors.primaryShadow};
  border-radius: 50%;
`

const CrossPicto = styled(Close)`
  font-size: 47px;
  margin: 70px;
  cursor: pointer;
`

const TitleText = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 25px;
  font-weight: bold;
  text-align: center;
`

const ContentText = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  text-align: justify;
`

const DemandeValidated = () => {
  let history = useHistory()

  const handleClose = () => {
    history.push("/")
  }

  return (
    <div>
      <Row className="justify-content-end">
        <Col xs="auto">
          <CrossPicto onClick={handleClose} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg="4">
          <Row className="justify-content-center">
            <Col xs="auto">
              <SuccessPicto primary={Colors.white} secondary={Colors.primary} />
            </Col>
          </Row>
          <TitleText>Le formulaire a bien été validé !</TitleText>
        </Col>
      </Row>
    </div>
  )
}

export default DemandeValidated
