import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"

import ElementContainer from "components/form/element/ElementContainer"
import SquareButton from "components/common/SquareButton"
import Plus from "components/icons/Plus"

import Colors from "theme/Colors"
import TableLine from "components/form/element/TableLine"
import FormLabel from "@material-ui/core/FormLabel"

import { v4 as uuidv4 } from "uuid"

const ContentContainer = styled.div`
  ${(props) => `min-width: ${props.minWidth};`}
`

const Bold = styled.span`
  font-weight: bold;
`

const AddDocsButtonContainer = styled.div`
  width: 215px;
  margin-top: 10px;
`

const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 50px;
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const StyledElementContainer = styled(ElementContainer)`
  overflow-x: auto;
`

const calculateTotal = (lines) => {
  const total = {}
  if (!lines || !lines.length) {
    return total
  }
  lines.map((line) => {
    const responses = line.responses
    responses.map((response) => {
      if (response.type === "sum_response") {
        if (total[response.question] == null) {
          total[response.question] = 0
        }
        total[response.question] += response.value
      }
    })
  })
  return total
}

const Table = ({ className, element, editable, onChange, value, ...props }) => {
  const [lines, setLines] = useState(
    value
      ? value.responseLines
      : [{ type: "response_line", responses: [], question: element["@id"], uniqueKey: uuidv4() }]
  )
  const [sums, setSums] = useState(
    value && value.responseLines ? calculateTotal(value.responseLines) : null
  )

  const handleChange = (line, position) => {
    if (!editable) {
      return
    }
    lines[position] = line
    onChange({
      ...value,
      type: "response_table",
      responseLines: lines,
      question: element["@id"],
    })
    // setTotal(calculateTotal(lines))
    setLines([...lines])
  }

  const handleAddLine = () => {
    lines.push({
      type: "response_line",
      responses: [],
      question: element["@id"],
      uniqueKey: uuidv4(),
    })
    setLines([...lines])
  }

  const handleDeleteLine = (indexOfTheLine) => {
    lines.splice(indexOfTheLine, 1)
    onChange({
      ...value,
      type: "response_table",
      responseLines: lines,
      question: element["@id"],
    })
    // lines.splice(indexOfTheLine, 1)
    setLines([...lines])
  }

  const calculateSum = () => {
    setSums({ ...calculateTotal(lines) })
  }

  return (
    <StyledElementContainer className="investment" width={element.width}>
      <FormLabel>{element.title}</FormLabel>
      <ContentContainer className="content-container" minWidth={`${element.minWidth}px`}>
        <div>
          {lines.map((item, index) => {
            return (
              <TableLine
                questions={element.questions}
                editable={editable}
                onChange={handleChange}
                key={item.id || item.uniqueKey}
                line={index}
                lineAnswers={item}
                tableMinWidth={element.minWidth}
                isDeletable={true}
                onDelete={handleDeleteLine}
                sumCallBack={calculateSum}
                sums={sums}
              />
            )
          })}
          {sums && (
            <Row className="justify-content-start align-items-center" noGutters>
              {element.questions.map((question, index) => {
                return (
                  <ElementContainer
                    key={index}
                    width={question.width}
                    // minWidth={minWidth}
                    noResponsive={true}
                  >
                    <Bold>{sums[question["@id"]] && `Total : ${sums[question["@id"]]}`}</Bold>
                  </ElementContainer>
                )
              })}
            </Row>
          )}
        </div>
      </ContentContainer>

      {editable && (
        <AddDocsButtonContainer onClick={handleAddLine}>
          <AddDocsButton noGutters={true}>
            <Plus />
            <Spacer />
            Ajouter une ligne
          </AddDocsButton>
        </AddDocsButtonContainer>
      )}
    </StyledElementContainer>
  )
}

export default styled(Table)`
background - color: transparent;
min - height: 32px;

color: ${Colors.black};
font - size: 13px;
  &.MuiInputBase - root {
    font - family: "Open Sans";
}
`
