import styled from "styled-components"
import { element } from "prop-types"

export default styled.div`
  padding: 10px 10px;
  display: inline-block;
  &:nth-last-child(2) {
    width: ${({ width }) => (width ? "calc(" + width + "% - 24px)" : "inherit")} !important;
  }
  width: ${({ width }) => (width ? width + "%" : "inherit")};
  ${({ minWidth }) => minWidth && `min-width : ${minWidth}px;`}

  ${({ noResponsive }) =>
    noResponsive
      ? ""
      : `@media screen and (max-width: 1200px) {
         display: inline-block;
         width: 100%; 
     }
  `};

  .mandatory-field {
    font-weight: bold;
  }
`
