import React, { useContext, useState } from "react"
import styled from "styled-components"
import Colors from "theme/Colors"
import * as Icons from "components/icons"
import { Row, Col } from "@bootstrap-styled/v4"
import AuthenticationContext from "contexts/AuthenticationContext"
import Text from "components/common/Text"
import MenuLink from "components/common/MenuLink"

const MenuDiv = styled.div``

const OpenMenu = styled(MenuDiv)`
  width: 360px !important;
`
const CloseMenu = styled(MenuDiv)`
  width: 100px;
`
const MenuText = styled(Text)`
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.32px;
`

const openOrClose = {
  true: OpenMenu,
  false: CloseMenu,
}

export const OpenMenuContext = React.createContext(false)

const Menu = ({ className, ...props }) => {
  const [fullMenuOpen, setFullMenuOpen] = useState(false)
  const Element = openOrClose[fullMenuOpen]

  const openFullMenu = () => {
    setFullMenuOpen(!fullMenuOpen)
  }
  const handlePictoClick = () => {
    setFullMenuOpen(false)
  }

  const DisplayFullMenuPicto = Icons["DisplayFullMenuPicto"]
  const CloseFullMenuPicto = Icons["CloseFullMenuPicto"]
  const { token } = useContext(AuthenticationContext)
  const content = token.split(".")
  const infos = JSON.parse(atob(content[1]))
  const isAdmin = infos.roles[0] === "ROLE_ADMIN"

  return (
    <OpenMenuContext.Provider value={fullMenuOpen}>
      <Element className={className}>
        <Col xs="12">
          <Row>
            <MenuLink onClick={openFullMenu}>
              {fullMenuOpen ? (
                <>
                  <Col xs="auto">
                    <CloseFullMenuPicto />
                  </Col>
                  <Col xs="9">
                    <MenuText> </MenuText>
                  </Col>
                </>
              ) : (
                <Col xs="auto">
                  <DisplayFullMenuPicto />
                </Col>
              )}
            </MenuLink>
          </Row>

          <Row>
            <MenuLink
              to="/demandes"
              name="demandes"
              fullMenuOpen={fullMenuOpen}
              label="Demandes actives"
              onClick={handlePictoClick}
            />
          </Row>

          <Row>
            <MenuLink
              to="/nouvelle-demande/choix-categorie"
              name="nouvelle-demande"
              label="Nouvelle demande"
              onClick={handlePictoClick}
            />
          </Row>

          <Row>
            <MenuLink
              to="/demandes-archivees"
              name="demandes-archivees"
              label="Demandes archivées"
              onClick={handlePictoClick}
            />
          </Row>

          <Row>
                <MenuLink
                  to="/remplacements"
                  name="remplacements"
                  label="Tous les remplacements"
                  onClick={handlePictoClick}
                />
              </Row>

          {isAdmin && (
            <>
              <Row>
                <MenuLink
                  to="/formulaires"
                  name="formulaires"
                  label="Tous les formulaires"
                  onClick={handlePictoClick}
                />
              </Row>

              <Row>
                <MenuLink
                  to="/nouveau-formulaire"
                  name="nouveau-formulaire"
                  label="Nouveau formulaire"
                  onClick={handlePictoClick}
                />
              </Row>

              <Row>
                <MenuLink
                  to="/parametres"
                  name="parametres"
                  label="Paramètres"
                  onClick={handlePictoClick}
                />
              </Row>
              <Row>
                <MenuLink
                  to="/changement-utilisateur"
                  name="changement-utilisateur"
                  label="Changer d'utilisateur"
                  onClick={handlePictoClick}
                />
              </Row>
            </>
          )}
        </Col>
      </Element>
    </OpenMenuContext.Provider>
  )
}

export default styled(Menu)`
  background-color: ${Colors.primary};
  box-shadow: 2px 2px 5px 0 ${Colors.primaryShadow};
  position: fixed !important;
  top: 80px;
  left: 0;
  bottom: 0;
  min-height: 1px;
  // padding-right: calc(30px / 2);
  // padding-left: calc(30px / 2);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  //width: 100px;
`
