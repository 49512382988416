import React, { useState, useEffect } from "react"
import TextInputParameters from "components/adminCreateForm/fieldsParameters/TextInputParameters"

import ContainerParameters from "components/adminCreateForm/fieldsParameters/ContainerParameters"
import SelectListParameters from "components/adminCreateForm/fieldsParameters/SelectListParameters"
import SubQuestionParameters from "components/adminCreateForm/fieldsParameters/SubQuestionParameters"
import InvestmentDetailsParameters from "components/adminCreateForm/fieldsParameters/InvestmentDetailsParameters"
import SiteParameters from "components/adminCreateForm/fieldsParameters/SiteParameters"
import RadioParameters from "components/adminCreateForm/fieldsParameters/RadioParameters"
import DateTimeParameters from "components/adminCreateForm/fieldsParameters/DateTimeParameters"
import TextAreaParameters from "components/adminCreateForm/fieldsParameters/TextAreaParameters"
import AttachmentParameters from "components/adminCreateForm/fieldsParameters/AttachmentParameters"
import BusinessUnitParameters from "components/adminCreateForm/fieldsParameters/BusinessUnitParameters"
import CommentParameters from "components/adminCreateForm/fieldsParameters/CommentParameters"
import CheckboxParameters from "components/adminCreateForm/fieldsParameters/CheckboxParameters"
import NumericParameters from "components/adminCreateForm/fieldsParameters/NumericParameters"
import SumParameters from "components/adminCreateForm/fieldsParameters/SumParameters"
import TableParameters from "components/adminCreateForm/fieldsParameters/TableParameters"
import FixedTableParameters from "components/adminCreateForm/fieldsParameters/FixedTableParameters"
import { Row, Col } from "@bootstrap-styled/v4"
import SquareButton from "components/common/SquareButton"
import Colors from "theme/Colors"
import styled from "styled-components"
import ChangeLabel from "components/adminCreateForm/fieldsParameters/commonParameters/ChangeLabel"
import SelectingWidth from "components/adminCreateForm/fieldsParameters/commonParameters/SelectingWidth"
import IsMandatory from "components/adminCreateForm/fieldsParameters/commonParameters/IsMandatory"
import { CreateFormContext } from "contexts/CreateFormContext"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"

const formElts = {
  container: ContainerParameters,
  text_input: TextInputParameters,
  select_list: SelectListParameters,
  sub_question: SubQuestionParameters,
  text_area: TextAreaParameters,
  attachment: AttachmentParameters,
  investment: InvestmentDetailsParameters,
  business_unit: BusinessUnitParameters,
  site: SiteParameters,
  radio: RadioParameters,
  checkbox: CheckboxParameters,
  date_time: DateTimeParameters,
  comment_question: CommentParameters,
  numeric_question: NumericParameters,
  table_question: TableParameters,
  fixed_table_question: FixedTableParameters,
  sum: SumParameters,
}

const Parameters = ({ element, saveParameters, fieldParameters, closePopup, ...props }) => {
  const AdaptedParameters = formElts[element]
  const [label, setLabel] = useState(fieldParameters.title || "")
  const [fieldWidth, setFieldWidth] = useState(fieldParameters.width || 100)
  const [description, setDescription] = useState(fieldParameters.description || "")
  const [isMandatory, setIsMandatory] = useState(fieldParameters.isMandatory === true || false)

  const handleLabelChange = (event) => {
    setLabel(event.target.value || "")
  }
  const handleWidthChange = (event) => {
    setFieldWidth(event.target.value)
  }

  const handleMandatoryChange = (event) => {
    setIsMandatory(event.target.checked)
    if (event.target.checked) {
      setLabel(label + " *")
    } else {
      const oldLabel = label
      setLabel(oldLabel.replace(" *", "").replace("*", ""))
    }
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value || "")
  }

  const addWidthAndLabelToSavedParameters = (childrenObject) => {
    if (parseInt(fieldWidth) >= 1 && parseInt(fieldWidth) <= 100) {
      childrenObject.title = label
      childrenObject.width = parseInt(fieldWidth)
      childrenObject.description = description
      childrenObject.isMandatory = isMandatory
      childrenObject.questionToRetrieveResponse = fieldParameters.questionToRetrieveResponse
      childrenObject.retrieveResponseInThePreviousDemande =
        fieldParameters.retrieveResponseInThePreviousDemande
      //si la question que l'on vient de modifier garde le même type qu'avant, alors on récupère son id. Cela évite les bugs notament sur les choices questions
      if (fieldParameters["@id"] && fieldParameters.type === childrenObject.type) {
        childrenObject["@id"] = fieldParameters["@id"]
        childrenObject.id = fieldParameters.id
      }
      saveParameters(childrenObject)
      closePopup()
    } else {
      alert("Taille du champ incorrecte")
    }
  }

  if (element) {
    return (
      <>
        <Row>
          <Col xs="6">
            <ChangeLabel onLabelChange={handleLabelChange} label={label} />
          </Col>
          <Col xs="6">
            <SelectingWidth onWidthChange={handleWidthChange} fieldWidth={fieldWidth} />
          </Col>
        </Row>
        {element !== "table_question" &&
          element !== "fixed_table_question" &&
          element !== "container" && (
            <Row className="justify-content-start">
              <Col xs="auto">
                <IsMandatory
                  fieldParameters={fieldParameters}
                  onMandatoryChange={handleMandatoryChange}
                  isMandatory={isMandatory}
                />
              </Col>
            </Row>
          )}
        <AdaptedParameters
          saveParameters={addWidthAndLabelToSavedParameters}
          fieldParameters={fieldParameters}
          label={label}
        />
      </>
    )
  } else {
    return <></>
  }
}

export default Parameters

export const OkButton = styled(SquareButton)`
  max-width: 90px !important;
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
`
