import React from "react"
import styled from "styled-components"
import Colors from "theme/Colors"
import Loader from "components/common/Loader"
import { Row } from "@bootstrap-styled/v4"

const ButtonLoader = styled(Loader)`
  padding-right: 30px;
`
const voidFunction = () => {}

const CustomButton = ({ children, loading, onClick, className, ...props }) => (
  <Row
    disabled={loading ? "disabled" : ""}
    onClick={loading ? voidFunction : onClick}
    className={`align-items-center justify-content-center ${className}`}
    {...props}
  >
    {loading && <ButtonLoader color={Colors.white} />}
    {children}
  </Row>
)

export default styled(CustomButton)`
  background-color: ${Colors.primary};
  min-height: 40px;
  max-width: 335px;
  width: 100%;
  border-radius: 20px;
  color: ${Colors.white};
  box-shadow: 0 0 15px 0 ${Colors.primaryShadow};
  &:-moz-focus-inner {
    border: 0;
  }
  &:hover {
    cursor: pointer;
  }
`
