import React from "react"

const SvgReglages = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 56 54" {...props}>
    <g transform="translate(-24 -653)" fill="#FFF" fillRule="evenodd">
      <path
        d="M61.661 675.111H59.23a.908.908 0 01-.863-.576.909.909 0 01.202-1.018l1.72-1.72a1.33 1.33 0 00.392-.946c0-.358-.139-.694-.392-.947l-2.192-2.192c-.505-.506-1.387-.507-1.894 0l-1.719 1.72a.904.904 0 01-.65.275.972.972 0 01-.658-.26.902.902 0 01-.286-.676v-2.432c0-.739-.6-1.339-1.339-1.339h-3.1c-.738 0-1.339.6-1.339 1.339v2.432c0 .586-.48.936-.944.936a.905.905 0 01-.65-.276l-1.72-1.72c-.506-.506-1.388-.505-1.894 0l-2.192 2.193a1.33 1.33 0 00-.392.947c0 .357.14.693.392.947l1.72 1.719a.91.91 0 01.202 1.018.907.907 0 01-.862.576h-2.432c-.739 0-1.339.6-1.339 1.339v3.1c0 .738.6 1.339 1.339 1.339h2.432c.39 0 .713.215.863.576a.909.909 0 01-.202 1.018l-1.72 1.72a1.33 1.33 0 00-.392.946c0 .358.139.694.392.947l2.192 2.192c.506.506 1.387.507 1.894 0l1.719-1.72a.904.904 0 01.65-.275c.465 0 .945.35.945.936v2.432c0 .738.6 1.339 1.338 1.339h3.1c.738 0 1.338-.6 1.338-1.339v-2.432c0-.586.48-.936.944-.936.17 0 .422.048.651.276l1.72 1.72c.506.506 1.388.505 1.893 0l2.192-2.193a1.33 1.33 0 00.392-.947c0-.357-.139-.693-.392-.947l-1.72-1.719a.91.91 0 01-.202-1.018.907.907 0 01.863-.576h2.432c.739 0 1.339-.601 1.339-1.34v-3.1c0-.737-.6-1.338-1.339-1.338zM53.371 678A3.375 3.375 0 0150 681.37a3.375 3.375 0 01-3.37-3.37 3.375 3.375 0 013.37-3.37 3.375 3.375 0 013.37 3.37z"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgReglages
