import React, { useEffect, useState, useContext } from "react"
import { useParams, useHistory } from "react-router-dom"
import styled from "styled-components"

import Colors from "theme/Colors"

import { Row, Col } from "@bootstrap-styled/v4"
import Close from "components/icons/Close"
import Success from "components/icons/Success"
import Button from "components/common/Button"
import api from "services/api"
import { GET_DEMANDE_ENDPOINT } from "constants/endpoints"
import DemandeCreationContext from "contexts/DemandeCreationContext"

const CustomButton = styled(Button)`
  min-width: 200px;
  margin-bottom: 10px;
`
const SuccessPicto = styled(Success)`
  font-size: 100px;
  box-shadow: 0 0 15px 0 ${Colors.primaryShadow};
  border-radius: 50%;
`

const CrossPicto = styled(Close)`
  font-size: 47px;
  margin: 70px;
  cursor: pointer;
`

const TitleText = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 25px;
  font-weight: bold;
  text-align: center;
`

const ContentText = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  text-align: center;
`

const DemandeValidated = () => {
  const [formTitle, setFormTitle] = useState("")
  let { id } = useParams()
  let history = useHistory()
  const { setValideurs, setForm, setValues, setTitle, setCategory, setBu } = useContext(
    DemandeCreationContext
  )

  useEffect(() => {
    api
      .get(GET_DEMANDE_ENDPOINT(id))
      .then((data) => {
        setFormTitle(data.form.title.toLowerCase())
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [id])

  const handleClose = () => {
    setValideurs([])
    setForm(null)
    setValues([])
    setCategory(null)
    setTitle("")
    setBu("")
    history.push("/")
  }

  const handleDoing = () => {
    api
      .put(GET_DEMANDE_ENDPOINT(id), {
        validated: true,
      })
      .then((data) => {
        setValideurs([])
        setCategory(null)
        setForm(null)
        setValues([])
        setTitle("")
        setBu("")
        history.push("/")
      })
      .catch((e) => alert("erreur : " + e.message))
  }

  return (
    <div>
      <Row className="justify-content-end">
        <Col xs="auto">
          <CrossPicto onClick={handleClose} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg="4">
          <Row className="justify-content-center">
            <Col xs="auto">
              <SuccessPicto primary={Colors.white} secondary={Colors.primary} />
            </Col>
          </Row>
          <TitleText>Votre {formTitle} a bien été enregistrée !</TitleText>
          <ContentText>
            Votre demande a été enregistrée en tant que brouillon. Pour envoyer cette demande au
            premier valideur, veuillez cliquer sur le bouton "Valider".
          </ContentText>
          <Row className="justify-content-around" noGutters={true}>
            <CustomButton noGutters={true} onClick={handleClose}>
              Fermer
            </CustomButton>
            <CustomButton noGutters={true} onClick={handleDoing}>
              Valider
            </CustomButton>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default DemandeValidated
