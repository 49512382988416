import React from "react"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import { Fade } from "@material-ui/core"

const ErrorPopUp = ({ title, content, open, close, ...props }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      transitionDuration={{ exit: 50 }}
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title ? title : "Erreur lors de l'envoi du fichier"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorPopUp
