import React, { useContext, useEffect, useState } from "react"

import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import LoaderOverlay from "components/common/LoaderOverlay"

import api from "services/api"
import Profils from "components/icons/Profils"
import UserContext from "contexts/UserContext"
import { BASE_URL } from "constants/endpoints"

const Firstname = styled.span`
  text-transform: capitalize;
`

const CustomSelect = styled(Select)`
  width: 100%;
`

const ProfilsPicto = styled(Profils)`
  font-size: 35px;
  margin-right: 20px;
`

const PosteDisplay = ({ className, validator, ...props }) => {
  const [selectedValue, setSelectedValue] = useState(validator.profile)
  const [profilesList, setProfilesList] = useState([])
  const handleChange = (event) => {
    setSelectedValue(event.target.value)
    validator.profile = event.target.value
  }
  useEffect(() => {
    if (validator.poste.profiles && typeof validator.poste.profiles[0] == "string") {
      validator.poste.profiles.map((url) => {
        api
          .get(`${BASE_URL}${url}`)
          .then((result) => {
            profilesList.push(result)
            setProfilesList([...profilesList])
          })
          .catch((e) => alert("erreur : " + e.message))
      })
    } else if (validator.poste.profiles) {
      validator.poste.profiles.map((choice) => {
        profilesList.push(choice)
      })
      setProfilesList([...profilesList])
    }
  }, [])

  return (
    <div className={className}>
      <Row className={"align-items-center"}>
        <Col xs="auto">
          <ProfilsPicto />
        </Col>
        <Col>
          <CustomSelect autoWidth={false} value={selectedValue} onChange={handleChange}>
            <MenuItem value={null}>Choisissez une valeur</MenuItem>
            {profilesList &&
              profilesList.map((choice) => {
                return (
                  <MenuItem value={choice["@id"]} key={choice.id}>
                    <span>
                      <Firstname>{choice.firstname && choice.firstname.toLowerCase()}</Firstname>{" "}
                      {choice.lastname && choice.lastname}
                    </span>
                  </MenuItem>
                )
              })}
          </CustomSelect>
        </Col>
      </Row>
    </div>
  )
}

export default PosteDisplay
