import React, { useState } from "react"
import { Switch, Route, useRouteMatch } from "react-router-dom"
import { useHistory } from "react-router-dom"

import DemandeStatutUpdated from "./DemandeStatusUpdated"
import ViewDemande from "./ViewDemande"
import DemandeUpdated from "./DemandeUpdated"
import DemandeSaved from "./DemandeSaved"
import DemandeList from "./DemandeList"

const DemandeListRouter = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={DemandeList} />
      <Route exact path={path + "/:id"} component={ViewDemande} />
      <Route path={path + "/validee/:id"} component={DemandeUpdated} />
      <Route path={path + "/enregistree/:id"} component={DemandeSaved} />
      <Route path={path + "/update_status/:id"} component={DemandeStatutUpdated} />
    </Switch>
  )
}
export default DemandeListRouter
