import React, { useState } from "react"
import { Switch, Route, useRouteMatch } from "react-router-dom"
import { useHistory } from "react-router-dom"

import ViewDemande from "./ViewDemande"
import DemandeArchived from "./DemandeArchived"

const DemandeArchivedRouter = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={DemandeArchived} />
      <Route exact path={path + "/:id"} component={ViewDemande} />
    </Switch>
  )
}
export default DemandeArchivedRouter
