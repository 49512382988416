import React, { useState, useContext } from "react"
import styled from "styled-components"
import PageTop from "components/layout/PageTop"
import ProgressionViewer from "components/newDemande/ProgressionViewer"
import NavigationButton from "components/common/NavigationButton"
import { Next, Back } from "components/icons"
import CancelLink from "components/common/CancelLink"
import Container from "components/common/Container"
import WorkFlowConfiguration from "components/adminCreateForm/settingWorkFlow/WorkFlowConfiguration"

import CancelLinkFormCreation from "components/common/CancelLinkFormCreation"
import PageBottom from "components/layout/PageBottom"

import { useRouteMatch, useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"

const Spacer = styled.span`
  width: 20px;
  height: 1 px;
`
const CustomContainer = styled(Container)`
  padding: 30px;
`

const SetWorkFlow = ({ className, ...props }) => {
  const { id } = useParams()

  let path
  if (id) {
    path = `/edition/${id}`
  } else {
    path = `/nouveau-formulaire`
  }
  let history = useHistory()

  const cancelButton = () => <CancelLinkFormCreation></CancelLinkFormCreation>
  const previousButton = () => (
    <NavigationButton isActive={true} to={`${path}`}>
      <Back />
      <Spacer />
      Précédent
    </NavigationButton>
  )
  const nextButton = () => (
    <NavigationButton isActive={true} to={`${path}/notifications`} isNext={true}>
      Suivant
      <Spacer />
      <Next />
    </NavigationButton>
  )

  return (
    <>
      <div>
        <PageTop getBackComponent={cancelButton} title={"Nouveau formulaire"} />
        <ProgressionViewer
          next={nextButton}
          previous={previousButton}
          title="Paramétrer le flux d'envoi"
          percent={50}
        />
      </div>

      <CustomContainer className={className}>
        <WorkFlowConfiguration />
      </CustomContainer>
      <PageBottom next={nextButton} />
    </>
  )
}

export default styled(SetWorkFlow)`
  margin: 30px 70px 0;
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
    text-align: center;
  }
`
