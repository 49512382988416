import React from "react"

import styled from "styled-components"
import Colors from "theme/Colors"
import Loader from "components/common/Loader"

const LoaderOverlay = ({ className, ...props }) => (
  <div className={className} {...props}>
    <Loader />
  </div>
)
export default styled(LoaderOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.greyBorder};
`
