import React, { useState, useContext } from "react"
import styled from "styled-components"
import UserContext from "contexts/UserContext"
import AuthenticationContext from "contexts/AuthenticationContext"
import { useHistory } from "react-router-dom"
import PageTop from "components/layout/PageTop"
import PageBottom from "components/layout/PageBottom"
import Link from "components/common/Link"
import ProgressionViewer from "components/newDemande/ProgressionViewer"
import NavigationButton from "components/common/NavigationButton"
import CustomRow from "components/newDemande/ProgressionViewer.js"
import { Row, Col } from "@bootstrap-styled/v4"
import { Next, Back } from "components/icons"
import CancelLinkFormCreation from "components/common/CancelLinkFormCreation"
import Container from "components/common/Container"

import { useRouteMatch, useParams } from "react-router-dom"
import Text from "components/common/Text.js"
import Contributor from "components/adminCreateForm/Contributor"

import draftToHtml from "draftjs-to-html"
import { ContentState, convertToRaw } from "draft-js"
import { CreateFormContext } from "contexts/CreateFormContext"
import api from "services/api"
import { BASE_URL, GET_FORMS_ENDPOINT } from "constants/endpoints"
import { moveElementInsideAnArray } from "functions/moveElementInsideAnArray"

const Spacer = styled.span`
  width: 20px;
  height: 1 px;
`
const CustomContainer = styled(Container)`
  padding: 30px;
`
const RedText = styled(Text)`
  color: #e83b5f;
  font-weight: bold;
`

const Notifications = ({ className, ...props }) => {
  const { profile } = useContext(UserContext)
  const {
    questions,
    steps,
    notice,
    setNotice,
    title,
    isBackupAllowed,
    abreviation,
    category,
    weight,
    setWeight,
    maillingList,
    notification,
    setNewFormId,
    titleInputLabel,
    previousForm,
    allFormsOfTheCategoryAndTheirNewWeight,
    validationUnitaire,
    version,
  } = useContext(CreateFormContext)
  const backBuntton = () => <Link to="/"> Retour</Link>
  const { token } = useContext(AuthenticationContext)
  const content = token.split(".")
  const infos = JSON.parse(atob(content[1]))
  const isAdmin = infos.roles[0] === "ROLE_ADMIN"
  let { path } = useRouteMatch()
  const params = useParams()
  const id = params.id
  let history = useHistory()
  const postForm = () => {
    steps.map((valideur) => {
      if (valideur.poste) {
        valideur.poste = valideur.poste["@id"]
      }
      if (valideur.profile) {
        if (typeof valideur.profile == "string") {
          valideur.profile = valideur.profile
        } else {
          valideur.profile = valideur.profile["@id"]
        }
      }
      if (valideur.editable) {
        valideur.editable = true
      } else {
        valideur.editable = false
      }
    })

    notification.map((notif) => {
      notif.profile = notif.profile["@id"]
      notif.type = "profile"
    })

    const rawContent = convertToRaw(notice.getCurrentContent())
    // const html = draftToHtml(rawContent)
    const jsonNotice = JSON.stringify(rawContent)

    const date = new Date()
    const formData = {
      questions: questions,
      steps: steps,
      // notice: `${html}`,
      notice: jsonNotice,
      title: title,
      abreviation: abreviation,
      category: category,
      weight: weight,
      maillingList: maillingList,
      previousForm: previousForm && previousForm.length > 0 ? previousForm : null,
      createdAt: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      notifications: notification,
      titleInputLabel: titleInputLabel,
      defaultUnitaryValidation: validationUnitaire,
      isBackupAllowed: isBackupAllowed
    }

    // en fonction du nouveau poids du formulaire, on va réattribuer les weights
    // des autres forms de la catégorie

    // cas d'une création de form
    if (!id) {
      allFormsOfTheCategoryAndTheirNewWeight.splice(weight - 1, 0, formData)
    } // cas d'une modification d'un formulaire
    else {
      // réorganisation du tableau contenant tous les forms. On replace tous les formulaires
      // au bon endroit
      var indexOfTheElementToMoveAtTheRightPlace
      const elementToMoveAtTheRightPlace = allFormsOfTheCategoryAndTheirNewWeight.find(
        (form, index) => {
          indexOfTheElementToMoveAtTheRightPlace = index
          return form.id === parseInt(id)
        }
      )
      moveElementInsideAnArray(
        allFormsOfTheCategoryAndTheirNewWeight,
        indexOfTheElementToMoveAtTheRightPlace,
        weight - 1
      )
    }
    // réattribution des weights
    allFormsOfTheCategoryAndTheirNewWeight.map((form, index) => {
      const formToModify = {}
      formToModify.weight = index + 1
      if (form.id && form.id) {
        api
          .put(GET_FORMS_ENDPOINT + "/" + form.id, formToModify)
          .then((data2) => {})
          .catch((e) => {
            alert(e.message)
          })
      }
    })

    if (id) {
      setNewFormId(id)
      api
        .put(`${BASE_URL}/api/forms/${id}`, formData)
        .then((data) => {})
        .catch((e) => {
          history.push("/")
          return alert("erreur : " + e.message)
        })
    } else {
      api
        .post(`${BASE_URL}/api/forms`, formData)
        .then((data) => {
          setNewFormId(data.id)
        })
        .catch((e) => {
          history.push("/")
          return alert(e.message)
        })
    }
  }
  if (params.id) {
    path = `/edition/${params.id}`
  } else {
    path = `/nouveau-formulaire`
  }

  const cancelButton = () => <CancelLinkFormCreation></CancelLinkFormCreation>
  const previousButton = () => (
    <NavigationButton isActive={true} to={`${path}/set_workflow`}>
      <Back />
      <Spacer />
      Précédent
    </NavigationButton>
  )
  const nextButton = () => (
    <NavigationButton isActive={true} to={`${path}/form_created`} onClick={postForm} isNext={true}>
      Enregistrer
      <Spacer />
      <Next />
    </NavigationButton>
  )

  if (!profile) {
    return null
  }
  return (
    isAdmin && (
      <div>
        <PageTop getBackComponent={cancelButton} title={"Nouveau formulaire"} />
        <ProgressionViewer
          next={nextButton}
          previous={previousButton}
          title="Création du formulaire"
          percent={95}
        />
        <CustomContainer className={className}>
          <RedText>
            Une fois la demande cloturée, une notification sera envoyée par mail au(x)
            collaborateur(s) ajouté(s) et lié(s) à la BU de la demande.
          </RedText>
        </CustomContainer>
        <Contributor className={className} />
        <PageBottom next={nextButton} />
      </div>
    )
  )
}

export default styled(Notifications)`
  margin: 30px 70px 0;
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
    text-align: center;
  }
`
