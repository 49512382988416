import React from "react"

import styled from "styled-components"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import ElementContainer from "components/form/element/ElementContainer"
import { Row, Col } from "@bootstrap-styled/v4"

import Colors from "theme/Colors"
import Delete from "components/icons/Delete"
import Show from "components/icons/Show"
import CustomTip from "components/common/ToolTip"

import FormTextField from "components/form/common/SingleLineTextField"

const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`
const ShowPicto = styled(Show)`
  font-size: 30px;
`

const InvestmentLine = ({
  className,
  editable,
  onChange,
  onDelete,
  value,
  position,
  categories,
  ...props
}) => {
  const handleChange = (event) => {
    let fieldValue = event.target.value || ""
    if (
      event.target.name == "quantity" ||
      event.target.name == "unity" ||
      event.target.name == "total"
    ) {
      fieldValue = fieldValue.replace(",", ".")
      fieldValue = Math.round(fieldValue)
    }

    const newValue = {
      ...value,
      [event.target.name]: fieldValue,
    }

    if (event.target.name == "quantity" || event.target.name == "unity") {
      if (newValue["quantity"] && newValue["unity"]) {
        newValue["total"] = newValue["quantity"] * newValue["unity"]
        newValue["total"] = newValue["total"].toString()
        newValue["quantity"] = newValue["quantity"].toString()
        newValue["unity"] = newValue["unity"].toString()
      }
    }
    onChange(newValue, position)
  }

  const handleCategorieChange = (event) => {
    const newValue = {
      ...value,
      category: event.target.value || "",
    }
    onChange(newValue, position)
  }

  const handleDelete = () => {
    onDelete(position)
  }

  return (
    <Row className="justify-content-center align-items-center " noGutters={true}>
      <Col>
        <ElementContainer noResponsive={true} width={13.74}>
          <FormControl fullWidth={true} margin="none">
            <FormTextField
              variant="outlined"
              name="provider"
              InputProps={{
                className,
                ...(editable ? {} : { readOnly: true }),
              }}
              value={value ? value.provider : ""}
              onChange={handleChange}
            />
          </FormControl>
        </ElementContainer>

        <ElementContainer noResponsive={true} width={7.2}>
          <FormControl fullWidth={true} margin="none">
            <FormTextField
              variant="outlined"
              name="quantity"
              InputProps={{
                className,
                ...(editable ? {} : { readOnly: true }),
              }}
              value={value ? value.quantity : ""}
              onChange={handleChange}
              data-tip
              data-for="integer"
            />
          </FormControl>
        </ElementContainer>

        <ElementContainer noResponsive={true} width={7.2}>
          <FormControl fullWidth={true} margin="none">
            <FormTextField
              variant="outlined"
              name="unity"
              InputProps={{
                className,
                ...(editable ? {} : { reDeleteButtonOnly: true }),
              }}
              value={value ? value.unity : ""}
              onChange={handleChange}
              data-tip
              data-for="integer"
            />
          </FormControl>
        </ElementContainer>

        <ElementContainer noResponsive={true} width={20.8}>
          <FormControl fullWidth={true} margin="none">
            <FormTextField
              variant="outlined"
              name="description"
              InputProps={{
                className,
                ...(editable ? {} : { readOnly: true }),
              }}
              value={value ? value.description : ""}
              onChange={handleChange}
            />
          </FormControl>
        </ElementContainer>

        <ElementContainer noResponsive={true} width={7.2}>
          <FormControl fullWidth={true} margin="none">
            <FormTextField
              variant="outlined"
              name="total"
              InputProps={{
                className,
                ...(editable ? {} : { readOnly: true }),
              }}
              value={value ? value.total : ""}
              onChange={handleChange}
            />
          </FormControl>
        </ElementContainer>

        <ElementContainer noResponsive={true} width={14.29}>
          <FormControl fullWidth={true} margin="none">
            <FormTextField
              variant="outlined"
              name="inputAmort"
              InputProps={{
                className,
                ...(editable ? {} : { readOnly: true }),
              }}
              value={value ? value.inputAmort : ""}
              onChange={handleChange}
            />
          </FormControl>
        </ElementContainer>

        <ElementContainer noResponsive={true} width={10.2}>
          <FormControl fullWidth={true} margin="none">
            <FormTextField
              variant="outlined"
              name="duration"
              InputProps={{
                className,
                ...(editable ? {} : { readOnly: true }),
              }}
              value={value ? value.duration : ""}
              onChange={handleChange}
            />
          </FormControl>
        </ElementContainer>

        <ElementContainer noResponsive={true} width={14.29}>
          <FormControl fullWidth={true} margin="none">
            <Select
              variant="outlined"
              margin="dense"
              value={value ? value.category : ""}
              onChange={handleCategorieChange}
              inputProps={{
                name: `category`,
                ...(editable ? {} : { readOnly: true }),
              }}
            >
              <MenuItem value={null}>Choisissez une categorie</MenuItem>
              {categories.map((categorie) => (
                <MenuItem value={categorie["@id"]} key={categorie.id}>
                  {categorie.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ElementContainer>
        <ElementContainer noResponsive={true} width={4}>
          <FormControl fullWidth={true} margin="none">
            {editable && (
              <div>
                <DeleteButton onClick={handleDelete} data-tip data-for="deleteLine" />
                <CustomTip id="deleteLine" place="bottom" effect="solid">
                  <span>supprimer</span>
                </CustomTip>
              </div>
            )}
          </FormControl>
        </ElementContainer>
      </Col>
      <CustomTip id="integer" place="bottom" effect="solid">
        <span>Veuillez entrer un nombre entier</span>
      </CustomTip>
    </Row>
  )
}

export default styled(InvestmentLine)`
  background-color: transparent;
  min-height: 32px;
  line-height: 0px;
  color: ${Colors.black};
  font-size: 13px;
  & input {
    height: auto;
    min-height: 40px;
    font-size: 13px;
    line-height: 40px;
    padding: 0 14px;
  }
`
