import React from "react"

import FormControl from "@material-ui/core/FormControl"
import { TextField } from "@material-ui/core"

const ChangeLabel = ({ onLabelChange, label, ...props }) => {
  return (
    <>
      <FormControl fullWidth={true} margin="none">
        <TextField
          margin="none"
          label={props.inputLabel || "Libellé du champ"}
          value={label}
          onChange={onLabelChange}
          hiddenLabel={true}
          InputProps={{ style: { minHeight: "32px", fontSize: "13px", lineHeight: "0px" } }}
        />
      </FormControl>
    </>
  )
}

export default ChangeLabel
