import React from "react"

const SvgDocument = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 16 21" {...props}>
    <path
      d="M11.694 1H2.161C1.521 1 1 1.523 1 2.165v16.67C1 19.478 1.521 20 2.161 20H13.84c.64 0 1.161-.522 1.161-1.165V4.603L11.694 1zm.275 1.744l1.448 1.58H11.97v-1.58zm2.056 16.09a.187.187 0 01-.187.188H2.161a.187.187 0 01-.186-.187V2.165c0-.103.084-.186.186-.186h8.832v2.834c0 .27.219.488.488.488h2.544v13.534zM3.696 6.205h8.283a.366.366 0 010 .733H3.696a.366.366 0 010-.733zm8.648 2.83a.366.366 0 01-.365.366H3.696a.366.366 0 010-.733h8.283c.202 0 .365.165.365.367zm0 2.383a.366.366 0 01-.365.366H3.696a.366.366 0 010-.733h8.283c.202 0 .365.164.365.367zm0 2.423a.366.366 0 01-.365.366H3.696a.366.366 0 010-.733h8.283c.202 0 .365.164.365.367z"
      fill="#979797"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgDocument
