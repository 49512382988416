import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"

import Image from "components/common/Image"
import Link from "components/common/Link"
import Initials from "components/user/Initials"

import UserContext from "contexts/UserContext"
import { BASE_URL, GET_CONFIG_BY_NAME } from "constants/endpoints"

import Colors from "theme/Colors"
import Images from "theme/Images"

import api from "services/api"
import { Help } from "components/icons"

const HelpLink = styled.a`
  color: ${Colors.red};
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
`

const HelpIcon = styled(Help)`
  font-size: 50px;
  margin: 0 30px 0 20px;
`

const Spacer = styled.div`
  width: 2px;
  border-left: solid 2px ${Colors.border};
  margin-right: 20px;
  margin-left: -28px;
  height: 52px;
`

const PictoImage = styled(Image)`
  max-width: 180px;
  margin: 0 20px;
`

const UserCompagnie = styled(PictoImage)`
  margin-right: 70px;
`
const ProfileDataContainer = styled.div`
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  display: flex;
`
const Firstname = styled.span`
  color: ${Colors.black};
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;

  @media screen and (max-width: 366px) {
    font-size: 14px;
  }
`

const Lastname = styled(Firstname)`
  font-weight: 600;
`

const DisplayName = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Header = ({ className, ...props }) => {
  const { profile } = useContext(UserContext)
  const [isTestServer, setIsTestServer] = useState(false)
  const [helpLink, setHelpLink] = useState(null)
  useEffect(() => {
    api
      .get(`${BASE_URL}/api/env`)
      .then((data) => {
        if (data && data.env === "dev_server_el") {
          setIsTestServer(true)
        }
      })
      .catch((e) => alert(e.message))
  }, [])
  useEffect(() => {
    api
      .get(GET_CONFIG_BY_NAME("help_doc"))
      .then((data) => {
        if (!data) {
          return
        }
        const configValue = data["hydra:member"][0]
        if (!configValue || !configValue.value || !configValue.value.mediaObject) {
          return
        }

        return api.get(`${BASE_URL}${configValue.value.mediaObject}`)
      })
      .then((data) => {
        if (!data) {
          return
        }
        setHelpLink(`${BASE_URL}${data.contentUrl}`)
      })
      .catch((e) => {
        alert(e.message)
      })
  }, [])

  return (
    <Row className={`${className} align-items-center`} noGutters={true}>
      <Col>
        <PictoImage src={Images.logo_groupe} />
      </Col>
      {isTestServer && (
        <Col>
          <h3>Application de TEST</h3>
        </Col>
      )}
      {profile && (
        <Col>
          <ProfileDataContainer>
            {profile.bu && profile.bu.logo && (
              <UserCompagnie
                className="d-none d-sm-block"
                src={`${BASE_URL}${profile.bu.logo.contentUrl}`}
              />
            )}
            <Link to="/profile">
              <DisplayName>
                <Firstname>{profile.firstname.toLowerCase()}</Firstname>
                <Lastname>{profile.lastname.toUpperCase()}</Lastname>
              </DisplayName>
              <Initials firstname={profile.firstname} lastname={profile.lastname} />
            </Link>
            {helpLink && (
              <>
                <Spacer />
                <HelpLink href={helpLink} target="_blank" rel="noreferrer">
                  Besoin
                  <br />
                  d&apos;aide ?
                </HelpLink>
                <a href={helpLink} target="_blank" rel="noreferrer">
                  <HelpIcon />
                </a>
              </>
            )}
          </ProfileDataContainer>
        </Col>
      )}
    </Row>
  )
}

export default styled(Header)`
  min-height: 80px;
  box-shadow: 2px 2px 5px 0 ${Colors.greyBorder};
  background-color: ${Colors.white};
`
