import React, { useState } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import SquareButton from "components/common/SquareButton"
import Colors from "theme/Colors"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"

import { Row, Col } from "@bootstrap-styled/v4"
import Plus from "components/icons/Plus"
import ProfileFields from "components/adminCreateForm/settingWorkFlow/variableFields/ProfileFields"
import PosteFields from "components/adminCreateForm/settingWorkFlow/variableFields/PosteFields"
import LineManagerFields from "components/adminCreateForm/settingWorkFlow/variableFields/LineManagerFields"
import AutoComplete from "components/form/AutoComplete"

const CustomRow = styled(Row)`
  margin-bottom: 5px;
`

const CustomSelect = styled(Select)`
  @media screen and (max-width: 1200px) {
    margin: 0 0 10px 0px !important;
  }
`

const CustomFormControl = styled(FormControl)`
  min-width: 200px !important;
  width: 100%;
`

const ButtonContainer = styled.div`
  padding: 15px 15px;
  margin: 10px 15px 0 20px;
  @media screen and (max-width: 1200px) {
    margin: 10px 15px 0 0;
  }
`

const PlusIcon = styled(Plus)`
  font-size: 20px;
  @media screen and (max-width: 1200px) {
    font-size: 15px;
    padding: 2px;
  }
`

const CustomInputLabel = styled(InputLabel)`
  background-color: ${Colors.white};
`

const AddDocsButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`

const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 56px;

  @media screen and (max-width: 1200px) {
    text-align: center;
    padding: 10px;
  }
`

const AddcontributorButton = styled(SquareButton)`
  min-height: 56px;
  max-height: 56px;
  max-width: 200px;
  min-width: 140px;
  margin-top: 5px;

  @media screen and (max-width: 1200px) {
    margin: 10px 0 0 0 !important;
  }
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const Input = styled.input`
 
  height: 56px;
  padding: 10px 20px;
  font-family: Helvetica, sans - serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 100%;
   @media screen and (max-width: 1200px) {
   
    margin: 0 0 10px 0px !important;
  }
}
`
const CustomAutoComplete = styled(AutoComplete)`
  width: 100%;
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0px !important;
  }
`

const adaptedFields = {
  responsable_hierarchique: LineManagerFields,
  profile: ProfileFields,
  poste: PosteFields,
}

const AddNotifiedContributor = ({ className, addContributor, bu, ...props }) => {
  const [postesList, setPostesList] = useState([])
  const [adaptedFormFilled, setAdaptedFormFilled] = useState(false)
  const [selectedBu, setSelectedBu] = useState(null)
  const [contributor, setContributor] = useState({})

  const handleLabelChange = (event) => {
    contributor.label = event.target.value || ""
    setContributor({ ...contributor })
  }

  const handleCreatecontributor = () => {
    addContributor(contributor)
    setContributor(null)
  }

  const handleBuChange = (event) => {
    setSelectedBu(event.target.value || "")
    contributor.businessUnit = event.target.value || ""
  }

  const handleProfileChange = (profile) => {
    contributor.profile = {
      "@id": profile["@id"],
      firstname: profile.firstname,
      lastname: profile.lastname,
    }
    setContributor({ ...contributor })
    //setProfile(validator.profile)
  }

  const buListItems = []
  bu.map((element) => {
    buListItems.push(
      <MenuItem value={element["@id"]} key={element.id}>
        {element.label}
      </MenuItem>
    )
  })

  if (contributor) {
    return (
      <Container className={className}>
        <CustomRow className="justify-content-start align-items-center">
          <Col xs="12" xl="4">
            <CustomFormControl variant="outlined">
              <CustomInputLabel htmlFor={`bu`}>Business Unit</CustomInputLabel>
              <CustomSelect
                value={selectedBu}
                onChange={handleBuChange}
                inputProps={{
                  name: `bu`,
                  className,
                }}
              >
                {buListItems}
              </CustomSelect>
            </CustomFormControl>
          </Col>
          <Col xs="12" xl="3">
            <Input
              className=""
              type="text"
              onChange={handleLabelChange}
              value={contributor.label}
              placeholder="Label"
            />
          </Col>
          <Col xs="12" xl="3">
            <CustomAutoComplete onProfileChange={handleProfileChange} />
          </Col>
          {selectedBu && contributor.label && contributor.profile && (
            <Col xs="12" xl="auto">
              <AddcontributorButton noGutters={true} onClick={handleCreatecontributor}>
                Ajouter
              </AddcontributorButton>
            </Col>
          )}
        </CustomRow>
      </Container>
    )
  } else {
    return (
      <ButtonContainer>
        <AddDocsButtonContainer>
          <AddDocsButton
            onClick={() => {
              setContributor({})
            }}
          >
            <PlusIcon />
            <Spacer />
            Ajouter un intervenant
          </AddDocsButton>
        </AddDocsButtonContainer>
      </ButtonContainer>
    )
  }
}

export default styled(AddNotifiedContributor)`
  padding: 20px;
  margin: 20px;
  border: 1px solid #2656c8;
`
