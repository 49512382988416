import React from "react"
import styled from "styled-components"
import Colors from "theme/Colors"


const Status = ({ children, className,backupPlan, ...props }) => (
  <div className={className} status={backupPlan.status} {...props}>
    <span>
      {(backupPlan.status == 'scheduled' &&  backupPlan.isEnabled ) && "En cours"}
      {(backupPlan.status == 'active' &&  backupPlan.isEnabled ) &&  "Actif"}
      {(backupPlan.status == 'disabled' || backupPlan.status == 'inactive' || !backupPlan.isEnabled) && "Inactif"}
    </span>
  </div>
)

export default styled(Status)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  background-color: ${Colors.background};
  width: 150px;
  border-radius: 15px;
  border: 2px solid
    ${(props) => {

        return props.backupPlan.status == 'scheduled' && props.backupPlan.isEnabled   ? Colors.doing  : ( props.backupPlan.status == 'active' && props.backupPlan.isEnabled ? Colors.green  : Colors.red  );
      
    }};
  color:   ${(props) => {
    return props.backupPlan.status == 'scheduled' && props.backupPlan.isEnabled ? Colors.doing  : ( props.backupPlan.status == 'active' && props.backupPlan.isEnabled ? Colors.green  : Colors.red  );
  }};
`
