import React, { useContext, useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useHistory, useParams } from "react-router-dom"
import { Row, Col } from "@bootstrap-styled/v4"

import UserContext from "contexts/UserContext"

import {
  GET_DEMANDE_ENDPOINT,
  POST_LINKED_DEMANDE_ENDPOINT,
  GET_FORM_ACCESSES_OF_A_PARTICULAR_FORM,
  GET_FORM_ENDPOINT,
} from "constants/endpoints"

import PageTop from "components/layout/PageTop"

import LoaderOverlay from "components/common/LoaderOverlay"
import Button from "components/common/Button"
import Form from "components/editDemande/Form"
import ValidationFlow from "components/editDemande/ValidationFlow"
import Comments from "components/editDemande/Comments"
import Actions from "components/editDemande/Actions"
import LinkedDemande from "components/form/LinkedDemande"
import { Next } from "components/icons"
import Colors from "theme/Colors"

import api from "services/api"

import Link from "components/common/Link"
import { REFUSED, WAITTING } from "constants/demande"
import SelectValidatorModal from "components/editDemande/SelectValidatorModal"

const ValidateButtonContainer = styled.div`
  margin: 0 0 0 30px;
`

const ValidateButton = styled(Button)`
  min-width: 200px;
`

const NextButton = styled(ValidateButton)`
  padding: 0 0 0 20px;
`

const NextPicto = styled(Next)`
  font-size: 18px;
`

const ButtonsContainer = styled(Row)`
  margin: 0 70px 30px !important;
`

const Spacer = styled.span`
  width: 10px;
  height: 1px;
`

const ViewDemande = ({ className, ...props }) => {
  let { id } = useParams()
  const { profile } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [demande, setDemande] = useState(null)
  const [form, setForm] = useState(null)
  const [values, setValues] = useState(null)
  const [valideurs, setValideurs] = useState(null)
  const [comments, setComments] = useState([])
  const [isNewComment, setIsNewComment] = useState(false)
  const [displayValidatorList, setDisplayValidatorList] = useState(false)
  const [associateDemandeLoading, setAssociateDemandeLoading] = useState(false)
  const [previousFormAccessList, setPreviousFormAccessList] = useState([])
  const [previousFormValideurs, setPreviousFormValideurs] = useState([])
  const [previousFormResponses, setPreviousFormResponses] = useState(null)
  const [isDraftAndValidatorIsAuthor, setIsDraftAndValidatorIsAuthor] = useState(false)
  const [validationUnitaire, setValidationUnitaire] = useState(false)
  const commentToAdd = useRef({})

  let history = useHistory()

  const currentPathname = window.location.pathname

  useEffect(() => {
    if (!profile) {
      return
    }
    api
      .get(GET_DEMANDE_ENDPOINT(id))
      .then((data) => {
        setValideurs(data.valideurs)
        setForm(data.form)
        setValues(data.responses)
        setComments(data.comments ? data.comments : [])
        data.businessUnit = data.businessUnit ? data.businessUnit["@id"] : ""
        setDemande(data)
        setValidationUnitaire(data.unitaryValidation)
        if (
          data &&
          data.author &&
          data.currentValidator &&
          data.author["@id"] === data.currentValidator["@id"] &&
          data.status === "draft"
        ) {
          setIsDraftAndValidatorIsAuthor(true)
        }
        return null
      })
      .catch((e) => {
        alert("erreur : " + e.message)
      })
  }, [id, profile])

  useEffect(() => {
    if (readOnly || !demande || !demande.previousDemande) {
      return
    }
    setPreviousFormResponses(demande.previousDemande.responses)
  }, [readOnly, demande])

  if (!demande) {
    return null
  }

  const handleUpdateDemande = (validated) => () => {
    if (loading) {
      return
    }
    setLoading(true)

    const valideursToSubmit = valideurs
      .filter((item) => !!item)
      .map((valideur) => {
        return {
          ...valideur,
          profile: valideur.profile["@id"] || valideur.profile,
          step: valideur.step ? valideur.step["@id"] : null,
        }
      })
    const data = {
      title: demande.title,
      businessUnit: demande.businessUnit,
      maillingList: "",
      responses: values.filter((item) => !!item),
      valideurs: valideursToSubmit,
      validated: validated,
      unitaryValidation: validationUnitaire,
    }

    if (Object.keys(commentToAdd.current).length > 0) {
      data.newComment = commentToAdd.current
    }

    const pathPrefix = currentPathname.split("/")[1]

    api
      .put(GET_DEMANDE_ENDPOINT(demande.id), data)
      .then((data) => {
        if (form.previousForm) {
          // si nouveaux valideurs, leur donner la visibilité sur le formulaire précédent.
          const previousFormId = form.previousForm.id
        }
        if (data.status == "draft") {
          history.push(`/nouvelle-demande/validation/${data.id}`)

          //chemins qui vont pas
        } else if (validated) {
          history.push(`/${pathPrefix}/validee/${data.id}`)
        } else if (data.status == WAITTING) {
          history.push(`/${pathPrefix}/update_status/${data.id}`)
        } else if (data.status == "refused") {
          history.push(`/${pathPrefix}/update_status/${data.id}`)
        } else {
          history.push(`/${pathPrefix}/enregistree/${data.id}`)
        }
        setLoading(false)
      })
      .catch((e) => {
        if (e.code === 400) {
          alert(e.message)
        } else {
          alert("Une erreur est survenue lors de la création de votre demande.")
        }
        setLoading(false)
      })
  }

  if (loading) {
    return <LoaderOverlay />
  }

  if (!demande || !profile) {
    return null
  }

  const handleChange = (values) => {
    demande.responses = values
    setDemande(demande)
  }

  const handleTitleChange = (title) => {
    demande.title = title
    setDemande(demande)
  }

  const handleBuChange = (bu) => {
    demande.businessUnit = bu
    setDemande(demande)
  }

  const handleValideursChange = (data) => {
    setValideurs(data)
  }

  const readOnlyIfWaitingStatusCondition =
    demande.status !== WAITTING ||
    (demande.status === WAITTING && profile["@id"] !== demande.currentValidator["@id"])

  const readOnly =
    demande.status !== "to_validate" &&
    readOnlyIfWaitingStatusCondition &&
    demande.status !== "draft"

  const handleAddComment = (newComment) => {
    commentToAdd.current = newComment
    setIsNewComment(true)
  }

  const handleStatusWaiting = (newValideur) => {
    if (!displayValidatorList) {
      setDisplayValidatorList(true)
      return
    }
    commentToAdd.current.status = WAITTING
    commentToAdd.current.valideurId = newValideur
    handleUpdateDemande(false)()
  }

  const handleStatusRefused = () => {
    commentToAdd.current.status = REFUSED
    handleUpdateDemande(false)()
  }

  const cancelButton = () => <Link to="/">Retour</Link>

  const isDraft = demande.status === "draft" ? true : false
  const setTitle = () => {
    if (demande.status === "doing") {
      return "Demande en cours"
    } else if (demande.status === "to_validate") {
      return "Demande à valider"
    } else if (demande.status === "draft") {
      return "Demande en brouillon"
    } else if (demande.status === "refused") {
      return "Demande refusée"
    } else if (demande.status === WAITTING) {
      return "Demande en attente"
    } else if (demande.status === "closed") {
      return "Demande cloturée"
    }
  }

  const createLinkedDemande = () => {
    setAssociateDemandeLoading(true)
    api
      .post(POST_LINKED_DEMANDE_ENDPOINT, { previousDemande: `/api/demandes/${demande.id}` })
      .then((data) => {
        //on redirige vers la page de la nouvelle demande
        history.push(`/demandes/${data.id}`)
        return null
      })
      .catch((e) => {
        alert("erreur : " + e.message)
        setAssociateDemandeLoading(false)
      })
  }

  const canCreateLinkedDemande = demande.status == "closed" && !demande.nextDemande && form.nextForm
  return (
    <div className={className}>
      <PageTop getBackComponent={cancelButton} title={setTitle()} />
      {demande.previousDemande && (
        <LinkedDemande
          demande={demande.previousDemande}
          color={Colors.pink}
          title="DEMANDE INITIALE"
        />
      )}
      {demande.nextDemande && (
        <LinkedDemande
          demande={demande.nextDemande}
          color={Colors.green}
          title="DEMANDE SUIVANTE"
        />
      )}
      <Form
        hideInputs={readOnly}
        form={form}
        values={values}
        profile={profile}
        onChange={handleChange}
        title={demande.title}
        bu={demande.businessUnit}
        demandeNumber={demande.number}
        status={demande.status}
        onTitleChange={handleTitleChange}
        onBuChange={handleBuChange}
        readOnly={readOnly}
        previousFormResponses={previousFormResponses}
        isDraftAndValidatorIsAuthor={isDraftAndValidatorIsAuthor}
        inputTitleLabel={form && form.titleInputLabel}
      />
      <ValidationFlow
        valideurs={valideurs}
        setValideurs={handleValideursChange}
        profile={profile}
        readOnly={readOnly}
        isDraft={isDraft}
        demande={demande}
        hasPreviousForm={form.previousForm != null}
        validationUnitaire={validationUnitaire}
        setValidationUnitaire={setValidationUnitaire}
      />
      {/* Ajout des block commentaire et historique */}
      <Comments comments={comments} addComment={handleAddComment} readOnly={readOnly} />
      <Actions actions={demande.actions} />
      {!readOnly && (
        <ButtonsContainer className="justify-content-end">
          <Col xs="auto">
            <ValidateButtonContainer>
              <ValidateButton onClick={handleUpdateDemande(false)}>Enregistrer</ValidateButton>
            </ValidateButtonContainer>
          </Col>

          {!isDraft && (
            <>
              <Col xs="auto">
                <ValidateButtonContainer>
                  <ValidateButton onClick={handleUpdateDemande(true)}>Valider</ValidateButton>
                </ValidateButtonContainer>
              </Col>

              {isNewComment && (
                <>
                  <Col xs="auto">
                    <ValidateButtonContainer>
                      <ValidateButton onClick={handleStatusWaiting}>
                        Retour à un valideur
                      </ValidateButton>
                    </ValidateButtonContainer>
                  </Col>
                  <Col xs="auto">
                    <ValidateButtonContainer>
                      <ValidateButton onClick={handleStatusRefused}>Refuser</ValidateButton>
                    </ValidateButtonContainer>
                  </Col>
                </>
              )}
            </>
          )}
        </ButtonsContainer>
      )}
      {canCreateLinkedDemande && (
        <ButtonsContainer className="justify-content-end">
          <Col xs="auto">
            <ValidateButtonContainer>
              <NextButton
                onClick={createLinkedDemande}
                noGutters={true}
                loading={associateDemandeLoading}
              >
                Lancer le formulaire suivant
                <Spacer />
                <NextPicto />
              </NextButton>
            </ValidateButtonContainer>
          </Col>
        </ButtonsContainer>
      )}
      {displayValidatorList && (
        <SelectValidatorModal
          isOpen={displayValidatorList}
          setOpen={setDisplayValidatorList}
          valideurs={valideurs}
          onValidate={handleStatusWaiting}
        />
      )}
    </div>
  )
}

export default styled(ViewDemande)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
