import React, { useState } from "react"

import styled from "styled-components"
import Colors from "theme/Colors"
import Container from "components/common/Container"
import { Row, Col } from "@bootstrap-styled/v4"
import AutoComplete from "components/form/AutoComplete"
import FormControl from "@material-ui/core/FormControl"
import PenPicto from "components/icons/Pen"
import Text from "components/common/Text"
import PlusPicto from "components/icons/Plus"
import DeletePicto from "components/icons/Delete"
import Link from "components/common/Link"




const CustomCol = styled(Col)`
margin:0px;
padding:0px;

`

const CustomLink = styled(Link)`

  
`

const TextForm = styled(Text)`
text-align:left;
margin-left:20px;
`

const TextFormAll = styled(Text)`
text-align:left;
margin-left:50px;
font-size:17px;
font-weight:bold;
color : ${Colors.primaryVeryDark};
`

const TextProfile = styled(Text)`
max-width:500px;

color : ${Colors.primaryVeryDark};
`

const TextProfileNew = styled(Text)`
text-align:left;
max-width:250px;
text-decoration: underline;
cursor: pointer;
color : ${Colors.primaryDark};
`



const CustomDeletePicto = styled(DeletePicto)`

margin-left:20px;

`

const CutomPenPicto = styled(PenPicto)`

margin-left:20px;

`

const CustomAutoComplete = styled(AutoComplete)`
  margin-top:20px;
  max-width:250px;
  @media screen and (max-width: 1200px) {
    margin: 20px 0 10px 0px !important;
  }
`




const Backup = ({
  className,
  form,
  id,
  profile,
  handleBackupChange,
  handleDeleteBackup,
  ...props
}) => {

  const [editMode, setEditMode] = useState(null)

  const handleProfileChange = (profile) => {
    if(profile) {
       handleBackupChange(profile , form);
       setEditMode(false)
    }
  }

  const handleDelete = () => {
      handleDeleteBackup(id);
    
  }


  const handleAddBackup = (profile) => {
    setEditMode(true)
}


  return (
    <Row>
    <CustomCol xs="6">
    {form && (<TextForm>{form.name}</TextForm>)}
    {!form && (<TextFormAll>Tous les formulaires</TextFormAll> )}
    </CustomCol>
    <CustomCol xs="3">
      {!editMode && (
        profile ? (
          <TextProfile>{profile.firstname} {profile.lastname}<CutomPenPicto onClick={handleAddBackup}/>{form && <CustomDeletePicto onClick={handleDelete}/> }</TextProfile> )
        
        :   
        <TextProfileNew onClick={handleAddBackup}> Ajouter un remplacant </TextProfileNew>
      
      )
    }
      {editMode &&
      <CustomAutoComplete onProfileChange={handleProfileChange} />
      }
    </CustomCol>
    <CustomCol xs="3"></CustomCol>
    </Row>
  )
}

export default Backup
