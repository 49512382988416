import React, { Component, useContext, useState } from "react"
import styled from "styled-components"
import UserContext from "contexts/UserContext"
import AuthenticationContext from "contexts/AuthenticationContext"

import PageTop from "components/layout/PageTop"
import PageBottom from "components/layout/PageBottom"
import Link from "components/common/Link"
import ProgressionViewer from "components/newDemande/ProgressionViewer"
import NavigationButton from "components/common/NavigationButton"
import CustomRow from "components/newDemande/ProgressionViewer.js"
import { Row, Col } from "@bootstrap-styled/v4"
import { Next, Back } from "components/icons"
import CancelLinkFormCreation from "components/common/CancelLinkFormCreation"
import FormInformations from "components/adminCreateForm/FormInformations"
import FormGuide from "components/adminCreateForm/FormGuide"
import OptionalBlocks from "components/adminCreateForm/OptionalBlocks.js"

import { useRouteMatch, useParams } from "react-router-dom"

//import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const Spacer = styled.span`
  width: 20px;
  height: 1 px;
`

//TODO: recupérer les infos de FormInformations qui vont être en fait l'élément père dans la BDD
// puis les éléments de OptionalBlocks qui vont être imbriquées dedans quoi
const CreateForm = ({ className, ...props }) => {
  const { profile } = useContext(UserContext)
  const backBuntton = () => <Link to="/"> Retour</Link>
  const { token } = useContext(AuthenticationContext)
  const content = token.split(".")
  const infos = JSON.parse(atob(content[1]))
  const isAdmin = infos.roles[0] === "ROLE_ADMIN"
  let { path } = useRouteMatch()
  const params = useParams()
  if (params.id) {
    path = `/edition/${params.id}`
  }

  const cancelButton = () => <CancelLinkFormCreation></CancelLinkFormCreation>
  const previousButton = () => (
    <NavigationButton>
      <Back />
      <Spacer />
      Précédent
    </NavigationButton>
  )
  const nextButton = () => (
    <NavigationButton isActive={true} to={`${path}/set_workflow`} isNext={true}>
      {" "}
      Suivant
      <Spacer />
      <Next />
    </NavigationButton>
  )

  if (!profile) {
    return null
  }
  return (
    isAdmin && (
      <div className={className}>
        <PageTop getBackComponent={cancelButton} title={"Nouveau formulaire"} />
        <ProgressionViewer
          next={nextButton}
          previous={previousButton}
          title="Création du formulaire"
          percent={5}
        />
        <FormInformations />
        <FormGuide />
        <OptionalBlocks />
        <PageBottom next={nextButton} />
      </div>
    )
  )
}

export default styled(CreateForm)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
