import React from "react"

const SvgProfils = ({ primary, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 63 54" {...props}>
    <path
      d="M38.018 9.268a11.685 11.685 0 015.327 8.663 9.051 9.051 0 003.905.89c5.103 0 9.24-4.213 9.24-9.41C56.49 4.213 52.352 0 47.25 0c-5.055.002-9.155 4.14-9.232 9.268zm-6.053 19.27c5.103 0 9.24-4.215 9.24-9.412 0-5.197-4.138-9.41-9.24-9.41-5.103 0-9.242 4.214-9.242 9.411 0 5.197 4.14 9.41 9.242 9.41zm3.919.64h-7.84c-6.524 0-11.83 5.407-11.83 12.05v9.767l.024.153.66.21C23.124 53.34 28.532 54 32.984 54c8.694 0 13.734-2.525 14.045-2.686l.617-.318h.066V41.23c.002-6.644-5.305-12.05-11.828-12.05zm15.286-9.715h-7.78a11.696 11.696 0 01-3.514 8.079c5.799 1.756 10.041 7.233 10.041 13.701v3.01c7.682-.287 12.108-2.504 12.4-2.653l.617-.319H63v-9.769c0-6.643-5.307-12.049-11.83-12.049zm-35.418-.64c1.807 0 3.49-.538 4.914-1.453a11.69 11.69 0 014.298-7.428c.01-.176.026-.35.026-.529 0-5.198-4.137-9.41-9.238-9.41-5.104 0-9.24 4.212-9.24 9.41 0 5.196 4.136 9.41 9.24 9.41zm8.298 8.719a11.703 11.703 0 01-3.512-8.034c-.289-.021-.574-.045-.868-.045h-7.84C5.307 19.463 0 24.87 0 31.513v9.766l.024.15.66.213c4.995 1.588 9.452 2.32 13.323 2.55v-2.949C14.01 34.775 18.25 29.3 24.05 27.542z"
      fill={primary || "#2656C8"}
      fillRule="nonzero"
    />
  </svg>
)

export default SvgProfils
