import React, { useState } from "react"

import styled from "styled-components"
import Colors from "theme/Colors"
import Container from "components/common/Container"
import { Row, Col } from "@bootstrap-styled/v4"

import Status from "components/backupList/Status"
import LoaderOverlay from "components/common/LoaderOverlay"
import CustomTip from "components/common/ToolTip"
import BackupsList from "./BackupsList"
import Schedule from "./Schedule"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Backup from "./Backup"
import BackupMode from "./BackupMode"
import SquareButton from "components/common/SquareButton"

import Link from "components/common/Link"
import DisabledLink from "components/common/DisabledLink"
const moment = require("moment")


const CustomContainer = styled(Container)`
padding: 10px 0 10px 0;
margin: 0px 70px 30px !important;

@media screen and (max-width: 1200px) {
  margin: 0;
  max-width: 100%;
  margin: 0;
  text-align: center;
  overflow: scroll;
}

`

const ContainerTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 600;
  margin-left:40px;
`



const EditBackupPlan = ({
  className,
  backupPlan,
  loading,
  forms,
  handleBackupChange,
  handleGlobalBackupChange,
  handleStartedAtChange,
  handleEndedAtChange,
  handleIsFormRestrictionChange,
  handleDeleteBackup,
  handleIsEnabledChange
}) => {
  //useEffect(() => CustomTip.rebuild(), [backups])
  const currentPathname = window.location.pathname



  if (loading) {
    return <LoaderOverlay />
  }

  return (
    <div>
      <CustomContainer>
          <ContainerTitle>Période de remplacement </ContainerTitle>
            <Schedule backupPlan={backupPlan} handleStartedAtChange={handleStartedAtChange} handleEndedAtChange={handleEndedAtChange} handleIsEnabledChange={handleIsEnabledChange}> </Schedule>
         
      </CustomContainer>

      {backupPlan.isEnabled && (
      <CustomContainer>
      <ContainerTitle>Paramétrer des remplaçants </ContainerTitle>
      <BackupMode backupPlan={backupPlan} handleIsFormRestrictionChange={handleIsFormRestrictionChange} ></BackupMode>
   

      {!backupPlan.isFormRestriction && 
       <Backup handleBackupChange={handleGlobalBackupChange} profile= {backupPlan.backup}   />
      }
      

      {backupPlan.isFormRestriction && 
        <BackupsList forms={forms} handleBackupChange={handleBackupChange} handleDeleteBackup={handleDeleteBackup} backupPlan={backupPlan} />
      }
      </CustomContainer>
      )}
    </div>
  )
}

export default EditBackupPlan
