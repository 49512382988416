import styled from "styled-components"
import Colors from "theme/Colors"
import { Container } from "@bootstrap-styled/v4"

const Background = styled(Container)`
  background-color: ${Colors.background};
  min-height: 100vh;
  max-width: 100%;
  width: auto;
`
export default Background
