import React from "react"

const SvgDelete = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 24" {...props}>
    <g fill="#FF0E00" fillRule="evenodd">
      <path d="M17.427 6.743c0-.21.169-.38.377-.38a.38.38 0 01.378.38v14.554a2.34 2.34 0 01-.685 1.652 2.318 2.318 0 01-1.644.687H4.147c-.64 0-1.223-.264-1.644-.687a2.338 2.338 0 01-.685-1.652V6.743c0-.21.17-.38.378-.38a.38.38 0 01.377.38v14.554c0 .433.178.829.463 1.115.285.286.679.466 1.11.466h11.706c.432 0 .825-.18 1.11-.466a1.58 1.58 0 00.463-1.114V6.743h.002z" />
      <path d="M7.273 20.546c0 .2-.204.363-.455.363-.25 0-.454-.163-.454-.363V7.636c0-.2.203-.363.454-.363s.455.163.455.363v12.91zM10 20.546c0 .2-.204.363-.455.363-.25 0-.454-.163-.454-.363V7.636c0-.2.204-.363.454-.363.251 0 .455.163.455.363v12.91zM13.636 20.546c0 .2-.203.363-.454.363s-.455-.163-.455-.363V7.636c0-.2.204-.363.455-.363.25 0 .454.163.454.363v12.91zM7.088 2.347c0 .21-.162.38-.362.38-.2 0-.362-.17-.362-.38A2.4 2.4 0 017.019.689 2.174 2.174 0 018.597 0h2.806c.614 0 1.173.265 1.577.69.404.425.656 1.013.656 1.657 0 .21-.162.38-.362.38-.2 0-.362-.17-.362-.38 0-.435-.17-.832-.443-1.119a1.47 1.47 0 00-1.065-.467H8.598c-.415 0-.792.18-1.066.466a1.622 1.622 0 00-.444 1.12z" />
      <path
        d="M1.335 3.636h17.33c.365 0 .7.153.941.4l.002.002c.242.248.392.589.392.962v.983c0 .21-.167.38-.373.38H.373A.377.377 0 010 5.984V5c0-.373.15-.714.392-.962l.002-.001c.242-.248.576-.4.941-.4zm17.33.761H1.335a.58.58 0 00-.416.176l-.001.002A.603.603 0 00.745 5v.603h18.509V5a.605.605 0 00-.172-.425l-.002-.002a.578.578 0 00-.415-.176z"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgDelete
