import React from "react"

const Backup = (props) => (
<svg width="1em" height="1em" viewBox="0 0 58 54" {...props}>
 <g transform="translate(12 10)" fill="#FFF" >
      <path d="M18.334 29.947A13.199 13.199 0 018.96 28.91a13.202 13.202 0 01-6.336-7.098 13.086 13.086 0 01-.201-8.773 13.323 13.323 0 013.575-5.595l-.301 2.499 1.754.212.664-5.509-5.44-.643-.207 1.755 2.439.288a15.099 15.099 0 00-4.164 6.444 14.943 14.943 0 00.228 9.946 14.965 14.965 0 007.182 8.047c2.143 1.099 4.49 1.659 6.85 1.659a14.93 14.93 0 003.78-.486l-.448-1.71zM29.093 22.265a14.973 14.973 0 00-.058-10.42 14.929 14.929 0 00-7.56-8.234 14.924 14.924 0 00-11.109-.735l.548 1.68a13.164 13.164 0 019.799.65 13.17 13.17 0 016.668 7.262 13.206 13.206 0 01.052 9.19 13.267 13.267 0 01-4.302 5.91l.328-2.719-1.755-.211-.664 5.508 5.44.643.207-1.755-2.23-.263a15.038 15.038 0 004.636-6.506z" />
      <path d="M19.916 13.947c.51-.64.815-1.449.815-2.329a3.75 3.75 0 00-3.746-3.746 3.748 3.748 0 00-3.26 1.905 5.52 5.52 0 015.116 5.499 5.5 5.5 0 01-.26 1.67 7.092 7.092 0 012.195 2.827l2.539-.003v-.882a5.299 5.299 0 00-3.399-4.94z" />
      <path d="M16.24 17.629a3.727 3.727 0 00.834-2.353 3.75 3.75 0 00-3.746-3.746 3.75 3.75 0 00-3.747 3.746c0 .892.315 1.713.838 2.357a5.299 5.299 0 00-3.327 4.912v.884h12.485v-.884a5.299 5.299 0 00-3.337-4.916z" />
    </g>
  </svg>
)

export default Backup



