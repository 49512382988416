import { DEV } from "constants/environement"

/**
 * API server URL
 */
export const BASE_URL = DEV ? "https://127.0.0.1:8000" : ""
export const ENDPOINT = ""
//export const ENDPOINT = DEV ? "http://srvmup073/api/" : "http://srvmup073/api/"

/**
 * Login endpoints
 */
export const LOGIN_MATRICULE = `${BASE_URL}/api/login_matricule`
export const LOGIN_LDAP = `${BASE_URL}/api/login_ldap`
export const LOGIN_KERBEROS = `${BASE_URL}/api/login_kerberos`

/**
 * profile endpoints
 */

export const GET_PROFILE_ENDPOINT = (id) => `${BASE_URL}/api/profiles/${id}`
export const GET_PROFILES_BY_POSTE_AND_BU_ENDPOINT = (poste, bu) =>
  `${BASE_URL}/api/profiles?bu=${bu}&postes=${poste}`
export const GET_PROFILES_BY_POSTE = (poste) => `${BASE_URL}/api/profiles?postes=${poste}`
export const SEARCH_PROFILES_ENDPOINT = (firstname, lastname) =>
  `${BASE_URL}/api/profiles?firstname=${firstname}&lastname=${lastname}`
export const GET_N_PLUS_1_ENDPOINT = (id) => `${BASE_URL}/api/n_plus1s/${id}`
export const GET_ADMIN_LIST = `${BASE_URL}/api/profiles?admin=1`

/**
 * Demandes endpoints
 */
export const GET_CATEGORIES_ENDPOINT = `${BASE_URL}/api/categories`
export const GET_FORMS_ENDPOINT = `${BASE_URL}/api/forms`
export const GET_PUBLISHED_FORMS_ENDPOINT = `${BASE_URL}/api/forms?status=published`
export const GET_FORM_ENDPOINT = (id) => `${BASE_URL}/api/forms/${id}`
export const GET_DEMANDE_ENDPOINT = (id) => `${BASE_URL}/api/demandes/${id}`
export const DELETE_DEMANDE_ENDPOINT = (id) => `${BASE_URL}/api/demandes/${id}`
export const GET_DEMANDES_ENDPOINT = `${BASE_URL}/api/indexed_demandes`
export const POST_DEMANDES_ENDPOINT = `${BASE_URL}/api/demandes`
export const GET_PDF_ENDPOINT = (id) => `${BASE_URL}/api/download/demandes/${id}`

export const ORDER_FILTER_TITLE_PARAM = `order[title]=asc`
export const ORDER_FILTER_LABEL_PARAM = `order[label]=asc`
export const ORDER_FILTER_EMBEDDED_CATEGORY_LABEL = `order[category.label]=asc`

export const POST_LINKED_DEMANDE_ENDPOINT = `${BASE_URL}/api/linked_demandes`
/**
 *
 * CloneDemande endpoints
 */
export const GET_CLONE_DEMANDE_ENDPOINT = (id) => `${BASE_URL}/api/cloned_demandes/${id}`
export const POST_CLONE_DEMANDES_ENPOINT = `${BASE_URL}/api/cloned_demandes`

/**
 *
 * CloneForm endpoints
 */
export const GET_CLONE_FORM_ENDPOINT = (id) => `${BASE_URL}/api/cloned_forms/${id}`
export const POST_CLONE_FORM_ENPOINT = `${BASE_URL}/api/cloned_forms`

/**
 * Valideurs endpoints
 */
export const DELETE_VALIDEUR_ENDPOINT = (id) => `${BASE_URL}/api/valideurs/${id}`

/**
 * Media Objects endpoints
 */
export const POST_MEDIA_OBJECT_ENDPOINT = `${BASE_URL}/api/media_objects`
export const GET_MEDIA_OBJECT_ENDPOINT = (id) => `${BASE_URL}/api/media_objects/${id}`
export const GET_MEDIA_OBJECT_ENDPOINT_WHITOUT_BASE = (id) => `/api/media_objects/${id}`

/**
 * bu endpoints
 */
export const GET_BUSINESS_UNITS_ENDPOINT = `${BASE_URL}/api/b_us`

/**
 * locations endpoints
 */
export const GET_LOCATIONS_ENDPOINT = `${BASE_URL}/api/locations`
export const GET_LOCATION_ENPOINT = (id) => `${BASE_URL}/api/location/${id}`

/**
 * config endpoints
 */
export const GET_CONFIG_BY_NAME = (name) => `${BASE_URL}/api/configs?name=${name}`
export const POST_CONFIG = `${BASE_URL}/api/configs`
export const PUT_CONFIG = (id) => `${BASE_URL}/api/config/${id}`


/**
 * form access endpoints
 */

export const GET_FORM_ACCESSES = `${BASE_URL}/api/form_accesses`
export const GET_FORM_ACCESSES_OF_A_PARTICULAR_FORM = (id) => `${BASE_URL}/api/form_accesses/${id}`


/**
 * backup endpoints
 */
export const GET_BACKUP_PLAN_ENDPOINT = (id) => `${BASE_URL}/api/backup_plans/${id}`
export const GET_BACKUP_ENDPOINT = (id) => `${BASE_URL}/api/backups/${id}`
export const POST_BACKUPS_ENDPOINT  =`${BASE_URL}/api/backups`
export const PUT_BACKUPS_ENDPOINT  = (id) => `${BASE_URL}/api/backups/${id}`