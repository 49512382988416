import React, { useState, useEffect } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import FormElement from "components/form/FormElement"
import Pen from "components/icons/Pen"
import CrossedPen from "components/icons/CrossedPen"
import { Row } from "@bootstrap-styled/v4"

import Colors from "theme/Colors"

const ContainerTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`

const EditIcon = styled(Pen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const CantEditIcon = styled(CrossedPen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const FieldsContainer = styled(Row)`
  flex-direction: row;
`

const FormContainer = ({
  className,
  hideInputs,
  readOnly,
  element,
  onChange,
  value,
  previousFormResponses,
  hasPreviousForm,
  isDraftAndValidatorIsAuthor,
  ...props
}) => {
  const [editable, setEditable] = useState(!hideInputs && !readOnly)
  const initialValue = {
    question: element["@id"],
    type: "response_group",
    responses: [],
    ...value,
  }

  const handleChange = (data) => {
    const index = initialValue.responses.findIndex((item) => {
      return item.question === data.question
    })

    if (index === 0 || index > 0) {
      initialValue.responses[index] = data
    } else {
      initialValue.responses.push(data)
    }

    onChange(initialValue)
  }

  const handleClick = () => {
    setEditable(!editable)
  }

  return (
    <Container className={`formContainer ${className}`} {...props}>
      {element.title && (
        <ContainerTitle>
          {element.title}
          {!readOnly && hideInputs && (
            <>
              {editable ? (
                <CantEditIcon onClick={handleClick} />
              ) : (
                <EditIcon onClick={handleClick} />
              )}
            </>
          )}
        </ContainerTitle>
      )}
      {element.questions && element.questions.length && (
        <FieldsContainer noGutters={true} className="align-items-start">
          {element.questions.map((item) => (
            <FormElement
              editable={editable}
              element={item}
              key={item.id}
              onChange={handleChange}
              value={initialValue.responses.find((response) => {
                return response.question === item["@id"]
              })}
              previousFormResponses={previousFormResponses}
              hasPreviousForm={hasPreviousForm}
              isDraftAndValidatorIsAuthor={isDraftAndValidatorIsAuthor}
              {...(item.type === "container" ? { hideInputs, readOnly } : {})}
            />
          ))}
        </FieldsContainer>
      )}
    </Container>
  )
}

export default styled(FormContainer)`
  padding: 20px 30px 30px 20px;
  margin: 30px 70px 0;

  .formContainer {
    margin: 0;
    box-shadow: none;
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    margin: 0px 0px 0px 0px;
    padding-top: 0px;
  }
`
