import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"
import Text from "components/common/Text.js"

import Delete from "components/icons/Delete"
import CustomTip from "components/common/ToolTip"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Colors from "theme/Colors"
import FormControl from "@material-ui/core/FormControl"
import TextInput from "components/form/element/TextInput"
import { TextField } from "@material-ui/core"

const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`

const Font = styled(Text)`
  color: #000000;
  font-size: 13px;
  line-height: 18px;
`
const MicroContainer = styled.div`
  margin: 5px;
  padding: auto;
  align-items: center;
`
const MicroContainerTitle = styled(Text)`
  color: #000000;
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
`
const MicroContainerContent = styled.div`
  text-align: center;
`
const ContentFont = styled(Text)`
  font-size: 13px;
  line-height: 18px;
`
const CustomInputLabel = styled(InputLabel)`
  background-color: ${Colors.white};
`
const CustomSelect = styled(Select)`
  @media screen and (max-width: 1200px) {
    margin: 0 0 10px 0px !important;
  }
`

const CustomTextField = styled(TextField)`
 // margin-bottom: 0px !important;
// background - color: transparent;
// min-height: 32px !important;
// width: 100 %;
// color: ${Colors.black};
// font - size: 13px;
//   &.MuiInputBase - root {
//   font - family: "Open Sans";
// }
`

const CustomFormControl = styled(FormControl)`
  font-size: 13px !important;
`

const InputFont = styled.div`
  font-size: 13px;
`

const CategoryAdminContainer = ({
  className,
  category,
  maxPosition,
  position,
  onCategoryPositionChange,
  onNameChange,
  handleDelete,
  handleEdit,
  ...props
}) => {
  const [editable, setEditable] = useState(true)

  const handleNameChange = (event) => {
    const newName = event.target.value || ""
    onNameChange(category, newName)
  }

  const handlePositionChange = (event) => {
    const oldPosition = position
    onCategoryPositionChange(oldPosition, event.target.value, category)
  }
  if (category) {
    const positions = []
    for (let i = 1; i <= maxPosition; i++) {
      positions.push(
        <MenuItem value={i} key={i} disabled={category.position == position}>
          <InputFont>{i}</InputFont>
        </MenuItem>
      )
    }

    return (
      <Col md="6" xs="12">
        <Row className="align-items-end ">
          <Col className="col-1">
            <Row>
              <MicroContainerTitle>Position</MicroContainerTitle>
            </Row>
            <Row>
              <FormControl componentClass="CustomFormControl" fullWidth={true}>
                <Select value={position} onChange={handlePositionChange} margin="dense">
                  <MenuItem value={null} className={InputFont}>
                    Choisissez une valeur
                  </MenuItem>
                  {positions}
                </Select>
              </FormControl>
            </Row>
          </Col>
          <Col>
            <Row className="align-items-end justify-content-start">
              <Col>
                <MicroContainerTitle>Libellé de la catégorie</MicroContainerTitle>
              </Col>
            </Row>
            <Row className="align-items-end justify-content-center" noGutters={true}>
              <Col className="col-10">
                <FormControl fullWidth={true} margin="none">
                  <TextField
                    margin="none"
                    id={`category-name-${category.id}`}
                    value={category.label ? category.label : ""}
                    onChange={handleNameChange}
                    hiddenLabel={true}
                    InputProps={{
                      style: { minHeight: "32px ", fontSize: "13px", lineHeight: "0px" },
                    }}
                  />
                </FormControl>
              </Col>

              <Col className="col-2">
                <DeleteButton
                  onClick={() => {
                    handleDelete(category.id)
                  }}
                  data-tip
                  data-for="deleteComment"
                />
                <CustomTip id="deleteComment" place="bottom" effect="solid">
                  <span>Supprimer</span>
                </CustomTip>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default styled(CategoryAdminContainer)`
  background-color: transparent;
  min-height: 32px;
  width: 100%;
  color: ${Colors.black};
  font-size: 13px;
  &.MuiInputBase-root {
    font-family: "Open Sans";
  }
`
