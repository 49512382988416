function flatten(arr) {
  return arr.reduce((acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur), [])
}

export const extractQuestions = (allQuestions) => {
  const extractedQuestions = []
  if (Array.isArray(allQuestions)) {
    allQuestions.map((question) => {
      extractedQuestions.push(extractQuestions(question))
    })
  }
  const question = allQuestions
  if (!Array.isArray(question) && question["@type"] === "Container") {
    extractedQuestions.push(extractQuestions(question.questions))
  } else if (!Array.isArray(question)) {
    return question
  }
  const flattenedExtractedQuestions = flatten(extractedQuestions)
  return flattenedExtractedQuestions
}
