import React, { useEffect, useState, useContext } from "react"

import Container from "components/form/element/Container"
import TextInput from "components/form/element/TextInput"
import SelectList from "components/form/element/SelectList"
import SubQuestion from "components/form/element/SubQuestion"
import TextArea from "components/form/element/TextArea"
import Attachment from "components/form/element/Attachment"
import InvestmentDetails from "components/form/element/InvestmentDetails"
import BusinessUnit from "components/form/element/BusinessUnit"
import Site from "components/form/element/Site"
import Radio from "components/form/element/Radio"
import DateTime from "./element/DateTime"
import CheckboxForm from "components/form/element/Checkbox"
import Comment from "components/form/element/Comment"
import Numeric from "components/form/element/Numeric"
import Table from "components/form/element/Table"
import FixedTable from "components/form/element/FixedTable"
import Sum from "components/form/element/Sum"

const formElts = {
  container: Container,
  text_input: TextInput,
  select_list: SelectList,
  sub_question: SubQuestion,
  text_area: TextArea,
  attachment: Attachment,
  investment: InvestmentDetails,
  business_unit: BusinessUnit,
  site: Site,
  radio: Radio,
  date_time: DateTime,
  checkbox: CheckboxForm,
  comment_question: Comment,
  numeric_question: Numeric,
  table_question: Table,
  fixed_table_question: FixedTable,
  sum: Sum,
}

const FormElement = ({
  previousFormResponses,
  hasPreviousForm,
  element,
  isDraftAndValidatorIsAuthor,
  ...props
}) => {
  const Element = formElts[element.type]

  // cas d'une 'demande suivante' pour un formulaire lié. Et l'option récupérer les réponses depuis
  // une certaine question.

  // je fais cette opération en front malheureusement car notre structure des réponses
  // (réponses imbriquées dans des containers) -> on ne sait pas combien on a de container au-dessus
  // donc pour créer une réponse à une question, il faut que cette réponse soit dans un responseGroup, lui
  // même potentiellement dans un responseGroup, lui même dans un responseGroup etc..

  // tout ça n'avait pas été pensé pour que les réponses puissent être créées côtés serveurs mais 'organisées en font'
  // donc je suis dans l'impossibilité de faire ces op côté serveur
  const [matchingResponse, setMatchingResponse] = useState([])
  const [matchingResponseAlreadySearched, setMatchingResponseAlreadySearched] = useState(false)
  const findMatchingResponse = (array, relatedQuestion) => {
    const result = array.find((resp) => {
      return resp.question === relatedQuestion
    })
    if (result) {
      matchingResponse.push(result)
    } else {
      array.map((resp) => {
        if (resp.responses) {
          findMatchingResponse(resp.responses, relatedQuestion)
        }
      })
    }
  }

  useEffect(() => {
    if (
      !previousFormResponses ||
      !hasPreviousForm ||
      !element.retrieveResponseInThePreviousDemande ||
      matchingResponseAlreadySearched
    ) {
      return
    }
    findMatchingResponse(previousFormResponses, element.questionToRetrieveResponse)
    setMatchingResponse([...matchingResponse])
    setMatchingResponseAlreadySearched(true)
  }, [previousFormResponses, hasPreviousForm, element])

  if (!Element) {
    return null
  }
  return (
    <Element
      element={element}
      previousFormResponses={previousFormResponses}
      hasPreviousForm={hasPreviousForm}
      matchingResponse={
        matchingResponse && matchingResponse.length > 0 && isDraftAndValidatorIsAuthor
          ? matchingResponse
          : null
      }
      isDraftAndValidatorIsAuthor={isDraftAndValidatorIsAuthor}
      {...props}
    />
  )
}

export default FormElement
