import React from "react"

const SvgNext = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
    <path
      d="M2 2l8 8-8 8"
      stroke="#FFF"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
)

export default SvgNext
