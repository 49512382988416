import React, { useState, useRef, useContext, useEffect } from "react"
import styled from "styled-components"

import PageTop from "components/layout/PageTop"

import ProgressionViewer from "components/newDemande/ProgressionViewer"
import FormToFill from "components/newDemande/FormToFill"
import { Next, Back } from "components/icons"
import CancelLink from "components/common/CancelLink"
import NavigationButton from "components/common/NavigationButton"
import { Row, Col } from "@bootstrap-styled/v4"
import Comments from "components/editDemande/Comments"

import DemandeCreationContext from "contexts/DemandeCreationContext"

const Spacer = styled.span`
  width: 20px;
  height: 1 px;
`
const CustomRow = styled(Row)`
  margin: 20px 70px 30px 70px !important;
  @media screen and (max-width: 1200px) {
    margin: 10px 0px 10px 50px !important;
  }
`

const FillForm = ({ className, ...props }) => {
  const cancelButton = () => <CancelLink></CancelLink>
  const nextButton = () => (
    <NavigationButton to="workflow" isNext={true} isActive onClick={saveNewComment}>
      Suivant
      <Spacer />
      <Next />
    </NavigationButton>
  )
  const previousButton = () => (
    <NavigationButton to="choix-formulaire" isActive>
      <Back />
      <Spacer />
      Précédent
    </NavigationButton>
  )

  window.addEventListener("beforeunload", (event) => {
    event.returnValue = `Etes vous sûr de quitter la page ?`
  })

  const [comments, setComments] = useState([])

  const [isNewComment, setIsNewComment] = useState(false)

  const commentToAdd = useRef({})

  const handleAddComment = (newComment) => {
    commentToAdd.current = newComment
    setIsNewComment(true)
  }

  const saveNewComment = () => {
    if (commentToAdd.current === "") {
      setNewComment(null)
    } else {
      setNewComment(commentToAdd.current)
    }
  }
  const { setNewComment, newComment } = useContext(DemandeCreationContext)

  return (
    <div className={className}>
      <PageTop getBackComponent={cancelButton} title={"Nouvelle demande"} />
      <ProgressionViewer
        next={nextButton}
        previous={previousButton}
        title="Saisie des informations"
        percent={50}
      />
      <FormToFill />
      <Comments
        comments={comments}
        addComment={handleAddComment}
        commentInitialValue={newComment && newComment.content && newComment.content}
        readOnly={false}
      />
      <CustomRow className="justify-content-end">
        <Col xs="auto p-0">{nextButton()}</Col>
      </CustomRow>
    </div>
  )
}

export default styled(FillForm)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-self: stretch;

  @media screen and (max-width: 1200px) {
    .progressionViewer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
    }
  }
`
