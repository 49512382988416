import styled from "styled-components"

import Colors from "theme/Colors"

export default styled.div`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  color: ${Colors.black};
  cursor: not-allowed;

  &:hover {
    text-decoration: none;
  }
`
