import React from "react"

const SvgWaittingSvg = ({ ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 50 50" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FD906A" cx={25} cy={25} r={25} />
      <path
        d="M37.105 30.891a.568.568 0 01-.529-.346c-.066-.156-1.716-3.857-7.885-4.59-1.285-.156-2.824-.236-4.693-.25v4.613a.574.574 0 01-.304.508.577.577 0 01-.588-.033l-11.852-7.978a.573.573 0 010-.953l11.857-7.979a.563.563 0 01.588-.029c.19.102.303.295.303.502v4.293c2.578.336 13.674 2.33 13.674 11.674a.574.574 0 01-.459.562c-.037.006-.076.006-.112.006z"
        fill={"#FFF"}
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgWaittingSvg
