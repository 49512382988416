import React from "react"

const SvgLast = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 11 10" {...props}>
    <g fill="#D1D1D1" fillRule="nonzero">
      <path d="M.893 9.812a.5.5 0 00.71 0l4.137-4.13a.75.75 0 00.22-.53v-.38a.77.77 0 00-.22-.53L1.603.112a.5.5 0 00-.71 0l-.71.71a.49.49 0 000 .7l3.448 3.44-3.448 3.44a.5.5 0 000 .71l.71.7z" />
      <path d="M5.893 9.812a.5.5 0 00.71 0l4.137-4.13a.75.75 0 00.22-.53v-.38a.77.77 0 00-.22-.53L6.603.112a.5.5 0 00-.71 0l-.71.71a.49.49 0 000 .7l3.448 3.44-3.448 3.44a.5.5 0 000 .71l.71.7z" />
    </g>
  </svg>
)

export default SvgLast
