import React, { useState, useEffect, useContext } from "react"

import styled from "styled-components"
import Field from "components/adminCreateForm/newBlock/Field"
import Container from "components/common/Container"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { resetWeight } from "components/adminCreateForm/functions/resetWeight.js"
import { CreateFormContext, CreateFormContextProvider } from "contexts/CreateFormContext"

const CustomContainer = styled(Container)`
  padding: 30px;
`

const AddNewBlockContainer = styled(CustomContainer)`
  background-color: #d1d1d1;
  text-align: center;
  color: white;
  font-family: "Open Sans";
  &:hover {
    cursor: pointer;
  }
  margin-bottom: 10px;
`

const MyDiv = styled.div`
  margin-bottom: 10px;
`

export function createAnId() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const OptionalBlocks = ({ className, ...props }) => {
  const { questions, setQuestions } = useContext(CreateFormContext)

  const handleCreateNewBlock = () => {
    const id = createAnId()
    const weightOfTheNewElement = questions.length
    questions.push({
      type: "container",
      description: "",
      questions: [],
      key: id,
      title: "",
      weight: weightOfTheNewElement,
    })
    setQuestions([...questions])
  }

  const deleteMainContainer = (position) => {
    let copyOfTheQuestionArray = questions.slice()
    copyOfTheQuestionArray.splice(position, 1)
    resetWeight(position, copyOfTheQuestionArray.length, copyOfTheQuestionArray)
    setQuestions([...copyOfTheQuestionArray])
  }

  return (
    <MyDiv>
      {questions.map((question, index) => {
        return (
          <Field
            field={question}
            position={index}
            saveParameters={() => {}}
            onLabelChange={() => {}}
            key={question.id}
            onDelete={deleteMainContainer}
          />
        )
      })}
      <AddNewBlockContainer className={className} onClick={handleCreateNewBlock}>
        <h1>+ Nouveau bloc de contenu</h1>
      </AddNewBlockContainer>
    </MyDiv>
  )
}

export default styled(OptionalBlocks)`
  margin: 30px 70px 0;
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
    text-align: center;
  }
`
