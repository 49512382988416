import React, { useState } from "react"

import FormTextField from "components/form/common/SingleLineTextField"
import styled from "styled-components"
import FormControl from "@material-ui/core/FormControl"

import ElementContainer from "components/form/element/ElementContainer"

import Colors from "theme/Colors"

const TitleInput = ({ className, editable, label, onChange, value, ...props }) => {
  const [elementValue, setValue] = useState(value || "")

  const handleChange = (event) => {
    onChange(event.target.value)
    setValue(event.target.value)
  }

  return (
    <ElementContainer width={100}>
      <FormControl fullWidth={true} margin="none">
        <FormTextField
          id={`element-title`}
          label={label}
          InputProps={{
            inputProps: { style: { "min-height": "35px", "line-height": "0px" } },
            className,
            ...(editable ? {} : { readOnly: true }),
          }}
          value={elementValue}
          onChange={handleChange}
        />
      </FormControl>
    </ElementContainer>
  )
}

export default styled(TitleInput)`
  background-color: transparent;
  width: 100%;
  color: ${Colors.black};
  font-size: 23px !important;
  font-weight: 600;
  &.MuiInputBase-root {
    font-family: "Open Sans";
  }
`
