import React, { useContext, useState } from "react"
import Link from "components/common/Link"
import styled from "styled-components"
import Colors from "theme/Colors"
import { Row, Col } from "@bootstrap-styled/v4"
import * as Icons from "components/icons"
import Text from "components/common/Text"
import { OpenMenuContext } from "components/layout/Menu"

const StyledLink = styled(Link)`
  font-size: 40px;
  padding: 15px;
  margin-top: 10px;
  border-radius: 8px;
  align-items: start !important;
  justify-content: start !important;
  min-width: 100px;
  padding: 0px 15px !important;
  font-size: 50px;
  .isActive {
    background-color: ${Colors.primaryDark};
    border-radius: 8px;
  }
  .fontBold {
    font-weight: bold;
  }
`

const MenuText = styled(Text)`
  color: #ffffff;
  font-size: 18px;
  //font-weight: bold;
  letter-spacing: -0.32px;
`

const StyledCol = styled(Col)`
  .svg-hover {
    &:hover {
      background-color: ${Colors.primaryDark};
      border-radius: 8px;
    }
  }
`

const MenuLink = ({ children, name, label, fullMenuOpen, ...props }) => {
  const currentRoute = window.location.pathname
  const isOpen = useContext(OpenMenuContext)

  const Home = Icons["Home"]
  const New = Icons["NouvelleDemande"]
  const Archives = Icons["Archives"]
  const Parameters = Icons["Reglages"]
  const NewForm = Icons["NouveauFormulaire"]
  const FormList = Icons["ListeFormulaire"]
  const DisplayFullMenuPicto = Icons["DisplayFullMenuPicto"]
  const CloseFullMenuPicto = Icons["CloseFullMenuPicto"]
  const ChangeUser = Icons["ChangerUser"]
  const Backup = Icons["BackupMenuPicto"]

  const pictoElement = {
    demandes: Home,
    "nouvelle-demande": New,
    "demandes-archivees": Archives,
    remplacements: Backup,
    formulaires: FormList,
    "nouveau-formulaire": NewForm,
    parametres: Parameters,
    open: DisplayFullMenuPicto,
    close: CloseFullMenuPicto,
    "changement-utilisateur": ChangeUser,
  }
  const Picto = pictoElement[name]

  return (
    <StyledLink {...props}>
      {Picto ? (
        <StyledCol xs="auto">
          <Picto className={"svg-hover " + (currentRoute.split("/")[1] === name && "isActive")} />
        </StyledCol>
      ) : (
        <>{children}</>
      )}
      {isOpen && (
        <Col xs="9">
          <MenuText className={currentRoute.split("/")[1] === name && "fontBold"}>{label}</MenuText>
        </Col>
      )}
    </StyledLink>
  )
}

export default MenuLink
