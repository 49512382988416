import React, { useState, useContext } from "react"

import ValidatorAdmin from "components/adminCreateForm/settingWorkFlow/ValidatorAdmin"
import AddValidatorAdmin from "components/adminCreateForm/settingWorkFlow/AddValidatorAdmin"
import { CreateFormContext } from "contexts/CreateFormContext"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const WorkFlowConfiguration = ({ ...props }) => {
  const { steps, setSteps, validationUnitaire, setValidationUnitaire } = useContext(
    CreateFormContext
  )

  const handleValidationUnitaireChange = (e) => {
    setValidationUnitaire(!validationUnitaire)
  }

  const handleChange = () => {}

  const handleDelete = (validator) => {
    steps.splice(validator.weight, 1)

    steps.map((item, index) => {
      if (!item) {
        return null
      }

      steps[index].weight = index
    })
    setSteps([...steps])
  }
  //TODO: voir ce qu'on fait avecle type : read_only
  const handleAdd = (validator) => {
    steps.splice(validator.weight, 0, validator)
    steps.map((item, index) => {
      if (!item) {
        return null
      }

      steps[index].weight = index
    })
    setSteps([...steps])
  }
  const bu = "Ruggieri"

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={validationUnitaire}
            onChange={handleValidationUnitaireChange}
            name={"unitaryValidation"}
            color="primary"
          />
        }
        label={"Validation unitaire par défaut"}
      />
      {steps.map((element, key) => {
        if (!element) {
          return null
        }

        return (
          <>
            <ValidatorAdmin
              validator={element}
              key={key}
              onChange={handleChange}
              onDelete={handleDelete}
              bu={bu}
            />
          </>
        )
      })}
      <AddValidatorAdmin addValidator={handleAdd} maxPosition={steps.length} />
    </>
  )
}

export default WorkFlowConfiguration
