import React from "react"
import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"
import TextField from "@material-ui/core/TextField"
import Delete from "components/icons/Delete"

const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`
const CustomRow = styled(Row)`
  margin-top: 10px;
`

const AdminField = ({ className, admin, onDelete, ...props }) => {
  const handleDelete = () => {
    onDelete(admin)
  }

  return (
    <Col xs="3">
      <CustomRow className="w-100 align-items-end">
        <Col xs="auto">
          <TextField
            InputProps={{
              readOnly: true,
              style: { minHeight: "32px", fontSize: "13px", lineHeight: "0px" },
            }}
            defaultValue={admin.firstname + " " + admin.lastname}
            label="Profil"
          />
        </Col>
        <Col xs="2">
          <DeleteButton onClick={handleDelete} />
        </Col>
      </CustomRow>
    </Col>
  )
}

export default AdminField
