import React, { useState, useEffect } from "react"

import styled from "styled-components"

import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Colors from "theme/Colors"
import api from "services/api"
import InputLabel from "@material-ui/core/InputLabel"
import { BASE_URL } from "constants/endpoints"
import MenuItem from "@material-ui/core/MenuItem"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import { Row, Col } from "@bootstrap-styled/v4"
const CustomSelect = styled(Select)`
  @media screen and (max-width: 1200px) {
    margin: 0 0 10px 0px !important;
  }
`
const CustomInputLabel = styled(InputLabel)`
  background-color: ${Colors.white};
`

const CustomFormControl = styled(FormControl)`
  min-width: 200px !important;
  width: 100%;
`

const PosteFields = ({ postes, sendListOfAvalaiblePostes, isFilled, validator, ...props }) => {
  const [postesList, setPostesList] = useState(postes)
  const [selectedPoste, setSelectedPoste] = useState(null)
  const [notifyOnClosed, setNotifyOnClosed] = useState(true)
  const [notifyToApprove, setNotifyToApprove] = useState(false)
  validator.notifyOnClosed = notifyOnClosed
  validator.notifyToApprove = notifyToApprove
  useEffect(() => {
    isFilled(selectedPoste != null)
  }, [selectedPoste != null])

  delete validator.profile

  const handlePosteChange = (event) => {
    setSelectedPoste(event.target.value)
    validator.poste = event.target.value
  }
  const handleNotifyOnCloseChange = (event) => {
    //event.target.value est une string
    if (event.target.value == "true") {
      setNotifyOnClosed(true)
    } else {
      setNotifyOnClosed(false)
    }
  }

  const handleNotifyToApproveChange = (event) => {
    //event.target.value est une string
    if (event.target.value == "true") {
      setNotifyToApprove(true)
    } else {
      setNotifyToApprove(false)
    }
  }

  if (postesList.length == 0) {
    api
      .get(`${BASE_URL}/api/postes`)
      .then((data) => {
        if (data) {
          setPostesList(data["hydra:member"])
          sendListOfAvalaiblePostes(data["hydra:member"])
        }
      })
      .catch((e) => alert(e.message))
  }

  const postesItems = []
  if (postesList.length > 0) {
    for (let i = 0; i < postesList.length; i++) {
      postesItems.push(
        <MenuItem value={postesList[i]} key={"poste" + i} index={i}>
          {postesList[i].label}
        </MenuItem>
      )
    }
  }

  return (
    <Col xs="10">
      <Row>
        <Col>
          <CustomFormControl variant="outlined">
            <CustomInputLabel htmlFor={`poste`}>Poste du collaborateur</CustomInputLabel>
            <CustomSelect value={selectedPoste} onChange={handlePosteChange}>
              {postesItems}
            </CustomSelect>
          </CustomFormControl>
        </Col>
        <Col>
          <FormControl component="not">
            <FormLabel component="legend_notify">
              Notifier le contributeur lorsque la demande est close
            </FormLabel>
            <RadioGroup
              aria-label="notify"
              name="notify"
              value={notifyOnClosed}
              onChange={handleNotifyOnCloseChange}
              row
            >
              <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
            </RadioGroup>
          </FormControl>
        </Col>
        <Col>
          <FormControl component="not">
            <FormLabel component="legend_notify">
            Autoriser la notification immédiate pour validation
            </FormLabel>
            <RadioGroup
              aria-label="notify-approve"
              name="notify-approve"
              value={notifyToApprove}
              onChange={handleNotifyToApproveChange}
              row
            >
              <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
            </RadioGroup>
          </FormControl>
        </Col>
      </Row>
    </Col>
  )
}

export default PosteFields
