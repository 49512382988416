import React from "react"
import styled from "styled-components"

const Image = styled.img`
  max-width: 100%;
  height: auto;
`

const Container = ({ className, ...props }) => {
  return (
    <div className={className}>
      <Image {...props} />
    </div>
  )
}

export default styled(Container)`
  text-align: center;
`
