import React from "react"

const SvgHelp = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 44 50" {...props}>
    <path
      d="M21.837 0C9.776 0 0 9.776 0 21.837c0 8.251 4.574 15.422 11.32 19.138L11.064 50l10.303-6.35c.156.003.311.024.47.024 12.061 0 21.837-9.776 21.837-21.837S33.898 0 21.837 0zm-.98 34.97c-1.95 0-3.277-1.435-3.277-3.35 0-1.955 1.36-3.354 3.277-3.354 1.992 0 3.28 1.4 3.316 3.354.002 1.91-1.323 3.35-3.316 3.35zm4.457-13.704c-1.361 1.511-1.915 2.945-1.877 4.608v.659h-4.9l-.038-.956c-.11-1.876.515-3.796 2.176-5.782 1.176-1.403 2.137-2.58 2.137-3.833 0-1.287-.85-2.135-2.694-2.206-1.21 0-2.688.438-3.64 1.104l-1.255-4.02c1.326-.772 3.532-1.504 6.15-1.504 4.865 0 7.071 2.687 7.071 5.746.002 2.795-1.728 4.636-3.13 6.184z"
      fill="#FF0035"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgHelp
