import React from "react"

const SvgArchives = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 59 55" {...props}>
    <g transform="translate(-22 -383)" fill="#FFF" fillRule="nonzero">
      <path d="M42.963 405.25c-.86-.345-1.235.209-1.235.209L36 419.75v.5c0 .367-.023.5.283.5h22.379c.578 0 1.087-.39 1.225-.907L65 405.75v-.5H42.963z" />
      <path d="M41.365 404.25H62v-3.134c0-.753-.613-1.366-1.366-1.366H48.257l-2.5-3.5h-9.391c-.753 0-1.366.613-1.366 1.366v20.898l5.141-13.358c.138-.516.646-.906 1.224-.906z" />
    </g>
  </svg>
)

export default SvgArchives
