import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"
import TextField from "@material-ui/core/TextField"
import Delete from "components/icons/Delete"

import api from "services/api"
import { BASE_URL } from "constants/endpoints"

const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`
const CustomRow = styled(Row)`
  margin-top: 10px;
`

const DemandeVisibilityField = ({ className, profile, form, onDelete, bu, ...props }) => {
  const handleDelete = () => {
    onDelete(profile["@id"], form)
  }

  const username = profile?.firstname + " " + profile?.lastname
  return (
    <Col xs="6">
      <CustomRow className="w-100 align-items-end">
        <Col xs="auto">
          <TextField
            InputProps={{
              readOnly: true,
              style: { minHeight: "32px", fontSize: "13px", lineHeight: "0px" },
            }}
            defaultValue={""}
            value={form ? form : ""}
            label="Formulaire"
          />
        </Col>
        <Col xs="auto">
          <TextField
            InputProps={{
              readOnly: true,
              style: {
                minHeight: "32px",
                fontSize: "13px",
                lineHeight: "0px",
              },
            }}
            defaultValue={""}
            value={username}
            label="Profil"
          />
        </Col>
        {bu && (
          <Col xs="auto">
            <TextField
              InputProps={{
                readOnly: true,
                style: {
                  minHeight: "32px",
                  fontSize: "13px",
                  lineHeight: "0px",
                },
              }}
              defaultValue={""}
              value={bu}
              label="Business Unit"
            />
          </Col>
        )}

        <Col xs="2">
          <DeleteButton onClick={handleDelete} />
        </Col>
      </CustomRow>
    </Col>
  )
}

export default DemandeVisibilityField
