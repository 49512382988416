import React, { useState, useEffect } from "react"
import styled from "styled-components"
import api from "services/api"
import { GET_FORM_ENDPOINT, BASE_URL } from "constants/endpoints"
import { Row, Col } from "@bootstrap-styled/v4"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import AutoComplete from "components/form/AutoComplete"
import SquareButton from "components/common/SquareButton"
import Colors from "theme/Colors"
import Plus from "components/icons/Plus"
import Container from "components/common/Container"

const CustomRow = styled(Row)`
  margin: 20px;
`

const CustomAutoComplete = styled(AutoComplete)`
  width: 100%;
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0px !important;
  }
`

const AddDocsButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`

const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 56px;
  padding: 5px 20px;
  min-width: 200px;
  @media screen and (max-width: 1200px) {
    text-align: center;
    padding: 10px;
  }
`

const PlusIcon = styled(Plus)`
  font-size: 20px;
  @media screen and (max-width: 1200px) {
    font-size: 15px;
    padding: 2px;
  }
`

const ButtonContainer = styled.div`
  padding: 15px 15px;
  margin: 10px 15px 0 20px;
  @media screen and (max-width: 1200px) {
    margin: 10px 15px 0 0;
  }
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const AddContributorButton = styled(SquareButton)`
  min-height: 56px;
  max-height: 56px;
  max-width: 200px;
  min-width: 140px;

  @media screen and (max-width: 1200px) {
    margin: 10px 0 0 0 !important;
  }
`

// const CustomFormControl = styled(FormControl)`
//   margin-bottom: 10px;
// `
const AddContributorVisibility = ({ formList, buList, refresh, className }) => {
  const [profile, setProfile] = useState(null)
  const [currentlyAdding, setCurrentlyAdding] = useState(false)
  const [selectedForm, setSelectedForm] = useState(null)
  const [selectedBu, setSelectedBu] = useState(null)

  const handleAddContributor = () => {
    const data = { profile: profile, form: selectedForm, active: true }
    if (selectedBu) {
      data.bu = selectedBu
    }
    api
      .post(`${BASE_URL}/api/form_accesses`, data)
      .then(() => refresh())
      .catch((e) => alert(e.message))
  }

  const handleProfileChange = (selectedProfile) => {
    setProfile(selectedProfile && selectedProfile["@id"])
  }

  const handleEnableAdding = () => {
    setCurrentlyAdding(true)
  }

  const handleFormChange = (event) => {
    setSelectedForm(event.target.value)
  }

  const handleBuChange = (event) => {
    setSelectedBu(event.target.value)
  }

  return (
    <Row className="w-100">
      <Col xs="12">
        {currentlyAdding ? (
          <Container className={className}>
            <Row className="w-100 align-items-center justify-content-between">
              <Col xs="auto" xl="3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Formulaire</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedForm}
                    onChange={handleFormChange}
                  >
                    {formList.map((form, index) => {
                      return <MenuItem value={form["@id"]}>{form.title}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col xs="auto" xl="auto">
                <Row className="w-100">
                  <CustomAutoComplete onProfileChange={handleProfileChange} />
                </Row>
              </Col>
              <Col xs="auto" xl="3">
                <FormControl fullWidth>
                  <InputLabel id="bu-select-label">BusinessUnit</InputLabel>
                  <Select
                    labelId="bu-select-label"
                    id="bu-select"
                    value={selectedBu}
                    onChange={handleBuChange}
                  >
                    <MenuItem value="">Toutes</MenuItem>
                    {buList.map((form, index) => {
                      return <MenuItem value={form["@id"]}>{form.label}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Col>

              {profile && selectedForm ? (
                <Col xs="auto" xl="auto">
                  <AddContributorButton noGutters={true} onClick={handleAddContributor}>
                    Ajouter
                  </AddContributorButton>
                </Col>
              ) : (
                //col offset 3
                <Col xs="3"></Col>
              )}
            </Row>
          </Container>
        ) : (
          <ButtonContainer onClick={handleEnableAdding}>
            <AddDocsButtonContainer>
              <AddDocsButton>
                <Row className="justify-content-center align-items-center">
                  <Col xs="auto">
                    <PlusIcon />
                  </Col>
                </Row>
                <Row className="justify-content-center align-items-center">
                  <Col xs="auto">
                    <Spacer />
                  </Col>
                  <Col xs="auto">Ajouter un profil</Col>
                </Row>
              </AddDocsButton>
            </AddDocsButtonContainer>
          </ButtonContainer>
        )}
      </Col>
    </Row>
  )
}

export default styled(AddContributorVisibility)`
  padding: 20px;
  margin: 20px;
  border: 1px solid #2656c8;
`
