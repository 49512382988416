import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

import styled from "styled-components"
import Colors from "theme/Colors"
import api from "services/api"
import { Row, Col } from "@bootstrap-styled/v4"

import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"

import SquareButton from "components/common/SquareButton"

import {
  GET_PUBLISHED_FORMS_ENDPOINT,
  GET_BUSINESS_UNITS_ENDPOINT,
  ORDER_FILTER_TITLE_PARAM,
} from "constants/endpoints"
import { ACTIVE_STATUS, ARCHIVED_STATUS, ACTIVE } from "constants/demande"

import Container from "components/common/Container"

import Search from "components/icons/Search"
import Plus from "components/icons/Plus"

const moment = require("moment")

const Spacer = styled.div`
  border-top: 1px solid ${Colors.grey};
  margin: 20px 0;
`

const SearchIcon = styled(Search)`
  font-size: 20px;
`

const PlusIcon = styled(Plus)`
  font-size: 20px;
  margin-right: 10px;
`

const CustomFomControl = styled(FormControl)`
  min-height: 50px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 10px !important;
  }
`

const CustomLabel = styled(InputLabel)`
  background-color: ${Colors.white};
`

const CustomSquareButton = styled(SquareButton)`
  min-height: 50px;
  max-width: 210px;
  padding: 15px;
`
const CustomDarkSquareButton = styled(CustomSquareButton)`
  background-color: ${Colors.primaryVeryDark};
`

const years = []
for (let i = 2019; i <= moment().format("YYYY"); i++) {
  years.push(i)
}

const SearchDemandes = ({ className, type, searchValues, setSearchValues, ...props }) => {
  const [forms, setForms] = useState([])
  const [bus, setBus] = useState([])
  const [text, setText] = useState("")
  const [status, setStatus] = useState("")
  const [year, setYear] = useState("")
  const [form, setForm] = useState("")
  const [bu, setBu] = useState("")
  let history = useHistory()

  useEffect(() => {
    api
      .get(`${GET_PUBLISHED_FORMS_ENDPOINT}&${ORDER_FILTER_TITLE_PARAM}&pagination=false`)
      .then((data) => {
        if (data) {
          setForms(data["hydra:member"])
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])

  useEffect(() => {
    api
      .get(`${GET_BUSINESS_UNITS_ENDPOINT}`)
      .then((data) => {
        if (data) {
          setBus(data["hydra:member"])
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])

  const statusAvailable = type === ACTIVE ? ACTIVE_STATUS : ARCHIVED_STATUS

  const handleStatusChange = (event) => {
    const value = event.target.value || ""
    setStatus(value)
  }

  const handleYearChange = (event) => {
    const value = event.target.value || ""
    setYear(value)
  }

  const handleFormChange = (event) => {
    const value = event.target.value || ""
    setForm(value)
  }

  const handleBuChange = (event) => {
    const value = event.target.value || ""
    setBu(value)
  }

  const handleTextChange = (event) => {
    const value = event.target.value || ""
    setText(value)
  }

  const handleApply = () => {
    setSearchValues({
      status: status != "" ? status : null,
      year: year != "" ? year : null,
      form: form != "" ? form : null,
      text: text != "" ? text : null,
      bu: bu != "" ? bu : null,
    })
  }

  const enterToApply = (event) => {
    if (event.key != "Enter") {
      return
    }
    handleApply()
  }

  const handleReset = () => {
    setSearchValues({
      status: null,
      year: null,
      form: null,
      text: null,
    })
    setStatus("")
    setYear("")
    setForm("")
    setText("")
    setBu("")
  }

  const handleNewDemande = () => {
    history.push("/nouvelle-demande/")
  }

  return (
    <Container className={className}>
      <Row className="justify-content-center">
        <Col lg="6">
          <CustomFomControl fullWidth={true}>
            <TextField
              inputProps={{
                "aria-label": "weight",
                onKeyPress: enterToApply,
              }}
              label="Rechercher une demande"
              hiddenLabel={true}
              labelWidth={0}
              value={text}
              onChange={handleTextChange}
              InputProps={{ style: { minHeight: "32px", lineHeight: "3px" } }}
            />
          </CustomFomControl>
        </Col>
        <Col lg="6" xs="auto">
          <Row className="justify-content-end" noGutters={true}>
            <CustomSquareButton noGutters={true} onClick={handleNewDemande}>
              <PlusIcon /> Nouvelle demande
            </CustomSquareButton>
          </Row>
        </Col>
      </Row>
      <Spacer />
      <Row>
        <Col md="8" lg="9">
          <Row>
            <Col md="6" lg="2">
              <CustomFomControl fullWidth={true} margin="none" variant="outlined">
                <CustomLabel htmlFor={`search-status`}>Statut</CustomLabel>
                <Select
                  inputProps={{
                    name: `status`,
                    id: `search-status`,
                  }}
                  value={status}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={null}>Choisissez une valeur</MenuItem>
                  {statusAvailable.map((item) => (
                    <MenuItem value={item.key} key={item.key}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </CustomFomControl>
            </Col>
            <Col md="6" lg="2">
              <CustomFomControl fullWidth={true} margin="none" variant="outlined">
                <CustomLabel htmlFor={`search-year`}>Année</CustomLabel>
                <Select
                  inputProps={{
                    name: `year`,
                    id: `search-year`,
                  }}
                  value={year}
                  onChange={handleYearChange}
                >
                  <MenuItem value={null}>Choisissez une valeur</MenuItem>
                  {years.map((item, key) => (
                    <MenuItem value={item} key={key}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </CustomFomControl>
            </Col>
            <Col md="6" lg="4">
              <CustomFomControl fullWidth={true} margin="none" variant="outlined">
                <CustomLabel htmlFor={`search-bu`}>Business Unit</CustomLabel>
                <Select
                  inputProps={{
                    name: `bu`,
                    id: `search-bu`,
                  }}
                  value={bu}
                  onChange={handleBuChange}
                >
                  <MenuItem value={null}>Choisissez une valeur</MenuItem>
                  {bus.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </CustomFomControl>
            </Col>
            <Col md="6" lg="4">
              <CustomFomControl fullWidth={true} margin="none" variant="outlined">
                <CustomLabel htmlFor={`search-form`}>Formulaire</CustomLabel>
                <Select
                  inputProps={{
                    name: `form`,
                    id: `search-form`,
                  }}
                  value={form}
                  onChange={handleFormChange}
                >
                  <MenuItem value={null}>Choisissez une valeur</MenuItem>
                  {forms.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </CustomFomControl>
            </Col>
          </Row>
        </Col>
        <Col lg="3" md="4">
          <Row>
            <Col>
              <CustomDarkSquareButton className="mb-sm-2" noGutters={true} onClick={handleApply}>
                Appliquer
              </CustomDarkSquareButton>
            </Col>
            <Col>
              <CustomDarkSquareButton noGutters={true} onClick={handleReset}>
                Réinitialiser
              </CustomDarkSquareButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default styled(SearchDemandes)`
  height: 100%;
  padding: 30px 20px;
  margin: 0 70px 30px;

  @media screen and (max-width: 1200px) {
    margin: 0 0 30px;
    text-align: center;
    overflow: scroll;
  }
`
