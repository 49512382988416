import React, { useState, useEffect } from "react"
import { Switch, Route, useRouteMatch } from "react-router-dom"
import { useHistory, useParams } from "react-router-dom"

import { CreateFormContext, CreateFormContextProvider } from "contexts/CreateFormContext"
import CreateForm from "./CreateForm"
import SetWorkFlow from "./SetWorkFlow"
import FormCreated from "./FormCreated"
import Notifications from "./Notifications"
import api from "services/api"

import { GET_FORM_ENDPOINT } from "constants/endpoints"

const EditFormRouter = ({ ...props }) => {
  let { path } = useRouteMatch()
  const { id } = useParams()

  return (
    <>
      <CreateFormContextProvider id={id}>
        <Switch>
          <Route
            exact
            path={path}
            component={() => {
              return <CreateForm />
            }}
          />
          <Route exact path={`${path}/notifications`} component={() => <Notifications />} />
          <Route exact path={`${path}/set_workflow`} component={() => <SetWorkFlow />} />
          <Route exact path={`${path}/form_created`} component={() => <FormCreated />} />
        </Switch>
      </CreateFormContextProvider>
    </>
  )
}

export default EditFormRouter
