import React, { useState, useEffect, useContext } from "react"
import { Row, Col } from "@bootstrap-styled/v4"

import styled from "styled-components"
import api from "services/api"
import UserContext from "contexts/UserContext"
import DemandeCreationContext from "contexts/DemandeCreationContext"

import HeaderForm from "components/form/HeaderForm"
import FormElement from "components/form/FormElement"
import TitleInput from "components/form/element/TitleInput"
import BusinessUnitHeader from "components/form/element/BusinessUnitHeader"
import Container from "components/common/Container"
import Pen from "components/icons/Pen"
import CrossedPen from "components/icons/CrossedPen"

import Colors from "theme/Colors"

import { GET_FORM_ENDPOINT, BASE_URL } from "constants/endpoints"

const TitleContainer = styled(Container)`
  padding: 30px;
  margin: 30px 70px 0;
`

const EditIcon = styled(Pen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const CantEditIcon = styled(CrossedPen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const ContainerTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`

const FormToFill = ({ className, hideInputs, ...props }) => {
  const {
    form,
    values,
    setValues,
    title,
    setTitle,
    bu,
    setBu,
    setUnitaryValidation,
    hasAlreadyGotUnitaryValidation,
    setHasAlreadyGotUnitaryValidation,
  } = useContext(DemandeCreationContext)
  const { profile } = useContext(UserContext)
  const [formDetails, setFormDetails] = useState(null)
  const [titleEditable, setTitleEditable] = useState(!hideInputs)
  const [
    defaultUnitaryValidationConfiguration,
    setDefaultUnitaryValidationConfiguration,
  ] = useState(null)

  useEffect(() => {
    api
      .get(GET_FORM_ENDPOINT(form))
      .then((data) => {
        setFormDetails(data)
        setDefaultUnitaryValidationConfiguration(data.defaultUnitaryValidation)
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [form])

  useEffect(() => {
    if (hasAlreadyGotUnitaryValidation || defaultUnitaryValidationConfiguration === null) {
      return
    }
    setUnitaryValidation(defaultUnitaryValidationConfiguration)
    setHasAlreadyGotUnitaryValidation(true)
  }, [defaultUnitaryValidationConfiguration])

  if (!formDetails) {
    return null
  }

  const pictureUrl =
    profile && profile.bu && profile.bu.logo ? `${BASE_URL}${profile.bu.logo.contentUrl}` : null

  const handleChange = (data) => {
    const index = values.findIndex((item) => {
      return item.question === data.question
    })

    if (index === 0 || index > 0) {
      values[index] = data
    } else {
      values.push(data)
    }

    setValues(values)
  }

  const handleSetTitle = (value) => {
    setTitle(value)
  }

  const handleSetBU = (value) => {
    setBu(value)
  }

  const handleClick = () => {
    setTitleEditable(!titleEditable)
  }

  return (
    <div className={className}>
      <HeaderForm
        picture={pictureUrl}
        formTitle={formDetails.title}
        formNumber={null}
        formNotice={formDetails.notice}
      />
      <TitleContainer>
        <ContainerTitle>
          {hideInputs && (
            <>
              {titleEditable ? (
                <CantEditIcon onClick={handleClick} />
              ) : (
                <EditIcon onClick={handleClick} />
              )}
            </>
          )}
        </ContainerTitle>
        <Row className="justify-content-between">
          <Col md="8" xs="12">
            <TitleInput
              label={
                formDetails.titleInputLabel ? formDetails.titleInputLabel : "Titre de la demande"
              }
              onChange={handleSetTitle}
              editable={titleEditable}
              value={title}
            />
          </Col>
          <Col md="3" xs="12">
            <BusinessUnitHeader
              label="Business Unit"
              onChange={handleSetBU}
              editable={titleEditable}
              value={bu}
            />
          </Col>
        </Row>
      </TitleContainer>

      {formDetails.questions.map((element) => (
        <FormElement
          element={element}
          key={element.id}
          onChange={handleChange}
          hideInputs={hideInputs}
          value={values.find((item) => {
            return item.question === element["@id"]
          })}
        />
      ))}
    </div>
  )
}

export default styled(FormToFill)`
  padding-bottom: 35px;

  @media screen and (max-width: 1200px) {
    margin: 0;
    text-align: center;
    padding-bottom: 3px;
    .investment {
      text-align: left !important;
    }
  }
`
