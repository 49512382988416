import React, { useState, useEffect } from "react"
import Container from "components/common/Container"
import styled from "styled-components"
import AddLogoPopUp from "components/common/AddLogoPopUp"
import { Row, Col } from "@bootstrap-styled/v4"
import Text from "components/common/Text.js"
import { BASE_URL } from "constants/endpoints"
import api from "services/api"
const SmallContainer = styled(Container)`
  overflow: hidden;
  max-width: 120px;
`
const BusinessUnitName = styled(Text)`
  color: #000000;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin: auto;
`

const ColumnRow = styled(Row)`
  min-height: 110px;
`
const TextCol = styled(Col)`
  padding: 10px 17px;
  background-color: #f2f2f2;
  margin: 0;
`
const ImageCol = styled(Col)`
  margin: 5px;
`
const BusinessUnitCol = styled(Col)`
  margin: 20px 0px;
`
const LogoBusinessUnitContainer = ({ bu, ...props }) => {
  const [pictureLink, setPictureLink] = useState("http://placehold.it/220x240")
  const [imageChanged, setImageChanged] = useState(false)
  useEffect(() => {
    if (imageChanged === true) {
      api
        .get(`${BASE_URL}/api/b_us/${bu.id}`)
        .then((bu) => {
          setPictureLink(BASE_URL + `${bu.logo.contentUrl}`)
        })
        .catch((e) => alert("erreur : " + e.message))
    } else if (bu.logo != null) {
      setPictureLink(BASE_URL + `${bu.logo.contentUrl}`)
    }
    setImageChanged(false)
  }, [imageChanged === true])

  const handleImageChange = (bool) => {
    setImageChanged(true)
  }
  return (
    <BusinessUnitCol xs="2">
      <SmallContainer className="h-100">
        <ColumnRow className="h-100 justify-content-center ">
          <Col xs="12">
            <Row className="h-100 align-items-center justify-content-center">
              <ImageCol className="align-items-center">
                <AddLogoPopUp onImageChange={handleImageChange} image={pictureLink} id={bu.id} />
              </ImageCol>
            </Row>
          </Col>
          <TextCol xs="12" className="align-self-end">
            <BusinessUnitName>{bu.label}</BusinessUnitName>
          </TextCol>
        </ColumnRow>
      </SmallContainer>
    </BusinessUnitCol>
  )
}

export default LogoBusinessUnitContainer
