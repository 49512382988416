import React from "react"

import styled from "styled-components"
import Colors from "theme/Colors"

import Container from "components/common/Container"
import { Row, Col } from "@bootstrap-styled/v4"

const TitleContainer = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 23px;
  font-weight: bold;
  margin: 0;
`
const PercentContainer = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${Colors.background};
  margin-top: 20px;
`
const PercentBar = styled.div`
  height: 100%;
  width: ${({ percent }) => (percent ? percent : 0)}%;
  background-color: ${Colors.primary};
  border-radius: ${({ percent }) => (percent && percent < 100 ? "0 5px 5px 0" : "0")};
`
const ButtonCol = styled(Col)`
  margin: 10px 50px 0 50px;
  @media screen and (max-width: 790px) {
    margin: auto;
    margin-top: 10px;
  }
`

const ProgressionViewer = ({ className, title, percent, previous, next, ...props }) => {
  return (
    <Container className={className}>
      <Row className="justify-content-between align-items-center d-xs-block">
        <ButtonCol className="d-xs-block" xs="auto">
          {previous && previous()}
        </ButtonCol>
        <Col xs="auto" className="d-none d-xl-block">
          <TitleContainer className="">{title}</TitleContainer>
        </Col>
        <ButtonCol className="d-xs-block" xs="auto">
          {next && next()}
        </ButtonCol>
      </Row>
      <TitleContainer className="d-xl-none mt-3">{title}</TitleContainer>
      <PercentContainer>
        <PercentBar percent={percent}></PercentBar>
      </PercentContainer>
    </Container>
  )
}

export default styled(ProgressionViewer)`
  padding: 19px 0 0 0;
  margin: 0 70px;
  text-align: center;

  @media screen and (max-width: 1200px) {
    margin: 0;
  }
`
