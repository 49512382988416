import React, { useState, useEffect } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import OutlineSuccess from "components/icons/OutlineSuccess"
import { Row, Col } from "@bootstrap-styled/v4"
import Success from "components/icons/Success"
import DeletePicto from "components/icons/Delete"
import PosteDisplay from "components/adminCreateForm/settingWorkFlow/variableValidatorRenders/PosteDisplay"
import LineManagerDisplay from "components/adminCreateForm/settingWorkFlow/variableValidatorRenders/LineManagerDisplay"
import ProfileDisplay from "components/adminCreateForm/settingWorkFlow/variableValidatorRenders/ProfileDisplay"
import InitiatorDisplay from "components/adminCreateForm/settingWorkFlow/variableValidatorRenders/InitiatorDisplay"

const CustomContainer = styled(Container)`
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0 !important;
  }
`

const CheckIcon = styled(OutlineSuccess)`
  font-size: 30px;
  margin-right: 25px;
`
const SuccessPicto = styled(Success)`
  font-size: 30px;
  margin-right: 25px;
`

const ValidatorText = styled.div`
  font-weight: bold;
  min-width: 150px;
`

const ValidatorLabel = styled.div`
  min-width: 150px;
`

const DeleteIcon = styled(DeletePicto)`
  margin-left: 25px;
  font-size: 25px;
  &:hover {
    cursor: pointer;
  }
`

const validatorsElts = {
  poste: PosteDisplay,
  profile: ProfileDisplay,
  responsable_hierarchique: LineManagerDisplay,
  initiator: InitiatorDisplay,
}

const ValidatorAdmin = ({ className, validator, isDraft, onDelete, bu, ...props }) => {
  const handleDelete = () => {
    onDelete(validator)
  }
  let Element
  Element = validatorsElts[validator.type]

  return (
    <>
      <CustomContainer className={className}>
        <Row className="align-items-center" noGutters={true}>
          <Col>
            <Row className="align-items-center" noGutters={true}>
              <CheckIcon />
              <ValidatorText className="">VALIDEUR {validator.weight}</ValidatorText>
              <ValidatorLabel>{validator.label}</ValidatorLabel>
            </Row>
          </Col>
          <Col xs="auto">
            {" "}
            <Element validator={validator} {...props} />
          </Col>
          <Col xs="1">
            {validator.type !== "initiator" && <DeleteIcon onClick={handleDelete} />}
          </Col>
        </Row>
      </CustomContainer>
    </>
  )
}

export default styled(ValidatorAdmin)`
  padding: 15px 25px;
  margin: 20px 20px 0;
`
