import React, { useState } from "react"

import { Row, Col } from "@bootstrap-styled/v4"

import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"
import ChangeLabel from "components/adminCreateForm/fieldsParameters/commonParameters/ChangeLabel"
import Text from "components/common/Text"
import styled from "styled-components"
import Colors from "theme/Colors"
import SquareButton from "components/common/SquareButton"
import Delete from "components/icons/Delete"

const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`

const ErrorMessage = styled(Text)`
  color: ${Colors.red};
`

const AddLine = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
`

const FixedTableParameters = ({ saveParameters, fieldParameters, label, fieldWidth, ...props }) => {
  const [minWidth, setMinWidth] = useState(fieldParameters.minWidth || 900)
  const [linesLabel, setLinesLabel] = useState(fieldParameters.linesLabel || [])
  const [invalidWidth, setDisplayInvalidWidth] = useState(false)
  const [invalidNumberOfLines, setDisplayInvalidNumberOfLines] = useState(false)
  const [labelColumnWidth, setLabelColumnWidth] = useState(fieldParameters.labelColumnWidth || 15)
  const handleMinWidthChange = (e) => {
    setMinWidth(parseInt(e.target.value))
  }

  const handleAddLine = () => {
    linesLabel.push("")
    setLinesLabel([...linesLabel])
  }

  const handleLabelChange = (e, index) => {
    linesLabel[index] = e.target.value
    setLinesLabel([...linesLabel])
  }

  const validateChangement = () => {
    if (!minWidth) {
      setDisplayInvalidWidth(true)
    }
    if (linesLabel.length === 0) {
      setDisplayInvalidNumberOfLines(true)
    }
    saveParameters({
      type: "fixed_table_question",
      questions: [],
      minWidth: minWidth,
      numberOfLines: linesLabel.length,
      linesLabel: linesLabel,
      labelColumnWidth: labelColumnWidth,
    })
  }

  const handleLabelColumnWidthChange = (e) => {
    setLabelColumnWidth(parseInt(e.target.value))
  }

  const deleteLine = (index) => {
    linesLabel.splice(index, 1)
    setLinesLabel([...linesLabel])
  }

  return (
    <>
      <Row className={"justify-content-center"}>
        <Col xs="12">
          <ChangeLabel
            onLabelChange={handleMinWidthChange}
            inputLabel={"Largeur minimale du tableau (en px)"}
            label={minWidth}
          />
        </Col>
      </Row>
      <Row className={"justify-content-center"}>
        <Col xs="12">
          <ChangeLabel
            onLabelChange={handleLabelColumnWidthChange}
            inputLabel={"Largeur de la colonne des libellés des lignes (en %)"}
            label={labelColumnWidth}
          />
        </Col>
      </Row>
      {linesLabel.map((line, index) => {
        return (
          <Row className="align-items-end" key={index}>
            <Col xs="10">
              <ChangeLabel
                onLabelChange={(newLabelEvent) => {
                  handleLabelChange(newLabelEvent, index)
                }}
                inputLabel={"Libellé de la ligne"}
                label={linesLabel[index]}
              />
            </Col>
            <Col xs="2">
              <DeleteButton
                onClick={() => {
                  deleteLine(index)
                }}
              />
            </Col>
          </Row>
        )
      })}
      <Row className={"justify-content-center"}>
        <AddLine onClick={handleAddLine}>Ajouter une ligne au tableau</AddLine>{" "}
      </Row>
      {invalidWidth && (
        <Row className="justify-content-center">
          <ErrorMessage>Attention votre largeur minimale est invalide</ErrorMessage>
        </Row>
      )}
      {invalidNumberOfLines && (
        <Row className="justify-content-center">
          <ErrorMessage>Attention votre number de ligne est invalide</ErrorMessage>
        </Row>
      )}
      <Row className={"justify-content-center"}>
        <OkButton onClick={validateChangement}>Valider</OkButton>
      </Row>
    </>
  )
}

export default FixedTableParameters
