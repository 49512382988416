import React, { useState } from "react"

import styled from "styled-components"
import Colors from "theme/Colors"
import Container from "components/common/Container"
import { Row, Col } from "@bootstrap-styled/v4"
import AutoComplete from "components/form/AutoComplete"
import FormControl from "@material-ui/core/FormControl"
import DateFnsUtils from "@date-io/date-fns"
import Text from "components/common/Text"
import Plus from "components/icons/Plus"
import Link from "components/common/Link"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import frLocale from "date-fns/locale/fr"
import format from "date-fns/format"
import Status from "./Status"
import SquareButton from "components/common/SquareButton"

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "d MMM yyyy", { locale: this.locale })
  }
}

const CustomRow = styled(Row)`
  margin-bottom: 20px;
  padding-left:40px;
`

const RightCol = styled(Col)`
text-align: right;
margin-top:-50px;
`

const Activate = styled(Link)`
background-color: ${Colors.success};
min-height: 40px;
max-width: 335px;
width: 200px;
margin:auto;
border-radius: 20px;
color: ${Colors.white};
&:-moz-focus-inner {
  border: 0;
}
&:hover {
  cursor: pointer;
}
`

const Desactivate = styled(Link)`
background-color: ${Colors.red};
min-height: 40px;
max-width: 335px;
width: 200px;
margin:auto;
border-radius: 20px;
color: ${Colors.white};
&:-moz-focus-inner {
  border: 0;
}
&:hover {
  cursor: pointer;
}
`

const CustomSquareButton = styled(SquareButton)`
  min-height: 50px;
  max-width: 210px;
  padding: 15px;
  margin-top:-10px;
`
const CustomDarkSquareButton = styled(CustomSquareButton)`
  background-color: ${Colors.primaryVeryDark};
`


const Schedule = ({
  className,
  backupPlan,
  handleScheduleChange,
  handleStartedAtChange,
  handleEndedAtChange,
  handleIsEnabledChange,
  ...props
}) => {
  const moment = require("moment")
  const [editMode, setEditMode] = useState(null)
  const [startedDate, setStartedDate] = useState(new Date(Date.now() + 24*60*60*1000));
const [endedDate, setEndedDate] = useState(new Date(Date.now() + 7*24*60*60*1000));

  const handleStartedDateChange = (date) => {
    handleStartedAtChange(date);
  }
  
  const handleEndedDateChange = (date) => {
    handleEndedAtChange(date);
  }




  return (
    
          <CustomRow>

              <Col xs="3">
              
                <FormControl fullWidth>
                  <MuiPickersUtilsProvider utils={LocalizedUtils} locale={frLocale}>
                    <KeyboardDatePicker
                      ampm={false}
                      name={`element-date_time-started-date`}
                      label={ "Date de début"}
                      value={backupPlan.startedAt}
                      InputProps={{
                        inputProps: { style: { minHeight: "25px" } }
                      }}
                      onChange={handleStartedDateChange}
                      format="dd/MM/yyyy"
                      cancelLabel="annuler"
                      initialFocusedDate={null} />
                  </MuiPickersUtilsProvider>
                </FormControl>
              
               </Col>
              <Col xs="3">
                <FormControl fullWidth>
                  <MuiPickersUtilsProvider utils={LocalizedUtils} locale={frLocale}>
                    <KeyboardDatePicker
                      ampm={false}
                      name={`element-date_time-ended-date`}
                      label={ "Date de fin"}
                      value={backupPlan.endedAt}
                      InputProps={{
                        inputProps: { style: { minHeight: "25px" } }
                      }}
                      onChange={handleEndedDateChange}
                      format="dd/MM/yyyy"
                      cancelLabel="annuler"
                      initialFocusedDate={null} />
                  </MuiPickersUtilsProvider>
                </FormControl>     
              </Col>
              {!backupPlan.isEnabled &&  
              <Col  xs="4">
               <CustomDarkSquareButton className="mb-sm-2" noGutters={true} onClick={handleIsEnabledChange}>
                Activer
                </CustomDarkSquareButton>
              </Col>
              }
               {backupPlan.isEnabled &&  
              <Col  xs="4">
                <CustomDarkSquareButton noGutters={true} onClick={handleIsEnabledChange} >
                Désactiver
                </CustomDarkSquareButton>
              </Col>
               }
              <RightCol xs="2">
                <Status backupPlan={backupPlan} />             
              </RightCol>
            </CustomRow>
  )
}

export default Schedule
