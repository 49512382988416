import React, { useState, useContext, useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import Login from "routes/anonymous/Login"
import Welcome from "routes/anonymous/Login"
import Disconnected from "routes/anonymous/Disconnected"
import LoginMatricule from "routes/anonymous/LoginMatricule"

import Background from "components/common/Background"
import { LOGIN_KERBEROS } from "constants/endpoints"

import api from "services/api"
import AuthenticationContext from "contexts/AuthenticationContext"
import LoaderOverlay from "components/common/LoaderOverlay"

const Anonymous = () => {
  // en fonction de si l'on vient de se déconnecter ou non, on ne souhaite pas forcément se reconnecter avec la session windows de l'utilisateur
  const [loading, setLoading] = useState(true)
  const { setToken } = useContext(AuthenticationContext)
  useEffect(() => {
    if (
      window.location.pathname.toString() === "/disconnected" ||
      window.location.pathname.toString() === "/login-matricule-789"
    ) {
      setLoading(false)
    } else {
      setLoading(true)
      api
        .get(LOGIN_KERBEROS, {anonymous: true})
        .then((data) => {
          const token = data.token
          setToken(token)
        })
        .catch((e) => {
          setLoading(false)
        })
    }
  }, [window.location.pathname.toString()])
  if (loading) {
    return <LoaderOverlay />
  }

  return (
    <Background fluid={true}>
      <Switch>
        <Route exact path="/" component={Welcome} />
        <Route path="/login" component={Login} />
        <Route exact path="/disconnected" component={Disconnected} />
        <Route path="/login-matricule-789" component={LoginMatricule} />
        <Redirect to="/login" />
      </Switch>
    </Background>
  )
}

export default Anonymous
