import React, { useState, useEffect } from "react"
import { Row, Col } from "@bootstrap-styled/v4"
import FormControl from "@material-ui/core/FormControl"
import styled from "styled-components"
import Settings from "components/icons/Settings"
import Popup from "reactjs-popup"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"

import Select from "@material-ui/core/Select"

import Button from "components/common/Button"
import api from "services/api"
import { BASE_URL } from "constants/endpoints"

const SettingsButton = styled(Settings)`
  cursor: pointer;
  font-size: 24px;
`
const PopupDiv = styled.div`
  min-width: 100px;
  min-height: 100px;
  max-height: 600px;
  max-width: 900px;
  text-align: center;
  //overflow-y: scroll;
  //overflow-x: hidden;
  margin: 20px;
`
const Spacer = styled.div`
  min-height: 15px;
`

const ChangeFormStatusPopup = ({
  trigger,
  formId,
  updateOneForm,
  formIndex,
  formStatus,
  ...props
}) => {
  const [selectedStatus, setSelectedStatus] = useState(formStatus)
  const status = []
  status.push(
    <MenuItem value={"published"} key={"published"}>
      Publié
    </MenuItem>
  )
  status.push(
    <MenuItem value={"unpublished"} key={"unpublished"}>
      Dépublié
    </MenuItem>
  )
  const handleSelectedStatusChange = (event) => {
    setSelectedStatus(event.target.value)
  }

  const handleValidateChange = () => {
    if (selectedStatus == "published" || selectedStatus == "unpublished") {
      //put
      api
        .put(`${BASE_URL}/api/forms/${formId}`, { status: selectedStatus })
        .then((data) => {
          updateOneForm(formIndex, formId)
        })
        .catch((e) => alert(e.message))
    }
  }

  return (
    <Popup
      trigger={trigger}
      modal
      closeOnDocumentClick
      contentStyle={{ width: "", borderRadius: "10px", padding: "25px" }}
    >
      {(close) => (
        <PopupDiv>
          <Row>
            <h3>
              Vous vous apprêtez à changer le statut du formulaire. Attention, si vous passez le
              statut sur dépublié, la demande ne sera plus disponible pour les utilisateurs.
            </h3>
          </Row>
          <Row>
            <FormControl componentClass="CustomFormControl" fullWidth={true}>
              <InputLabel id="form-status-select">Type du champ</InputLabel>
              <Select value={selectedStatus} onChange={handleSelectedStatusChange} margin="dense">
                <MenuItem value={selectedStatus}>Selectionnez le statut de la demande</MenuItem>
                {status}
              </Select>
            </FormControl>
          </Row>
          <Row>
            <Spacer />
          </Row>
          <Row className={"justify-content-center align-items-center"}>
            <Col xs="6">
              <Button
                onClick={() => {
                  handleValidateChange()
                  close()
                }}
              >
                Valider
              </Button>
            </Col>
            <Col xs="6">
              <Button onClick={close}>Annuler</Button>
            </Col>
          </Row>
        </PopupDiv>
      )}
    </Popup>
  )
}

export default ChangeFormStatusPopup
