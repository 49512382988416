import React from "react"

const SvgSearch = (props) => (
  <svg width="1em" height="1em" viewBox="-1 -1 22 22" {...props}>
    <path
      d="M19.827 18.98l-4.861-4.86a8.492 8.492 0 002.093-5.592C17.06 3.823 13.231 0 8.53 0 3.824 0 0 3.827 0 8.528c0 4.7 3.828 8.527 8.53 8.527 2.138 0 4.094-.789 5.593-2.093l4.861 4.86c.116.116.27.178.422.178a.6.6 0 00.421-1.02zM1.193 8.528c0-4.045 3.291-7.33 7.332-7.33 4.045 0 7.332 3.29 7.332 7.33s-3.287 7.335-7.332 7.335c-4.04 0-7.332-3.29-7.332-7.335z"
      fill="#2656C8"
      fillRule="nonzero"
      stroke="#2656C8"
      strokeWidth={1.5}
    />
  </svg>
)

export default SvgSearch
