import React from "react"

const SvgCloseFullMenuPicto = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 58 49" {...props}>
    <g transform="translate(-33 -133)" fill="#FFF" fillRule="nonzero">
      <path d="M73.012 146.052H57.89a1.89 1.89 0 000 3.78h15.122a1.89 1.89 0 000-3.78zM73.012 153.613H57.89a1.89 1.89 0 000 3.78h15.122a1.89 1.89 0 000-3.78zM73.012 161.174H57.89a1.89 1.89 0 000 3.78h15.122a1.89 1.89 0 000-3.78zM50.316 164.84a1 1 0 00.6-.923v-8a1 1 0 00-.6-.924.953.953 0 00-1.06.217l-3.888 4c-.19.195-.285.45-.285.707 0 .256.095.512.285.707l3.889 4a.952.952 0 001.06.216z" />
    </g>
  </svg>
)

export default SvgCloseFullMenuPicto
