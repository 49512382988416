import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import React, { useState, useEffect } from "react"
import { Fade, Paper } from "@material-ui/core"
import { styled } from "@material-ui/styles"

const DeleteDone = ({
  deletedStatus,
  handleDeletedStatus,
  alertTitle,
  delDemand,
  ref,
  ...props
}) => {
  const [open, setOpen] = useState(deletedStatus)

  useEffect(() => {
    setOpen(deletedStatus)
  })

  const handleClose = () => {
    setOpen(false)
    handleDeletedStatus(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        TransitionComponent={Fade}
      >
        <DialogTitle id="alert-dialog-slide-title">{"La demande a bien été supprimée"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeleteDone
