import React from "react"

import { Row, Col } from "@bootstrap-styled/v4"
import styled from "styled-components"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import First from "components/icons/First"
import Last from "components/icons/Last"

import Colors from "theme/Colors"

const FirstIcon = styled(First)`
  font-size: 20px;
`
const LastIcon = styled(Last)`
  font-size: 20px;
`

const PagerItem = styled.div`
  background-color: ${(props) => (props.active ? Colors.primaryShadow : Colors.background)};
  cursor: ${(props) => (props.active ? "not-allowed" : "pointer")};
  text-align: center;
  min-width: 25px;
  line-height: 30px;
  border-radius: 2px;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
`

const CustomFormControl = styled(FormControl)`
  margin-right: 15px !important;
`

const Pager = ({
  className,
  page,
  totalItems,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
  ...props
}) => {
  const maxPage = Math.ceil(totalItems / itemsPerPage)

  if (page > maxPage && page !== 1) {
    onPageChange(maxPage)
  }

  const pages = []
  for (let i = page - 4; i <= page + 4; i++) {
    if (i < 1) {
      continue
    }
    if (i > maxPage) {
      continue
    }
    pages.push(i)
  }

  const handleItemsPerPageChange = (event) => {
    onItemsPerPageChange(event.target.value)
  }

  const handlePageChange = (newPage) => () => {
    if (newPage == page) {
      return
    }

    onPageChange(newPage)
  }

  return (
    <Row className={`justify-content-between`}>
      <Col></Col>
      <Col>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Row noGutters={true}>
              {page > 1 && (
                <PagerItem onClick={handlePageChange(1)} active={false}>
                  <FirstIcon />
                </PagerItem>
              )}
              {pages.map((newPage, index) => (
                <PagerItem key={index} onClick={handlePageChange(newPage)} active={newPage == page}>
                  {newPage}
                </PagerItem>
              ))}

              {page < maxPage && (
                <PagerItem onClick={handlePageChange(maxPage)} active={false}>
                  <LastIcon />
                </PagerItem>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row className="justify-content-end">
          <Col xs="auto">
            <CustomFormControl margin="none" size="small">
              <Select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                <MenuItem value={50} key={50}>
                  {50}
                </MenuItem>
                <MenuItem value={100} key={100}>
                  {100}
                </MenuItem>
                <MenuItem value={150} key={150}>
                  {150}
                </MenuItem>
              </Select>
            </CustomFormControl>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Pager
