import React, { useState, useRef, useEffect } from "react"
import TextField from "@material-ui/core/TextField"
import Overlay from "react-bootstrap/Overlay"
import Tooltip from "react-bootstrap/Tooltip"

const FormTextField = ({ onChange, ...props }) => {
  const [displayAlertMessage, setDisplayAlertMessage] = useState(false)
  const target = useRef(null)
  const handleChange = (event) => {
    if (event.target.value.length <= 255) {
      onChange(event)
    } else {
      setDisplayAlertMessage(true)
      new Promise((resolve) => {
        setTimeout(() => {
          setDisplayAlertMessage(false)
          resolve("resolved")
        }, 1500)
      })
    }
  }

  return (
    <>
      <TextField {...props} onChange={handleChange} ref={target} />{" "}
      <Overlay target={target.current} show={displayAlertMessage} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            <b>Attention, le texte ne doit pas excéder 255 caractères</b>
          </Tooltip>
        )}
      </Overlay>{" "}
    </>
  )
}

export default FormTextField
