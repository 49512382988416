import React from "react"

const SvgDownload = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 24" {...props}>
    <g fill="#4A4A4A" fillRule="nonzero">
      <path d="M9.747 17.704a.357.357 0 01-.357-.357V9.291a.357.357 0 01.714 0v8.056c0 .197-.16.357-.357.357z" />
      <path d="M9.747 17.704a.359.359 0 01-.253-.104l-3.08-3.08a.357.357 0 01.505-.506l2.827 2.828 2.828-2.828a.357.357 0 01.505.505L10 17.6a.353.353 0 01-.252.104z" />
      <g>
        <path d="M17.556 23.78H1.939c-.984 0-1.785-.8-1.785-1.785V1.785C.154.802.955 0 1.939 0H13.28a.357.357 0 010 .714H1.94c-.591 0-1.072.481-1.072 1.072v20.209c0 .59.48 1.071 1.071 1.071h15.617c.59 0 1.071-.48 1.071-1.071V6.217a.357.357 0 01.714 0v15.779c0 .984-.8 1.785-1.785 1.785z" />
        <path d="M18.984 6.574H13.28a.357.357 0 01-.357-.357V.512a.357.357 0 01.714 0V5.86h5.347a.357.357 0 010 .715z" />
        <path d="M18.984 6.574a.358.358 0 01-.255-.108L13.024.607a.357.357 0 01.512-.498l5.705 5.86a.357.357 0 01-.257.605z" />
      </g>
    </g>
  </svg>
)

export default SvgDownload
