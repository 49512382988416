import React, { useState } from "react"

import { Row, Col } from "@bootstrap-styled/v4"

import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"
import ChangeLabel from "components/adminCreateForm/fieldsParameters/commonParameters/ChangeLabel"
import Text from "components/common/Text"
import styled from "styled-components"
import Colors from "theme/Colors"

const ErrorMessage = styled(Text)`
  color: ${Colors.red};
`

const TableParameters = ({ saveParameters, fieldParameters, label, fieldWidth, ...props }) => {
  const [minWidth, setMinWidth] = useState(fieldParameters.minWidth || 900)
  const [invalidWidth, setDisplayInvalidWidth] = useState(false)

  const handleMinWidthChange = (e) => {
    setMinWidth(parseInt(e.target.value))
  }

  const validateChangement = () => {
    if (!minWidth) {
      setDisplayInvalidWidth(true)
    }
    saveParameters({ type: "table_question", questions: [], minWidth: minWidth })
  }
  return (
    <>
      <Row className={"justify-content-center"}>
        <Col xs="12">
          <ChangeLabel
            onLabelChange={handleMinWidthChange}
            inputLabel={"Largeur minimale du tableau (en px)"}
            label={minWidth}
          />
        </Col>
      </Row>
      {invalidWidth && (
        <Row className="justify-content-center">
          <ErrorMessage>Attention votre largeur minimale est invalide</ErrorMessage>
        </Row>
      )}
      <Row className={"justify-content-center"}>
        <OkButton onClick={validateChangement}>Valider</OkButton>
      </Row>
    </>
  )
}

export default TableParameters
