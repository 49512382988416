import React, { useState, useEffect } from "react"
import { EditorState, convertFromRaw } from "draft-js"
import api from "services/api"
import "abort-controller/polyfill"
import { GET_FORM_ENDPOINT, GET_BUSINESS_UNITS_ENDPOINT, BASE_URL } from "constants/endpoints"
import { extractQuestions } from "functions/extractQuestions"

export const CreateFormContext = React.createContext({})

const form = {
  questions: [],
  steps: [],
  title: "",
  abreviation: "",
  category: null,
  weight: 1,
  maillingList: "",
  previsousForm: "",
}

export const CreateFormContextProvider = ({ children, id, ...props }) => {
  const [questions, setQuestions] = useState([])
  const [steps, setSteps] = useState([
    {
      type: "initiator",
      notifyOnClosed: false,
      notifyToApprove: false,
      weight: 0,
      editable: false,
      label: "Demandeur",
    },
  ])
  const [notice, setNotice] = useState(EditorState.createEmpty())

  const [title, setTitle] = useState("")
  const [abreviation, setAbreviation] = useState("")
  const [category, setCategory] = useState(null)
  const [previousForm, setPreviousForm] = useState("")
  const [weight, setWeight] = useState(null)
  const [maillingList, setMaillingList] = useState("")
  const [newFormId, setNewFormId] = useState(null)
  const [notification, setNotification] = useState([])
  const [bu, setBu] = useState([])
  const [titleInputLabel, setTitleInputLabel] = useState(null)
  const [
    allFormsOfTheCategoryAndTheirNewWeight,
    setAllFormsOfTheCategoryAndTheirNewWeight,
  ] = useState([])
  const [version, setVersion] = useState(null)
  const [validationUnitaire, setValidationUnitaire] = useState(false)
  const [isBackupAllowed, setIsBackupAllowed] = useState(true)
  const [allPreviousQuestions, setAllPreviousQuestions] = useState(null)

  useEffect(() => {
    const AbortController = window.AbortController
    const abortController = new AbortController()
    const signal = abortController.signal
    api
      .get(GET_BUSINESS_UNITS_ENDPOINT)
      .then((data) => {
        if (data) {
          setBu(data["hydra:member"])
        }
      })
      .catch((e) => alert(e.message))
    if (id) {
      api
        .get(GET_FORM_ENDPOINT(id))
        .then((data) => {
          form.questions = data.questions
          setQuestions(data.questions)
          form.steps = data.steps
          setSteps(data.steps)
          form.title = data.title
          setTitle(data.title)
          form.isBackupAllowed = data.isBackupAllowed
          setIsBackupAllowed(data.isBackupAllowed)
          form.abreviation = data.abreviation
          setAbreviation(data.abreviation)
          form.category = data.category
          setCategory(data.category)
          form.previousForm = data.previousForm
          setPreviousForm(data.previousForm ? data.previousForm["@id"] : null)
          form.weight = data.weight
          setWeight(data.weight)
          form.maillingList = data.maillingList
          setMaillingList(data.maillingList)
          form.notification = data.notifications
          setNotification(data.notifications)
          form.notice = data.notice
          // si stocké en json
          const savedData = JSON.parse(data.notice)
          // si stocké en html et on essaye de le repasser en json
          // const json = parse(data.notice)
          setNotice(
            // qd stocké en json :
            EditorState.createWithContent(convertFromRaw(savedData))
            //en dessous c l'original :
            //ContentState.createFromBlockArray(convertFromHTML(data.notice))
          )
          form.titleInputLabel = data.titleInputLabel
          setTitleInputLabel(data.titleInputLabel)
          setVersion(data.version)
          return data.notifications
        })
        .then(() => {
          form.notification.map((notif) => {
            api
              .get(`${BASE_URL}${notif.profile}`)
              .then((data) => {
                notif.profile = {
                  "@id": data["@id"],
                  firstname: data.firstname,
                  lastname: data.lastname,
                }
              })
              .catch((e) => alert(e.message))
          })
        })
        .catch((e) => alert(e.message))
      // TODO: récupérer les notifications aussi
      return function cleanUp() {
        abortController.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (!previousForm) {
      return
    }
    api.get(`${BASE_URL}${previousForm}`).then((data) => {
      setAllPreviousQuestions(extractQuestions(data.questions))
    })
  }, [previousForm])

  return (
    <CreateFormContext.Provider
      value={{
        questions,
        setQuestions,
        steps,
        setSteps,
        notice,
        setNotice,
        title,
        setTitle,
        isBackupAllowed,
        setIsBackupAllowed,
        abreviation,
        setAbreviation,
        category,
        setCategory,
        previousForm,
        setPreviousForm,
        weight,
        setWeight,
        maillingList,
        setMaillingList,
        newFormId,
        setNewFormId,
        notification,
        setNotification,
        bu,
        setBu,
        titleInputLabel,
        setTitleInputLabel,
        allFormsOfTheCategoryAndTheirNewWeight,
        setAllFormsOfTheCategoryAndTheirNewWeight,
        version,
        validationUnitaire,
        setValidationUnitaire,
        allPreviousQuestions,
      }}
    >
      {children}
    </CreateFormContext.Provider>
  )
}
