import { useHistory } from "react-router-dom"
import { CreateFormContext } from "contexts/CreateFormContext"
import React, { useContext } from "react"
import styled from "styled-components"

const CancelLinkFormCreation = ({
  onClick,
  className,
  children,
  viewDemand,
  isView,
  setSearchValues,
  ...props
}) => {
  const {
    setQuestions,
    setSteps,
    setNotice,
    setTitle,
    setAbreviation,
    setCategory,
    setWeight,
    setMaillingList,
  } = useContext(CreateFormContext)
  let history = useHistory()

  const handleClick = () => {
    setQuestions([])
    setCategory(null)
    setTitle("")
    setNotice("")
    setAbreviation("")
    setSteps([])
    setWeight(null)
    setMaillingList("")
    history.push("/")
  }

  if (!children) {
    children = "Annuler"
  }

  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  )
}

export default styled(CancelLinkFormCreation)`
  cursor: pointer;
`
