import React, { useState } from "react"

import styled from "styled-components"
import Colors from "theme/Colors"

import Container from "components/common/Container"
import SquareButton from "components/common/SquareButton"
import Plus from "components/icons/Plus"
import Comment from "components/editDemande/Comment"

import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"

import Delete from "components/icons/Delete"
import CustomTip from "components/common/ToolTip"

const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`

const AddCommentButtonContainer = styled.div`
  width: 215px;
  margin-top: 10px;
`

const AddCommentButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 50px;
`
const Spacer = styled.span`
  height: 1px;
  width: 5px;
`
const ContainerTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 20px;
`
const CommentTitle = styled.p`
  color: ${Colors.primaryVeryDark};
  font-size: 18px;
  font-weight: 300;
`

const Comments = ({ className, comments, addComment, readOnly, commentInitialValue, ...props }) => {
  const [comment, setComment] = useState(commentInitialValue ? commentInitialValue : "")

  const [displayCommentField, setDisplayCommentField] = useState(
    commentInitialValue && commentInitialValue !== "" ? true : false
  )

  const handleDisplayCommentField = () => {
    setDisplayCommentField(true)
  }

  const handleCommentChange = (event) => {
    setComment(event.target.value)
    generateComment(event.target.value)
  }

  const handleDelete = () => {
    setComment("")
    setDisplayCommentField(false)
    generateComment("")
  }

  const generateComment = (comment) => {
    const commentToCreate = {}
    if (comment && comment.length > 0) {
      commentToCreate.content = comment
    }

    addComment(commentToCreate)
  }

  return (
    <Container className={className}>
      <ContainerTitle className="pl-3 pb-0">Commentaires</ContainerTitle>
      {comments.length > 0 && comments.map((item, key) => <Comment comment={item} key={key} />)}
      {!readOnly && (
        <>
          {displayCommentField ? (
            <>
              <CommentTitle className="pl-3">
                Votre commentaire{" "}
                <DeleteButton onClick={handleDelete} data-tip data-for="deleteComment" />
                <CustomTip id="deleteComment" place="bottom" effect="solid">
                  <span>supprimer</span>
                </CustomTip>
              </CommentTitle>
              <FormControl className="pl-3" fullWidth={true} margin="none">
                <TextField
                  multiline
                  id={`comment-text-area-el`}
                  label="Ajouter un commentaire"
                  rows="4"
                  value={comment}
                  onChange={handleCommentChange}
                  className="h-0 pl-0 pr-3"
                />
              </FormControl>
            </>
          ) : (
            <AddCommentButtonContainer className="ml-4 mb-4">
              <AddCommentButton onClick={handleDisplayCommentField} noGutters={true}>
                <Plus />
                <Spacer />
                Ajouter un commentaire
              </AddCommentButton>
            </AddCommentButtonContainer>
          )}
        </>
      )}
    </Container>
  )
}

export default styled(Comments)`
  padding: 20px;
  margin: 2px 70px 0px;
`
