import React, { useState, useEffect } from "react"

import styled from "styled-components"
import FormControl from "@material-ui/core/FormControl"

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import frLocale from "date-fns/locale/fr"
import format from "date-fns/format"

import ElementContainer from "components/form/element/ElementContainer"

import Colors from "theme/Colors"

import moment from "moment"

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "d MMM yyyy", { locale: this.locale })
  }
}
const DateTime = ({
  fullWidth,
  className,
  noLabel,
  element,
  editable,
  onChange,
  value,
  utils,
  noResponsive,
  minWidth,
  matchingResponse,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(
    value && value.value ? moment(value.value) : null
  )
  const [alreadyIntializeMandatoryResponse, setAlreadyIntializeMandatoryResponse] = useState(false)

  useEffect(() => {
    if (element && element.isMandatory && !alreadyIntializeMandatoryResponse && !value) {
      onChange({
        ...value,
        type: "date_time_value",
        isDate: true,
        question: element["@id"],
        value: null,
      })
      setAlreadyIntializeMandatoryResponse(true)
    }
  }, [element])

  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    const initialisedValue = matchingResponse[0].value
    const formattedDate = initialisedValue ? moment(initialisedValue).format("YYYY-MM-DD") : null
    onChange({
      ...value,
      type: "date_time_value",
      isDate: true,
      value: formattedDate,
      question: element["@id"],
    })
    setSelectedDate(initialisedValue)
  }, [matchingResponse])

  const handleChange = (date) => {
    const formattedDate = date ? moment(date).format("YYYY-MM-DD") : null
    if (!editable) {
      return
    }
    // if (date) {
    onChange({
      ...value,
      type: "date_time_value",
      isDate: true,
      question: element["@id"],
      value: formattedDate,
    })
    // }

    setSelectedDate(date)
  }

  return (
    <ElementContainer
      width={fullWidth ? 100 : element.width}
      minWidth={minWidth}
      noResponsive={noResponsive}
    >
      <FormControl fullWidth={true} margin="none">
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={frLocale}>
          <KeyboardDatePicker
            ampm={false}
            name={`element-date_time-${element.id}`}
            value={selectedDate}
            label={noLabel ? "" : element.title}
            InputProps={{
              inputProps: { style: { minHeight: "25px" } },
              className,
              ...(editable ? {} : { disabled: true }),
            }}
            InputLabelProps={
              element.isMandatory && {
                style: { fontWeight: "bold" },
              }
            }
            onChange={handleChange}
            format="dd/MM/yyyy"
            cancelLabel="annuler"
            initialFocusedDate={null}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </ElementContainer>
  )
}

export default styled(DateTime)`
  background-color: transparent;
  min-height: 32px;
  width: 100%;
  color: ${Colors.black};
  font-size: 13px;
  &.MuiInputBase-root {
    font-family: "Open Sans";
  }
`
