import React, { useState, useContext } from "react"

import ValidatorAdmin from "components/adminCreateForm/settingWorkFlow/ValidatorAdmin"
import AddValidatorAdmin from "components/adminCreateForm/settingWorkFlow/AddValidatorAdmin"
import { CreateFormContext } from "contexts/CreateFormContext"
import Container from "components/common/Container"
import styled from "styled-components"
import ContributorNotified from "components/adminCreateForm/ContributorNotified"
import AddNotifiedContributor from "./AddNotifiedContributor"

const CustomContainer = styled(Container)`
  padding: 30px;
`

const Contributor = ({ className, ...props }) => {
  const { notification, setNotification, bu } = useContext(CreateFormContext)

  const handleDelete = (position) => {
    const copy = notification.slice()
    copy.splice(position, 1) //, validator)
    setNotification([...copy])
  }

  const handleAdd = (contributor) => {
    notification.splice(notification.length, 0, contributor)
    setNotification([...notification])
  }
  return (
    <CustomContainer className={className}>
      {notification.map((element, key) => {
        if (!element) {
          return null
        }

        return (
          <ContributorNotified
            contributor={element}
            key={key}
            index={key}
            onDelete={handleDelete}
          />
        )
      })}

      <AddNotifiedContributor addContributor={handleAdd} bu={bu} />
    </CustomContainer>
  )
}

export default Contributor
