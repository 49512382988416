import React from "react"
import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"
import { Row, Col } from "@bootstrap-styled/v4"

const NumericParameters = ({ saveParameters, fieldParameters, label, fieldWidth, ...props }) => {
  const validateChangement = () => {
    saveParameters({ type: "numeric_question" })
  }
  return (
    <Row className={"justify-content-center"}>
      <OkButton onClick={validateChangement}>Valider</OkButton>
    </Row>
  )
}

export default NumericParameters
