import React from "react"

const SvgSuccess = ({ primary, secondary, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 50 50" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill={primary || "#60B97F"} cx={25} cy={25} r={25} />
      <path
        stroke={secondary || "#FFF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M38 15L22 33l-10-8"
      />
    </g>
  </svg>
)

export default SvgSuccess
