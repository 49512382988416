import React from "react"
import { Row, Col } from "@bootstrap-styled/v4"
import FormElement from "components/form/FormElement"
import Delete from "components/icons/Delete"
import styled from "styled-components"
import ElementContainer from "components/form/element/ElementContainer"

const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`
const TableLine = ({
  questions,
  onChange,
  lineAnswers,
  line,
  tableMinWidth,
  isDeletable,
  onDelete,
  editable,
  lineLabel,
  labelColumnWidth,
  sumCallBack,
  sums,
  ...props
}) => {
  const findAnswer = (questionIri) => {
    return lineAnswers.responses.findIndex((answer) => answer.question == questionIri)
  }

  const handleChange = (response) => {
    const questionIri = response.question
    const result = findAnswer(questionIri)
    const answerIndex = result >= 0 ? result : null
    if (answerIndex !== null) {
      lineAnswers.responses[answerIndex] = response
    } else {
      lineAnswers.responses.push(response)
    }

    onChange(lineAnswers, line)
  }

  const calculateElementMinWidth = (elementWidth) => {
    return (elementWidth / 100) * tableMinWidth
  }

  return (
    <Row
      minWidth={tableMinWidth}
      className="justify-content-start align-items-center"
      noGutters={true}
    >
      {lineLabel && (
        <ElementContainer
          width={labelColumnWidth}
          noResponsive={true}
          minWidth={calculateElementMinWidth(labelColumnWidth)}
          style={{ marginBottom: "-15px" }}
        >
          {lineLabel}
        </ElementContainer>
      )}
      {questions.map((question, index) => {
        const result = findAnswer(question["@id"])
        const indexOfTheAnswer = result >= 0 ? result : null
        return (
          <FormElement
            key={question.id}
            noLabel={line !== 0}
            // fullWidth
            element={question}
            minWidth={calculateElementMinWidth(question.width)}
            editable={editable}
            {...props}
            width={100}
            value={indexOfTheAnswer !== null && lineAnswers.responses[indexOfTheAnswer]}
            onChange={handleChange}
            className="form element"
            sumCallBack={sumCallBack}
            sums={sums}
            noResponsive
          />
        )
      })}
      {isDeletable && editable && (
        <Col xs="auto">
          <DeleteButton
            onClick={() => {
              onDelete(line)
            }}
          />
        </Col>
      )}
    </Row>
  )
}

export default TableLine
