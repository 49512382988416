import styled from "styled-components"
import Colors from "theme/Colors"

const Container = styled.div`
  background-color: ${Colors.white};
  border-radius: 8px;
  background-color: #fffefe;
  box-shadow: -1px -1px 10px 0 rgba(187, 187, 187, 0.5), 1px 1px 10px 0 rgba(187, 187, 187, 0.5);

  @media screen and (max-width: 1200px) {
    margin: 30px 0px 0px 0px !important;
  }
`
export default Container
