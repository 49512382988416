import React, { useState, useContext } from "react"
import styled from "styled-components"

import AuthenticationContext from "contexts/AuthenticationContext"

import { Row, Col } from "@bootstrap-styled/v4"

import ElContainer from "components/common/Container"
import Image from "components/common/Image"
import Text from "components/common/Text"
import TextInput from "components/common/TextInput"
import Button from "components/common/Button"

import Images from "theme/Images"
//import Colors from "theme/Colors"

import api from "services/api"
import { BASE_URL } from "constants/endpoints"

import { useHistory } from "react-router-dom"

const Logo = styled(Image)`
  width: 260px;
  margin-bottom: 36px;
`
const LoginMatriculeRowContainer = styled(Row)`
  min-height: 100vh;
`

const LoginMatriculeContainer = styled(ElContainer)`
  max-width: 600px;
  width: 100%;
  padding: 50px !important;
`

const CustomText = styled(Text)`
  text-align: center;
`

const ChangeUser = () => {
  const [login, setLogin] = useState("")
  const [loading, setLoading] = useState(false)
  const { setToken } = useContext(AuthenticationContext)

  let history = useHistory()

  const formValues = {
    login: {
      value: login,
      setter: setLogin,
    },
  }

  const handleChange = (name, value) => {
    formValues[name].setter(value)
  }

  const handleClick = (e) => {
    e.preventDefault()
    setLoading(true)
    api
      .post(`${BASE_URL}/api/admin_login_matricules`, { matricule: parseInt(login) })
      .then((data) => {
        const token = data.token
        setToken(token)
        history.push("/")
      })
      .catch((e) => alert("Veuillez vérifier le matricule"))
  }

  return (
    <LoginMatriculeRowContainer className={"align-items-center justify-content-center"}>
      <LoginMatriculeContainer>
        <Row className={"align-items-center justify-content-center"}>
          <Col xs="12">
            <center>
              <Logo src={Images.logo_groupe} />
            </center>
          </Col>
          <CustomText>
            Renseignez votre identifiant
            <br />
            pour vous connecter
          </CustomText>
          <TextInput
            placeholder="saisissez votre identifiant"
            onChange={handleChange}
            name="login"
          />
          <Button onClick={handleClick} loading={loading}>
            Connexion
          </Button>
        </Row>
      </LoginMatriculeContainer>
    </LoginMatriculeRowContainer>
  )
}

export default ChangeUser
