import React from "react"
import styled from "styled-components"

import Colors from "theme/Colors"

const Initials = ({ className, firstname, lastname }) => {
  return (
    <div className={className}>
      <span>{firstname.charAt(0)}</span>
      <span>{lastname.charAt(0)}</span>
    </div>
  )
}

export default styled(Initials)`
  display: flex;
  height: ${(props) => (props.size ? props.size : "52")}px;
  width: ${(props) => (props.size ? props.size : "52")}px;
  border: 2px solid ${Colors.primary};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 48px;
  background-color: ${Colors.background};
  color: ${Colors.primary};
  font-weight: 900;
  font-size: ${(props) => (props.size ? props.size / 2 : "26")}px;
  &:hover {
    text-decoration: none;
  }
  @media screen and (max-width: 366px) {
    height: ${(props) => (props.size ? props.size : "35")}px;
    width: ${(props) => (props.size ? props.size : "35")}px;
    font-size: ${(props) => (props.size ? props.size / 2 : "15")}px;
  }
`
