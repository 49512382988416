import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { Row } from "@bootstrap-styled/v4"

import Profils from "components/icons/Profils"

const CustomRow = styled(Row)`
  min-height: 56px;
`

const ProfilsPicto = styled(Profils)`
  font-size: 35px;
  margin-right: 20px;
`

const InitiatorDisplay = ({ className, validator, onChange, ...props }) => {
  return (
    <div className={className}>
      <CustomRow noGutters={true} className="align-items-center">
        <ProfilsPicto />
        <span>NOM DU DEMANDEUR</span>
      </CustomRow>
    </div>
  )
}

export default styled(InitiatorDisplay)`
  text-align: left;
  min-width: 250px;
`
