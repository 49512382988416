import React, { useState, useEffect } from "react"

import styled from "styled-components"
import Container from "components/common/Container"

import { Row, Col } from "@bootstrap-styled/v4"

import AddSomethingButton from "components/common/AddSomethingButton"
import Field from "components/adminCreateForm/newBlock/Field"
import Delete from "components/icons/Delete"
import ChangeLabel from "components/adminCreateForm/fieldsParameters/commonParameters/ChangeLabel"
import Pen from "components/icons/Pen"
import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"
import { createAnId } from "components/adminCreateForm/OptionalBlocks.js"
import { resetWeight } from "components/adminCreateForm/functions/resetWeight.js"
import FieldSettings from "components/adminCreateForm/newBlock/FieldSettings"

const CustomContainer = styled(Container)`
  padding: 30px;
  margin: 30px;
`
const TitleFont = styled.div`
  color: #24445c;
  titlefont-family: "Open Sans";
  titlefont-size: 18px;
  titlefont-weight: 600;
`
const DeleteButton = styled(Delete)`
  cursor: pointer;
  font-size: 24px;
`
const EditIcon = styled(Pen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`

const AddSomethingButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`
const ButtonDiv = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-left: 15px;
  // max-height: 80px;
`
const TitleDiv = styled.div`
  padding-left: 15px;
  margin-right: 15px;
`
const OuterContainer = styled.div``

const NewBlockContainer = ({
  className,
  onDelete,
  blockIndex,
  key,
  field,
  deepContainer,
  parentType,
  othersClass,
  ...props
}) => {
  const [questions, setQuestions] = useState(field.questions)
  const [title, setTitle] = useState(field.title || "")
  const [editTitleMode, setEditTitleMode] = useState(false)
  const [leftWidth, setLeftWidth] = useState(null)
  const [containerWidth, setContainerWidth] = useState(field.width || 100)

  useEffect(() => {
    if (field.type === "container") {
      return
    }
    setLeftWidth(calculateLeftWidth())
  }, [questions])

  const addQuestion = () => {
    const weightOfTheNewElement = questions.length
    const id = createAnId()
    field.questions.push({
      type: "text_input",
      weight: weightOfTheNewElement,
      key: id,
    })
    setQuestions([...field.questions])
  }

  const addEmbeddedBlock = () => {
    const id = createAnId()
    const weightOfTheNewElement = questions.length
    field.questions.push({
      type: "container",
      title: "",
      description: "",
      questions: [],
      key: id,
      weight: weightOfTheNewElement,
      width: 100,
    })
    setQuestions([...field.questions])
  }

  const handleDeleteField = (position) => {
    let copyOfTheArray = field.questions.slice()
    copyOfTheArray.splice(position, 1)
    resetWeight(position, copyOfTheArray.length, copyOfTheArray)
    field.questions = copyOfTheArray
    setQuestions([...field.questions])
  }
  const handleFieldLabelChange = (newLabel, position) => {
    field.questions[position].title = newLabel
    setQuestions([...field.questions])
  }

  const saveParameters = (question, position) => {
    if (position != undefined) {
      question.weight = position
      field.questions.splice(position, 1, question)
      setQuestions([...field.questions])
    } else {
      field.title = question.title
      field.width = question.width
      field.minWidth = question.minWidth
      if (field.labelColumnWidth) {
        field.labelColumnWidth = question.labelColumnWidth
      }
      if (field.linesLabel) {
        field.linesLabel = question.linesLabel
      }
      setTitle(question.title)
      setContainerWidth(question.width)
    }
  }

  const handleDeleteHimself = () => {
    if (field.id) {
      onDelete(blockIndex)
    } else {
      onDelete(blockIndex)
    }
  }

  const handleEditTitle = () => {
    setEditTitleMode(true)
  }

  const handleTitleChange = (event) => {
    field.title = event.target.value || ""
    setTitle(event.target.value || "")
  }

  const handleValidateTitle = () => {
    setEditTitleMode(false)
  }

  // label columnWidth, param optionnel. C'est la largeur de la colonne contenant le label d'une ligne (utile pour un tableau de question à nb de lignes fixes)
  const calculateLeftWidth = () => {
    var totalQuestionWidth = 0
    if (field.labelColumnWidth) {
      totalQuestionWidth += parseInt(field.labelColumnWidth)
    }
    questions.map((question) => {
      if (question.width) {
        totalQuestionWidth += parseInt(question.width)
      } else {
        totalQuestionWidth += 100
      }
    })
    return 100 - parseInt(totalQuestionWidth)
  }

  const getBlocType = () => {
    if (field.type === "container") {
      return "Container"
    }
    if (field.type === "table_question") {
      return "Tableau de questions"
    }
    if (field.type === "fixed_table_question") {
      return "Tableau de questions à nb de lignes fixes"
    }
  }

  return (
    <OuterContainer style={{ width: "" + containerWidth + "%" }}>
      <CustomContainer className={className + ` ${othersClass}`}>
        <TitleDiv>
          <Row className="align-items-top">
            <Col>
              {!editTitleMode ? (
                <Row className="align-items-center">
                  <Col xs="auto">
                    <h3>
                      <TitleFont>
                        {title != "" ? title : "Nom du bloc"} <br />
                        {leftWidth !== null && (
                          <>
                            (Place restante : {leftWidth}%)
                            <br />
                          </>
                        )}
                        Type : {getBlocType()}
                      </TitleFont>
                    </h3>
                  </Col>
                  <Col xs="auto">
                    {field.type === "container" ? (
                      <EditIcon onClick={handleEditTitle} />
                    ) : (
                      <FieldSettings
                        saveParameters={saveParameters}
                        label={field.label}
                        fieldParameters={field}
                        closePopup={() => {
                          window.close()
                        }}
                        parentType={parentType}
                      />
                    )}
                  </Col>
                </Row>
              ) : (
                <Row className="align-items-end">
                  <Col xs="auto">
                    <ChangeLabel
                      onLabelChange={handleTitleChange}
                      label={title}
                      inputLabel={"Titre du container"}
                    />
                  </Col>
                  <Col xs="3">
                    <OkButton onClick={handleValidateTitle}>OK</OkButton>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs="auto">
              <DeleteButton onClick={handleDeleteHimself} />
            </Col>
          </Row>{" "}
        </TitleDiv>
        <Row noGutters={true}>
          <Col>
            <Row className={"w-100"} noGutters={true}>
              {questions.length > 0 &&
                questions.map((question, index) => {
                  return (
                    <Field
                      onDelete={handleDeleteField}
                      field={question}
                      position={index}
                      saveParameters={saveParameters}
                      onLabelChange={handleFieldLabelChange}
                      key={question.id}
                      deepContainer={othersClass ? true : false}
                      othersClass={"embeddedContainer"}
                      parentType={field.type}
                    />
                  )
                })}
            </Row>
          </Col>
        </Row>
        <ButtonDiv>
          <Row className={"align-items-center"}>
            {field.type === "container" && (
              <Col xs="6" xl="3">
                <AddSomethingButton
                  title={"Groupement de champs"}
                  onClick={addEmbeddedBlock}
                  noGutters={true}
                />
              </Col>
            )}
            <Col xs="6" xl="3">
              <AddSomethingButton title={"Champ Seul"} onClick={addQuestion} noGutters={true} />
            </Col>
          </Row>
        </ButtonDiv>
      </CustomContainer>
    </OuterContainer>
  )
}

export default styled(NewBlockContainer)`
  margin: 30px 70px 0;
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
    text-align: center;
  }
  .embeddedContainer {
    border-style: dashed;
    border-color: red;
    border-width: 1px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    width: 100%;
  }

  .deepContainer {
    border-left-width: 0px;
    border-right-width: 0px;
  }

  .MuiTextField-root {
    margin-top: 5px;
  }
`
