import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Colors from "theme/Colors"
import { Table, Thead, Tr, Th, Tbody, Td, Row, Col } from "@bootstrap-styled/v4"

import Status from "components/demandesList/Status"

import Show from "components/icons/Show"

import DuplicatePicto from "components/common/DuplicateButton"
import LoaderOverlay from "components/common/LoaderOverlay"
// import CustomTip from "components/common/ToolTip"
import Pen from "components/icons/Pen"
import api from "services/api"
import { GET_CATEGORIES_ENDPOINT } from "constants/endpoints"
import ChangeFormStatusPopup from "components/form/ChangeFormStatusPopup"

const CustomTable = styled(Table)`
  // width: 100%;
`

const TableHead = styled(Th)`
  font-size: 13px;
  font-weight: 600;
  border-top: none !important;
  vertical-align: middle !important;
  border-color: ${Colors.background} !important;
  border-bottom-width: 1px !important;
`

const TableContent = styled(Td)`
  font-size: 13px;
  border-top: none !important;
  vertical-align: middle !important;
`

const ShowPicto = styled(Show)`
  font-size: 30px;
`
const CustomStatus = styled(Status)`
  &:hover {
    cursor: pointer;
  }
`
const EditIcon = styled(Pen)`
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
`
const StyledDuplicatePicto = styled(DuplicatePicto)`
  margin-left: 20px;
`

const DisplayFormList = ({
  forms,
  loading,
  updateOneForm,
  handleDuplicate
}) => {
  const [categoriesLabel, setCategoriesLabel] = useState([])
  useEffect(() => {
    api
      .get(GET_CATEGORIES_ENDPOINT)
      .then((categories) => {
        if (categories) {
          const newCategoriesLabel = []
          categories["hydra:member"].map((data) => {
            newCategoriesLabel.push({ id: data["@id"], label: data.label })
          })
          setCategoriesLabel(newCategoriesLabel)
        }
      })
      .catch((e) => {
        alert(e.message)
      })
  }, [])

  const getCategoryLabel = (category) => {
    if (categoriesLabel.length > 0) {
      const found = categoriesLabel.find((element) => element.id === category)
      return found.label
    }
    return null
  }


  if (loading) {
    return <LoaderOverlay />
  }
  return (
    <CustomTable>
      <Thead>
        <Tr>
          <TableHead className="ml-2">DATE DE CRÉATION</TableHead>
          <TableHead>CATEGORIE</TableHead>
          <TableHead>TITRE DU FORMULAIRE</TableHead>
          <TableHead>VERSION</TableHead>
          <TableHead>STATUT</TableHead>
          <TableHead>ACTIONS</TableHead>
        </Tr>
      </Thead>
      <Tbody>
        {forms.map((form, index) => {
          if (!form) {
            return null
          }
          return (
            <Tr key={form.id}>
              <TableContent>{form.createdAt && form.createdAt.substring(0, 10)}</TableContent>
              <TableContent>{form.category && getCategoryLabel(form.category)}</TableContent>
              <TableContent>{form.title}</TableContent>
              <TableContent>{form.version}</TableContent>
              <TableContent>
                <ChangeFormStatusPopup
                  trigger={<CustomStatus status={form.status} />}
                  formStatus={form.status}
                  formId={form.id}
                  updateOneForm={updateOneForm}
                  formIndex={index}
                />
              </TableContent>
              <TableContent>
                <Row className="justify-content-start align-items-center" noGutters={true}>
                  <Col xs="auto">
                    <Link to={`/formulaires/${form.id}`}>
                      <ShowPicto data-tip="ouvrir" data-for="demande_icon" />
                    </Link>
                  </Col>
                  <Col xs="auto">
                    <Link to={`/edition/${form.id}`}>
                      <EditIcon data-tip="edit" data-for="demande_icon" />
                    </Link>
                  </Col>
                  <Col xs="auto">
                    <StyledDuplicatePicto
                      onClick={() => {
                        handleDuplicate(form.id)
                      }}
                    />
                  </Col>
                </Row>
              </TableContent>
            </Tr>
          )
        })}
      </Tbody>
    </CustomTable>
  )
}

export default DisplayFormList
