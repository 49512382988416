import React, { useState, useEffect, useContext } from "react"
import UserContext from "contexts/UserContext"
import api from "services/api"
import { useHistory, useParams } from "react-router-dom"

import HeaderForm from "components/form/HeaderForm"
import FormElement from "components/form/FormElement"
import styled from "styled-components"
import { GET_FORM_ENDPOINT, BASE_URL } from "constants/endpoints"

const CustomDiv = styled.div`
  margin-bottom: 25px;
  margin-top: 25px;
`

const ViewFormAdmin = ({ className, ...props }) => {
  const { id } = useParams()
  const { profile } = useContext(UserContext)
  const [formDetails, setFormDetails] = useState(null)
  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal
    if (!profile) {
      return
    }
    api
      .get(GET_FORM_ENDPOINT(id))
      .then((data) => {
        setFormDetails({ ...data })
      })
      .catch((e) => {
        alert("erreur : " + e.message)
      })
    if (!formDetails) {
      return
    }

    return function cleanUp() {
      abortController.abort()
    }
  }, [profile])

  return (
    <CustomDiv className={className}>
      <HeaderForm
        picture={null}
        formTitle={formDetails && formDetails.title}
        formNumber={null}
        formNotice={formDetails && formDetails.notice}
      />

      {formDetails &&
        formDetails.questions.map((element) => (
          <FormElement
            element={element}
            key={element.id}
            onChange={() => {}}
            hideInputs={false}
            value={null}
          />
        ))}
    </CustomDiv>
  )
}

export default ViewFormAdmin
