import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"

import DemandeCreationContext from "contexts/DemandeCreationContext"
import UserContext from "contexts/UserContext"

import { POST_DEMANDES_ENDPOINT } from "constants/endpoints"

import PageTop from "components/layout/PageTop"
import LoaderOverlay from "components/common/LoaderOverlay"
import ProgressionViewer from "components/newDemande/ProgressionViewer"
import FormToFill from "components/newDemande/FormToFill"
import ValidationFlowReview from "components/newDemande/ValidationFlowReview"
import { Next, Back } from "components/icons"
import { Row, Col } from "@bootstrap-styled/v4"
import api from "services/api"

import CancelLink from "components/common/CancelLink"
import NavigationButton from "components/common/NavigationButton"

const Spacer = styled.span`
  width: 20px;
  height: 1 px;
`
const CustomRow = styled(Row)`
  margin: 30px 70px 30px 70px !important;
  @media screen and (max-width: 1200px) {
    margin: 10px 0px 10px 0px !important;
  }
`

const ReviewForm = ({ className, ...props }) => {
  const { valideurs, form, values, title, bu, newComment, unitaryValidation } = useContext(
    DemandeCreationContext
  )
  const { profile } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  let history = useHistory()

  const handleCreateDemande = () => {
    setLoading(true)
    const data = {
      author: profile["@id"],
      form: `/api/forms/${form}`,
      businessUnit: bu,
      title: title,
      maillingList: "",
      status: "draft",
      responses: values.filter((item) => !!item),
      valideurs: valideurs.filter((item) => !!item),
      unitaryValidation: unitaryValidation,
    }
    if (newComment && newComment.content && newComment.content.length > 0) {
      data.newComment = newComment
    }

    api
      .post(POST_DEMANDES_ENDPOINT, data)
      .then((data) => {
        history.push(`/nouvelle-demande/validation/${data.id}`)
        setLoading(false)
      })
      .catch((e) => {
        if (e.code === 400) {
          alert(e.message)
        } else {
          alert("Une erreur est survenue lors de la création de votre demande.")
        }
        setLoading(false)
      })
  }

  const cancelButton = () => <CancelLink></CancelLink>

  const nextButton = () => (
    <NavigationButton onClick={handleCreateDemande} isActive isNext={true}>
      Suivant
      <Spacer />
      <Next />
    </NavigationButton>
  )

  const previousButton = () => (
    <NavigationButton isActive to="workflow">
      <Back />
      <Spacer />
      Précédent
    </NavigationButton>
  )

  if (loading) {
    return <LoaderOverlay />
  }

  return (
    <div className={className}>
      <PageTop getBackComponent={cancelButton} title={"Nouvelle demande"} />
      <ProgressionViewer
        next={nextButton}
        previous={previousButton}
        title="Récapitulatif de la demande"
        percent={95}
      ></ProgressionViewer>
      <FormToFill hideInputs={false} />
      <ValidationFlowReview />
      <CustomRow className="justify-content-end">
        <Col xs="auto p-0">{nextButton()}</Col>
      </CustomRow>
    </div>
  )
}

export default styled(ReviewForm)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
