import React, { useEffect, useState, useContext } from "react"

import styled from "styled-components"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

import ElementContainer from "components/form/element/ElementContainer"

import { GET_BUSINESS_UNITS_ENDPOINT } from "constants/endpoints"
import api from "services/api"
import UserContext from "contexts/UserContext"

const BusinessUnit = ({
  className,
  element,
  editable,
  onChange,
  value,
  readOnly,
  matchingResponse,
  ...props
}) => {
  const [selectValue, setValue] = useState(value && value.value ? value.value : "")
  const [bus, setBus] = useState([])
  const { profile } = useContext(UserContext)

  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    const initialisedValue = matchingResponse[0].value
    onChange({
      ...value,
      type: "text",
      value: initialisedValue,
      question: element["@id"],
    })
    setValue(initialisedValue)
    setValue(initialisedValue)
  }, [matchingResponse])

  useEffect(() => {
    api
      .get(GET_BUSINESS_UNITS_ENDPOINT)
      .then((data) => {
        if (data) {
          setBus(data["hydra:member"])

          const defaultValue = data["hydra:member"].find((item) => {
            return item.id === profile.bu.id
          })

          if (!value && editable) {
            if (defaultValue) {
              onChange({
                ...value,
                type: "text",
                value: defaultValue["@id"],
                question: element["@id"],
              })
              setValue(defaultValue["@id"])
            }
          }
        }
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [])

  const handleChange = (event) => {
    const selectedValue = event.target.value || ""

    if (selectedValue) {
      onChange({
        ...value,
        type: "text",
        value: selectedValue || "",
        question: element["@id"],
      })
    } else {
      onChange({
        ...value,
        type: "text",
        value: null,
        question: element["@id"],
      })
    }
    setValue(selectedValue)
  }

  return (
    <ElementContainer width={element.width}>
      <FormControl fullWidth={true} margin="none">
        <InputLabel
          htmlFor={`elment-${element.id}`}
          className={element.isMandatory && "mandatory-field"}
        >
          {element.title}
        </InputLabel>
        <Select
          value={selectValue}
          onChange={handleChange}
          inputProps={{
            name: `elment-${element.id}`,
            id: `elment-${element.id}`,
            className,
            ...(editable ? {} : { readOnly: true }),
          }}
        >
          <MenuItem value={null}>Choisissez une valeur</MenuItem>
          {bus.map((bu) => (
            <MenuItem value={bu["@id"]} key={bu.id}>
              {bu.label}
            </MenuItem>
          ))}
        </Select>
        {element.description && <FormHelperText>{element.description}</FormHelperText>}
      </FormControl>
    </ElementContainer>
  )
}

export default styled(BusinessUnit)`
  &.MuiInputBase-input {
    font-family: "Open Sans", sans-serif;
  }
`
