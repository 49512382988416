export default {
  background: "#F2F2F2",
  white: "#FFFFFF",
  black: "#000000",
  border: "#CCCCCC",
  greyLight: "#4A4A4A",
  grey: "#D1D1D1",
  greyBorder: "rgba(74,74,74,0.3)",
  primary: "#2656C8",
  primaryShadow: "rgba(38,86,200,0.3)",
  primaryDark: "#1842A7",
  primaryVeryDark: "#24445C",
  primaryVeryDarkOpacity: "rgba(36,68,92,0.08)",
  success: "#60B97F",
  toValid: "#FF0035",
  waitting: "#FD906A",
  doing: "#FDE06A",
  green: "#60B97F",
  red: "#FF0035",
  pink: "#E83B5F",
}
