import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"

import api from "services/api"
import Colors from "theme/Colors"
import Profils from "components/icons/Profils"
import BackUpProfils from "components/icons/BackupProfils"
import Success from "components/icons/Success"
import LoaderOverlay from "components/common/LoaderOverlay"
import Waitting from "components/icons/WaittingSvg"

import { BASE_URL } from "constants/endpoints"
import moment from "moment"

const CustomRow = styled(Row)`
  min-height: 56px;
  flex-direction: column;
  height: 100%;
  border: 1px solid
    ${(props) =>
      props.waitting ? Colors.waitting : props.validated ? Colors.success : Colors.primaryVeryDark};
  border-radius: 8px;
  overflow: hidden;
`

const BackupRow = styled(Row)`
font-size: 12px;
color: red;
padding-bottom:10px;
  `
const NameContainer = styled.span`
  width: 100%;
  text-align: center;
  padding: 8px 0;
  background-color: ${(props) =>
    props.waitting ? Colors.waitting : props.validated ? Colors.success : Colors.primaryVeryDark};
  color: ${Colors.white};
  font-size: 13px;
  font-weight: 600;
`

const Firstname = styled.span`
  text-transform: capitalize;
`

const SuccessPicto = styled(Success)`
  font-size: 50px;
  flex: 1;
`
const WaittingPicto = styled(Waitting)`
  font-size: 50px;
  flex: 1;
`

const ProfilsPicto = styled(Profils)`
  font-size: 60px;
  flex: 1;
`

const BackUpProfilsPicto = styled(BackUpProfils)`
  font-size: 60px;
  flex: 1;
`

const ValidatorItem = ({ className, validator, demandeStatus, currentValidator,initialValidator, ...props }) => {
  const [profile, setProfile] = useState(null)
  useEffect(() => {
    let validatorUrl = null
    if (!validator) {
      return
    } else if (!validator.profile) {
      return
    } else if (typeof validator.profile == "string") {
      validatorUrl = validator.profile
    } else {
      validatorUrl = validator.profile["@id"]
    }
    api
      .get(`${BASE_URL}${validatorUrl}`)
      .then((data) => {
        setProfile(data)
      })
      .catch((e) => alert("erreur : " + e.message))
  }, [validator, validator.profile])

  const waitting = demandeStatus === "waitting"

  if (!profile) {
    return <LoaderOverlay />
  }

  const { validated, validationDate } = validator
  return (
    <Col className={className} xs={12} lg={4} sm={6} xl={2}>
      <CustomRow
        noGutters={true}
        className="align-items-center justify-content-center"
        validated={validated}
        waitting={waitting && currentValidator["@id"] === validator.profile["@id"]}
      >
        <NameContainer
          validated={validated}
          waitting={waitting && currentValidator["@id"] === validator.profile["@id"]}
        >
          {validator.label}
        </NameContainer>

        {waitting && currentValidator["@id"] === validator.profile["@id"] ? (
          <WaittingPicto />
        ) : (
          <>
            {validated ? (
              <SuccessPicto primary={Colors.success} />
            ) : (
              <>
              {initialValidator && currentValidator["@id"] !== initialValidator["@id"] && currentValidator["@id"] == validator.profile["@id"]  ? (
                 <> 
                <BackUpProfilsPicto primary={Colors.doing} />
                <BackupRow>
                <Firstname>{initialValidator.firstname.toLowerCase()}</Firstname> {initialValidator.lastname}
                </BackupRow>
                </>
                ) : (
                <ProfilsPicto primary={Colors.primaryVeryDark} />
                
                )}

              </>
            )}
          </>
        )}
        <NameContainer
          validated={validated}
          waitting={waitting && currentValidator["@id"] === validator.profile["@id"]}
        >
          {validationDate && (
            <>
              {moment(validationDate).format("DD/MM/Y")}
              <br />
            </>
          )}
          <Firstname>{profile.firstname.toLowerCase()}</Firstname> {profile.lastname}
        </NameContainer>
      </CustomRow>
    </Col>
  )
}

export default styled(ValidatorItem)`
  padding: 10px !important;

  &::before {
    content: "";
    float: left;
    padding-top: calc(100% - 30px);
  }
`
