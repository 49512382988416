import React, { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from "draftjs-to-html"
import { Row, Col } from "@bootstrap-styled/v4"
import { OkButton } from "components/adminCreateForm/fieldsParameters/Parameters"

const WrapperStyleProperties = {
  margin: "5px",
  border: "1px solid",
  borderColor: "#D1D1D1",
}

const EditorStyleProperties = {
  padding: "0 10px",
}

const ToolbarStyleProperties = {
  borderBottom: "1px solid",
  color: "#D1D1D1",
}

const CommentParameters = ({ saveParameters, fieldParameters, label, ...props }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    if (fieldParameters && fieldParameters.content && fieldParameters.content !== `<p></p>`) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(fieldParameters.content))
        )
      )
    }
  }, [])

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const validateChangement = () => {
    saveParameters({
      type: "comment_question",
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    })
  }

  return (
    <>
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        wrapperStyle={WrapperStyleProperties}
        toolbarStyle={ToolbarStyleProperties}
        editorStyle={EditorStyleProperties}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "emoji",
            "history",
          ],
        }}
        onEditorStateChange={onEditorStateChange}
        editorState={editorState}
      />
      <Row className={"justify-content-center"}>
        <OkButton onClick={validateChangement}>Valider</OkButton>
      </Row>
    </>
  )
}

export default CommentParameters
