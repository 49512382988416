import React, { useState, useContext, useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import Profile from "./Profile"
import NewDemandeRouter from "./NewDemandeRouter"
import DemandeListRouter from "./DemandeListRouter"
import DemandeArchivedRouter from "./DemandeArchivedRouter"
import DemandeList from "./DemandeList"
import ChangeUser from "components/adminLoginMatricule/ChangeUser"
import Layout from "components/layout/Layout"
import Background from "components/common/Background"
import BackupPlanRouter from './BackupPlanRouter'


import AuthenticationContext from "contexts/AuthenticationContext"
import UserContext from "contexts/UserContext"

import api from "services/api"

import { GET_PROFILE_ENDPOINT } from "constants/endpoints"
import Parameters from "./Parameters"
import CreateFormRouter from "./CreateFormRouter"
import FormList from "./FormList"
import ViewFormAdmin from "components/form/ViewFormAdmin"
import EditFormRouter from "routes/authenticated/EditFormRouter.js"
import LoaderOverlay from "components/common/LoaderOverlay"

const Authenticated = () => {
  const { token, setToken } = useContext(AuthenticationContext)
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    const content = token.split(".")
    const infos = JSON.parse(atob(content[1]))
    api
      .get(GET_PROFILE_ENDPOINT(infos.profile))
      .then((data) => {
        setProfile(data)
      })
      .catch((e) => setToken(null))
  }, [token, setToken])

  if (!token) {
    return <LoaderOverlay />
  }

  return (
    <Background fluid={true}>
      <UserContext.Provider value={{ profile }}>
        <Layout>
          <Switch>
            <Route exact path="/formulaires/:id" component={ViewFormAdmin} />
            <Route exact path="/list" component={DemandeList} />
            <Route path="/demandes-archivees" component={DemandeArchivedRouter} />
            <Route exact path="/profile" component={Profile} />
            <Route path="/demandes" component={DemandeListRouter} />
            <Route path="/nouvelle-demande" component={NewDemandeRouter} />
            <Route path="/parametres" component={Parameters} />
            <Route path="/edition/:id" component={EditFormRouter} />
            <Route path="/nouveau-formulaire" component={CreateFormRouter} />
            <Route path="/formulaires" component={FormList} />
            <Route path="/changement-utilisateur" component={ChangeUser} />
            <Route path="/remplacements" component={BackupPlanRouter} />
            <Redirect to="/demandes" />
          </Switch>
        </Layout>
      </UserContext.Provider>
    </Background>
  )
}

export default Authenticated
