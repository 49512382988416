import React from "react"

const SvgShow = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <path
      d="M15 8C9.607 8 5 14.667 5 14.667s4.603 6.666 10 6.666c5.393 0 10-6.666 10-6.666S20.397 8 15 8zm0 12.5c-4.09 0-7.822-4.367-8.96-5.833 1.139-1.468 4.873-5.834 8.96-5.834 4.09 0 7.823 4.367 8.96 5.834C22.822 16.134 19.088 20.5 15 20.5zm0-9.167A3.333 3.333 0 1015 18a3.333 3.333 0 000-6.667zm0 5.834a2.503 2.503 0 01-2.5-2.5c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"
      fill="#4A4A4A"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgShow
