import React, { useState } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import AutoComplete from "components/form/AutoComplete"
import SquareButton from "components/common/SquareButton"
import Colors from "theme/Colors"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"

import { Row, Col } from "@bootstrap-styled/v4"
import Plus from "components/icons/Plus"

const CustomSelect = styled(Select)`
  @media screen and (max-width: 1200px) {
    margin: 0 0 10px 0px !important;
  }
`

const CustomFormControl = styled(FormControl)`
  min-width: 200px !important;
  width: 100%;
`

const ButtonContainer = styled.div`
  padding: 15px 15px;
  margin: 10px 15px 0 20px;
  @media screen and (max-width: 1200px) {
    margin: 10px 15px 0 0;
  }
`

const PlusIcon = styled(Plus)`
  font-size: 20px;
  @media screen and (max-width: 1200px) {
    font-size: 15px;
    padding: 2px;
  }
`

const CustomInputLabel = styled(InputLabel)`
  background-color: ${Colors.white};
`

const AddDocsButtonContainer = styled.label`
  width: 215px;
  margin-top: 10px;
`

const AddDocsButton = styled(SquareButton)`
  background-color: ${Colors.primaryVeryDark};
  font-size: 13px;
  font-weight: 600;
  min-height: 56px;
  padding: 5px 20px;
  min-width: 200px;
  @media screen and (max-width: 1200px) {
    text-align: center;
    padding: 10px;
  }
`

const AddValidatorButton = styled(SquareButton)`
  min-height: 56px;
  max-height: 56px;
  max-width: 200px;
  min-width: 140px;

  @media screen and (max-width: 1200px) {
    margin: 10px 0 0 0 !important;
  }
`

const Spacer = styled.span`
  height: 1px;
  width: 5px;
`

const Input = styled.input`
 
  height: 56px;
  padding: 10px 20px;
  font-family: Helvetica, sans - serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 100%;
   @media screen and (max-width: 1200px) {
   
    margin: 0 0 10px 0px !important;
  }
}
`
const CustomAutoComplete = styled(AutoComplete)`
  margin-top: 10px;
  width: 100%;
  @media screen and (max-width: 1200px) {
    margin: 10px 0 10px 0px !important;
  }
`

const AddAdmin = ({ className, onAddAdmin, ...props }) => {
  const [profile, setProfile] = useState(null)
  const [currentlyAdding, setCurrentlyAdding] = useState(false)

  const handleProfileChange = (selectedProfile) => {
    setProfile(selectedProfile.id)
    //setProfile({ ...profile })
  }

  const handleEnableAdding = () => {
    setCurrentlyAdding(true)
  }

  const handleAddAdmin = () => {
    onAddAdmin(profile)
    setCurrentlyAdding(false)
  }

  return (
    <>
      {currentlyAdding ? (
        <Col xs="6">
          <Container className={className}>
            <Row className="w-100 justify-content-between">
              <Col xs="12" xl="auto">
                <CustomAutoComplete onProfileChange={handleProfileChange} />
              </Col>
              {profile && (
                <Col xs="12" xl="auto">
                  <AddValidatorButton noGutters={true} onClick={handleAddAdmin}>
                    Ajouter
                  </AddValidatorButton>
                </Col>
              )}
            </Row>
          </Container>
        </Col>
      ) : (
        <ButtonContainer onClick={handleEnableAdding}>
          <AddDocsButtonContainer>
            <AddDocsButton>
              <Row className="justify-content-center align-items-center">
                <Col xs="auto">
                  <PlusIcon />
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center">
                <Col xs="auto">
                  <Spacer />
                </Col>
                <Col xs="auto">Ajouter un profil</Col>
              </Row>
            </AddDocsButton>
          </AddDocsButtonContainer>
        </ButtonContainer>
      )}
    </>
  )
}

export default styled(AddAdmin)`
  padding: 20px;
  margin: 20px;
  border: 1px solid #2656c8;
`
