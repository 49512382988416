import React, { useEffect } from "react"
import { Link } from "react-router-dom"

import styled from "styled-components"
import Colors from "theme/Colors"
import Container from "components/common/Container"
import { Row, Col } from "@bootstrap-styled/v4"

import Status from "components/backupList/Status"
import LoaderOverlay from "components/common/LoaderOverlay"
//import CustomTip from "components/common/ToolTip"

const moment = require("moment")

const NewBackupPlan = ({
  className,
  backupPlan,
  loading,
  profile,
  ...props
}) => {
 // useEffect(() => CustomTip.rebuild(), [backups])
  const currentPathname = window.location.pathname

  if (loading) {
    return <LoaderOverlay />
  }

  return (
    <Container>
      <Row className="justify-content-start align-items-center">
          <Col xs="12" xl="4">
            Nouveau plan de backup
          </Col>
      </Row>
    </Container>
  )
}

export default NewBackupPlan
