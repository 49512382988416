import React, { useContext, useEffect, useState } from "react"

import styled from "styled-components"
import { Row, Col } from "@bootstrap-styled/v4"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import LoaderOverlay from "components/common/LoaderOverlay"

import api from "services/api"
import Profils from "components/icons/Profils"
import UserContext from "contexts/UserContext"

import { GET_PROFILES_BY_POSTE, BASE_URL } from "constants/endpoints"

const Firstname = styled.span`
  text-transform: capitalize;
`

const CustomSelect = styled(Select)`
  width: 100%;
`

const ProfilsPicto = styled(Profils)`
  font-size: 35px;
  margin-right: 20px;
`

const Poste = ({ className, validator, onChange, bu, onValidatorChange, ...props }) => {
  const { profile } = useContext(UserContext)
  const [profileList, setProfileList] = useState(null)
  const [selectValue, setValue] = useState("")
  const profileId = profile ? profile.id : null

  useEffect(() => {
    let mounted = true
    if (!profile) {
      return
    }

    api
      .get(`${BASE_URL}${validator.step}`)
      .then((data) => {
        const id = data.poste.split("/")
        return parseInt(id[id.length - 1])
      })
      .then((posteId) => {
        return api.get(GET_PROFILES_BY_POSTE(posteId))
      })
      .then((data) => {
        if (data) {
          const validatorRelatedBu = data["hydra:member"].find((item) => {
            return item.bu["@id"] === bu
          })
          if (mounted) {
            setProfileList(data["hydra:member"])
          }
          if (validatorRelatedBu) {
            validator.profile = validatorRelatedBu["@id"]
            onChange({
              ...validator,
              profile: validatorRelatedBu["@id"],
            })
            if (mounted) {
              setValue(validator.profile)
            }
          }
          if (mounted) {
            if (!validator) {
              setValue("")
            } else if (!validator.profile) {
              setValue("")
            } else if (typeof validator.profile === "string") {
              setValue(validator.profile)
            } else if (typeof validator.profile === "object") {
              if (validator.profile["@id"]) {
                setValue(validator.profile["@id"])
              } else {
                setValue("")
              }
            } else {
              setValue("")
            }
          }
        }
      })
      .catch((e) => {})
    return () => (mounted = false)
  }, [profile, profileId, validator.step])

  const handleChange = (event) => {
    const selectedValue = event.target.value || null || selectValue
    if (selectedValue) {
      onChange({
        ...validator,
        profile: selectedValue,
      })
    } else {
      onChange({
        ...validator,
        profile: null,
      })
    }
    setValue(selectedValue)
  }

  if (!profileList) {
    return <LoaderOverlay />
  }

  if (profileList.length === 1) {
    validator.type = "read_only"
    validator.profile = profileList[0]["@id"]
    onChange({
      ...validator,
    })
    // onValidatorChange(profileList[0]["@id"], validator)
  }

  if (validator.validated) {
    onChange({
      ...validator,
      type: "read_only",
    })
  }

  return (
    <div className={className}>
      <Row noGutters={true} className="align-items-center">
        <FormControl fullWidth={true} margin="none" variant="outlined">
          <Row noGutters={true} className="align-items-center">
            <ProfilsPicto />
            <Col>
              <CustomSelect autoWidth={false} value={selectValue} onChange={handleChange}>
                <MenuItem value={null}>Choisissez une valeur</MenuItem>
                {profileList.map((choice) => (
                  <MenuItem value={choice["@id"]} key={choice.id}>
                    <span>
                      <Firstname>{choice.firstname.toLowerCase()}</Firstname> {choice.lastname}
                    </span>
                  </MenuItem>
                ))}
              </CustomSelect>
            </Col>
          </Row>
        </FormControl>
      </Row>
    </div>
  )
}

export default styled(Poste)`
  text-align: left;
  min-width: 250px;
`
