import React, { useContext } from "react"

import styled from "styled-components"
import Container from "components/common/Container"
import UserContext from "contexts/UserContext"
import Colors from "theme/Colors"
import Button from "components/common/Button"
import { useHistory } from "react-router-dom"
import { Row, Col } from "@bootstrap-styled/v4"

import { Next } from "components/icons"

const NextPicto = styled(Next)`
  font-size: 18px;
`

const Text = styled.p`
  color: ${(props) => props.color};
  margin: 0;
`

const Number = styled.p`
  color: ${(props) => props.color};
  font-size: 23px;
  margin: 0;
`

const Title = styled(Number)`
  font-weight: bold;
`

const ViewDemandeButton = styled(Button)`
  background-color: ${(props) => props.color};
  padding-left: 20px;
`

const Spacer = styled.div`
  height: 1;
  width: 20px;
`

const LinkedDemande = ({ className, color, demande, title, ...props }) => {
  const { profile } = useContext(UserContext)
  let history = useHistory()
  const handleClick = () => history.push(`/demandes/${demande.id}`)
  return (
    <Container className={className} {...props}>
      <Row>
        <Col>
          <Row>
            <Col xs="auto">
              <Title color={color}>{title}</Title>
            </Col>
            <Col xs="auto">
              <Number color={color}>{demande.number}</Number>
            </Col>
          </Row>
        </Col>

        <Col xs="auto">
          {demande.status == "draft" && demande.author.id != profile.id ? (
            <Text color={color}>
              {demande.author.firstname} {demande.author.lastname} est en train de créer la demande
            </Text>
          ) : (
            <ViewDemandeButton noGutters={true} onClick={handleClick} color={color}>
              Voir la demande
              <Spacer /> <NextPicto />
            </ViewDemandeButton>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default styled(LinkedDemande)`
  padding: 20px 30px;
  margin: 30px 70px 0;
  border: 1px solid ${(props) => props.color};
`
