import React from "react"

const SvgFormListIcon = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 55 49" {...props}>
    <g transform="translate(-24 -475)" fill="#FFF" fillRule="nonzero">
      <g transform="translate(37 488)">
        <path d="M24.348 19.135H7.212a1.279 1.279 0 110-2.558h17.136a1.279 1.279 0 010 2.558zM24.348 11.108H7.212a1.279 1.279 0 110-2.557h17.136a1.279 1.279 0 010 2.557zM24.348 3.082H7.212a1.279 1.279 0 110-2.557h17.136a1.279 1.279 0 010 2.557z" />
        <circle cx={1.717} cy={1.902} r={1.717} />
        <circle cx={1.717} cy={9.83} r={1.717} />
        <circle cx={1.717} cy={17.757} r={1.717} />
      </g>
    </g>
  </svg>
)

export default SvgFormListIcon
