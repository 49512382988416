import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"

import PageTop from "components/layout/PageTop"
import EditBackupPlan from "components/backupPlan/EditBackupPlan"
import NewBackupPlan from "components/backupPlan/NewBackupPlan"


import api from "services/api"
import {
  GET_BACKUP_PLAN_ENDPOINT , BASE_URL , GET_CATEGORIES_ENDPOINT
} from "constants/endpoints"
import UserContext from "contexts/UserContext"

const BackupPlan = ({ className }) => {

  const [backupPlan, setBackupPlan] = useState([])
  const [categoriesLabel, setCategoriesLabel] = useState(null)
  const [forms, setForms] = useState([])
  const [loading, setLoading] = useState(true)
  const { profile } = useContext(UserContext)

  const getBackupPlan = (id) => {
    return api.get(GET_BACKUP_PLAN_ENDPOINT(id)).then((data) => {
      if (!data) {
        return
      }
      setBackupPlan(data)
    })
  }

  const getCategoryLabel = (category) => {
    if (categoriesLabel.length > 0) {
      const found = categoriesLabel.find((element) => element.id === category)
      return found.label
    }
    return null
  }

  const loadCategories = async () => {
  
  const data =  await api
    .get(GET_CATEGORIES_ENDPOINT)
    .then((categories) => {
        const newCategoriesLabel = []
        categories["hydra:member"].map((data) => {
          newCategoriesLabel.push({ id: data["@id"], label: data.label })
        })
        return newCategoriesLabel;        
    })
    .catch((e) => {
      alert(e.message)
    });
    setCategoriesLabel(data);
  }
  
  useEffect(() => {
    loadCategories();
  }, [profile]);


  useEffect(() => {
    if (!profile || !categoriesLabel) {
      return
    }
    api
    .get(`${BASE_URL}/api/forms?status=published&isBackupAllowed=1&order[category.label]=asc&pagination=false`)
    .then((forms) => {
      if (forms) {
        const allowedForms = []
        forms["hydra:member"].map((data) => {
          allowedForms.push({ id: data["@id"], name: data.title , category: getCategoryLabel(data.category) })
        })

        const groupForms = allowedForms.reduce((groupForms, item) => ({
          ...groupForms,
          [item.category]: [...(groupForms[item.category] || []), item]
        }), {});

        setForms(groupForms);
      }
    })
    .catch((e) => alert(e.message))


    if(!profile.backupPlan ) {
      api
      .post(`${BASE_URL}/api/backup_plans`, {profile : profile["@id"] , isFormRestriction: true, status: "inactive" },{} )
      .then((response) => {
        setBackupPlan(response);   
        setLoading(false) ;
      })
    } else {

          getBackupPlan( profile.backupPlan.id ).then(
            setLoading(false)
          );

    }

  },
  
  [ profile , categoriesLabel])


  const handleIsFormRestrictionChange = () => {

    api
    .put(`${BASE_URL}/api/backup_plans/${backupPlan.id}`, {isFormRestriction : !backupPlan.isFormRestriction , status: "scheduled"} )
    .then((response) => {
      setBackupPlan(response);   
    })

  }

  const handleEndedAtChange = (date) => {

    api
    .put(`${BASE_URL}/api/backup_plans/${backupPlan.id}`, {endedAt : date , status: "scheduled"} )
    .then((response) => {
      setBackupPlan(response);   
    }).catch((e) => {
      alert(e.message)
    })

  }

  const handleIsEnabledChange = () => {

    api
    .put(`${BASE_URL}/api/backup_plans/${backupPlan.id}`, {isEnabled : !backupPlan.isEnabled , status: "scheduled"} )
    .then((response) => {
      setBackupPlan(response);   
    }).catch((e) => {
      alert(e.message)
    })

  }

  
  const handleStartedAtChange = (date) => {
    api
    .put(`${BASE_URL}/api/backup_plans/${backupPlan.id}`, {startedAt : date , status: "scheduled"})
    .then((response) => {
      setBackupPlan(response);   
    }).catch((e) => {
      alert(e.message)
    })
  }

  const handleGlobalBackupChange = (backupChange , form) => {

    api
    .put(`${BASE_URL}/api/backup_plans/${backupPlan.id}`, {backup : backupChange['@id'] , status: "scheduled"})
    .then((response) => {
      setBackupPlan(response);   
    })

  }

  const handleDeleteBackup = (id) => { 

    api
    .delete(`${BASE_URL}/api/backups/${id}`).then(() => {

      api
      .put(`${BASE_URL}/api/backup_plans/${backupPlan.id}`, {status: "scheduled"})
      .then((response) => {
        setBackupPlan(response);   
      })
    }
    )

  }

  const handleBackupChange = (backupChange , form) => {
  
        // if backup is already defined for this form => update profile
        const backupExist = backupPlan.backups.find(backup => backup.form == form.id);
      
        if (backupExist) {
          const formData = {profile:backupChange['@id']}


          api
          .put(`${BASE_URL}/api/backups/${backupExist.id}`, formData).then(() => {

            api
            .put(`${BASE_URL}/api/backup_plans/${backupPlan.id}`, {status: "scheduled"})
            .then((response) => {
              setBackupPlan(response);   
            })
          }
          )
        } else {

          const formData = {profile:backupChange['@id'] ,backupPlan: backupPlan['@id'], form: form.id}


          api
          .post(`${BASE_URL}/api/backups`, formData, {}).then(() => {

            api
            .put(`${BASE_URL}/api/backup_plans/${backupPlan.id}`, {status: "scheduled"})
            .then((response) => {
              setBackupPlan(response);   
            })

          }

          )

        }
 
  }


  return (
    <div className={className}>

      <PageTop title={"Gestion des remplaçants"} getBackComponent={() => null} />
   
      <div>
      {   backupPlan ? (
              <EditBackupPlan
                handleBackupChange={handleBackupChange}
                handleDeleteBackup={handleDeleteBackup}
                handleStartedAtChange={handleStartedAtChange}
                handleEndedAtChange={handleEndedAtChange}
                handleIsFormRestrictionChange={handleIsFormRestrictionChange}
                handleGlobalBackupChange={handleGlobalBackupChange}
                handleIsEnabledChange={handleIsEnabledChange}
                backupPlan={backupPlan}
                loading={loading}
                profile={profile}
                forms = {forms}
              /> ) : 
                    
            <NewBackupPlan
            loading={loading}
            profile={profile}
            forms = {forms}
          />
      }

      </div>
    </div>
  )
}

export default styled(BackupPlan)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  @media screen and (max-width: 1200px) {
    text-align: center;
  }
`
