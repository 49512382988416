import React, { useState, useEffect } from "react"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

const IsMandatory = ({ onMandatoryChange, fieldParameters, isMandatory, ...props }) => {
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isMandatory}
            onChange={onMandatoryChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Obligatoire ?"
      />
    </>
  )
}

export default IsMandatory
