import React, { useState, useEffect } from "react"

import FormTextField from "components/form/common/SingleLineTextField"
import styled from "styled-components"
import FormControl from "@material-ui/core/FormControl"
import StyledRadio from "components/common/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import ElementContainer from "components/form/element/ElementContainer"

import Colors from "theme/Colors"

const SubQuestion = ({
  className,
  element,
  editable,
  onChange,
  value,
  matchingResponse,
  ...props
}) => {
  const [choiceValue, setValue] = useState(value ? value.checked : false)
  const [textValue, setTextValue] = useState(value ? value.value : "")

  useEffect(() => {
    if (!matchingResponse || !matchingResponse[0]) {
      return
    }
    const matchedResponse = matchingResponse[0]

    onChange({
      ...value,
      type: "boolean_and_text",
      checked: matchedResponse.checked,
      value: matchedResponse.value,
      question: element["@id"],
    })
    setTextValue(matchedResponse.value)
    setValue(matchedResponse.checked)
  }, [matchingResponse])

  useEffect(() => {
    if (!value) {
      onChange({
        ...value,
        type: "boolean_and_text",
        checked: false,
        question: element["@id"],
        value: "",
      })
      setValue(false)
    }
  }, [element, onChange, value])

  const handleChange = (event) => {
    if (!editable) {
      return
    }

    const checkBoxValue = event.target.value === "y"
    onChange({
      ...value,
      type: "boolean_and_text",
      checked: checkBoxValue,
      question: element["@id"],
      value: checkBoxValue ? textValue : "",
    })
    setValue(checkBoxValue)
  }

  const handleTextChange = (event) => {
    const text = event.target.value
    onChange({
      ...value,
      type: "boolean_and_text",
      checked: choiceValue,
      question: element["@id"],
      value: text,
    })
    setTextValue(text)
  }

  return (
    <ElementContainer width={element.width}>
      <FormControl fullWidth={true} margin="none">
        <FormLabel component="legend">{element.title}</FormLabel>

        <RadioGroup
          row
          name={`element-subquestion-${element.id}`}
          value={choiceValue ? "y" : "n"}
          onChange={handleChange}
        >
          <FormControlLabel value={"y"} control={<StyledRadio />} label="Oui" />
          <FormControlLabel value={"n"} control={<StyledRadio />} label="Non" />
        </RadioGroup>
      </FormControl>
      {choiceValue && (
        <FormControl fullWidth={true} margin="none">
          <FormTextField
            id={`element-subquestion-subfield-${element.id}`}
            label={element.subFieldTitle}
            InputProps={{
              className,
              ...(editable ? {} : { readOnly: true }),
            }}
            value={textValue}
            onChange={handleTextChange}
            InputLabelProps={
              element.isMandatory && {
                style: { fontWeight: "bold" },
              }
            }
          />
        </FormControl>
      )}
    </ElementContainer>
  )
}

export default styled(SubQuestion)`
  background-color: transparent;
  min-height: 32px;
  line-height: 0px;
  width: 100%;
  color: ${Colors.black};
  font-size: 13px;
  &.MuiInputBase-root {
    font-family: "Open Sans";
  }
`
