import React, { Fragment } from "react"
import { ScaleLoader } from "react-spinners"

const Loader = ({ className, ...props }) => {
  return (
    <Fragment>
      <ScaleLoader className={className} {...props} />
    </Fragment>
  )
}

export default Loader
