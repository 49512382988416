import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"

import PageTop from "components/layout/PageTop"
import Container from "components/common/Container"
import SearchForm from "components/form/SearchForm.js"
import Pager from "components/common/Pager"
import api from "services/api"
import {
  BASE_URL,
  POST_CLONE_FORM_ENPOINT,
  ORDER_FILTER_TITLE_PARAM,
  ORDER_FILTER_EMBEDDED_CATEGORY_LABEL,
} from "constants/endpoints"
import { ACTIVE } from "constants/demande"
import DeleteDone from "components/demandesList/DeleteDoneAlert"
import UserContext from "contexts/UserContext"
import DisplayFormList from "components/form/DisplayFormList"

const CustomContainer = styled(Container)`
  padding: 10px 0 10px 0;
  margin: 0px 70px 30px !important;

  @media screen and (max-width: 1200px) {
    margin: 0;
    max-width: 100%;
    margin: 0;
    text-align: center;
    overflow: scroll;
  }
`

const FormList = ({ className, ...props }) => {
  const [searchValues, setSearchValues] = useState({})
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(1)
  const [deletedStatus, setDeletedStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const { profile } = useContext(UserContext)

  const [formList, setFormList] = useState([])

  const refreshFormList = () => {
    let params = `?page=${page}&itemsPerPage=${itemsPerPage}&${ORDER_FILTER_EMBEDDED_CATEGORY_LABEL}&${ORDER_FILTER_TITLE_PARAM}`
    if (searchValues.status) {
      params += "&status=" + searchValues.status
    }
    if (searchValues.category) {
      params += `&category=${searchValues.category}`
    }
    if (searchValues.title) {
      params += `&title=` + searchValues.title
    }
    return api
      .get(`${BASE_URL}/api/forms` + params)
      .then((data) => {
        if (data) {
          setFormList(data["hydra:member"])
          setTotalItems(data["hydra:totalItems"])
        }
      })
      .catch((e) => alert(e.message))
  }

  const updateOneForm = (indexInTheState, formId) => {
    api.get(`${BASE_URL}/api/forms/` + formId).then((data) => {
      // setFormList(data["hydra:member"])
      // setTotalItems(data["hydra:totalItems"])
      formList[indexInTheState] = data
      setFormList([...formList])
    })
  }

  const handleDuplicate = (id) => {
    setLoading(true)
    api
      .post(POST_CLONE_FORM_ENPOINT, { formToClone: id })
      .then(() => {
        refreshFormList()
          .then(() => setLoading(false))
          .catch((e) => alert(e.message))
      })
      .catch((e) => {
        setLoading(false)
        alert(e.message)
      })
  }

  useEffect(() => {
    refreshFormList().then(() => setLoading(false))
  }, [searchValues.status, searchValues.title, searchValues.category, page, itemsPerPage])

  const handleSearchValuesChange = (newValues) => {
    setSearchValues(newValues)
  }

  return (
    <div className={className}>
      <PageTop title={"Tous les formulaires"} getBackComponent={() => null} />
      <SearchForm
        type={ACTIVE}
        searchValues={searchValues}
        setSearchValues={handleSearchValuesChange}
      />
      <CustomContainer>
        {deletedStatus && (
          <DeleteDone deletedStatus={deletedStatus} handleDeletedStatus={setDeletedStatus} />
        )}
        <DisplayFormList
          forms={formList}
          loading={loading}
          profile={profile}
          updateOneForm={updateOneForm}
          handleDuplicate={handleDuplicate}
        />
        <Pager
          page={page}
          itemsPerPage={itemsPerPage}
          onPageChange={setPage}
          onItemsPerPageChange={setItemsPerPage}
          totalItems={totalItems}
        />
      </CustomContainer>
    </div>
  )
}

export default styled(FormList)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  @media screen and (max-width: 1200px) {
    text-align: center;
  }
`
